import React, { useState, useEffect } from "react";
// import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import logoImage from "../../assets/img/ltc_logo.png";
import { useHistory, Redirect } from "react-router-dom";
import {
  phoneFormatter,
  setPhoneNo,
  medFlowInLocalStorage,
  personalizationLocalStorage,
  loggedInUser,
} from "utils";
import { Auth } from "aws-amplify";
import API from "api";
import { userCompanyID } from "utils";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import SweetAlert from "react-bootstrap-sweetalert";
import { NEW_PERSONALIZE } from "constant";
import { PERSONALIZATION } from "constant";

const Login = () => {
  const [phone, setPhone] = useState("+1");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  let history = useHistory();
  let url = window.location.href;

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        redirectToAdmin();
      }
    };
    getUSer();
  }, []);

  const handleSubmit = async () => {
    if (!phone) {
      setPhoneError("please enter the phone");
      return;
    }
    if (!password) {
      setPasswordError("please enter the password");
      return;
    }

    // const attributePayload = {
    //   email: "adminsafecamp@yopmail.com",
    //   preferred_username: "admin",
    //   phone_number: "+17777777777",
    //   "custom:role": "Admins",
    //   "custom:autoConfirm": "true",
    //   "custom:firstName": "Super",
    //   "custom:lastName": "Admin",
    //   "custom:note": "System",
    //   // "custom:labID": "",
    // };

    // const signUp = await Auth.signUp({
    //   username: "+17777777777",
    //   password: "Test1234!",
    //   attributes: attributePayload,
    // });

    // console.log("signUpsignUp", signUp);

    try {
      setDisableBtn(true);
      const user = await Auth.signIn(phone, password);
      const data = await API.getEmployeeClients(phone);
      const labID = user.attributes["custom:labID"] || null;

      if (labID) {
        userCompanyID.save(user.attributes["custom:labID"]);
      }
      medFlowInLocalStorage.save();
      loggedInUser.save({
        name: `${user.attributes["custom:firstName"]} ${user.attributes["custom:lastName"]}`,
      });
      personalizationLocalStorage.save(
        data ? data.personalization : JSON.stringify(PERSONALIZATION)
      );
      redirectToAdmin();
    } catch (error) {
      setDisableBtn(false);
      console.log("error", error);
      setPhoneError(error.message);
    }
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
    else window.location.reload();
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();
  return (
    <div className="loginWrapper">
      <div className="login-left-side">
        <div className="text-center">
          {<img src={logoImage} alt="logo" className="logo-width" />}
        </div>
      </div>
      <div className="login-right-side">
        <h3 className="mt-0">Login</h3>
        <p className="tag-line">Sign in to your account</p>
        <div className="form-group">
          <PhoneNoInput handleChange={(e) => setPhone(e)} value={phone} />
        </div>
        <div className="input-group group-wrapper mt-3">
          <span className="input-group-text">
            <i className="fa fa-lock" />{" "}
          </span>
          <input
            className="form-control"
            placeholder="Enter your password"
            minLength={8}
            value={password}
            type="password"
            onKeyDown={handleKeyDownEvent}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-right w-100">
          <button
            type="submit"
            disabled={disableBtn}
            className="text-uppercase w-100 btn signin-btn"
            onClick={handleSubmit}
          >
            Sign In
          </button>
        </div>
        {phoneError && (
          <SweetAlert
            show={phoneError ? true : false}
            error
            title="Error"
            onConfirm={() => setPhoneError("")}
          >
            {phoneError}
          </SweetAlert>
        )}
      </div>
    </div>
  );
};

export default Login;
