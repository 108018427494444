import Badge from "components/Badge";
import React, { useEffect, useState } from "react";
import { removeWhiteSpaces } from "../../utils.js";

const ProcedureCodeInput = (props) => {
  const { items, label, maxLength, onChangeList, itemKey } = props;
  const [list, setList] = useState(items || []);
  const [value, setValue] = useState("");

  useEffect(() => {
    setList(items);
  }, [items]);

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter" && event.target.value) {
      const val = event.target.value;
      const index = list.indexOf(val);
      if (index !== -1 || list.length >= (maxLength || 1)) return;
      const newList = [...list, removeWhiteSpaces(val)];
      setList(newList);
      onChangeList(newList, itemKey);
      setValue("");
    }
  };

  const handleItemClick = (item) => {
    const index = list.indexOf(item);
    if (index !== -1) {
      const newList = list;
      newList.splice(index, 1);
      onChangeList(newList, itemKey);
      setList([...newList]);
    }
  };

  return (
    <>
      <div>
        <label className="modalLineHeaders">{label}:</label>
        <input
          value={value}
          className="modalInput"
          maxLength={9}
          placeholder={label}
          style={{
            width: "100%",
          }}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDownEvent}
        />
      </div>
      <div className="d-flex mt-4 align-items-center gap-2 ">
        {list.map((item) => (
          <Badge label={item} handleClose={handleItemClick}></Badge>
        ))}
      </div>
    </>
  );
};

export default ProcedureCodeInput;
