export const NEW_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "locationName",
    title: "Facility",
    textAlign: "left",
    textOverflow: "visible",
    isCheck: true,
    id: 5,
  },
  {
    itemKey: "subAgentName",
    title: "Sub Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
  },

  {
    itemKey: "street2",
    title: "Apt/Unit#",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
  },
  {
    itemKey: "dob",
    title: "Date Of Birth",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
  },
  {
    itemKey: "gender",
    title: "Gender",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 13,
  },
  {
    itemKey: "insuranceCompany",
    title: "Insurance",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
  },
  {
    itemKey: "medicalNo",
    title: "Insurance #",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
  },
  {
    itemKey: "insuranceGroupId",
    title: "Group ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
  },
  {
    itemKey: "secondaryInsurance",
    title: "Secondary Insurance",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
  },
  {
    itemKey: "secondaryInsNumber",
    title: "Secondary Insurance Number",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
  },
  {
    itemKey: "schrID",
    title: "LTCS ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
  },
  {
    itemKey: "testOrdered",
    title: "Test Ordered",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
  },
  {
    itemKey: "testAvailable",
    title: "Test Available",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
  },
  {
    itemKey: "updatedAt",
    title: "Last Update",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
  },
  {
    itemKey: "eligibilityStatus",
    title: "Eligibility",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 23,
  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 24,
  },
];

export const LAB_MEMBER_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 5,
  },

  {
    itemKey: "street2",
    title: "Apt/Unit#",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
  },
  {
    itemKey: "dob",
    title: "Date Of Birth",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
  },
  {
    itemKey: "gender",
    title: "Gender",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
  },
  {
    itemKey: "insuranceCompany",
    title: "Insurance",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 12,
  },
  {
    itemKey: "medicalNo",
    title: "Insurance #",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
  },
  {
    itemKey: "insuranceGroupId",
    title: "Group ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
  },
  {
    itemKey: "secondaryInsurance",
    title: "Secondary Insurance",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
  },
  {
    itemKey: "secondaryInsNumber",
    title: "Secondary Insurance Number",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
  },
  {
    itemKey: "schrID",
    title: "LTCS ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
  },
  {
    itemKey: "updatedAt",
    title: "Last Update",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
  },
  {
    itemKey: "eligibilityStatus",
    title: "Eligibility",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
  },
  {
    itemKey: "providerName",
    title: "Provider",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
  },
  {
    itemKey: "primaryCarePhysician",
    title: "Primary Care Physician",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
  },
  {
    itemKey: "referringPyhsician",
    title: "Referring Physician",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 23,
  },
];

export const CLIENT_PERSONALIZE = [
  {
    itemKey: "name",
    title: "Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 5,
  },
  {
    itemKey: "monthToDate",
    title: "Total MTD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 6,
  },
  {
    itemKey: "yearToDate",
    title: "Total YTD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
  },
  {
    itemKey: "totalOrders",
    title: "Total ITD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 8,
  },
  {
    itemKey: "contact_name",
    title: "Contact Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 9,
  },
  {
    itemKey: "contact_email",
    title: "Contact Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 10,
  },
  {
    itemKey: "phone_number",
    title: "Contact Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 11,
  },
  {
    itemKey: "testOrderCategory",
    title: "Order Category",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 12,
  },
  {
    itemKey: "testLimit",
    title: "Test Limit",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 13,
  },
  {
    itemKey: "minTestOrderQty",
    title: "Min Order Qty",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 14,
  },
];

export const ORDER_PERSONALIZE = [
  {
    itemKey: "orderDate",
    title: "Order Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "dos",
    title: "DOS",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 5,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 6,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
  },
  {
    itemKey: "payerId",
    title: "Primary Insurance",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
  },
  {
    itemKey: "medicalNo",
    title: "Primary Insurance Number",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
  },
  {
    itemKey: "testQty",
    title: "Order Qty",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 13,
  },
  {
    itemKey: "orderStatus",
    title: "Order Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
  },
  {
    itemKey: "orderId",
    title: "Order ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
  },
  {
    itemKey: "pcn",
    title: "LTCS ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
  },
  // {
  //   itemKey: "claim",
  //   title: "Claim Submitted",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 16,
  // },
  // {
  //   itemKey: "claimStatus",
  //   title: "Claim Status",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 17,
  // },
  // {
  //   itemKey: "InsuranceStatus",
  //   title: "Ins Status",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 18,
  // },
  {
    itemKey: "clientName",
    title: "Client",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 17,
  },
  {
    itemKey: "siteName",
    title: "Facility/Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 18,
  },
  {
    itemKey: "subAgentName",
    title: "Sub Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 19,
  },
  {
    itemKey: "trackingNumber",
    title: "Tracking ID",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 20,
  },
  {
    itemKey: "requestedByName",
    title: "Ordered By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
  },
];

export const Billing_PERSONALIZE = [
  {
    itemKey: "orderDate",
    title: "Order Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "claimSubmitDate",
    title: "Claim Submitted",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 5,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 6,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
  },
  {
    itemKey: "clientName",
    title: "Client",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
  },
  {
    itemKey: "siteName",
    title: "Facility/Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
  },
  {
    itemKey: "subAgentName",
    title: "Sub Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 13,
  },
  {
    itemKey: "payerId",
    title: "Primary Insurance",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 14,
  },
  {
    itemKey: "medicalNo",
    title: "Primary Insurance Number",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 15,
  },
  {
    itemKey: "testQty",
    title: "Order Qty",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 16,
  },

  {
    itemKey: "claimStatus",
    title: "Claim Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
  },
  {
    itemKey: "InsuranceStatus",
    title: "Ins Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
  },
  {
    itemKey: "orderStatus",
    title: "Order Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
  },
  {
    itemKey: "pcn",
    title: "LTCS ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
  },
  {
    itemKey: "employeeClaimID",
    title: "Claim ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
  },

  {
    itemKey: "claimAmount",
    title: "Claim Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
  },
  {
    itemKey: "expectedAmount",
    title: "Ex. Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 23,
  },
  {
    itemKey: "amountRecieved",
    title: "Claim Paid",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 24,
  },
  {
    itemKey: "amountRecievedDate",
    title: "Amount Received Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 25,
  },
  {
    itemKey: "chequeNo",
    title: "ERA",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 26,
  },
  {
    itemKey: "requestedByName",
    title: "Ordered By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 27,
  },
];

export const MEMBER_ORDER_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Order Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "testQty",
    title: "Qty Ordered",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 4,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 5,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "orderStatus",
    title: "Order Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
  },
];

export const ELIGIBILITY_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Date Requested",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "clientName",
    title: "Client",
    textAlign: "",
    textOverflow: "visible",
    isCheck: true,
    id: 4,
  },
  {
    itemKey: "locationName",
    title: "Facility/Agent",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
  },
  {
    itemKey: "subAgentName",
    title: "Sub Agent",
    textAlign: "",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "payerId",
    title: "Insurance Provider",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
  },
  {
    itemKey: "medicalNo",
    title: "ID #",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 8,
  },

  {
    itemKey: "groupId",
    title: "Group ID #",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 9,
  },

  {
    itemKey: "message",
    title: "Status",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 10,
  },
  {
    itemKey: "userName",
    title: "User",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 11,
  },
];

export const LAB_ELIGIBILITY_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Date Requested",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "clientName",
    title: "Client",
    textAlign: "",
    textOverflow: "visible",
    isCheck: true,
    id: 4,
  },
  {
    itemKey: "payerId",
    title: "Insurance Provider",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
  },
  {
    itemKey: "medicalNo",
    title: "ID #",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 6,
  },

  {
    itemKey: "groupId",
    title: "Group ID #",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
  },

  {
    itemKey: "message",
    title: "Status",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 8,
  },
  {
    itemKey: "userName",
    title: "User",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 9,
  },
];

export const USER_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "phone_number",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "userRole",
    title: "Role",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 5,
  },
  {
    itemKey: "note",
    title: "Associated",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 6,
  },
];

export const PROVIDER_PERSONALIZE = [
  {
    itemKey: "code",
    title: "Code",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "name",
    title: "Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "npi",
    title: "NPI",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
  },
  {
    itemKey: "speciality",
    title: "Speciality",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
  },
  {
    itemKey: "taxid",
    title: "Taxonomy",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
  },
];

export const EMP_CLAIM_PERSONALIZE = [
  {
    itemKey: "submissionDate",
    title: "Claim Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "pat_name_f",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "pat_name_l",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "pat_email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "pat_phone",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 5,
  },
  {
    itemKey: "pat_addr_1",
    title: "Address",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
  },

  {
    itemKey: "pat_addr_2",
    title: "Apt/Unit#",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
  },
  {
    itemKey: "pat_city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
  },
  {
    itemKey: "pat_state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
  },
  {
    itemKey: "pat_zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
  },
  {
    itemKey: "pat_dob",
    title: "Date Of Birth",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
  },
  {
    itemKey: "pat_sex",
    title: "Gender",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
  },
  {
    itemKey: "payer_name",
    title: "Insurance",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
  },
  {
    itemKey: "ins_number",
    title: "Insurance #",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
  },
  {
    itemKey: "claimNo",
    title: "Claim ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
  },
  {
    itemKey: "total_charge",
    title: "Claim Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
  },
  {
    itemKey: "adjustedAmount",
    title: "Adjusted Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
  },
  {
    itemKey: "paidAmount",
    title: "Paid Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
  },
  {
    itemKey: "eraIds",
    title: "ERA",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
  },
  {
    itemKey: "from_date_1",
    title: "DOS",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
  },

  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
  },
];
