import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import ColorLabel from "components/ColorLabel";
import Status from "components/Status";
import { toTitleCase } from "utils";
import payerlist from "./Jsons/payer-list.json";
import diagnosisCodesList from "./Jsons/diagnosisCodesList.json";
import cptCodesAndDesc from "./Jsons/CPTCodesAndDescription.json";
import notesOnIcon from "../src/assets/img/file-icon.png";
import placeOfServices from "./Jsons/POS.json";
import eraDenialsReasons from "./Jsons/DenialReasonGroupCode.json";
import eraDenialsRemarks from "./Jsons/DenialRemarkCode.json";
import notesOffIcon from "../src/assets/img/file-icon-gray.png";
import TrashIcon from "assets/img/trash-icon.png";

export const PAGE_LIMIT = 20;
export const CE_PAGE_LIMIT = 10;
export const MIN_TEST_QUANTITY = 2;
export const MAX_TEST_QUANTITY = 8;
export const MIN_TEST_LIMIT = 8;

export const DEFAULT_MEDICARE_PRO_CODE = [{ label: "K1034", value: "K1034", diagnosis: ["Z20.822"], modifire: [] }];

export const CONFIG = {
  env: process.env.REACT_APP_ENV,
  isLive: process.env.REACT_APP_ENV === "live",
  isLabType: process.env.REACT_APP_COMPANY_TYPE === "LAB",
  generalClient: process.env.REACT_APP_GENERAL_CLIENT,
  generalFacility: process.env.REACT_APP_GENERAL_FACILITY,
  generalSubAgent: process.env.REACT_APP_GENERAL_SUBAGENT,
  appURL: process.env.REACT_APP_API_URL,
  eligibilityBucket: process.env.REACT_APP_ELIGIBILITY_BUCKET,
  updateBucket: process.env.REACT_APP_ELIGIBILITY_RESULT_BUCKET,
};

export const filterRoutesPath = ["/clients", "/facilities", "/subagents", "/users"];

export const LTC_UPDATE_BUCKET = "ltcs-update-record";
export const LTC_ELIGIBILITY_BUCKET = "ltcs-eligibility";

export const DIAG_REF_CODE = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
};

export const MEDICATRE_IDS = [
  "00831",
  "02001",
  "10111",
  "10112",
  "26119",
  "ISA01",
  "07101",
  "07102",
  "03101",
  "03102",
  "BTHS1",
  "01911",
  "01111",
  "01102",
  "01192",
  "33070",
  "04111",
  "04102",
  "ISA02",
  "ISA03",
  "ISA04",
  "ISA05",
  "13102",
  "12201",
  "12202",
  "12301",
  "12101",
  "12102",
  "16003",
  "17003",
  "18003",
  "19003",
  "ISA06",
  "ISA07",
  "09101",
  "09102",
  "10211",
  "10212",
  "ISA08",
  "15004",
  "06014",
  "1100H",
  "01211",
  "01202",
  "05101",
  "05102",
  "02202",
  "06101",
  "00952",
  "08101",
  "08102",
  "04901",
  "ISA09",
  "05201",
  "05202",
  "ISA10",
  "15101",
  "15102",
  "07201",
  "07202",
  "ISA11",
  "ISA12",
  "14211",
  "14212",
  "12302",
  "14011",
  "14112",
  "ISA13",
  "05901",
  "12M65",
  "08201",
  "08202",
  "06201",
  "06202",
  "05301",
  "05302",
  "07301",
  "00512",
  "03201",
  "03001",
  "11501",
  "11502",
  "03302",
  "05401",
  "05402",
  "14312",
  "14013",
  "12401",
  "12402",
  "04211",
  "04202",
  "12M07",
  "12M82",
  "12M83",
  "12M45",
  "12M30",
  "01312",
  "01302",
  "13201",
  "13202",
  "13292",
  "13282",
  "15201",
  "15202",
  "04311",
  "04302",
  "02302",
  "12501",
  "12502",
  "09202",
  "ISA14",
  "ISA15",
  "00882",
  "14411",
  "14412",
  "ISA16",
  "45552",
  "11001",
  "11202",
  "03402",
  "ISA17",
  "ISA18",
  "ISA19",
  "MCAMA",
  "10311",
  "10312",
  "04411",
  "04402",
  "ISA20",
  "09302",
  "03501",
  "03502",
  "12004",
  "11302",
  "14512",
  "02402",
  "06001",
  "06302",
  "11402",
  "11003",
  "03602",
];

export const PROFILE_IMAGE_PATH = "https://medflow-images.s3.eu-west-1.amazonaws.com/public/profile/";
export const LOGO_IMAGE_PATH = "https://medflow-images.s3.eu-west-1.amazonaws.com/public/logoImages/";
export const EXTRERNAL_IMAGE_PATH = "https://employee-external-tests.s3.eu-west-1.amazonaws.com/public/";

export const MESSAGE_TYPE = {
  default: "default",
  info: "info",
  success: "success",
  warning: "warning",
  danger: "danger",
  error: "error",
  input: "input",
  custom: "custom",
  controlled: "controlled",
};

export const TEST_HEADER_TYPE = {
  unprocessed: "unprocessed",
  completed: "completed",
  pending: "pending",
};

export const TEST_STATUS = {
  Sent: "Sent",
  Processing: "Processing",
  Pending: "Pending",
  Processed: "Processed",
};

export const EMPLYEE_STATUS = {
  active: "Active",
  inactive: "In Active",
};

export const newRoleOptions = [
  {
    value: "Admins",
    label: "SafeCamp Admin",
  },
  {
    value: "Clients",
    label: "Client Admin",
  },
  {
    value: "Employers",
    label: "Facility/Agent Admin",
  },
  {
    value: "SubAgents",
    label: "Sub Agent Admin",
  },
];

export const claimActions = {
  receive: "receive",
  return: "return",
};

export const claimSubmitStatus = {
  draft: "draft",
  submitted: "submitted",
  rejected: "rejected",
  approved: "approved",
  paid: "paid",
  denied: "denied",
  cancel: "cancel",
};

export const claimSubmitStatusColorOpts = {
  draft: "gray",
  submitted: "blue",
  partial: "green",
  rejected: "red",
  approved: "green",
  paid: "green",
  denied: "red",
  cancel: "gray",
};

export const claimActionOptions = [
  { value: "receive", label: "Receive" },
  { value: "return", label: "Refund" },
];

export const USER_ROLE_TYPE = {
  Admin: "Admin",
  Coordinator: "Coordinator",
  Staff: "Staff",
  Employee: "Employee",
};

export const USER_ROLE_TITLE = {
  Admins: "SafeCamp Admin",
  Employers: "Facility/Agent Admin",
  Clients: "Client Admin",
  SubAgents: "Sub Agent Admin",
};

export const FILTER_USER_ROLE = {
  Admins: ["Admins", "Clients", "Employers", "SubAgents"],
  Clients: ["Clients", "Employers", "SubAgents"],
  Employers: ["Employers", "SubAgents"],
};

export const HEALTH_AND_SAFTEY = "Health & Safety";

export const EmptyUserObj = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  isNew: true,
  countryCode: "",
  autoShipment: false,
  isMemberLogin: "N",
  locationID: "",
  picture: "",
  dob: "",
  medicalNo: "",
  insruanceProvider: "",
  country: "United States",
  isoCode: "US",
  note: "",
  status: "active",
  isSecondary: "N",
  primaryInsurance: {
    copay: 0,
    deductible: 0,
  },
};

export const NEW_PROVIDER_OBJECT = {
  name: "",
  firstName: "",
  middleName: "",
  lastName: "",
  code: "",
  npi: "",
  taxid: "",
  speciality: "",
  email: "",
  phoneNumber: "",
  street: "",
  street2: "",
  city: "",
  state: "",
};
export const TEST_TYPE_VALUE = {
  Lucira: "Lucira",
  PCR: "PCR",
  Antigen: "Rapid Antigen",
  Other: "Rapid PCR",
  Molecular: "Cue",
};

export const AMAZON_CLIENT_RESULT = {
  Positive: "Fail",
  Negative: "Pass",
  Expired: "Expired",
  positive: "Fail",
  negative: "Pass",
  expired: "Expired",
  Pass: "Pass",
  Fail: "Fail",
  pass: "Pass",
  fail: "Fail",
};

export const RESULT_COLOR = {
  positive: "red",
  negative: "green",
  expired: "orange",
  pass: "green",
  fail: "red",
};
export const customData = [
  {
    value: "custom",
    label: "custom",
  },
  {
    value: "custom1",
    label: "custom1",
  },
];

export const CLI_DEFAULT_OPTIONS = {
  yes: "Yes",
  no: "No",
};

export const NEW_CLI_ITEM = {
  cliCode: "",
  isDefault: CLI_DEFAULT_OPTIONS.no,
};

export const CLI_ACTIONS = {
  add: "add",
  delete: "delete",
  update: "update",
};

export const weekDaysOptions = [
  {
    value: "0",
    label: "SUN",
  },
  {
    value: "1",
    label: "MON",
  },
  {
    value: "2",
    label: "TUE",
  },
  {
    value: "3",
    label: "WED",
  },
  {
    value: "4",
    label: "THU",
  },
  {
    value: "5",
    label: "FRI",
  },
  {
    value: "6",
    label: "SAT",
  },
];

export const TestTypeOptions = [
  {
    value: "Antigen",
    label: "Antigen",
  },
  {
    value: "Molecular",
    label: "Molecular",
  },
];
export const AUTO_SHIP_OPTIONS = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
];

export const WEEK_DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const SUPER_ADMIN_ID = "20cafd4b-2671-4854-8389-4f185d60c69b";

export const EmptyEmployeeObj = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  isNew: true,
};

export const QuestionStatus = {
  new: 1,
  edit: 2,
  delete: 3,
};

export const INSURANCE_TYPE = {
  primary: "primary",
  secondary: "secondary",
};

export const SCHEDULE_TYPES = {
  everyday: "Every Day",
  selecteddays: "Selected Days",
  untilcancel: "Until Cancel",
};

export const QUESTION_GROUP_TYPES = {
  questionandtesting: {
    value: 1,
    label: "Question/Testing",
    shortCode: "QT",
  },
  question: { value: 2, label: "Question", shortCode: "Q" },
  testing: { value: 3, label: "Testing", shortCode: "T" },
};

export const LOCATION_OPTIONS = [
  { value: "Cupertino", label: "Cupertino" },
  { value: "Los Angeles", label: "Los Angeles" },
  { value: "Austin", label: "Austin" },
  { value: "Nashville", label: "Nashville" },
  { value: "New York", label: "New York" },
  { value: "London", label: "London" },
  { value: "Singapore", label: "Singapore" },
  { value: "Tokyo", label: "Tokyo" },
];

export const REGION_OPTIONS = [
  { value: "Americas", label: "Americas" },
  { value: "EMEA", label: "EMEA" },
  { value: "Asia Pac", label: "Asia Pac" },
  { value: "Japan", label: "Japan" },
];
export const GROUP_TYPES = {
  1: { value: 1, label: "Question/Testing", shortCode: "QT" },
  2: { value: 2, label: "Question", shortCode: "Q" },
  3: { value: 3, label: "Testing", shortCode: "T" },
};
export const CLAIM_SUBMIT = [
  {
    value: "1",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "0",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];
export const CLAIM_STATUS = [
  {
    value: "Accepted",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "Rejected",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];

export const ELIGIBILITY_STATUS = [
  {
    value: "eligibile",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "invalid",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
  {
    value: "not checked",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="yellow" questionIcon />
      </div>
    ),
  },
];

export const NOTE_FILTER = [
  {
    value: "0",
    label: "Empty",
  },
  {
    value: "1",
    label: (
      <div className="action-exclamation">
        <span
          className="cursor-pointer"
          style={{
            borderColor: "rgb(83 83 232)",
          }}
        >
          <img className="notesIcon" src={notesOnIcon} alt="notes-on-img" />
        </span>
      </div>
    ),
  },
];

export const INSURANCE_STATUS = [
  {
    value: "Pending",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" crossIcon />
      </div>
    ),
  },
  {
    value: "Approved",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="lightgreen" crossIcon />
      </div>
    ),
  },
  {
    value: "Paid",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "Denied",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];

export const STATUS_OPTIONS = [
  {
    value: "inactive",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
  {
    value: "active",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
];
export const ZONE_STATUS_OPTIONS = [
  { value: "0", label: "Empty" },
  {
    value: "3",
    label: <ViewCheckBox id="filter" bgColor="green" />,
  },
];

export const QA_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
  { value: "M", label: <ViewCheckBox id="filter" bgColor="blue" /> },
  { value: "X", label: <div style={{ color: "red" }}>X</div> },
];
export const TEST_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
  { value: "X", label: <div style={{ color: "red" }}>X</div> },
];
export const CHECKIN_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
];

export const TEST_TYPES = [
  { value: "Molecular", label: "Cue" },
  { value: "PCR", label: "PCR" },
  { value: "Antigen", label: "Rapid Antigen" },
  { value: "Other", label: "Rapid PCR" },
  { value: "Lucira", label: "Lucira" },
];

export const ORDER_STATUS_OPT = [
  { value: "Pending", label: "Pending" },
  { value: "Sent", label: "Sent" },
  { value: "Delivered", label: "Delivered" },
  { value: "Returned", label: "Returned" },
];

export const VACCINATED_OPTIONS = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];

export const ELIGIBLE_STATUS = [
  { value: "1", label: "Eligible" },
  { value: "0", label: "InEligible" },
];

export const ON_LOCATION_OPT = [
  { value: "onLocation", label: "onLocation" },
  { value: "At Home", label: "At Home" },
];
export const MESSAGE_SEND_TYPE = [
  { value: "E", label: "Email" },
  { value: "M", label: "Text Message" },
];
export const SCHEDULE_TYPE_EVERY_DAY = "everyday";
export const SCHEDULE_TYPE_SELECTED_DAYS = "selecteddays";
export const SCHEDULE_TYPE_UNTIL_CANCEL = "untilcancel";

export const AssignGroup_Type_Question_Testing = "questionandtesting";
export const AssignGroup_Type_Question_Only = "question";
export const AssignGroup_Type_testing_Only = "testing";

export const USER_TYPE_USER = "user";
export const USER_TYPE_ADMIN = "admin";
export const USER_TYPE_CLIENT = "client";
export const USER_TYPE_SUBAGENT = "subagent";

export const DAYS_LABEL = {
  0: "SUN",
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
};
export const COLOR_OPT = [
  { value: "Black", label: <ColorLabel color={"Black"} /> },
  { value: "Green", label: <ColorLabel color={"Green"} /> },
  { value: "Blue", label: <ColorLabel color={"Blue"} /> },
  { value: "Gray", label: <ColorLabel color={"Gray"} /> },
  { value: "Brown", label: <ColorLabel color={"Brown"} /> },
  { value: "Deep Pink", label: <ColorLabel color={"Deep Pink"} /> },
  { value: "Purple", label: <ColorLabel color={"Purple"} /> },
  { value: "Orange", label: <ColorLabel color={"Orange"} /> },
];

export const COLOR_VALUE = {
  black: "Black",
  green: "Green",
  blue: "Blue",
  gray: "Gray",
  pink: "Deep Pink",
  purple: "Purple",
  orange: "Orange",
};

export const EMPLOYEE_HEADERS = ["firstName", "lastName", "phoneNumber"];

export const objTypes = {
  isVaccinated: "BOOL",
  isNew: "BOOL",
  vaccinationCardUpload: "BOOL",
  isBooster: "BOOL",
  isExternalTest: "BOOL",
  unionNo: "BOOL",
  testOrdered: "N",
  totalOrderd: "N",
  testAvailable: "N",
  isSignUp: "N",
  paidAmount: "N",
  units: "N",
  autoShipment: "BOOL",
};

export const AdditionalKeys = [
  "_lastChangedAt",
  "_version",
  "updatedAt",
  "createdAt",
  "updatedBy",
  "updatedByName",
  "clientID",
  "SubAgentID",
  "locationID",
];

export const fieldLabel = {
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  email: "Email",
  sex: "Gender",
  jobTitle: "Job Title",
  picture: "Picture",
  dob: "DOB",
  street: "Address",
  street2: "Apt/Unit #",
  city: "City",
  state: "State",
  country: "Country",
  zip: "Zip Code",
  insuranceCompany: "Insurance Provider",
  medicalNo: "Insurance Number",
  medicareNo: "Medicare Number",
  insuranceGroupId: "Insurance Group ID",
  testOrdered: "Test Ordered",
  totalOrderd: "Total Test Ordered",
  testAvailable: "Test Available",
  autoShipment: "Auto Shipment",
  ClientName: "Client",
  AgentName: "Facility/Agent",
  SubAgentName: "SubAgent",
  note: "Note",
  status: "Status",
  eligibilityStatus: "Eligibility Status",
  pat_name_f: "First Name",
  pat_name_l: "Last Name",
  pat_name_phone: "Phone Number",
  pat_email: "Email",
  pat_sex: "Gender",
  pat_state: "State",
  pat_city: "City",
  pat_addr_1: "Patient Address",
  pat_addr_2: "Patien Apt/Unit",
  pat_zip: "zip",
  payer_name: "Insurance",
  payerid: "Insurnace",
  ins_number: "Insurance Number",
  clia_number: "Cli Number",
  diag_1: "Diag 1",
  diag_2: "Diag 2",
  diag_3: "Diag 3",
  diag_4: "Diag 4",
  diag_5: "Diag 5",
  diag_6: "Diag 6",
  diag_7: "Diag 7",
  diag_8: "Diag 8",
  mod1: "Modifier 1",
  mod2: "Modifier 2",
  mod3: "Modifier 3",
  mod4: "Modifier 4",
  place_of_service: "POS",
  prov_name_f: "Rendering Provider",
  ord_prov_name_f: "Ordering Provider",
  ref_name_f: "Referring Provider",
  message: "Message",
  from_date_1: "Date of Services",
  payer_icn: "Claim No",
  total_charge: "Total Charge",
  units: "Units",
};

export const applyCustomOrder = (arr, desiredOrder) => {
  const orderForIndexVals = desiredOrder.reverse();
  return arr.sort((a, b) => {
    const aIndex = -orderForIndexVals.indexOf(a);
    const bIndex = -orderForIndexVals.indexOf(b);
    return aIndex - bIndex;
  });
};

export const LOCATION_HEADERS = [
  "name",
  // "contact_name",
  "contact_first_name",
  "contact_last_name",
  // "phone_number",
  "main_phone_number",
  "contact_email",
];

export const ELIGIBILITY_HEADERS = ["firstName", "lastName", "dob", "medicareNo", "medicareAgent"];

export const LOCATION = ["cupertino", "los angeles", "austin", "nashville", "new york", "london", "singapore", "tokyo"];
export const REGION = ["americas", "emea", "asia pac", "japan"];

export const TIMEZONE_OPT = [
  { label: "Eastern Standard Time", value: "Eastern Standard Time" },
  { label: "Central Standard Time", value: "Central Standard Time" },
  { label: "Mountain Standard Time", value: "Mountain Standard Time" },
  { label: "Pacific Standard Time", value: "Pacific Standard Time" },
  { label: "Pakistan Standard Time", value: "Pakistan Standard Time" },
];

export const IMAGE_UPLOAD_TYPE = {
  frontCard: "front_card",
  backCard: "back_card",
};

export const TIMEZONE_VALUE = {
  est: "Eastern Standard Time",
  cst: "Central Standard Time",
  mst: "Mountain Standard Time",
  pst: "Pacific Standard Time",
  pkt: "Pakistan Standard Time",
};

export const SCHEDULE_CATEGORY = {
  TESTING: "Testing",
  QUESTIONS: "Question",
};

export const TEST_ORDER_LIMIT_CATEGORY = [
  { label: "Per Week", value: "0" },
  { label: "Per Month", value: "1" },
  { label: "Per Year", value: "2" },
];

export const TEST_ORDER_LIMIT_CATEGORY_VALUE = {
  0: "Per Week",
  1: "Per Month",
  2: "Per Year",
};

export const SCHEDULE_OPTIONS = [
  {
    id: 0,
    name: "Sunday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 1,
    name: "Monday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 2,
    name: "Tuesday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 3,
    name: "Wednesday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 4,
    name: "Thursday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 5,
    name: "Friday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 6,
    name: "Saturday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        testNow: false,
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
];

export const DAYS = [0, 1, 2, 3, 4, 5, 6];

export const CONFIRMATION_TYPE = {
  DELETE: "delete",
  PROGRAM_START: "programstart",
  ASSIGN_PROGRAM: "assignprogram",
  UPDATE_TIME: "updateTime",
  AUTO_SHIPMENT: "autoshipment",
  PUBLIC_ORDER: "publicOrder",
  SUBMIT_CLAIM: "submitClaim",
  RESUBMIT_CLAIM: "reSubmitClaim",
};

export const DAILY_QA_STATUS = {
  X: false,
  1: true,
  M: true,
  0: null,
};

export const Audit_Entries = [
  "Member Profile Created",
  "Member Profile Edited",
  "Eligibility",
  "Test Ordered",
  "Order Limit Reset",
  "Insurance Status",
  "Claim Status",
  "Claim",
];

export const Audit_Types = {
  OrderPlaced: "Test Ordered",
  OrderShipped: "Test Ordered",
  OrderDelivered: "Test Ordered",
  OrderReturn: "Test Ordered",
  Eligibility: "Eligibility",
  MemberUpdated: "Member Profile Edited",
  MemberCreated: "Member Profile Created",
  OrderLimitReset: "Order Limit Reset",
  insuranceStatus: "Insurance Status",
  claimStatus: "Claim Status",
  ClaimPartiallyProcessed: "Claim",
  SubmissionFailed: "Claim",
  SendToInsurance: "Claim",
  ClaimCreated: "Claim",
  ClaimUpdated: "Claim",
  SubmitForBill: "Claim",
  ClaimRejected: "Claim",
  ClaimDenied: "Claim",
  OrderUpdate: "Order Update",
};

export const Audit_Parse = {
  OrderPlaced: "Order Placed",
  OrderShipped: "Order Shipped",
  OrderReturned: "Order Returned",
  OrderOrderReturn: "Order Returned",
  OrderDelivered: "Order Delivered",
  Eligibility: "Eligibility Checked",
  MemberUpdated: "Member Edited",
  MemberCreated: "Member Created",
  SubmitForBill: "Claim Submitted",
  ClaimDenied: "Claim Denied",
  OrderLimitReset: "Order Limit Reset",
  insuranceStatus: "Insurance Status",
  claimStatus: "Claim Status",
  claimStatusRejected: "Claim Rejected",
  claimRejected: "Claim Rejected",
  ClaimRejected: "Claim Rejected",
  claimApproved: "Claim Approved",
  SendToInsurance: "Send To Insurance",
  SubmissionFailed: "Submission Failed",
  ClaimDenied: "Claim Denied",
  ClaimPartiallyProcessed: "Claim Partially Processed",
  claimAmountReceived: "Claim Paid",
  claimAmountReturned: "Claim Returned",
  ClaimCreated: "Claim Created",
  ClaimUpdated: "Claim Updated",
  OrderUpdate: "Order Update",
  Note: "Note",
};

export const OrderStatusEvents = {
  Sent: "Shipped",
  Delivered: "Delivered",
  Returned: "Returned",
};
export const HISTORY_TEST = [
  {
    date: "2022-09-12",
    id: "3031b1ba-7101-4ff7-b5e1-e2c01768426d",
    isDone: false,
    label: "PCR",
    location: "On Location",
    lucira: false,
    result: "Positive",
    scheduleCategory: "Testing",
    value: "PCR",
  },
  {
    date: "2022-09-06",
    id: "31ebc832-6f1a-4cd3-aa94-4fbf9d87c41f",
    isDone: false,
    label: "Rapid PCR",
    location: "On Location",
    lucira: false,
    result: "Negative",
    scheduleCategory: "Testing",
    value: "Other",
  },
  {
    date: "2022-09-12",
    id: "c4cbb706-8a15-4996-9790-91fb9cd48cb1",
    isDone: false,
    label: "Cue",
    location: "On Location",
    lucira: false,
    result: "Invlaid",
    scheduleCategory: "Testing",
    value: "Molecular",
  },
];

export const DAYS_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const STATUS = {
  assignProgram: 2,
  startProgram: 1,
  zoneAssign: 3,
  removeProgram: 0,
};

export const AltaSetting = {
  name: "ALTA DIAGNOSTIC LABORATORIES, INC",
  npi: "1063180354",
  city: "ALTADENA",
  state: "CA",
  street: "2268 LINCOLN AVE",
  street2: "",
  taxid: "871701212",
  zip: "910015432",
};

export const countryListAllIsoData = [
  { code: "AF", code3: "AFG", name: "Afghanistan", number: "004" },
  { code: "AL", code3: "ALB", name: "Albania", number: "008" },
  { code: "DZ", code3: "DZA", name: "Algeria", number: "012" },
  { code: "AS", code3: "ASM", name: "American Samoa", number: "016" },
  { code: "AD", code3: "AND", name: "Andorra", number: "020" },
  { code: "AO", code3: "AGO", name: "Angola", number: "024" },
  { code: "AI", code3: "AIA", name: "Anguilla", number: "660" },
  { code: "AQ", code3: "ATA", name: "Antarctica", number: "010" },
  { code: "AG", code3: "ATG", name: "Antigua and Barbuda", number: "028" },
  { code: "AR", code3: "ARG", name: "Argentina", number: "032" },
  { code: "AM", code3: "ARM", name: "Armenia", number: "051" },
  { code: "AW", code3: "ABW", name: "Aruba", number: "533" },
  { code: "AU", code3: "AUS", name: "Australia", number: "036" },
  { code: "AT", code3: "AUT", name: "Austria", number: "040" },
  { code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031" },
  { code: "BS", code3: "BHS", name: "Bahamas", number: "044" },
  { code: "BH", code3: "BHR", name: "Bahrain", number: "048" },
  { code: "BD", code3: "BGD", name: "Bangladesh", number: "050" },
  { code: "BB", code3: "BRB", name: "Barbados", number: "052" },
  { code: "BY", code3: "BLR", name: "Belarus", number: "112" },
  { code: "BE", code3: "BEL", name: "Belgium", number: "056" },
  { code: "BZ", code3: "BLZ", name: "Belize", number: "084" },
  { code: "BJ", code3: "BEN", name: "Benin", number: "204" },
  { code: "BM", code3: "BMU", name: "Bermuda", number: "060" },
  { code: "BT", code3: "BTN", name: "Bhutan", number: "064" },
  { code: "BO", code3: "BOL", name: "Bolivia", number: "068" },
  {
    code: "BQ",
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    number: "535",
  },
  { code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", number: "070" },
  { code: "BW", code3: "BWA", name: "Botswana", number: "072" },
  { code: "BV", code3: "BVT", name: "Bouvet Island", number: "074" },
  { code: "BR", code3: "BRA", name: "Brazil", number: "076" },
  {
    code: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory",
    number: "086",
  },
  { code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096" },
  { code: "BG", code3: "BGR", name: "Bulgaria", number: "100" },
  { code: "BF", code3: "BFA", name: "Burkina Faso", number: "854" },
  { code: "BI", code3: "BDI", name: "Burundi", number: "108" },
  { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132" },
  { code: "KH", code3: "KHM", name: "Cambodia", number: "116" },
  { code: "CM", code3: "CMR", name: "Cameroon", number: "120" },
  { code: "CA", code3: "CAN", name: "Canada", number: "124" },
  { code: "KY", code3: "CYM", name: "Cayman Islands", number: "136" },
  { code: "CF", code3: "CAF", name: "Central African Republic", number: "140" },
  { code: "TD", code3: "TCD", name: "Chad", number: "148" },
  { code: "CL", code3: "CHL", name: "Chile", number: "152" },
  { code: "CN", code3: "CHN", name: "China", number: "156" },
  { code: "CX", code3: "CXR", name: "Christmas Island", number: "162" },
  { code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands", number: "166" },
  { code: "CO", code3: "COL", name: "Colombia", number: "170" },
  { code: "KM", code3: "COM", name: "Comoros", number: "174" },
  {
    code: "CD",
    code3: "COD",
    name: "Congo, The Democratic Republic of the",
    number: "180",
  },
  { code: "CG", code3: "COG", name: "Congo", number: "178" },
  { code: "CK", code3: "COK", name: "Cook Islands", number: "184" },
  { code: "CR", code3: "CRI", name: "Costa Rica", number: "188" },
  { code: "HR", code3: "HRV", name: "Croatia", number: "191" },
  { code: "CU", code3: "CUB", name: "Cuba", number: "192" },
  { code: "CW", code3: "CUW", name: "Curaçao", number: "531" },
  { code: "CY", code3: "CYP", name: "Cyprus", number: "196" },
  { code: "CZ", code3: "CZE", name: "Czechia", number: "203" },
  { code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384" },
  { code: "DK", code3: "DNK", name: "Denmark", number: "208" },
  { code: "DJ", code3: "DJI", name: "Djibouti", number: "262" },
  { code: "DM", code3: "DMA", name: "Dominica", number: "212" },
  { code: "DO", code3: "DOM", name: "Dominican Republic", number: "214" },
  { code: "EC", code3: "ECU", name: "Ecuador", number: "218" },
  { code: "EG", code3: "EGY", name: "Egypt", number: "818" },
  { code: "SV", code3: "SLV", name: "El Salvador", number: "222" },
  { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226" },
  { code: "ER", code3: "ERI", name: "Eritrea", number: "232" },
  { code: "EE", code3: "EST", name: "Estonia", number: "233" },
  { code: "SZ", code3: "SWZ", name: "Eswatini", number: "748" },
  { code: "ET", code3: "ETH", name: "Ethiopia", number: "231" },
  {
    code: "FK",
    code3: "FLK",
    name: "Falkland Islands (Malvinas)",
    number: "238",
  },
  { code: "FO", code3: "FRO", name: "Faroe Islands", number: "234" },
  { code: "FJ", code3: "FJI", name: "Fiji", number: "242" },
  { code: "FI", code3: "FIN", name: "Finland", number: "246" },
  { code: "FR", code3: "FRA", name: "France", number: "250" },
  { code: "GF", code3: "GUF", name: "French Guiana", number: "254" },
  { code: "PF", code3: "PYF", name: "French Polynesia", number: "258" },
  {
    code: "TF",
    code3: "ATF",
    name: "French Southern Territories",
    number: "260",
  },
  { code: "GA", code3: "GAB", name: "Gabon", number: "266" },
  { code: "GM", code3: "GMB", name: "Gambia", number: "270" },
  { code: "GE", code3: "GEO", name: "Georgia", number: "268" },
  { code: "DE", code3: "DEU", name: "Germany", number: "276" },
  { code: "GH", code3: "GHA", name: "Ghana", number: "288" },
  { code: "GI", code3: "GIB", name: "Gibraltar", number: "292" },
  { code: "GR", code3: "GRC", name: "Greece", number: "300" },
  { code: "GL", code3: "GRL", name: "Greenland", number: "304" },
  { code: "GD", code3: "GRD", name: "Grenada", number: "308" },
  { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312" },
  { code: "GU", code3: "GUM", name: "Guam", number: "316" },
  { code: "GT", code3: "GTM", name: "Guatemala", number: "320" },
  { code: "GG", code3: "GGY", name: "Guernsey", number: "831" },
  { code: "GN", code3: "GIN", name: "Guinea", number: "324" },
  { code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624" },
  { code: "GY", code3: "GUY", name: "Guyana", number: "328" },
  { code: "HT", code3: "HTI", name: "Haiti", number: "332" },
  {
    code: "HM",
    code3: "HMD",
    name: "Heard Island and McDonald Islands",
    number: "334",
  },
  {
    code: "VA",
    code3: "VAT",
    name: "Holy See (Vatican City State)",
    number: "336",
  },
  { code: "HN", code3: "HND", name: "Honduras", number: "340" },
  { code: "HK", code3: "HKG", name: "Hong Kong", number: "344" },
  { code: "HU", code3: "HUN", name: "Hungary", number: "348" },
  { code: "IS", code3: "ISL", name: "Iceland", number: "352" },
  { code: "IN", code3: "IND", name: "India", number: "356" },
  { code: "ID", code3: "IDN", name: "Indonesia", number: "360" },
  {
    code: "IR",
    code3: "IRN",
    name: "Iran, Islamic Republic Of",
    number: "364",
  },
  { code: "IQ", code3: "IRQ", name: "Iraq", number: "368" },
  { code: "IE", code3: "IRL", name: "Ireland", number: "372" },
  { code: "IM", code3: "IMN", name: "Isle of Man", number: "833" },
  { code: "IL", code3: "ISR", name: "Israel", number: "376" },
  { code: "IT", code3: "ITA", name: "Italy", number: "380" },
  { code: "JM", code3: "JAM", name: "Jamaica", number: "388" },
  { code: "JP", code3: "JPN", name: "Japan", number: "392" },
  { code: "JE", code3: "JEY", name: "Jersey", number: "832" },
  { code: "JO", code3: "JOR", name: "Jordan", number: "400" },
  { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398" },
  { code: "KE", code3: "KEN", name: "Kenya", number: "404" },
  { code: "KI", code3: "KIR", name: "Kiribati", number: "296" },
  {
    code: "KP",
    code3: "PRK",
    name: "Korea, Democratic People's Republic of",
    number: "408",
  },
  { code: "KR", code3: "KOR", name: "Korea, Republic of", number: "410" },
  { code: "KW", code3: "KWT", name: "Kuwait", number: "414" },
  { code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417" },
  {
    code: "LA",
    code3: "LAO",
    name: "Lao People's Democratic Republic",
    number: "418",
  },
  { code: "LV", code3: "LVA", name: "Latvia", number: "428" },
  { code: "LB", code3: "LBN", name: "Lebanon", number: "422" },
  { code: "LS", code3: "LSO", name: "Lesotho", number: "426" },
  { code: "LR", code3: "LBR", name: "Liberia", number: "430" },
  { code: "LY", code3: "LBY", name: "Libya", number: "434" },
  { code: "LI", code3: "LIE", name: "Liechtenstein", number: "438" },
  { code: "LT", code3: "LTU", name: "Lithuania", number: "440" },
  { code: "LU", code3: "LUX", name: "Luxembourg", number: "442" },
  { code: "MO", code3: "MAC", name: "Macao", number: "446" },
  { code: "MG", code3: "MDG", name: "Madagascar", number: "450" },
  { code: "MW", code3: "MWI", name: "Malawi", number: "454" },
  { code: "MY", code3: "MYS", name: "Malaysia", number: "458" },
  { code: "MV", code3: "MDV", name: "Maldives", number: "462" },
  { code: "ML", code3: "MLI", name: "Mali", number: "466" },
  { code: "MT", code3: "MLT", name: "Malta", number: "470" },
  { code: "MH", code3: "MHL", name: "Marshall Islands", number: "584" },
  { code: "MQ", code3: "MTQ", name: "Martinique", number: "474" },
  { code: "MR", code3: "MRT", name: "Mauritania", number: "478" },
  { code: "MU", code3: "MUS", name: "Mauritius", number: "480" },
  { code: "YT", code3: "MYT", name: "Mayotte", number: "175" },
  { code: "MX", code3: "MEX", name: "Mexico", number: "484" },
  {
    code: "FM",
    code3: "FSM",
    name: "Micronesia, Federated States of",
    number: "583",
  },
  { code: "MD", code3: "MDA", name: "Moldova, Republic of", number: "498" },
  { code: "MC", code3: "MCO", name: "Monaco", number: "492" },
  { code: "MN", code3: "MNG", name: "Mongolia", number: "496" },
  { code: "ME", code3: "MNE", name: "Montenegro", number: "499" },
  { code: "MS", code3: "MSR", name: "Montserrat", number: "500" },
  { code: "MA", code3: "MAR", name: "Morocco", number: "504" },
  { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508" },
  { code: "MM", code3: "MMR", name: "Myanmar", number: "104" },
  { code: "NA", code3: "NAM", name: "Namibia", number: "516" },
  { code: "NR", code3: "NRU", name: "Nauru", number: "520" },
  { code: "NP", code3: "NPL", name: "Nepal", number: "524" },
  { code: "NL", code3: "NLD", name: "Netherlands", number: "528" },
  { code: "NC", code3: "NCL", name: "New Caledonia", number: "540" },
  { code: "NZ", code3: "NZL", name: "New Zealand", number: "554" },
  { code: "NI", code3: "NIC", name: "Nicaragua", number: "558" },
  { code: "NE", code3: "NER", name: "Niger", number: "562" },
  { code: "NG", code3: "NGA", name: "Nigeria", number: "566" },
  { code: "NU", code3: "NIU", name: "Niue", number: "570" },
  { code: "NF", code3: "NFK", name: "Norfolk Island", number: "574" },
  { code: "MP", code3: "MNP", name: "Northern Mariana Islands", number: "580" },
  { code: "NO", code3: "NOR", name: "Norway", number: "578" },
  { code: "OM", code3: "OMN", name: "Oman", number: "512" },
  { code: "PK", code3: "PAK", name: "Pakistan", number: "586" },
  { code: "PW", code3: "PLW", name: "Palau", number: "585" },
  {
    code: "PS",
    code3: "PSE",
    name: "Palestinian Territory, Occupied",
    number: "275",
  },
  { code: "PA", code3: "PAN", name: "Panama", number: "591" },
  { code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598" },
  { code: "PY", code3: "PRY", name: "Paraguay", number: "600" },
  { code: "PE", code3: "PER", name: "Peru", number: "604" },
  { code: "PH", code3: "PHL", name: "Philippines", number: "608" },
  { code: "PN", code3: "PCN", name: "Pitcairn", number: "612" },
  { code: "PL", code3: "POL", name: "Poland", number: "616" },
  { code: "PT", code3: "PRT", name: "Portugal", number: "620" },
  { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630" },
  { code: "QA", code3: "QAT", name: "Qatar", number: "634" },
  {
    code: "MK",
    code3: "MKD",
    name: "Macedonia, The Former Yugoslav Republic of",
    number: "807",
  },
  { code: "RO", code3: "ROU", name: "Romania", number: "642" },
  { code: "RU", code3: "RUS", name: "Russian Federation", number: "643" },
  { code: "RW", code3: "RWA", name: "Rwanda", number: "646" },
  { code: "RE", code3: "REU", name: "Reunion", number: "638" },
  { code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652" },
  { code: "SH", code3: "SHN", name: "Saint Helena", number: "654" },
  { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659" },
  { code: "LC", code3: "LCA", name: "Saint Lucia", number: "662" },
  {
    code: "MF",
    code3: "MAF",
    name: "Saint Martin (French part)",
    number: "663",
  },
  {
    code: "PM",
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
    number: "666",
  },
  {
    code: "VC",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
    number: "670",
  },
  { code: "WS", code3: "WSM", name: "Samoa", number: "882" },
  { code: "SM", code3: "SMR", name: "San Marino", number: "674" },
  { code: "ST", code3: "STP", name: "Sao Tome and Principe", number: "678" },
  { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682" },
  { code: "SN", code3: "SEN", name: "Senegal", number: "686" },
  { code: "RS", code3: "SRB", name: "Serbia", number: "688" },
  { code: "SC", code3: "SYC", name: "Seychelles", number: "690" },
  { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694" },
  { code: "SG", code3: "SGP", name: "Singapore", number: "702" },
  {
    code: "SX",
    code3: "SXM",
    name: "Sint Maarten (Dutch part)",
    number: "534",
  },
  { code: "SK", code3: "SVK", name: "Slovakia", number: "703" },
  { code: "SI", code3: "SVN", name: "Slovenia", number: "705" },
  { code: "SB", code3: "SLB", name: "Solomon Islands", number: "090" },
  { code: "SO", code3: "SOM", name: "Somalia", number: "706" },
  { code: "ZA", code3: "ZAF", name: "South Africa", number: "710" },
  {
    code: "GS",
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    number: "239",
  },
  { code: "SS", code3: "SSD", name: "South Sudan", number: "728" },
  { code: "ES", code3: "ESP", name: "Spain", number: "724" },
  { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144" },
  { code: "SD", code3: "SDN", name: "Sudan", number: "729" },
  { code: "SR", code3: "SUR", name: "Suriname", number: "740" },
  { code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", number: "744" },
  { code: "SE", code3: "SWE", name: "Sweden", number: "752" },
  { code: "CH", code3: "CHE", name: "Switzerland", number: "756" },
  { code: "SY", code3: "SYR", name: "Syrian Arab Republic", number: "760" },
  { code: "TW", code3: "TWN", name: "Taiwan", number: "158" },
  { code: "TJ", code3: "TJK", name: "Tajikistan", number: "762" },
  {
    code: "TZ",
    code3: "TZA",
    name: "Tanzania, United Republic of",
    number: "834",
  },
  { code: "TH", code3: "THA", name: "Thailand", number: "764" },
  { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626" },
  { code: "TG", code3: "TGO", name: "Togo", number: "768" },
  { code: "TK", code3: "TKL", name: "Tokelau", number: "772" },
  { code: "TO", code3: "TON", name: "Tonga", number: "776" },
  { code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780" },
  { code: "TN", code3: "TUN", name: "Tunisia", number: "788" },
  { code: "TR", code3: "TUR", name: "Turkey", number: "792" },
  { code: "TM", code3: "TKM", name: "Turkmenistan", number: "795" },
  { code: "TC", code3: "TCA", name: "Turks and Caicos Islands", number: "796" },
  { code: "TV", code3: "TUV", name: "Tuvalu", number: "798" },
  { code: "UG", code3: "UGA", name: "Uganda", number: "800" },
  { code: "UA", code3: "UKR", name: "Ukraine", number: "804" },
  { code: "AE", code3: "ARE", name: "United Arab Emirates", number: "784" },
  { code: "GB", code3: "GBR", name: "United Kingdom", number: "826" },
  {
    code: "UM",
    code3: "UMI",
    name: "United States Minor Outlying Islands",
    number: "581",
  },
  { code: "US", code3: "USA", name: "United States", number: "840" },
  { code: "UY", code3: "URY", name: "Uruguay", number: "858" },
  { code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860" },
  { code: "VU", code3: "VUT", name: "Vanuatu", number: "548" },
  { code: "VE", code3: "VEN", name: "Venezuela", number: "862" },
  { code: "VN", code3: "VNM", name: "Vietnam", number: "704" },
  { code: "VG", code3: "VGB", name: "Virgin Islands, British", number: "092" },
  { code: "VI", code3: "VIR", name: "Virgin Islands, U.S.", number: "850" },
  { code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876" },
  { code: "EH", code3: "ESH", name: "Western Sahara", number: "732" },
  { code: "YE", code3: "YEM", name: "Yemen", number: "887" },
  { code: "ZM", code3: "ZMB", name: "Zambia", number: "894" },
  { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716" },
  { code: "AX", code3: "ALA", name: "Åland Islands", number: "248" },
];

export const newRegisterObj = {
  firstName: "",
  lastName: "",
  dob: null,
  idNumber: "",
  medicalNo: "",
  street: "",
  city: "",
  state: "",
  phoneNumber: "",
  sex: "",
  email: "",
  isVaccinated: null,
  country: "United States",
  street2: "",
  countryCode: "",
  zip: "",
  testSendQty: MIN_TEST_QUANTITY,
  isNew: true,
  isSecondary: "N",
};

export const newLocationObject = {
  name: "",
  primary_contact: "",
  contact_email: "",
  street: "",
  city: "",
  state: "",
  phoneNumber: "",
  email: "",
  street2: "",
  countryCode: "",
  zip: "",
  preRegistration: false,
  isNew: true,
  testOrderCategory: "1",
  testLimit: 6,
  country: "United States",
};

export const TEST_ORDER_QUANTITY = [
  { label: 2, value: 2 },
  { label: 4, value: 4 },
  { label: 6, value: 6 },
  { label: 8, value: 8 },
];

export const PERSONALIZATION = {
  member: "1,2,3,4,14,15,20,21,23,24",
  client: "1,6,7,8,12,13,14",
  facility: "1,6,7,8,12,13,14",
  subAgent: "1,6,7,8,12,13,14",
  orders: "1,2,3,12,13,14,20",
  memberOrders: "1,2,3,4,5,6,7",
  employeeClaim: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20",
  checkEligibility: "1,2,3,4,5,6,7,8,9,10,11",
  billing: "2,3,4,5,6,14,15,16,17,18,21,22",
  provider: "1,2,3,4,5",
  systemUser: "1,2,3,4,5,6",
};

export const INSURANCE_PROVIDER = payerlist;
export const DIAGNOSIS_OPTIONS = diagnosisCodesList;
export const POS_OPT = placeOfServices;
export const PROC_CODE_OPT = cptCodesAndDesc.PROCEDURE_CODE;
export const ERA_DENAIEL_REASONS = eraDenialsReasons;
export const ERA_DENAIEL_REMARKS = eraDenialsRemarks;

export const PARSE_BENEFIT_CODE = {
  1: "Active Coverage",
  2: "Active - Full Risk Capitation",
  3: "Active - Services Capitated",
  4: "Active - Services Capitated to Primary Care Physician",
  5: "Active - Pending Investigation",
  6: "Inactive",
  7: "Inactive - Pending Eligibility Update",
  8: "Inactive - Pending Investigation",
  A: "Co-Insurance",
  B: "Co-Payment",
  C: "Deductible",
  CB: "Coverage Basis",
  D: "Benefit Description",
  E: "Exclusions",
  F: "Limitations",
  G: "Out of Pocket (Stop Loss)",
  H: "Unlimited",
  I: "Non-Covered",
  J: "Cost Containment",
  K: "Reserve",
  L: "Primary Care Provider",
  M: "Pre-Existing Condition",
  MC: "Managed Care Coordinator",
  N: "Services Restricted to Following Provider",
  O: "Not Deemed a Medical Necessity",
  P: "Benefit Disclaimer",
  Q: "Second Surgical Opinion Required",
  R: "Other or Additional Payor",
  S: "Prior Year(s) History",
  T: "Card(s) Reported Lost/Stolen",
  U: "Contact Following Entity for Eligibility or Benefit Information",
  V: "Cannot Process",
  W: "Other Source of Data",
  X: "Health Care Facility",
  Y: "Spend Dow",
};

export const currencyformatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const SUB_AGENT_HEADERS = [
  "name",
  "street",
  "street2",
  "city",
  "state",
  "zip",
  "phoneNumber",
  "webSite",
  "contact_first_name",
  "contact_last_name",
  "contact_email",
  "main_phone_number",
];
export const COLUMNS = {
  1: "First",
  2: "Second",
  3: "Third",
  4: "Fourth",
  5: "Fifth",
  6: "Sixth",
  7: "Seventh",
  8: "Eighth",
  9: "Ninth",
  10: "Tenth",
  11: "Eleventh",
  12: "Twelfth",
};

export const ORDER_FILTER_CAPS = {
  orderStatus: true,
  firstName: true,
  lastName: true,
  requestedByName: true,
};

export const MEDICARE_ID = "01192";

export const TEST_QUANTITY = [
  { value: 0, label: 0 },
  { value: 8, label: 8 },
  { value: 16, label: 16 },
  { value: 24, label: 24 },
  { value: 32, label: 32 },
  { value: 40, label: 40 },
];

export const ORDER_QUANTITY = [
  { value: 0, label: 0 },
  { value: 8, label: 8 },
];

export const ActionEventType = ["Eligibility", "OrderShipped"];

export const PROCEDURE_CODE_HEADER = [
  "Date of Service",
  "Place of Service",
  "Procedure",
  "Modifier1",
  "Modifier2",
  "Modifier3",
  "Modifier4",
  "Diag1",
  "Diag2",
  "Diag3",
  "Diag4",
  "Billed",
  "Units",
];

export const ERA_PROCEDURE_CODE_HEADER = [
  "Paid Date",
  "Claim Number",
  "DOS",
  "Provider Name",
  "Provider Npi",
  "Cheque Number",
  "Proc code",
  "Units",
  "Modifiers",
  "Billed",
  "Allowed",
  "Deduct",
  "Co-Ins",
  "Co-Pay",
  "Write Off",
  "Paid",
  "Status",
];

export const PREV_CLAIM_TABLE = [
  "Date of Service",
  "Procedure Code",
  "Payer Name",
  "Amount Billed",
  "Rendering Provider",
];

export const PREV_CLAIM_ACTIONS = {
  auto: "auto",
  update: "update",
  new: "new",
};

export const CLAIM_CHANGE_OPTS = [
  { label: "Submitted", value: claimSubmitStatus.submitted },
  { label: "Denied", value: claimSubmitStatus.denied },
  { label: "Rejected", value: claimSubmitStatus.rejected },
];

export const CLAIM_STATUS_LOG_OBJ = {
  submitted: "SubmitForBill",
  denied: "ClaimDenied",
  rejected: "ClaimRejected",
};

export const DIAGNOSIS_ITEMS = ["A", "B", "C", "D", "E", "F", "G", "H"];

export const PARSE_ALPHA_INTO_NUM = {
  A: "1",
  B: "2",
  C: "3",
  D: "4",
  E: "5",
  F: "6",
  G: "7",
  H: "8",
};

export const ClaimDropDownOptions = [
  { value: "logs", title: "Logs" },
  {
    value: "delete",
    title: "Delete",
    // title: (
    //   <img
    //     src={TrashIcon}
    //     alt="trash icon"
    //     width="15"
    //     onMouseLeave={(e) => (e.target.style.color = "black")}
    //     onMouseOver={(e) => {
    //       e.target.style.cursor = "pointer";
    //     }}
    //     aria-hidden="true"
    //   />
    // ),
  },
];

export const LAB_CLAIM_STATUS_OPT = [
  {
    value: "submitted",
    label: "Submitted",
  },
  { value: "denied", label: "Denied" },
  { value: "approved", label: "Approved" },
];

export const HOSPICE_MODFIRE = {
  GW: "GW",
  GV: "GV",
};

export const MOD_KEYS = ["mod1", "mod2", "mod3", "mod4"];

export const usersNo = {
  ibrahim: "+923234217605",
};

export const ProcTooltipHeader = ["Code", "Description"];

export const ERA_VIEW_STATUS = {
  paid: "paid",
  denied: "denied",
  partial: "partial",
};

export const DEFAULT_PROVIDERS = [
  {
    value: "renderingProvider",
    label: "Rendering Provider",
  },
  { value: "referringProvider", label: "Referring Provider" },
  { value: "orderingProvider", label: "Ordering Provider" },
  { value: "referringPyhsician", label: "Referring Pyhsician" },
  { value: "primaryCarePhysician", label: "Primary Care Physician" },
];
export const PARSE_PROVIDER = {
  renderingProvider: "Rendering Provider",
  referringProvider: "Referring Provider",
  orderingProvider: "Ordering Provider",
  referringPyhsician: "Referring Pyhsician",
  primaryCarePhysician: "Primary Care Physician",
};

export const DEFAULT_PROVIDERS_ARR = [
  "renderingProvider",
  "referringProvider",
  "orderingProvider",
  "referringPyhsician",
  "primaryCarePhysician",
];

export const ADMIN_USERS = ["+18888888888", "+17777777777"];
