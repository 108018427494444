import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import Select from "react-select";
import { Button, Modal, Table, Form } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { INSURANCE_PROVIDER, MEDICATRE_IDS, TEST_ORDER_QUANTITY } from "constant";
import { getValidDep, convertToUpper } from "utils";
import { t } from "stringConstants";
import moment from "moment";

import Loader from "components/Loader/Loader";
import { currencyformatter } from "constant";
import ErrorMessage from "components/Message/ErrorMessage";
import { CONFIG } from "constant";
import api from "api";
import { formatNumber } from "utils";

const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const EligibilityViewModal = (props) => {
  const { handleClose, data, eligibilityFiles, user } = props;
  const [newUser, setNewUser] = useState(data);
  const [checkboxes, setCheckboxes] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      setNewUser(data.map((m, i) => ({ ...m, id: i })));
    }
  }, [data]);

  console.log("newUser", newUser);

  const handlePlaceOrder = async () => {
    if (!quantity) {
      setError("Please Select Order Quantity");
      return;
    }
    if (checkboxes.every((s) => s.status !== "Eligibile")) {
      setError("Select at least one Eligible member");
      return;
    }
    try {
      setLoading(true);
      const fileName = `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_order_test.csv`;
      const data = checkboxes
        .filter((f) => f.status === "Eligibile")
        .map((m) => ({
          ...m,
          quantity: quantity,
          payerId: m.payerId.padStart(5, "0"),
          orderDate: moment().format("YYYYMMDD"),
        }));
      await api.addEligibilityCsv(data, fileName, CONFIG.eligibilityBucket);
      const obj = {
        fileType: "Order",
        fileName,
        totalRecord: data.length,
        clientID: eligibilityFiles.clientID,
        locationID: eligibilityFiles.locationID,
        locationName: eligibilityFiles.locationName,
        clientName: eligibilityFiles.clientName,
      };
      const res = await api.newEligibiliyFiles(obj, user);
      handleClose(data);
      setLoading(false);
    } catch (err) {
      console.log("err", err.message);
      setLoading(false);
    }
  };

  const TableRow = ({ user, index }) => {
    return (
      <tr key={index}>
        <td style={{ width: "10%" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              checked={checkboxes.findIndex((f) => f.id === user.id) !== -1}
              onChange={(e) =>
                e.target.checked
                  ? setCheckboxes((prev) => [...prev, user])
                  : setCheckboxes((prev) => prev.filter((f) => f.id !== user.id))
              }
            />
          </Form.Check>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.firstName}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.lastName}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.dob}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.medicareAgent}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.medicareNo}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.status}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Modal
        show
        animation={true}
        size="xl"
        onHide={() => {
          handleClose();
        }}
        backdrop="static"
        style={{ paddingLeft: "0" }}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {t("eligibilityStatus")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="d-flex align-items-center w-100">
            <div className="form-group-eligibity-wrapper col-md-4">
              <label className="modalLineHeaders fw-bold">Total Eligible: </label>
              <span className="fw-bold">{newUser.filter((f) => f.status === "Eligibile").length}</span>
            </div>
            <div className="form-group-eligibity-wrapper  col-md-4">
              <label className="modalLineHeaders fw-bold">Total Ineligible: </label>
              <span className="fw-bold">{newUser.filter((f) => f.status !== "Eligibile").length}</span>
            </div>
            <div className="form-group-eligibity-wrapper  col-md-4">
              <label className="modalLineHeaders fw-bold">Order Quantity:</label>
              <Select
                options={TEST_ORDER_QUANTITY}
                className="w-100"
                placeholder="Quantity"
                onChange={(e) => setQuantity(e.value)}
              />
            </div>
          </div>
          <div>
            <span className="fw-bold">Selected Record : ({formatNumber(checkboxes.length)})</span>
          </div>
          {/* <div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">First Name: </label>
                <span>{newUser.ins_name_f}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Last Name: </label>
                <span>{newUser.ins_name_l}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Status:</label>
                <span className="fw-bold">
                  {newUser.benefit[0].benefit_coverage_description}
                </span>
              </div>
            </div> */}
          {/* <div className="form-group-wrapper">
              <label className="modalLineHeaders">DOB: </label>
              <input
                value={moment(newUser.ins_dob).format("YYYY-MM-DD")}
                type="date"
                className="modalInput"
                placeholder="Dob"
                readOnly
              />
            </div> */}

          {/* <div className="col-lg-12 col-12 mb-3">
                <label className="modalLineHeaders mt-0">
                  Insurance Provider:
                </label>
                <Select
                  options={INSURANCE_PROVIDER}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select Insuraance Provider"
                  className="w-100"
                  value={insuranceObject}
                  readOnly
                />
              </div>

              {newUser.insuranceCompany &&
                MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                  <>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Group ID:</label>
                      <input
                        value={newUser.insuranceGroupId}
                        className="modalInput"
                        maxLength={16}
                        placeholder="Enter Group ID"
                        style={{
                          width: "100%",
                        }}
                        readOnly
                      />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Insurance ID#:</label>
                      <input
                        value={newUser.medicalNo}
                        className="modalInput"
                        maxLength={16}
                        placeholder="Enter Insurance ID"
                        style={{
                          width: "100%",
                        }}
                        readOnly
                      />
                    </div>
                  </>
                )}

              {newUser.insuranceCompany &&
                MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1 && (
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">ID#:</label>
                    <input
                      value={newUser.medicalNo}
                      className="modalInput"
                      maxLength={16}
                      placeholder={
                        MEDICATRE_IDS.includes(newUser.insuranceCompany)
                          ? "Medicare Number"
                          : "ID Number"
                      }
                      style={{
                        width: "100%",
                      }}
                      readOnly
                    />
                  </div>
                )} */}

          {/* <div className="form-group-eligibity-wrapper">
              <label className="modalLineHeaders fw-bold">
                Plan Begin Date:
              </label>
              <span>
                {`${moment(newUser.planStartDate).format("MMM DD, YYYY")} -
                  ${moment(newUser.planEndDate).format("MMM DD, YYYY")}`}
              </span>
            </div>
          </div> */}
          <div className="form-group-wrapper d-block">
            <div className="table-responsive" style={{ overflow: "hidden" }}>
              <Table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>
                      <Form.Check>
                        <Form.Check.Input
                          type="checkbox"
                          className="my-2"
                          onChange={(e) => (e.target.checked ? setCheckboxes(newUser) : setCheckboxes([]))}
                        />
                      </Form.Check>
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      First Name
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      Last Name
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      DOB
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      Insurance Provider
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      ID #
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newUser.length > 0 &&
                    newUser.map((user, index) => <TableRow user={user} index={index} key={index} />)}
                </tbody>
              </Table>
            </div>
          </div>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </Modal.Body>
        {!loading ? (
          <Modal.Footer>
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              disabled={checkboxes.length === 0}
              onClick={handlePlaceOrder}
            >
              Place Order
            </Button>
          </Modal.Footer>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

export default EligibilityViewModal;
