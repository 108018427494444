import { claimSubmitStatusColorOpts } from "constant";
import React from "react";

const SingleStatsCard = (props) => {
  const { label, totalCounts, amount, respCss, handelClick, filterKey, value, color, title } = props;

  return (
    <div className="single-stats-item">
      <div
        className="card-body custom-card-body py-1 cursor-pointer"
        onClick={() => handelClick && handelClick({ [filterKey]: value })}
      >
        <div className="detail d-flex align-items-center">
          <p
            className="title mb-0 fw-bold textUnderLine text-center"
            style={{ color: `${value && claimSubmitStatusColorOpts[value]}` }}
          >
            {label || title}
          </p>
        </div>
        <span className="number total-number" style={{ color: color }}>
          {amount || 0}
        </span>
        <span className="number total-number">{totalCounts || 0}</span>
      </div>
    </div>
  );
};

export default SingleStatsCard;
