import React, { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import { getValidName } from "utils";
const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const AutoCompleteAddress = (props) => {
  const { handleChange, value, cssClass, disabled } = props;
  const [address, setAddress] = useState(null);

  useEffect(() => {
    if (address) {
      !disabled && handleChange(address, true);
    }
  }, [address]);

  const handleAddressUpdate = (place) => {
    console.log("place", place);
    if (!place || Object.keys(place).length === 1) return;

    const obj = {};
    let address1 = "";
    let postcode = "";
    for (const curr of place.address_components) {
      const type = curr.types[0];

      switch (type) {
        case "street_number": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "premise": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "plus_code": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "neighborhood": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_4": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_3": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_2": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "route": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "postal_code": {
          postcode = `${curr.long_name}`;
          break;
        }
        case "postal_code_suffix": {
          postcode = `${postcode}-${curr.long_name}`;
          break;
        }
        case "postal_town": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "locality": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "administrative_area_level_1": {
          Object.assign(obj, { city: obj.city || curr.long_name, state: curr.short_name });
        }
        case "country": {
          Object.assign(obj, { country: curr.long_name, isoCode: curr.short_name });
          break;
        }
      }
    }
    Object.assign(obj, { street: address1.trim() || place.name, zipcode: postcode });
    setAddress({ ...obj });
  };
  return (
    <Autocomplete
      apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
      onPlaceSelected={(place) => {
        handleAddressUpdate(place);
      }}
      value={value}
      options={options}
      className={cssClass || "form-control"}
      placeholder="Enter Address"
      onChange={(e) => !disabled && handleChange(getValidName(e.target.value))}
    />
  );
};

export default AutoCompleteAddress;
