import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import dotIcons from "assets/img/dots.png";

const DropdownMenu = (props) => {
  const { options, handleDropDownOptions } = props;
  return (
    <>
      <Dropdown className="custom-dropdown">
        <Dropdown.Toggle id="dropdown-basic">
          <img src={dotIcons} alt="more icon" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map((option, index) => (
            <Dropdown.Item href={option.link} key={index} onClick={() => handleDropDownOptions(option.value)}>
              {option.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropdownMenu;
