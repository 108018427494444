import React, { useEffect, useState } from "react";

const ErrorMessage = (props) => {
  const { error, handleChange, timeLess } = props;
  useEffect(() => {
    if (!timeLess) setTimeout(() => handleChange(), 4000);
  }, []);

  return (
    <div style={{ color: "red" }}>
      <h6>{error}</h6>
    </div>
  );
};

export default ErrorMessage;
