import React, { useState, useEffect, useCallback, useRef } from "react";
import notesOnIcon from "../../src/assets/img/file-icon.png";
import notesOffIcon from "../../src/assets/img/file-icon-gray.png";

const Notes = (props) => {
  const { note, handleClick } = props;

  return (
    <div className="action-exclamation" onClick={() => handleClick()}>
      <span
        className="cursor-pointer"
        title={note || "Add Note"}
        style={{
          borderColor: note ? "rgb(83 83 232)" : "rgb(186 184 184)",
        }}
      >
        {/* <i
          className="fa fa-scroll"
          style={{ color: emp.notes && emp.notes.length > 0 ? "rgb(83 83 232)" : "rgb(186 184 184)" }}
        /> */}
        {note ? (
          <img className="notesIcon" src={notesOnIcon} alt="notes-on-img" />
        ) : (
          <img className="notesIcon" src={notesOffIcon} alt="notes-off-img" />
        )}
      </span>
    </div>
  );
};

export default Notes;
