import React, { useState, useEffect } from "react";

const PdfViewer = ({ url }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const handleDownload = async () => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setPdfUrl(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    };

    handleDownload();

    // return () => {
    //   if (objectUrl) {
    //     URL.revokeObjectURL(objectUrl);
    //   }
    // };
  }, [url]);

  return (
    <div>
      {pdfUrl ? (
        <embed src={pdfUrl} type="application/pdf" width="100%" height="600px" title="PDF Viewer" />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PdfViewer;
