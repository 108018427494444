import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = (props) => {
  const { title, message, handleConfirm, closeBtn, actionType, handleClose, cssClass } = props;
  return (
    <Modal
      show
      backdrop="static"
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose ? handleClose() : handleConfirm(false, actionType);
      }}
      className={cssClass}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleConfirm(false, actionType);
          }}
        >
          {closeBtn ? "No" : "Go back"}
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => {
            handleConfirm(true, actionType);
          }}
        >
          {closeBtn ? "Yes" : "Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
