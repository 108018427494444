import React, { useState, useContext, useRef } from "react";
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { Auth } from "aws-amplify";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { AppContext } from "../context/app-context";
import Filter from "../components/Filter";
import TimeFilter from "../components/TimeFilter";
import HeaderItem from "components/Table/HeaderItem";
import UserModal from "components/Users/UserModal";
import UserPasswordModal from "components/Users/UserPasswordModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import TDData from "components/Table/TDData";
import {
  EmptyUserObj,
  USER_ROLE_TYPE,
  PAGE_LIMIT,
  HEALTH_AND_SAFTEY,
  USER_ROLE_TITLE,
  CONFIG,
} from "../constant";
import { USER_PERSONALIZE } from "constants/personalization"
import { t } from "../stringConstants";
import {
  formatPhoneNumber,
  setPhoneNo,
  capitalizeLetter,
  personalizationLocalStorage,
  tdCheckBox,
  calculateTdWidth,
  tdEmail,
  tdPhone,
  formatNumber,
} from "../utils";
import EditIcon from "../assets/img/edit-icon.png";
import TrashIcon from "../assets/img/trash-icon.png";
import Select from "react-select";
import "../../src/tableStyling.css";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Icon from "../components/Icon";

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [newUser, setNewUser] = useState(EmptyUserObj);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [fullscreen, setFullscreen] = useState(true);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] =
    useState(false);
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const componentRef = useRef();

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(
    width - 160,
    personalize.length > 0 ? personalize.filter((p) => p.isCheck).length : 6
  );

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Role",
    "Associated",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);
  // const [pageNumbers, setPageNumbers] = useState([]);
  const [clients, setClients] = useState([]);

  const setUsersArray = async (admins, employers, clients, subAgents) => {
    if (!admins || admins === undefined) return;
    const users = [];
    if (appContext.user?.isAdmin()) {
      await users.push(
        ...admins.map((admin) => formatUserObject(admin, "Admin"))
      );
      users.push(
        ...employers.map((employer) => formatUserObject(employer, "Employer"))
      );
      users.push(
        ...clients.map((coordinator) => formatUserObject(coordinator, "Client"))
      );
      users.push(
        ...subAgents.map((coordinator) =>
          formatUserObject(coordinator, "SubAgents")
        )
      );
    } else if (appContext.user?.isClient()) {
      users.push(
        ...employers
          .map((employer) => formatUserObject(employer, "Employer"))
          .filter(
            (user) =>
              appContext.locations.findIndex((f) => f.id === user.clientID) !==
              -1
          )
      );
    }
    setUsers(sortUsers(users, sortBy));
    setFilteredUsers(sortUsers(users, sortBy));
    // return users;
  };

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) =>
        a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0
      );
    } else {
      return [...users].sort((a, b) =>
        b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0
      );
    }
  };

  const formatUserObject = (user, role) => {
    const newUser = {};
    const email = user.Attributes.find((attr) => {
      return attr.Name === "email";
    }).Value;
    newUser.email = email;
    const phone = user.Attributes.find((attr) => {
      return attr.Name === "phone_number";
    }).Value;
    const firstName = user.Attributes.find((attr) => {
      return attr.Name === "custom:firstName";
    })?.Value;
    const lastName = user.Attributes.find((attr) => {
      return attr.Name === "custom:lastName";
    })?.Value;

    const note = user.Attributes.find((attr) => {
      return attr.Name === "custom:note";
    })?.Value;
    const clientID = user.Attributes.find((attr) => {
      return attr.Name === "custom:labID";
    })?.Value;

    newUser.name = firstName && lastName ? `${firstName} ${lastName}` : "-";
    newUser.phone_number = formatPhoneNumber(phone);
    newUser.phone = phone;
    newUser.username = user.Username;
    newUser.firstName = firstName ? `${capitalizeLetter(firstName)}` : "-";
    newUser.lastName = lastName ? `${capitalizeLetter(lastName)}` : "-";
    newUser.status = user.UserStatus;
    newUser.role = role;
    (newUser.roleTitle = USER_ROLE_TITLE[role]), (newUser.clientID = clientID);
    newUser.associated = note || "N/A";

    const obj = Object.assign(
      {
        role: role,
      },
      newUser
    );
    return obj;
  };

  const filterUsers = (searchTerm, searchInput) => {
    return setFilteredUsers(
      users.filter((user) => user[searchTerm].includes(searchInput))
    );
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "First Name") {
          return (
            obj.firstName &&
            obj.firstName.toLowerCase().includes(filters[key].toLowerCase())
          );
        }

        if (key === "Last Name") {
          return (
            obj.lastName &&
            obj.lastName.toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "userRole") {
          return (
            obj.userRole &&
            USER_ROLE_TITLE[obj.userRole]
              .toLowerCase()
              .includes(filters[key].toLowerCase())
          );
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  const createUser = async (user) => {
    try {
      setLoading(true);
      let userId = null;
      const phoneNo = user.phone;
      let clientName = "System";
      let connectedID = null;

      if (user.roles.length > 0) {
        clientName = user.roles[0].title || "System";
        connectedID = user.roles[0].relatedId;
      }

      if (!user.role) {
        appContext.showErrorMessage("No Role is Assigned");
        return;
      }

      if (user.isNew) {
        const attributePayload = {
          preferred_username: phoneNo,
          email: user.email,
          phone_number: phoneNo,
          "custom:role": user.role,
          "custom:autoConfirm": "true",
          "custom:firstName": `${user.firstName}`,
          "custom:lastName": `${user.lastName}`,
          "custom:note": clientName,
          "custom:labID": connectedID,
          "custom:personalisation": JSON.stringify({
            personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
          }),
        };

        const signUp = await Auth.signUp({
          username: phoneNo,
          password: user.password,
          attributes: attributePayload,
        });
        if (signUp) {
          await api.addUserClients(user, clientName, connectedID);
        }
        userId = signUp.userSub;
        appContext.showSuccessMessage("Create User Successfully");
      } else {
        userId = user.username;
        await api.updateUserInfo(user, clientName, connectedID);
        if (!newUser.clientID) {
          await api.udpatePersonalization(userId, {
            personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
          });
        }
        appContext.showSuccessMessage("Update User Successfully");
      }

      let msg = `Hello, you have been added as an administrator for SafeCamp HR. Please login at https://www.houston.safecamphr.com/. User Name: ${user.phone} \nPassword: ${user.password}`;
      if (userId) {
        await appContext.resetUsers();
      }
    } catch (error) {
      console.log("ERROR:User-", error);
      setLoading(false);
      appContext.showErrorMessage(error.message);
      setOpenCreator(true);
    }
  };

  const handleResetPassword = (obj) => {
    console.log("Password Obj", obj);
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = (users) => {
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredUsers);

  const getPageNumbers = (users) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);

  useEffect(() => {
    setLoading(true);
    appContext.resetUsers();
  }, []);

  useEffect(() => {
    if (appContext.users.length > 0) {
      setUsers(sortUsers(appContext.users, sortBy));
      setFilteredUsers(
        sortUsers(nestedFilter(appContext.users, filter), sortBy)
      );
      // appContext.users.then((resp) => {
      //   setUsersArray(resp[0], resp[1], resp[2], resp[3]);
      //   setLoading(false);
      // });
      setPersonalize(
        personalizationLocalStorage.get(
          appContext?.user,
          "systemUser",
          USER_PERSONALIZE
        )
      );
    }
    setLoading(false);
  }, [appContext.users]);

  useEffect(() => {
    if (filteredUsers.length > 0)
      setFilteredUsers(sortUsers(filteredUsers, sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(nestedFilter(users, filter));
      setCurrentPage(1);
    }
  }, [filter]);

  if (filteredUsers.length > 0) {
    pageNumbers = getPageNumbers(filteredUsers);
  }

  const onRoleChange = async (e, user) => {
    console.log("EVENT HERE", e, user);
    setLoading(true);
    await api.changeUserRole(user.role, e.value, user.username);
    setTimeout(() => {
      appContext.resetUsers();
    }, 1500);
  };

  const renderPageNumbers = () => {
    let slicedPages = pageNumbers;
    if (currentPage === 1) {
      slicedPages = pageNumbers.slice(0, 3);
    } else if (currentPage === pageNumbers.length) {
      slicedPages = pageNumbers.slice(currentPage - 3, currentPage);
    } else {
      slicedPages = pageNumbers.slice(currentPage - 2, currentPage + 1);
    }

    return slicedPages.map((number) => {
      return (
        <li
          key={number}
          onClick={() => handleChangePage(number)}
          style={{
            textDecoration: currentPage === number ? "none" : "underline",
            color: currentPage === number ? "black" : "#A82632",
            display: "inline-block",
            padding: 5,
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration =
              currentPage === number ? "none" : "underline";
          }}
        >
          {number}
        </li>
      );
    });
  };

  const roleChangeOptions = [
    { value: "Admin", label: "Admin" },
    { value: "Lab", label: "Lab" },
    { value: "Tester", label: "Tester" },
    { value: "Employee", label: "Crew" },
    { value: "Employer", label: "Health & Safety" },
  ];

  const headerAlign = (item) => (item === "center" ? "centered" : undefined);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const getUserLabName = (user) => {
    return appContext.labs.find((lab) => {
      if (lab.lab_contacts !== null && lab.lab_contacts) {
        const isExists = lab.lab_contacts.find((u) => u === user.username);
        if (isExists) return lab;
      }
    });
  };

  const getUserSiteName = (user) => {
    return appContext.sites.find((site) => {
      if (site.show_contacts !== null && site.show_contacts) {
        const isExists = site.show_contacts.find((u) => u === user.username);
        if (isExists) return site;
      }
    });
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = personalizationLocalStorage.saveAs(
      data,
      "systemUser"
    );
    setOpenPersonalizationModal(false);
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(
      appContext.user.phone_number,
      personalisationData
    );
  };

  const handleOpenCreateModal = () => {
    const obj = EmptyUserObj;
    if (appContext.user?.isClient()) {
      Object.assign(obj, {
        clientID: appContext.userCompany.id,
        client: appContext.userCompany,
        locationID: null,
        location: null,
        subAgent: null,
        subAgentID: null,
      });
    } else if (appContext.user?.isSite()) {
      Object.assign(obj, {
        clientID: null,
        client: null,
        locationID: appContext.userLocation.id,
        location: appContext.userLocation,
        subAgent: null,
        subAgentID: null,
      });
    } else if (appContext.user?.isSubAgent()) {
      Object.assign(obj, {
        clientID: null,
        client: null,
        locationID: null,
        location: null,
        subAgent: appContext.userSubAgent,
        subAgentID: appContext.userSubAgent.id,
      });
    }
    setNewUser({ ...obj });
    setOpenCreator(true);
  };

  const handleEditUser = (user) => {
    let location = null;
    let client = null;
    let subAgent = null;

    if (user.locationID) {
      location = appContext.locations.find((l) => l.id === user.locationID);
      if (location) {
        location = { ...location, value: location.id, label: location.name };
      }
    }
    if (user.clientID) {
      client = appContext.companies.find((l) => l.id === user.clientID);
      if (client) {
        client = { ...client, value: client.id, label: client.name };
      }
    }
    if (user.subAgentID) {
      subAgent = appContext.subAgents.find((f) => f.id === user.subAgentID);

      if (subAgent) {
        subAgent = { ...subAgent, value: subAgent.id, label: subAgent.name };
      }
    }

    const obj = {
      ...user,
      isNew: false,
      prevRole: user.userRole,
      phone: user.phone_number,
      username: user.userID,
      role: user.userRole,
      location,
      client,
      subAgent,
    };
    setNewUser(obj);
    setOpenCreator(true);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await api.deleteUser(userToDelete);
      setUserToDelete(null);
      appContext.showSuccessMessage("User Deleted Successfully");
      appContext.resetUsers();
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const getRoleDetails = (user) => {
    if (
      user.role === USER_ROLE_TYPE.Tester ||
      user.role === USER_ROLE_TYPE.Admin
    ) {
      return "System";
    }
    if (user.role === HEALTH_AND_SAFTEY) {
      const site = getUserSiteName(user);
      if (site) return site.name;
    }
    if (user.role === USER_ROLE_TYPE.Lab) {
      const lab = getUserLabName(user);
      if (lab) return lab.name;
    }
    if (user.role === USER_ROLE_TYPE.Employee) {
      const f = async () => await api.getEmployeeLastShow(user.username);
      return f();
    }
    return user.role;
  };

  const tdFormat = (item, user) => {
    if (item === "updatedAt") return formatDate(user.updatedAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "userRole") return USER_ROLE_TITLE[user.userRole];
    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "email") return tdEmail(user.email);
    if (item.itemKey === "phone_number") return tdPhone(user.phone_number);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const TableRow = ({ user }) => {
    return (
      <tr key={user.username}>
        <td
          className="icon"
          style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}
        >
          <img
            src={EditIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={user.id}
            onClick={(e) => handleEditUser(user)}
          />
        </td>
        {personalize.map((data) => data.isCheck && renderTd(data, user))}

        <td
          className="icon"
          style={{
            textAlign: "center",
            textOverflow: "visible",
            width: "45px",
            minWidth: "45px",
            maxWidth: "45px",
          }}
        >
          <img
            src={TrashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={user.id}
            onClick={(e) => {
              setOpenDeleteConfirmation(true);
              setUserToDelete(user);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {t("users")} (
                  {filteredUsers
                    ? formatNumber(filteredUsers.length)
                    : users.length}
                  )
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => handleOpenCreateModal()}
                    title={"Create a User"}
                    label={"Create"}
                    iconType={"addUserIcon"}
                  />

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                  />
                )}

                <Row className="pull-right" style={{ borderWidth: 1 }}>
                  <Col></Col>
                  <Col
                    style={{ textAlign: "right" }}
                    md={{ span: 3, offset: 1 }}
                    xl={{ span: 3, offset: 1 }}
                  >
                    Page
                    <ul
                      className="createButtons pr-0"
                      style={{ display: "inline" }}
                    >
                      <i
                        className="fas fa-angle-left"
                        style={{ color: "#585858" }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.color = "black";
                        }}
                        onMouseLeave={(e) => (e.target.style.color = "#585858")}
                        onClick={() => {
                          currentPage !== 1 &&
                            handleChangePage(currentPage - 1);
                        }}
                      />
                      {/* {currentPage !== 1 && "..."} */}
                      {currentPage} of {pageNumbers.length}
                      {/* {pageNumbers.length >= currentPage && "..."} */}
                      <i
                        className="fas fa-angle-right"
                        style={{ color: "#585858" }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.color = "black";
                        }}
                        onMouseLeave={(e) => (e.target.style.color = "#585858")}
                        onClick={() => {
                          currentPage !== pageNumbers.length &&
                            handleChangePage(currentPage + 1);
                        }}
                      />
                    </ul>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table>
                    <thead ref={componentRef}>
                      <tr>
                        <th />

                        {personalize.map(
                          (item, i) =>
                            item.isCheck && (
                              <HeaderItem
                                key={i}
                                width={tdWidth}
                                ItemKey={item.itemKey}
                                title={item.title}
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                aligned={headerAlign(item.textAlign)}
                              />
                            )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {usersToMap &&
                        usersToMap.map((user, i) => {
                          return <TableRow key={i} user={user} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Bounce
          style={{
            position: "fixed",
            left: "50%",
            right: "50%",
            top: "50%",
          }}
          size={35}
          color={"#A82632"}
        />
      )}
      {openCreator && (
        <UserModal
          show={openCreator}
          user={newUser}
          clients={clients.map((c) => {
            return { label: c.companyName, value: c.id };
          })}
          handleClose={handleClose}
          handleSave={createUser}
          resetCall={setOpenPasswordModal}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal
          user={newUser}
          handleClose={handlePasswordClose}
          handleSave={handleResetPassword}
        />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete User"
          message="Are you sure, you want to remove User?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default Users;
