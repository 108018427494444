import React from "react";
import { Table } from "react-bootstrap";
import { currencyformatter, ERA_PROCEDURE_CODE_HEADER } from "constant";
import { formatDateMDY } from "utils";

const ProcedureInfoSection = (props) => {
  const { newUser, dataList, eraPdfPath, handleOpenEraFile } = props;

  return (
    <>
      <div className="personal-info-wrapper pt-3 px-3">
        {/* <div className="d-flex justify-content-between font-bold">
          <div className="d-flex flex-column">
            <span>{newUser.bill_name || ""}</span>
            <span>{newUser.bill_addr_1 || ""}</span>
            <span>{newUser.bill_addr_2 || ""}</span>
            <span>
              {newUser.bill_city || ""}, {newUser.bill_state || ""} {newUser.bill_zip || ""}
            </span>
          </div>
          <div className="d-flex flex-column">
            <span>NPI#: {newUser.bill_npi || ""}</span>
            <span>Date#: {formatDateMDY(newUser.from_date)}</span>
            <span>EFT#: 0</span>
            <span>Tax ID#: {newUser.bill_taxid || ""}</span>
          </div>
        </div> */}
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="text-decoration-underline text-black mt-0">ERA Detail</h4>
          {eraPdfPath && (
            <div className="linkTag text-decoration-underline" onClick={() => handleOpenEraFile(eraPdfPath)}>
              Download PDF
            </div>
          )}
        </div>
        <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
          <div className="data-listing-form">
            <div>
              <Table className="table">
                <thead>
                  <tr className="data-listing-row">
                    {ERA_PROCEDURE_CODE_HEADER.map((title) => (
                      <th className="centered">{title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dataList?.map((data, index) => (
                    <tr index={index}>
                      <td className="text-center">{formatDateMDY(data.paid_date)}</td>
                      <td className="text-center">{data.payer_icn}</td>
                      <td className="text-center">{formatDateMDY(data.from_dos)}</td>
                      <td className="text-center ellipsis">{data.prov_name}</td>
                      <td className="text-center">{data.prov_npi}</td>
                      <td className="text-center">{data.check_number}</td>
                      <td className="text-center">{data?.proc_code}</td>
                      <td className="text-center">{data.units}</td>
                      <td className="text-center">
                        {`${data?.mod1 || ""} ${data?.mod2 || ""} ${data?.mod3 || ""} ${data?.mod4 || ""}`.trim()}
                      </td>
                      <td className="text-center">{currencyformatter.format(data.charge || 0)}</td>
                      <td className="text-center">{currencyformatter.format(data.allowed || 0)}</td>
                      <td className="text-center">{currencyformatter.format(data.deductible || 0)}</td>
                      <td className="text-center">{currencyformatter.format(data.coinsurance || 0)}</td>
                      <td className="text-center">{currencyformatter.format(data.copay || 0)}</td>
                      <td className="text-center">{currencyformatter.format(data.adjustedAmount || 0)}</td>
                      <td className="text-center">{currencyformatter.format(data.paid || 0)}</td>
                      <td className="text-center">{data.status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcedureInfoSection;
