import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  Button,
  Modal,
  Card,
  Table,
  Row,
  Col,
  Collapse,
} from "react-bootstrap";
import {
  formatDateMDY,
  formatDateMDYTime,
  toTitleCase,
  formatOrder,
} from "utils";
import { Audit_Types, Audit_Entries } from "constant";
import { Storage } from "aws-amplify";
import { t } from "../../stringConstants";
import "bootstrap/dist/css/bootstrap.min.css";
import ExportToExcel from "components/ExportToExcel";
import { AppContext } from "context/app-context";
import moment from "moment";
import api from "api";
import Loader from "components/Loader/Loader";
import AuditTrailEntries from "./AuditTrailEntries";
import EligibilityDetailsModal from "./CheckEligibility/EligibilityDetailsModal";
import { CONFIG } from "constant";

const EmployeeOrderHistory = ({ user, handleClose }) => {
  const [items, setItems] = useState([]);
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [eligibilityDetailsData, setEligibilityDetailsData] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (items != "") {
      const auditEntries = Audit_Entries.filter(
        (f) => items.findIndex((log) => Audit_Types[log.eventType] === f) !== -1
      );
      setEntries(auditEntries);
    }
  }, [items]);

  const handleOpenDetailModal = async (item) => {
    if (item.apiData) {
      const data = JSON.parse(item.apiData);
      if (data.fileName) {
        try {
          let res = await Storage.get(`${data.fileName}`, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });

          res.Body.text().then((string) => {
            // handle the String data return String
            const apidata = JSON.parse(string);
            setEligibilityDetailsData(apidata?.elig || null);
          });
        } catch (err) {
          console.log("Error:-", err.message);
          appContext.showErrorMessage(err.message);
        }
      } else {
        setEligibilityDetailsData(data?.elig || null);
      }
    } else {
      setEligibilityDetailsData(item);
    }
  };

  const loadData = async () => {
    setLoading(true);
    // const orders = await api.getOrderByEmployee(user.id);
    const logs = await api.getEmployeeLogs(user.id);
    // const formatttedOrders = orders.map((o) => formatOrder(o));
    setItems(logs);
    setLoading(false);
  };

  const TableRow = ({ log, index }) => {
    return (
      <>
        <tr>
          <td></td>
          <td>{index + 1}</td>
          <td>{formatDateMDY(log.createdAt)}</td>
          <td style={{ textAlign: "center" }}>{log.testQty}</td>
          <td style={{ textAlign: "center" }}>{log.address}</td>
          <td style={{ textAlign: "center" }}>{log.orderStatus}</td>
          {/* <td style={{ textAlign: "center", textOverflow: "visible" }}>
            {log.orderId}
          </td>
          <td style={{ textAlign: "center" }}>{log.requestedByName}</td> */}
        </tr>
      </>
    );
  };

  return (
    <Modal
      className="historyViewModal"
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      size={"xl"}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${user.firstName} ${user.lastName} Logs`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0">
        <>
          {!loading ? (
            <div style={{ flex: 1, marginTop: "10px" }}>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body>
                      <div className="form-group-wrapper d-block">
                        <div className="table-responsive overFlow-y-hidden">
                          <Table className="table">
                            {/* <thead>
                            <tr>
                              <th></th>
                              <th style={{ width: "10%" }}>{`${t("srNo")}`}</th>
                              <th>Date Ordered</th>
                              <th className="border-0 centered">{`${t(
                                "qtyOrdered"
                              )}`}</th>
                              <th className="border-0 centered">Address</th>
                              <th className="border-0 centered">{`${t(
                                "orderStatus"
                              )}`}</th>
                              <th className="border-0 centered">orderId</th>
                              <th className="border-0 centered">
                                Requested By
                              </th>
                            </tr>
                          </thead> */}
                            <tbody>
                              {entries.length > 0 ? (
                                entries.map((item, i) => {
                                  return (
                                    <AuditTrailEntries
                                      key={i}
                                      index={i}
                                      item={item}
                                      handleOpenDetailModal={
                                        handleOpenDetailModal
                                      }
                                      auditTrails={items}
                                      user={user}
                                    />
                                  );
                                })
                              ) : (
                                <span>No Records Found!</span>
                              )}
                              {/* {items.length > 0 &&
                              items.map((item, i) => {
                                return (
                                  <TableRow key={i} index={i} log={item} />
                                );
                              })} */}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <Loader />
          )}
        </>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="modalButtons headerButton btn-fill"
          variant="primary"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
      {eligibilityDetailsData && (
        <EligibilityDetailsModal
          handleClose={() => setEligibilityDetailsData(null)}
          data={eligibilityDetailsData}
        />
      )}
    </Modal>
  );
};

export default EmployeeOrderHistory;
