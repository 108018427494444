import React, { useState, useContext, useRef } from "react";
import { Button, Card, Table, Row, Col, DropdownButton, Dropdown, Modal, Form } from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { Auth } from "aws-amplify";
import { AppContext } from "../context/app-context";
import Filter from "../components/Filter";
import TimeFilter from "../components/TimeFilter";
import HeaderItem from "components/Table/HeaderItem";
import UserModal from "components/Users/UserModal";
import UserPasswordModal from "components/Users/UserPasswordModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import TDData from "components/Table/TDData";
import {
  NEW_PROVIDER_OBJECT,
  USER_ROLE_TYPE,
  PAGE_LIMIT,
  HEALTH_AND_SAFTEY,
  USER_ROLE_TITLE,
  CONFIG,
  currencyformatter,
  ClaimDropDownOptions,
  claimSubmitStatus,
  claimSubmitStatusColorOpts,
  AltaSetting,
  usersNo,
  ERA_VIEW_STATUS,
} from "../constant";
import { EMP_CLAIM_PERSONALIZE } from "constants/personalization";
import { t } from "../stringConstants";
import {
  formatPhoneNumber,
  setPhoneNo,
  capitalizeLetter,
  personalizationLocalStorage,
  tdCheckBox,
  calculateTdWidth,
  tdEmail,
  tdPhone,
  formatNumber,
  formatDateMDY,
  formatDate,
  phoneFormatter,
  parseClaimDate,
  RemoveSpaceAndChar,
  getValidName,
  getValidDep,
  formatEmployeeClaim,
  downloadDataAsCSV,
  CapsFirstLetter,
} from "../utils";
import EditIcon from "../assets/img/edit-icon.png";
import TrashIcon from "../assets/img/trash-icon.png";
import Select from "react-select";
import "../../src/tableStyling.css";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Icon from "../components/Icon";
import Loader from "components/Loader/Loader";
import CreateClaimResubmissionModal from "components/Modal/Order/CreateClaimResubmissionModal";
import Stats from "components/Stats";
import OrderImportModal from "components/Modal/Order/OrderImportModal";
import moment from "moment";
import MFPagination from "components/Pagination/MFPagination";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import DropdownMenu from "components/DropDownMenu";
import SingleStatsCard from "components/SingleStatsCard";
import { useLocation } from "react-router";
import EraModal from "components/Modal/Order/EraModal";
import ChangeClaimStatusModal from "./EmployeeClaims/ChangeClaimStatusModal";
import Status from "components/Status";
import Notes from "components/Notes";
import NotesModal from "components/Modal/NotesModal";
import { Storage } from "aws-amplify";

const EmployeeClaim = (props) => {
  const [users, setUsers] = useState([]);
  const [employeeClaims, setEmployeeClaims] = useState([]);
  const [filteredEmployeeClaims, setFilteredEmployeeClaims] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [newUser, setNewUser] = useState(NEW_PROVIDER_OBJECT);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [allSelected, setAllSelected] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [claimReSubmissionModal, setClaimReSubmissionModal] = useState(null);
  const [checkboxes, setCheckboxes] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [openEraModal, setOpenEraModal] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [claimNote, setClaimNote] = useState(null);
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [changeClaimStatusModal, setChangeClaimStatusModal] = useState(false);
  const componentRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      const nextTerm = location.state.filterTerm;
      const nextTermName = location.state.filterName;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
        setTimeout(() => setShowFilter(false), 1);
      }
      if (nextTerm && nextTermName) {
        setFilter({
          ...filter,
          [nextTerm]: nextTermName,
          [filterTerm]: filterTermName,
        });
        setTimeout(() => setShowFilter(false), 1);
      }
    }
  }, [location.state]);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(
    width - 120,
    personalize.length > 0 ? personalize.filter((p) => p.isCheck).length : 6
  );

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Address",
    "Apt/Unit#",
    "City",
    "State",
    "Zip Code",
    "Date Of Birth",
    "Gender",
    "Insurance",
    "Insurance #",
    "Claim Amount",
    "Adjusted Amount",
    "Paid Amount",
    "DOS",
    "Status",
    "LTCS ID",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);

  const sortUsers = (users, sortBy) => {
    if (sortDescending) {
      return [...users].sort((a, b) => {
        const testerB = b[sortBy] ?? "";
        const testerA = a[sortBy] ?? "";
        return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
      });
    } else {
      return [...users].sort((a, b) => {
        const testerB = b[sortBy] ?? "";
        const testerA = a[sortBy] ?? "";
        return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
      });
    }
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }

        if (key === "from_date_1") {
          return Object.keys(filters[key]).length > 0
            ? new Date(obj.from_date_1) >= filters[key].startDate?._d &&
                new Date(obj.from_date_1) <= filters[key].endDate?._d
            : obj.from_date_1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = (users) => {
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredEmployeeClaims);

  const getPageNumbers = (users) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);

  useEffect(() => {
    getEmployeeClaims();
  }, []);

  const getEmployeeClaims = async () => {
    const models = await api.getEmployeeClaims();

    setEmployeeClaims(formatClaims(models));
    setFilteredEmployeeClaims(sortUsers(nestedFilter(formatClaims(models), filter), sortBy));
  };

  const formatClaims = (models) => {
    let claims = [...models];
    claims = claims.map((m) => {
      if (m.paidAmount && m.proc_array.some((s) => !s.isClaimProcess)) {
        return { ...m, partialAmount: true };
      }
      return { ...m, partialAmount: false };
    });
    return claims;
  };

  useEffect(() => {
    if (filteredEmployeeClaims.length > 0)
      setFilteredEmployeeClaims(sortUsers(nestedFilter(filteredEmployeeClaims, filter), sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (employeeClaims.length > 0) {
      setFilteredEmployeeClaims(sortUsers(nestedFilter(employeeClaims, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter, employeeClaims]);

  useEffect(() => {
    setPersonalize(personalizationLocalStorage.get(appContext?.user, "employeeClaim", EMP_CLAIM_PERSONALIZE));
  }, [appContext?.company]);

  if (filteredEmployeeClaims.length > 0) {
    pageNumbers = getPageNumbers(filteredEmployeeClaims);
  }

  const headerAlign = (item) => (item === "center" ? "centered" : undefined);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = personalizationLocalStorage.saveAs(data, "employeeClaim");
    setOpenPersonalizationModal(false);
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const handleOpenCreateModal = () => {
    const obj = NEW_PROVIDER_OBJECT;
    setNewUser({ ...obj, isNew: true });
    setOpenCreator(true);
  };

  // const getDuplicateMember = async () => {
  //   const emps = appContext.employees.filter(
  //     (f, i, arr) =>
  //       arr.findIndex((m) => m.phoneNumber === f.phoneNumber && f.dob === m.dob && f.medicalNo === m.medicalNo) !== i
  //   );
  //   const duplicateData = appContext.employees.filter(
  //     (f, i) =>
  //       emps.findIndex((m) => m.phoneNumber === f.phoneNumber && f.dob === m.dob && f.medicalNo === m.medicalNo) !== -1
  //   );
  //   const dataList = duplicateData.map((m) => {
  //     const claims = employeeClaims.filter((f) => f.pcn === m.schrID).length;
  //     return { ...m, claims };
  //   });

  //   console.log("duplicateData", dataList);
  //   await downloadDataAsCSV(dataList, "patientDuplicate.csv");
  // };

  const getDuplicateData = async () => {
    const emps = filteredEmployeeClaims.filter(
      (f, i, arr) =>
        arr.findIndex(
          (m) => m.proc_array[0].remote_chgid_1 === f.proc_array[0].remote_chgid_1 && f.from_date_1 === m.from_date_1
        ) !== i
    );
    const duplicateData = filteredEmployeeClaims.filter(
      (f, i) =>
        emps.findIndex(
          (m) => m.proc_array[0].remote_chgid_1 === f.proc_array[0].remote_chgid_1 && f.from_date_1 === m.from_date_1
        ) !== -1
    );
    console.log("duplicateData", duplicateData);
    await downloadDataAsCSV(duplicateData, "SevantDuplicateEmployees.csv");
  };

  const handleImport = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      appContext.showErrorMessage("Please select Claim date");
      return;
    }
    if (!claimStatus) {
      appContext.showErrorMessage("Please Select Claim Status");
      return;
    }

    const subAgent = await api.getSubAgentById(subAgentID || "886a8f16-7d65-4a18-8f8e-51c14e71b8ed");
    const location = await api.getLocationById(subAgent.locationID);
    const client = await appContext.getClientById(subAgent.clientID);
    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];
    let fileData = [];
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };
      if (!emp.pat_name_f && !emp.pat_dob) continue;
      emp.clientID = client.id;
      emp.clientName = client.name;
      emp.locationID = location.id;
      emp.locationName = location.name;
      emp.subAgentID = subAgent.id;
      emp.subAgentName = subAgent.name;
      emp.payerid = emp.payerid.padStart(5, 0);
      emp.pat_phone = phoneFormatter(emp.pat_phone || "");
      emp.bill_phone = phoneFormatter(emp.bill_phone || "");
      emp.ins_phone = phoneFormatter(emp.ins_phone || "");
      emp.other_payer_phone = phoneFormatter(emp.other_payer_phone || "");
      emp.pat_dob = parseClaimDate(emp.pat_dob || "");
      emp.ins_dob = parseClaimDate(emp.ins_dob || "");
      emp.other_ins_dob = parseClaimDate(emp.other_ins_dob || "");
      emp.createdAt = moment(`${orderMonth} 20:00:00`).toISOString();
      emp.units_1 = parseFloat(emp.units_1);
      emp.status = claimStatus;
      emp.ins_number = RemoveSpaceAndChar(emp.ins_number);
      emp.orderId = emp.remote_claimid.slice(7);
      emp.pat_name_f = getValidDep(emp.pat_name_f || "");
      emp.pat_name_l = getValidDep(emp.pat_name_l || "");
      emp.pat_name_m = getValidDep(emp.pat_name_m || "");
      emp.ins_name_f = getValidDep(emp.ins_name_f || "");
      emp.ins_name_l = getValidDep(emp.ins_name_l || "");
      emp.ins_name_m = getValidDep(emp.ins_name_m || "");

      if (claimStatus !== "submitted") {
        const findClaim = employeeClaims.find(
          (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
        );
        if (findClaim) {
          await api.updateEmployeeClaimStatus(findClaim, claimStatus);
          updateArr.push(emp);
        }
        continue;
      }
      let proc_array = [];
      const procArr = Object.keys(emp).filter((f) => f.includes("proc_code"));
      procArr.map((key, index) => {
        const i = index + 1;
        const splitDiagRef = emp[`diag_ref_${i}`].split(",");
        const obj = {
          proc_code: emp[`proc_code_${i}`],
          from_date: parseClaimDate(emp[`from_date_${i}`]),
          diag_ref_1: splitDiagRef[0],
          diag_ref_2: splitDiagRef[1],
          diag_ref_3: splitDiagRef[2],
          diag_ref_4: splitDiagRef[3],
          charge: emp[`charge_${i}`],
          units: emp[`units_${i}`],
          place_of_service: emp[`place_of_service_${i}`],
          mod1: emp[`mod1_${i}`],
          mod2: emp[`mod2_${i}`],
          mod3: emp[`mod3_${i}`],
          mod4: emp[`mod4_${i}`],
          [`remote_chgid_${i}`]: emp[`remote_chgid_${i}`],
        };
        proc_array.push(obj);
      });
      emp.proc_array = proc_array;
      const {
        prov_name_f,
        prov_name_l,
        prov_name_m,
        prov_npi,
        prov_taxid,
        prov_taxid_type,
        prov_taxonomy,
        ord_prov_name_f,
        ord_prov_name_l,
        ord_prov_name_m,
        ord_prov_npi,
        ref_name_f,
        ref_name_l,
        ref_name_m,
        ref_npi,
      } = emp;
      const renderProviderID = appContext.providers.find(
        (f) => f.lastName.trim().toLowerCase() === prov_name_l.trim().toLowerCase()
      );
      const referingProviderID = appContext.providers.find(
        (f) => f.lastName.trim().toLowerCase() === ref_name_l.trim().toLowerCase()
      );
      const orderingProviderID = appContext.providers.find(
        (f) => f.lastName.trim().toLowerCase() === ord_prov_name_l.trim().toLowerCase()
      );
      emp.provider = {
        prov_name_f,
        prov_name_l,
        prov_name_m,
        prov_id: renderProviderID?.id || "",
        prov_npi,
        prov_taxid,
        prov_taxid_type,
        prov_taxonomy,
        ord_prov_id: orderingProviderID?.id || "",
        ord_prov_name_f,
        ord_prov_name_l,
        ord_prov_name_m,
        ord_prov_npi,
        ref_id: referingProviderID?.id || "",
        ref_name_f,
        ref_name_l,
        ref_name_m,
        ref_npi,
      };
      emp.employeeID = appContext.employees.find((f) => f.schrID === emp.pcn.trim())?.id ?? "";
      emp.updatedByName = appContext.user.name;
      emp.updatedBy = appContext.user.sub;

      const findClaim = employeeClaims.find(
        (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
      );
      if (findClaim) {
        console.log("findClaim", findClaim);
        continue;
      }

      try {
        console.log("emp", emp);
        const res = await api.createClaim(emp);
        if (res.data) {
          fileData.push(res.data);
        }
        if (res) arr.push(emp);
      } catch (err) {
        console.log("Error:-", err);
      }
    }
    console.log("FileData", fileData);
    const fileName = `claim/audit_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    await api.saveFileOnBucket(fileData, fileName);
    setLoading(false);
    getEmployeeClaims();
    if (updateArr.length > 0) appContext.showSuccessMessage(`${updateArr.length} Claim(s) Updated Successfully!`);
    if (arr.length > 0) appContext.showSuccessMessage(`${arr.length} Claim(s) Imported Successfully!`);
    console.log("ArrData", arr);
  };

  const handleEditUser = (user) => {
    const obj = {
      ...user,
      isNew: false,
    };
    setNewUser(obj);
    setOpenCreator(true);
  };

  const handleRejcetedImport = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      appContext.showErrorMessage("Please select Claim date");
      return;
    }
    if (!claimStatus) {
      appContext.showErrorMessage("Please Select Claim Status");
      return;
    }
    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };

      const findClaim = employeeClaims.find(
        (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
      );
      if (findClaim) {
        const logStatus = emp.messages.includes("ERA DENIAL") ? "denied" : "rejected";
        // await api.updateEmployeeClaimStatus(findClaim, logStatus,emp.messages);
        updateArr.push({
          ...findClaim,
          message: emp.messages,
          status: logStatus,
          local_status_time: emp.local_status_time,
        });
      }
    }

    // const findClaim = employeeClaims.map((f)=>{
    //   return {...f , message: 'Submit for Bill', status: 'submitted', local_status_time: f.createdAt }
    // });

    const fileName = `claim/audit_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    await api.saveFileOnBucket(updateArr, fileName);

    setLoading(false);
    getEmployeeClaims();
    if (updateArr.length > 0) appContext.showSuccessMessage(`${updateArr.length} Claim(s) Updated Successfully!`);
    if (arr.length > 0) appContext.showSuccessMessage(`${arr.length} Claim(s) Imported Successfully!`);
    console.log("ArrData", arr);
  };

  const handleDownloadExportCsv = async () => {
    const claimList = filteredEmployeeClaims.map((claim) => {
      return formatEmployeeClaim(claim, AltaSetting);
    });
    await downloadDataAsCSV(claimList, "Alta_Claim.csv");
  };

  const getTotalClaimSubmit = (list, val) => {
    switch (val) {
      case "totalClaimsSubmitted":
        return formatNumber(
          list.reduce((val, obj) => {
            return 1 + val;
          }, 0)
        );
      case "totalClaimsRejected":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "rejected" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsDraft":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "draft" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsDenied":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "denied" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsApproved":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "approved" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsPaid":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "paid" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsPartail":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "partial" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsCancel":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "cancel" ? 1 : 0) + val;
          }, 0)
        );
      case "totalDraftAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "draft" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);
      case "totalChargeAmount":
        return list.reduce((val, obj) => {
          return (parseFloat(obj.total_charge) || 0) + val;
        }, 0);
      case "totalAmountRejected":
        return list.reduce((val, obj) => {
          return (obj.status === "rejected" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);
      case "totalReceivedAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "approved" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);
      case "totalPaidAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "paid" ? parseFloat(obj.paidAmount) || 0 : 0) + val;
        }, 0);
      case "totalPartialAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "partial" ? parseFloat(obj.paidAmount) || 0 : 0) + val;
        }, 0);
      case "totalDeniedAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "denied" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);
      case "totalCancelAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "cancel" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);

      default:
        return 0;
    }
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredEmployeeClaims.map((t) => t.id) : []);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    const empsFilter = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && f.staus === claimSubmitStatus.draft
    );
    let ttl = empsFilter.length;

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    if (ttl === 0 || userToDelete.status !== claimSubmitStatus.draft) {
      appContext.showErrorMessage("Only draft claims would be able to delete");
      setCheckboxes([]);
      return;
    }
    try {
      setLoading(true);
      if (userToDelete || ttl > 0) {
        if (userToDelete) {
          await api.deleteEmployeeClaim(userToDelete.id);
        } else if (ttl > 0) {
          for (let i = 0; i < ttl; i++) {
            const emp = users.find((f) => f.id === checkboxes[i]);
            await api.deleteEmployeeClaim(checkboxes[i]);
          }
        }
      }
      setUserToDelete(null);
      setCheckboxes([]);
      appContext.showSuccessMessage("Claim Deleted Successfully");
      getEmployeeClaims();
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const handleOpenEraFile = async (user) => {
    const eraid = user.eraIds.split(",");
    const path = await api.getPatientERA({ ...user, eraid: eraid[0] });
    if (!path) return;
    try {
      const res = await Storage.get(path, {
        bucket: CONFIG.eligibilityBucket,
      });

      // setOpenPdf(res);

      // const baseUrl = res.split("?")[0];
      // setOpenPdf(baseUrl);

      window.open(`${res}`);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const tdFormat = (item, user) => {
    if (item === "updatedAt") return formatDate(user.updatedAt);
    if (item === "from_date_1") return formatDateMDY(user.from_date_1);
    if (item === "submissionDate") return formatDateMDY(user.submissionDate);
    if (item === "pat_dob") return formatDateMDY(user.pat_dob);
    if (item === "total_charge") return currencyformatter.format(user.total_charge || 0);
    if (item === "adjustedAmount") return currencyformatter.format(user.adjustedAmount || 0);
    if (item === "paidAmount") {
      return <span className="cursor-pointer">{currencyformatter.format(user.paidAmount || 0)}</span>;
    }
    if (item === "userRole") return USER_ROLE_TITLE[user.userRole];
    return user[item];
  };
  const tdStatus = (user) => (
    <td
      className="ellipsis text-center text-capitalize cursor-pointer"
      title={user.status}
      onClick={() => user.status === ERA_VIEW_STATUS[user.status] && setOpenEraModal(user)}
      style={{ color: `${claimSubmitStatusColorOpts[`${user.status}`]}` }}
    >
      {user.status}
    </td>
  );

  const tdName = (name) => (
    <td className="ellipsis" style={{ textOverflow: "visible", textAlign: "left" }}>
      {CapsFirstLetter(name)}
    </td>
  );

  const tdEra = (user) => (
    <td
      className="table-column ellipsis linkedImage"
      style={{ textAlign: "center" }}
      onClick={() => {
        user.eraIds && handleOpenEraFile(user);
      }}
    >
      {user.eraIds ? <i className="fas fa-image" /> : <></>}
    </td>
  );

  const renderTd = (item, user) => {
    if (item.itemKey === "pat_email") return tdEmail(user.pat_email);
    if (item.itemKey === "pat_phone") return tdPhone(user.pat_phone);
    if (item.itemKey === "status") return tdStatus(user);
    if (item.itemKey === "eraIds") return tdEra(user);
    if (item.itemKey === "pat_name_f") return tdName(user.pat_name_f);
    if (item.itemKey === "pat_name_l") return tdName(user.pat_name_l);
    if (item.itemKey === "payer_name") return tdName(user.payer_name);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const TableRow = ({ user }) => {
    return (
      <tr key={user.username}>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <div className="d-flex align-items-center w-100 column-gap-10">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={checkboxes.indexOf(user.id) !== -1}
                value={user.id}
                onChange={(e) => handleCheckboxChange(e, user)}
              />
            </Form.Check>
            <img
              src={EditIcon}
              alt="edit icon"
              width="18"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={user.id}
              onClick={(e) => setClaimReSubmissionModal([user])}
            />
            <Notes note={user.note} handleClick={() => !appContext.isReadOnly() && setClaimNote(user)} />
          </div>
        </td>

        {personalize.map((data) => data.isCheck && renderTd(data, user))}

        <td
          className="icon"
          style={{
            textAlign: "center",
            textOverflow: "visible",
            width: "45px",
            minWidth: "45px",
            maxWidth: "45px",
          }}
        >
          {/* <i
            className="fas fa-ellipsis-v"
            onClick={() => {
             
            }}
          /> */}

          <DropdownMenu
            options={ClaimDropDownOptions}
            handleDropDownOptions={(type) => {
              if (type === "logs") {
                setOpenOrderDetail(true);
                setSelectedOrder(user);
              }
              if (type === "delete") {
                setOpenDeleteConfirmation(true);
                setUserToDelete(user);
              }
            }}
          />
          {/* <img
            src={TrashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={user.id}
            onClick={(e) => {
              setOpenDeleteConfirmation(true);
              setUserToDelete(user);
            }}
          /> */}
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {t("employeeClaim")} (
                  {filteredEmployeeClaims ? formatNumber(filteredEmployeeClaims.length) : employeeClaims.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={async () => {
                      if (filteredEmployeeClaims && filteredEmployeeClaims.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                      const fileName = `claim/test_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
                      await api.saveFileOnBucket(
                        employeeClaims.filter((f) => f.status === "accepted"),
                        fileName
                      );
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />
                  <Icon
                    handleClick={() => setOpenDeleteConfirmation(true)}
                    label="Delete"
                    title="Delete"
                    disabled={checkboxes.length === 0}
                    iconType={"binIcon"}
                  />

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />
                  <Icon
                    title={t("createClaim")}
                    label={t("createClaim")}
                    iconType={"acknowledgedPositiveIcon"}
                    handleClick={() => {
                      setClaimReSubmissionModal([]);
                    }}
                  />
                  <Icon
                    handleClick={handleDownloadExportCsv}
                    title={"Export Claim"}
                    label={"Export"}
                    iconType={"downloadIcon"}
                  />
                  {/* <Icon
                    handleClick={() => {
                      setOpenImportModal(true);
                    }}
                    title={t("loadOrderData")}
                    label={"Import"}
                    iconType={"importIcon"}
                  /> */}
                  {appContext.user.phone_number === usersNo.ibrahim && (
                    <Icon
                      title={t("updateClaimStatus")}
                      label={t("updateClaimStatus")}
                      iconType={"acknowledgedPositiveIcon"}
                      disabled={checkboxes.length === 0}
                      handleClick={() => setChangeClaimStatusModal(true)}
                    />
                  )}
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    employeeClaim={true}
                  />
                )}

                <div className="dashboard-wrapper">
                  <div className="d-flex w-100">
                    <SingleStatsCard
                      title={"Draft"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsDraft")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalDraftAmount"))}
                      filterKey="status"
                      value="draft"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Submitted"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsSubmitted")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalChargeAmount"))}
                      filterKey="status"
                      value="submitted"
                      handelClick={(obj) => setFilter({})}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Rejected"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsRejected")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalAmountRejected"))}
                      filterKey="status"
                      value="rejected"
                      handelClick={(obj) =>
                        setFilter({
                          ...obj,
                        })
                      }
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Approved"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsApproved")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalReceivedAmount"))}
                      filterKey="status"
                      value="approved"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Partially Processed"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsPartail")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalPartialAmount"))}
                      filterKey="status"
                      value="partial"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Paid"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsPaid")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalPaidAmount"))}
                      filterKey="status"
                      value="paid"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Denied"}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalDeniedAmount"))}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsDenied")}
                      filterKey="status"
                      value="denied"
                      handelClick={(obj) =>
                        setFilter({
                          ...obj,
                        })
                      }
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Cancelled"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsCancel")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalCancelAmount"))}
                      filterKey="status"
                      value="cancel"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                  </div>
                </div>
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers.length}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="user-table employeeTableWrapper">
                    <thead ref={componentRef}>
                      <tr>
                        <th />
                        {personalize.map(
                          (item, i) =>
                            item.isCheck && (
                              <HeaderItem
                                key={i}
                                width={tdWidth}
                                ItemKey={item.itemKey}
                                title={item.title}
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                aligned={headerAlign(item.textAlign)}
                              />
                            )
                        )}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {usersToMap &&
                        usersToMap.map((user, i) => {
                          return <TableRow key={i} user={user} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {claimReSubmissionModal && (
        <CreateClaimResubmissionModal
          // orders={filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1)}
          user={claimReSubmissionModal}
          handleClose={(val) => {
            setClaimReSubmissionModal(null);
            if (val) {
              getEmployeeClaims();
            }
          }}
          fileDir="claim"
        />
      )}
      {changeClaimStatusModal && (
        <ChangeClaimStatusModal
          selectedClaimIds={checkboxes || []}
          cssClass={"seperateModal"}
          handleClose={(val) => {
            if (val) getEmployeeClaims();
            setChangeClaimStatusModal(false);
            setCheckboxes([]);
          }}
        />
      )}
      {openEraModal && <EraModal user={openEraModal} handleClose={() => setOpenEraModal(null)} />}
      {openImportModal && (
        <OrderImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={handleImport}
          appContext={appContext}
          claim
          title="Import Claim Data"
        />
      )}

      {openOrderDetail && (
        <OrderLogsModal
          order={selectedOrder}
          show={openOrderDetail}
          appContext={appContext}
          handleClose={() => {
            setOpenOrderDetail(false);
            setSelectedOrder("");
          }}
          claim
        />
      )}

      {claimNote && (
        <NotesModal
          handleClose={(obj) => {
            if (obj) {
              obj.note
                ? appContext.showSuccessMessage("add Notes Successfully")
                : appContext.showSuccessMessage("Remove Notes Successfully");
              const findVal = employeeClaims.find((f) => f.id === obj.id);
              if (findVal) {
                Object.assign(findVal, { note: obj.note });
                const index = employeeClaims.findIndex((f) => f.id === findVal.id);
                if (index !== -1) {
                  const data = [...employeeClaims];
                  data.splice(index, 1, findVal);
                  setEmployeeClaims(data);
                  setFilteredEmployeeClaims(sortUsers(nestedFilter(data, filter), sortBy));
                }
              }
            }
            setClaimNote(null);
          }}
          data={claimNote}
          claim={true}
          user={appContext.user}
        />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Claim"
          message="Are you sure, you want to remove claim?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default EmployeeClaim;
