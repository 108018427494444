import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const TabPills = (props) => {
    const {tabs} = props;
    return(
            <>
                <Tabs>
                    <TabList>
                        {
                            tabs.map((item) => <Tab>{item.title} {item.length ? <b>({item.length})</b> : ''}</Tab>)
                        }
                    </TabList>

                    {
                        tabs.map((item) => <TabPanel>{item.component}</TabPanel>)
                    }
                </Tabs>
            </>
    )
}
export default TabPills;



