import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import { TEST_TYPES } from "constant";
import moment from "moment";
import ErrorMessage from "components/Message/ErrorMessage";
import TextAreaMessageInput from "components/TextInput/TextAreaMessageInput";
import api from "api";
import RadioButton from "components/RadioButton/RadioButton";
import { formatDateMDY } from "utils";
import Loader from "components/Loader/Loader";
import { formatDateMDYTime } from "utils";

const NotesModal = (props) => {
  const { data, handleClose, user, emp, order, claim } = props;

  const [notes, setNotes] = useState((emp ? data.note : data.note?.message) || "");
  const [error, setError] = useState("");
  const [status, setStatus] = useState(data.status);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    const logs = await api.getEmployeeLogs(order || claim ? data.employeeID : data.id);

    setItems(logs.filter((f) => f.eventType === "MemberUpdated" && f.changeObject && f.changeObject.newRecord?.note));
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const onHandleSave = async () => {
    setLoading(true);
    const obj = {
      id: data.id,
      ...(order && {
        note: {
          message: notes.trim(),
          userID: user.sub,
          userName: user.name,
          createdAt: moment().utc().toISOString(),
        },
      }),
      ...((emp || claim) && { note: notes.trim(), status }),
      userID: user.sub,
      userName: user.name,
    };
    try {
      if (order) await api.addOrderNote(obj);
      if (emp) await api.addMemberNote(obj);
      if (claim) await api.addClaimNote(obj);

      setLoading(false);
      handleClose(obj);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  const TableRow = ({ log, index }) => {
    const obj = log.changeObject.newRecord;
    return (
      <>
        <tr>
          <td>{index + 1}</td>
          <td className="w-15">{formatDateMDY(log.createdAt)}</td>
          {/* <td
            className="w-15"
            style={{
              textAlign: "center",
            }}
          >
            Note
          </td> */}
          <td className="ellipsis w-50" style={{ textAlign: "center" }} title={obj.note?.S || ""}>
            {obj.note?.S || ""}
          </td>
          <td style={{ textAlign: "center" }} title={obj.noteAddedBy?.S || "System"}>
            {obj.noteAddedBy?.S || "System"}
          </td>
        </tr>
      </>
    );
  };
  return (
    <>
      <Modal show backdrop="static" onHide={handleClose} animation={true} size={"lg"} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">
            Notes {data && `for ${data[claim ? "pat_name_f" : "firstName"]} ${data[claim ? "pat_name_l" : "lastName"]}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {items.length > 0 && (
            <Row>
              <Col md="12">
                <Card className="strpied-tabled-with-hover">
                  <Card.Header className="fw-bold">Previous Note's</Card.Header>
                  <Card.Body>
                    {/* {items.map((log, i) => {
                      const obj = log.changeObject.newRecord;
                      return (
                        <>
                          <div className="msgBox position-relative">
                          <p className="mb-1">{obj.note?.S || ""}</p>
                          <div className="date-user-wrapper">
                            <span className="">{formatDateMDYTime(log.createdAt)}</span>
                            <span className="ms-3"> {obj.noteAddedBy?.S || "System"}</span>
                          </div>
                        </div>
                        </>
                      );
                    })} */}

                    <Table className="table">
                      <thead>
                        <tr>
                          <th className="ellipsis">Sr.</th>
                          <th className="ellipsis">Date</th>
                          {/* {/* <th className="border-0 centered">Action</th>  */}
                          <th className="border-0 centered ellipsis" style={{ width: "65%" }}>
                            Note
                          </th>
                          <th className="border-0 centered ellipsis">User Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item, i) => {
                          return <TableRow key={i} index={i} log={item} />;
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {emp && (
            <div className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">Status:</label>
              <div className="d-flex justify-content-between w-25 flex-wrap">
                <RadioButton
                  name="status"
                  id="active"
                  label="Active"
                  checked={status === "active" ? true : false}
                  handleChange={(e) => setStatus(e.target.checked ? "active" : "")}
                />
                <RadioButton
                  name="status"
                  id="inactive"
                  checked={status === "inactive" ? true : false}
                  label="In Active"
                  handleChange={(e) => {
                    setStatus(e.target.checked ? "inactive" : "");
                  }}
                />
              </div>
            </div>
          )}
          <div className="row textAreaMessageInput">
            <div className="col-12 mb-3">
              <textarea
                placeholder={"Note!"}
                className="MessageInput"
                onChange={(e) => setNotes(e.target.value)}
                maxLength={200}
              />
            </div>
          </div>
          {loading && <Loader />}
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <div className="d-flex">
            <Button
              style={{ marginBottom: 10 }}
              disabled={loading}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={onHandleSave}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotesModal;
