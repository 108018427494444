import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import {
  calculateTdWidth,
  checkValidity,
  parseBooleanValue,
  tdPhone,
  tdEmail,
  sortList,
  getPreRegistrationLink,
  sortingFilterInLC,
} from "utils";
import { t } from "../stringConstants";
import logo from "assets/img/logo.jpg";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import HeaderItem from "components/Table/HeaderItem";
import Filter from "../components/Filter";
import MFPagination from "components/Pagination/MFPagination";
import {
  newLocationObject,
  TEST_ORDER_LIMIT_CATEGORY_VALUE,
  PAGE_LIMIT,
  CONFIG,
  USER_TYPE_USER,
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
} from "constant";
import Loader from "components/Loader/Loader";
import { QRCode } from "react-qrcode-logo";
import { Auth } from "aws-amplify";
import { emailMsgToCreatedFacility } from "utils";
import { formatOrder } from "utils";
import moment from "moment";
import { Link } from "react-router-dom";
import SubAgentsModal from "components/Modal/SubAgentsModal";
import { USER_TYPE_SUBAGENT } from "constant";
import EmployeeImportModal from "components/Modal/EmployeeImportModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import { LOCATION_HEADERS } from "constant";
import { getValidReg } from "utils";
import { importPhoneNoFormat } from "utils";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { validatePhone } from "utils";
import { isValidEmail } from "utils";
import { downloadDataAsCSV } from "utils";
import { SUB_AGENT_HEADERS } from "constant";
import { CLIENT_PERSONALIZE } from "constants/personalization";
import { personalizationLocalStorage } from "utils";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import { tdCheckBox } from "utils";
import Icon from "../components/Icon";
import { formatNumber } from "utils";

const SubAgents = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(newLocationObject);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showCopyText, setShowCopyText] = useState("");
  const [orders, setOrders] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [title, setTitle] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] =
    useState(false);

  const searchTerms = [
    "Name",
    "Contact Name",
    "Contact Email",
    "Contact Phone",
  ];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(
    width - 160,
    personalize.length > 0 ? personalize.filter((p) => p.isCheck).length : 14
  );

  const currentItems = (locs) => {
    if (!locs || locs.length === 0) return [];
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return locs.slice(indexOfFirstUser, indexOfLastUser);
  };

  let locationsToMap = currentItems(filteredLocations);

  const sortData = (schs, sortParam) => {
    if (sortDescending) {
      return [...schs].sort((a, b) =>
        a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0
      );
    } else {
      return [...schs].sort((a, b) =>
        b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0
      );
    }
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.subAgents && sortLS.subAgents.sortBy) {
      setSortBy(sortLS.subAgents.sortBy);
      setSortDescending(sortLS.subAgents.sortDescending);
    }
    // if (sortLS.subAgents && sortLS.subAgents.filter) {
    //   setFilter(sortLS.subAgents.filter);
    // }
    setPersonalize(
      personalizationLocalStorage.get(
        appContext.user,
        "subAgent",
        CLIENT_PERSONALIZE
      )
    );
  }, [appContext?.company]);

  const headerAlign = (item) => (item === "center" ? "centered" : undefined);

  const getPageNumbers = (users) => {
    const pageNumbers =
      users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(locations);

  if (filteredLocations && filteredLocations.length > 0) {
    pageNumbers = getPageNumbers(filteredLocations);
  }

  const getOrders = async () => {
    let userType = appContext.user.isUser() ? USER_TYPE_USER : USER_TYPE_ADMIN;
    let id = null;
    if (appContext.user.isUser()) {
      id = appContext.employeeRecord?.id;
      if (!id) {
        const empRecord = await API.getLoggedInUserEmployeeID(
          null,
          appContext.user.preferred_username
        );
        if (empRecord) {
          id = empRecord.id;
        }
      }
    } else if (appContext.user.isClient()) {
      userType = USER_TYPE_CLIENT;
    } else if (appContext.user.isSubAgent()) {
      userType = USER_TYPE_SUBAGENT;
    }

    const data = await API.getOrders(userType, id);
    const formatttedOrders = data.map((o) => formatOrder(o));
    setOrders(formatttedOrders);
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (appContext.subAgents && appContext.subAgents.length > 0) {
      formatSubAgents();
    }
    // setLocations(
    //   appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility)
    // );
    // setFilteredLocations(
    //   appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility)
    // );
  }, [appContext.subAgents, orders]);

  const formatSubAgents = () => {
    const subAgents = appContext.subAgents?.filter(
      (f) => f.id !== CONFIG.generalSubAgent
    );
    // const data = orders
    //   .filter((f) => f.siteID)
    //   .reduce(
    //     (obj, order) => {
    //       obj["generic"][order.subAgentID] =
    //         (obj["generic"][order.subAgentID] || 0) + order.testQty;
    //       if (moment(order.createdAt).isSame(new Date(), "month")) {
    //         obj["monthToDate"][order.subAgentID] =
    //           (obj["monthToDate"][order.subAgentID] || 0) + order.testQty;
    //       }
    //       return obj;
    //     },
    //     { generic: {}, monthToDate: {} }
    //   );

    // const locData = subAgents.map((t) => {
    //   return {
    //     ...t,
    //     totalOrders: data["generic"][t.id] || 0,
    //     yearToDate: data["generic"][t.id] || 0,
    //     monthToDate: data["monthToDate"][t.id] || 0,
    //   };
    // });
    setLocations(subAgents);
    setFilteredLocations(sortData(nestedFilter(subAgents, filter), sortBy));
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.subAgents = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (locations.length > 0) {
      setFilteredLocations(sortData(nestedFilter(locations, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    if (filteredLocations.length > 0) {
      handleSaveFilter();
      setFilteredLocations(sortData(filteredLocations, sortBy));
    }
  }, [sortBy, sortDescending]);

  const handleConfirmDelete = async (isConfirm) => {
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await API.deleteSubAgent(itemToDelete.id);
      if (isDeleted) {
        // await API.deleteUser(itemToDelete.phone_number);
        appContext.resetSubAgents();
        appContext.showSuccessMessage("Sub Agent deleted successfully");
      } else {
        appContext.showErrorMessage(
          "Sub Agent can't be deleted because few members are linked with it"
        );
      }
      setItemToDelete(null);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const OnHandleLocation = async (loc) => {
    const findVal = locations.find(
      (f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name)
    );
    if (findVal) {
      appContext.showErrorMessage("Sub Agent already exist");
      return;
    }
    setOpenCreateModal(false);

    try {
      setLoading(true);

      if (loc.isNew) {
        const res = await API.newSubAgent(loc, appContext.user);
        if (res) {
          // const userCreated = await createUser({ ...res, ...loc });
          // if (userCreated) {

          // } else {
          //   await API.deleteLocation(res.id);
          // }
          appContext.showSuccessMessage("Sub Agent Created Successfully");
        }
      } else {
        await API.updateSubAgent(loc);
        const filterMembers = appContext.employees?.filter(
          (f) => f.companyID === loc.id
        );

        if (filterMembers.length > 0) {
          await API.updateMemberTestKit(filterMembers, loc);
        }
        appContext.showSuccessMessage("Sub Agent Updated Successfully");
        appContext.resetEmployees();
      }
      appContext.resetSubAgents();
      setSelectItem(newLocationObject);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(err.message);
    }
  };

  const createUser = async (newUser) => {
    const firstName = newUser.contact_name.split(" ")[0] || "";
    const lastName = newUser.contact_name.split(" ")[1] || "";
    const attributePayload = {
      preferred_username: newUser.phone_number,
      email: newUser.contact_email,
      phone_number: newUser.phone_number,
      "custom:role": "Employers",
      "custom:autoConfirm": "true",
      "custom:firstName": `${firstName}`,
      "custom:lastName": `${lastName}`,
      "custom:note": `${newUser.contact_name}`,
      "custom:labID": newUser.id,
      "custom:personalisation": JSON.stringify({
        personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
      }),
    };

    try {
      const signUp = await Auth.signUp({
        username: newUser.phone_number,
        password: newUser.password,
        attributes: attributePayload,
      });
      if (signUp) {
        const message = emailMsgToCreatedFacility(
          newUser.contact_name,
          newUser.phone_number.replaceAll(/[^0-9]/gi, ""),
          newUser.password
        );
        await API.sendEmail([
          {
            email: newUser.contact_email,
            subject: "SafeCamp LTC Team",
            msg: message,
          },
        ]);
      }
      return true;
    } catch (error) {
      console.log("ERROR createUser: ", error);
      appContext.showErrorMessage(error.message);
      return false;
    }
  };

  const handleEdit = (item) => {
    const location = appContext.getLocationById(item.locationID);
    setSelectItem({ ...item, isNew: false, location });
    setOpenCreateModal(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const downloadQRCode = (site) => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${site.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleCopyPRLink = (id) => {
    navigator.clipboard.writeText(getPreRegistrationLink(id, "kit"));
    setShowCopyText(id);
    setTimeout(() => setShowCopyText(null), 2000);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);

    const models = targetArray.filter((obj) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const parseClient = (item) => {
    if (item.client) item.client.name;
    if (!item.clientID) return "N/A";

    if (appContext.companies) {
      const client = appContext.companies.find((c) => c.id === item.clientID);
      if (client) {
        return client.name;
      }
    }
    return "N/A";
  };

  const handleImport = async (locData, locationID) => {
    if (locData.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));

      setOpenImportModal(false);
      return;
    }

    if (!locationID) {
      appContext.showErrorMessage("Facility/Agent was not selected");
      return;
    }
    const client = await API.getLocationById(locationID);
    const location = { ...client, label: client.name, value: client.id };
    const arr = [];
    const arrdata = [];
    const locList = [...locations];
    const locationKeysArr = Object.keys(locData[0].data);
    if (locationKeysArr.length > 0) {
      let err = false;
      SUB_AGENT_HEADERS.forEach((f, i) => {
        if (!locationKeysArr.includes(f)) {
          appContext.showErrorMessage(`Column Header must be ${f}`);
          setOpenImportModal(false);
          err = true;
          return;
        }
      });
      if (err) return;
    }

    // const filterHeader = LOCATION_HEADERS.every((c) =>
    //   locationKeysArr.includes(c)
    // );
    // if (!filterHeader) {
    //   appContext.showErrorMessage("data not matched");
    //   setOpenImportModal(false);
    //   return;
    // }

    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = locData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = locData[index];
      const loc = {
        ...data,
        locationID,
        location,
        clientID: client.clientID,
        testLimit: client.testLimit,
        minTestOrderQty: client.minTestOrderQty,
      };

      if (Object.values(data).every((c) => c.length == 0)) continue;
      loc.phone_number = importPhoneNoFormat(loc.main_phone_number);
      loc.contactName = importPhoneNoFormat(loc.phoneNumber);
      loc.contact_name = `${loc.contact_first_name || ""} ${
        loc.contact_last_name || ""
      }`.trim();

      if (loc.name) {
        loc.name = getValidReg(loc.name);
        const findVal = locList.find(
          (f) => checkValidity(f.name) == checkValidity(loc.name)
        );

        if (findVal) {
          arr.push({
            message: `Sub Agent record already exists on row ${index + 2}`,
            data: loc,
          });
          continue;
        }
      } else {
        arr.push({
          message: `name is required on row ${index + 2}`,
          data: loc,
        });
        continue;
      }

      if (!loc.contactName) {
        arr.push({
          message: `phoneNumber is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }
      if (loc.contactName && !validatePhone(loc.contactName)) {
        arr.push({
          message: `phoneNumber is invalid ${loc.phoneNumber} on row ${
            index + 2
          }`,
          data: loc,
        });
        continue;
      }
      if (!loc.main_phone_number) {
        arr.push({
          message: `main_phone_number is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }

      const phone = formatPhoneNumberIntl(loc.main_phone_number);

      const phoneArr = phone.split(" ");
      loc.countryCode = phoneArr[0];

      if (loc.main_phone_number && !validatePhone(loc.phone_number)) {
        arr.push({
          message: `main_phone_number is invalid ${
            loc.main_phone_number
          } on row ${index + 2}`,
          data: loc,
        });
        continue;
      }
      if (!loc.contact_first_name) {
        arr.push({
          message: `contact_first_name is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }
      if (!loc.contact_last_name) {
        arr.push({
          message: `contact_last_name is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }

      if (!loc.contact_email) {
        arr.push({
          message: `contact_email is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }

      if (loc.contact_email && !isValidEmail(loc.contact_email)) {
        arr.push({
          message: `Invalid contact_email ${loc.contact_email} on row ${
            index + 2
          }.`,
          data: loc,
        });
        continue;
      }

      locList.push(loc);
      (await API.newSubAgent(loc, appContext.user)) && arrdata.push("Success");
    }

    if (arrdata.length == 0 && arr.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setLoading(false);
      return;
    }

    setOpenImportModal(false);
    appContext.resetSubAgents();
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle("Sub Agent");
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleDownloadSample = async () => {
    await downloadDataAsCSV(
      [
        {
          name: "",
          street: "",
          street2: "",
          city: "",
          state: "",
          zip: "",
          phoneNumber: "",
          webSite: "",
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          main_phone_number: "",
        },
      ],
      t("SACsvSampleName")
    );
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = personalizationLocalStorage.saveAs(
      data,
      "subAgent"
    );
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await API.udpatePersonalization(
      appContext.user.phone_number,
      personalisationData
    );
  };

  const tdLink = (item, dates, itemID, filterKey) => (
    <td
      className="ellipsis"
      style={{
        textAlign: "center",
        textOverflow: "none",
      }}
    >
      {item > 0 ? (
        <Link
          style={{ color: "#42cef5" }}
          to={{
            pathname: "/admin/orders",
            state: {
              name: itemID,
              term: filterKey,
              filterName: dates,
              filterTerm: dates,
            },
          }}
        >
          {formatNumber(item)}
        </Link>
      ) : (
        item
      )}
    </td>
  );

  const tdFormat = (item, user) => {
    if (item === "updatedAt") return formatDate(user.updatedAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "isVaccinated") return parseBooleanValue(user.isVaccinated);
    if (item === "insuranceCompany")
      return insuranceProviderFormatter(user.insuranceCompany);
    if (item === "testOrderCategory")
      return TEST_ORDER_LIMIT_CATEGORY_VALUE[user.testOrderCategory];
    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "contact_email") return tdEmail(user.contact_email);
    if (item.itemKey === "phone_number") return tdPhone(user.phone_number);
    if (item.itemKey === "qaDone") return tdCheckBox(item, user);
    if (item.itemKey === "monthToDate")
      return tdLink(user.monthToDate, "month", user.id, "subAgentID");
    if (item.itemKey === "yearToDate")
      return tdLink(user.yearToDate, "year", user.id, "subAgentID");
    if (item.itemKey === "totalOrders")
      return tdLink(user.totalOrders, "", user.id, "subAgentID");
    if (item.itemKey === "testDone") {
      return tdCheckBox(item, user);
    }
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };
  const TableRow = ({ item }) => {
    return (
      <tr>
        <td className="icon">
          {item.id !== "f144f859-8465-4212-9256-0bd15bd47a22" &&
            item.id !== "f144f859-8465-4212-9256-0bd15bd47a22" && (
              <img
                src={editIcon}
                alt="edit icon"
                width="18"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                onClick={() => handleEdit(item)}
              />
            )}
        </td>
        {personalize.map((data) => data.isCheck && renderTd(data, item))}
        <td
          className="ellipsis position-relative"
          style={{ textAlign: "center" }}
        >
          {item.preRegistration && (
            <div className="d-flex align-items-center justify-content-evenly qr-code-download">
              {showCopyText && showCopyText === item.id && (
                <div className="copy-link-text clipBoard-copy">
                  Copied to clipboard!
                </div>
              )}
              <div onClick={() => handleCopyPRLink(item.id)}>
                <i className="fas fa-copy maroon" />
              </div>
              <div onClick={() => downloadQRCode(item)}>
                <i className="fas fa-download maroon"></i>
              </div>
              <span style={{ display: "none" }}>
                <QRCode
                  id="qr-gen"
                  ecLevel={"H"}
                  value={getPreRegistrationLink(item.id, "kit")}
                  enableCORS={true}
                  logoImage={logo}
                  size="250"
                  logoHeight={"70"}
                  logoWidth={"70"}
                  fgColor={"#000"}
                  bgColor={"#fff"}
                />
              </span>
            </div>
          )}
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          {item.id !== "f144f859-8465-4212-9256-0bd15bd47a22" &&
            item.id !== "f144f859-8465-4212-9256-0bd15bd47a22" && (
              <img
                src={trashIcon}
                alt="trash icon"
                width="15"
                style={{ marginLeft: "10px" }}
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                onClick={(e) => setItemToDelete(item)}
              />
            )}
        </td>
      </tr>
    );
  };
  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("subagents")} (
                  {filteredLocations
                    ? formatNumber(filteredLocations.length)
                    : 0}
                  )
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      const obj = { ...newLocationObject };
                      if (appContext.user?.isSite()) {
                        Object.assign(obj, {
                          location: appContext.locations[0],
                          locationID: appContext.locations[0]?.id,
                          testLimit: appContext.locations[0]?.testLimit,
                          minTestOrderQty:
                            appContext.locations[0]?.minTestOrderQty,
                        });
                      } else {
                        const location = appContext.locations.find(
                          (f) => f.id === CONFIG.generalFacility
                        );
                        Object.assign(obj, {
                          location,
                          clientID: location?.clientID,
                          locationID: location?.id,
                          testLimit: location?.testLimit,
                          minTestOrderQty: location?.minTestOrderQty,
                        });
                      }
                      setSelectItem(obj);

                      setOpenCreateModal(true);
                    }}
                    label={"Create"}
                    title={t("createSubAgents")}
                    iconType={"addUserIcon"}
                  />

                  {/* <Icon
                    handleClick={() => {
                      setOpenImportModal(true);
                    }}
                    title={"Import Sub Agents"}
                    label={"Import"}
                    iconType={"importIcon"}
                  /> */}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  {/* <Icon
                    handleClick={handleDownloadSample}
                    title={"Download Csv Sample"}
                    label={"Download"}
                    iconType={"downloadIcon"}
                  /> */}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    locations.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>

                        {personalize.map(
                          (item, i) =>
                            item.isCheck && (
                              <HeaderItem
                                key={i}
                                width={tdWidth}
                                ItemKey={item.itemKey}
                                title={item.title}
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                aligned={headerAlign(item.textAlign)}
                              />
                            )
                        )}
                        <th
                          className={`border-0`}
                          style={{
                            color: "#231f20",
                            fontWeight: "bold",
                            width: "100px",
                            minWidth: "100px",
                          }}
                        >
                          PR Link
                        </th>
                        <th
                          className={`border-0`}
                          style={{
                            color: "#231f20",
                            fontWeight: "bold",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {locationsToMap &&
                        locationsToMap.map((item, i) => {
                          return <TableRow key={i} item={item} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete Sub Agent"
              message="Are you sure, you want to remove Sub Agent?"
              handleConfirm={handleConfirmDelete}
            />
          )}
          {openCreateModal && (
            <SubAgentsModal
              handleClose={() => {
                setSelectItem(newLocationObject), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleLocation}
              appContext={appContext}
            />
          )}
          {openImportModal && (
            <EmployeeImportModal
              handleClose={() => setOpenImportModal(false)}
              handleImport={handleImport}
              appContext={appContext}
              title="Import Sub Agent Data"
              subAgents={true}
            />
          )}

          {openPersonalizationModal && (
            <PersonalizationModal
              data={JSON.stringify(personalize)}
              handleChange={handlePersonalization}
              show={openPersonalizationModal}
              handleClose={() => setOpenPersonalizationModal(false)}
            />
          )}

          {openErrModal && (
            <ImportErrorModal
              subAgents
              title={title}
              errData={errorData}
              successData={successData}
              handleClose={async () => {
                setOpenErrModal(false);
                setErrorData([]);
                setsuccessData([]);
                setTitle("");
                await appContext.resetSubAgents();
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SubAgents;
