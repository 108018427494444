import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { usePlacesWidget } from "react-google-autocomplete";
import Select from "react-select";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import imagePlaceHolder from "assets/img/imagePlcaholder.png";
import ImageCropModal from "components/Input/ImageCropModal";
import {
  TIMEZONE_OPT,
  PROFILE_IMAGE_PATH,
  EXTRERNAL_IMAGE_PATH,
  INSURANCE_PROVIDER,
  countryListAllIsoData,
  MEDICATRE_IDS,
} from "constant";
import {
  validatePhone,
  isValidEmail,
  convertToLower,
  formatZip,
  getValidDep,
  getValidReg,
  getValidSpaces,
  formatZipCode,
  toTitleCase,
  searchPhone,
  isValidName,
  isValidIDNumber,
  convertToUpper,
  getStateLength,
  isValidZipCode,
} from "../../utils";
import { t } from "../../stringConstants";
import moment from "moment";
import api from "api";
import { AppContext } from "context/app-context";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Default_Profile from "assets/img/default_profile.png";
import countriesOption from "countries";
import EditableDropDown from "components/Dropdown/EditableDropDown";
import CustomDropDown from "components/Dropdown/CustomDropDown";
import localnoOptions from "localno";
import QrCodeCard from "components/QrCard/QrCodeCard";
import ImageInput from "components/Input/ImageInput";
import EmployeeSearchRecord from "components/EmployeeSearchRecord";
import Loader from "components/Loader/Loader";
import RadioButton from "../RadioButton/RadioButton";
import { IMAGE_UPLOAD_TYPE } from "constant";
import { Storage } from "aws-amplify";
import { CONFIG } from "constant";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import ImageView from "components/Image/ImageView";
import { MEDICARE_ID } from "constant";
import InsuranceDetailsModal from "components/Modal/InsuranceDetailsModals/InsuranceDetailsModal";
import { INSURANCE_TYPE } from "constant";
import InputGrid from "components/Input/InputGrid";
import { usersNo } from "constant";
const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const EmployeeModal = (props) => {
  const { user, handleSave, handleClose, resetCall, users, importMember } = props;
  const appContext = useContext(AppContext);
  const [departments, setDepartments] = useState([]);
  const [newUser, setNewUser] = useState(user);
  const [address, setAddress] = useState(null);
  const [show, setShow] = useState("");
  const [editButton, setEditButton] = useState(newUser.status === "active" ? false : true);
  const [locations, setLocations] = useState([]);
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [copyImage, setCopyImage] = useState(Default_Profile);
  const [showImageCroper, setShowImageCropper] = useState(false);
  const [regions, setRegions] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [showPopOver, setShowPopOver] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showImages, setShowImages] = useState(true);
  const [selectedVal, setSelectedVal] = useState(user.isNew ? user.department : "");
  const [autoShipmentConfirmation, setAutoShipmentConfirmation] = useState(false);
  const [selectSearchdata, setSelectSearchData] = useState(null);
  const [insuranceCardFront, setInsuranceCardFront] = useState("");
  const [insuranceCardBack, setInsuranceCardBack] = useState("");
  const [openInsuranceModal, setOpenInsuranceModal] = useState("");
  const [providers, setProviders] = useState([]);
  const firstNameRef = useRef();
  const childNode = useRef();

  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      try {
        const path = await Storage.get(`VaccinationImage/${file}`);
        setImage(path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  useEffect(async () => {
    setNewUser({
      ...user,
      ...(!user.primaryInsurance && { primaryInsurance: { copay: 0, deductible: 0 } }),
      ...(user.medicareNo && user.insuranceCompany !== MEDICARE_ID && { isMedicare: "Y" }),
      ...(!user.medicareNo && { isMedicare: "N" }),
      ...(user.insuranceDetails?.insuranceCompany ? { isSecondary: "Y" } : { isSecondary: "N" }),
      insuranceDetails: {
        ...user.insuranceDetails,
        ...(user.insuranceDetails?.medicareNo && user.insuranceDetails?.insuranceGroupId && { isMedicare: "Y" }),
        ...(!user.insuranceDetails?.copay && { copay: 0 }),
        ...(!user.insuranceDetails?.deductible && { deductible: 0 }),
      },
      isoCode: countryListAllIsoData.find((f) => f.name === user.country)?.code || "US",
    });
    if (!user.isNew && user.picture) {
      setProfileImage(`${PROFILE_IMAGE_PATH}${user.picture}`);
    }
    if (!user.isNew && user.insuranceCardFront) {
      await getFileFromStorage(user.insuranceCardFront, setInsuranceCardFront);
    }
    if (!user.isNew && user.insuranceCardBack) {
      await getFileFromStorage(user.insuranceCardBack, setInsuranceCardBack);
    }
  }, [user]);

  useEffect(async () => {
    if (appContext.providers?.length > 0 && appContext.setting) {
      const primaryCarePhysicianLabel = appContext.providers.find(
        (f) => f.id === appContext.setting?.defaultSetting?.primaryCarePhysician
      )?.name;
      const referringPhysicianLabel = appContext.providers.find(
        (f) => f.id === appContext.setting?.defaultSetting?.referringPyhsician
      )?.name;

      if (newUser.isNew) {
        setNewUser({
          ...newUser,
          primaryCarePhysician: user.primaryCarePhysician || primaryCarePhysicianLabel,
          referringPyhsician: user.referringPyhsician || referringPhysicianLabel,
          renderingProvider: user.renderingProvider || appContext.setting?.defaultSetting?.renderingProvider,
          referringProvider: user.referringProvider || appContext.setting?.defaultSetting?.referringProvider,
          orderingProvider: user.orderingProvider || appContext.setting?.defaultSetting?.orderingProvider,
        });
      }
      let arr = [];
      for (let val of appContext.providers) {
        arr.push({
          label: val.name,
          value: val.id,
        });
      }
      setProviders(arr);
    }
  }, [appContext.providers, appContext.setting]);

  useEffect(() => {
    if (selectedVal) {
      const calltime = departments.find((dpt) => selectedVal === dpt.id)?.callTime || "";
      const timeZone = departments.find((dpt) => selectedVal === dpt.id)?.deptTimeZone || null;
      setTimeZone(timeZone);
      const obj = { ...newUser, callTime: calltime };
      setNewUser(obj);
    }
  }, [selectedVal, departments]);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    setLocations(appContext.locations);
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (timeZone === null || timeZone) {
      const obj = { ...newUser, empTZ: "" };
      setNewUser(obj);
    }
  }, [timeZone]);

  useEffect(() => {
    if (address) {
      setNewUser({ ...newUser, ...address });
    }
  }, [address]);

  const handleAddressUpdate = (place) => {
    if (!place || Object.keys(place).length === 1) return;

    const obj = {};
    let address1 = "";
    let postcode = "";
    for (const curr of place.address_components) {
      const type = curr.types[0];

      switch (type) {
        case "street_number": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "premise": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "plus_code": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "neighborhood": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_4": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_3": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_2": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "route": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "postal_code": {
          postcode = `${curr.long_name}`;
          break;
        }
        case "postal_code_suffix": {
          postcode = `${postcode}-${curr.long_name}`;
          break;
        }
        case "postal_town": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "locality": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "administrative_area_level_1": {
          Object.assign(obj, {
            city: obj.city || curr.long_name,
            state: curr.short_name,
          });
        }
        case "country": {
          Object.assign(obj, {
            country: curr.long_name,
            isoCode: curr.short_name,
          });
          break;
        }
      }
    }
    Object.assign(obj, {
      street: address1.trim() || place.name,
      zip: postcode,
    });
    setAddress({ ...obj });
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc",
    onPlaceSelected: (place) => {
      handleAddressUpdate(place);
    },
    options,
  });

  const checkValidation = (newUser) => {
    if ((appContext.user.phone_number = usersNo.ibrahim)) return true;
    if (!newUser.firstName || !newUser.firstName.trim()) {
      setShow("First name is required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName || !newUser.lastName.trim()) {
      setShow("Last name is required");
      return;
    }

    if (!isValidName(newUser.lastName)) {
      setShow("Name is invalid must contains at least 1 letters and Name should be realistic");
      return;
    }

    if (!newUser.email || !newUser.email.trim()) {
      setShow("Email Address is required");
      return;
    }

    if (!isValidEmail(newUser.email)) {
      setShow("Invalid Email Address");
      return;
    }

    if (!newUser.phoneNumber || newUser.phoneNumber === " ") {
      setShow("Phone number is required");
      return;
    }
    if (!validatePhone(newUser.phoneNumber)) {
      setShow("Invalid Phone Number");
      return;
    }

    if (!newUser.dob) {
      setShow("Date of Birth is required");
      return;
    }

    if (!newUser.street || !newUser.street.trim()) {
      setShow("Address is required");
      return;
    }

    if (!newUser.city || !newUser.city.trim()) {
      setShow("City is required");
      return;
    }

    if (!newUser.state || !newUser.state.trim()) {
      setShow("State is required");
      return;
    }
    if (newUser.state.length > getStateLength(newUser)) {
      setShow("State must be 2 characters");
      return;
    }
    if (!newUser.zip) {
      setShow("Zip Code is required");
      return;
    }
    if (!isValidZipCode(newUser.isoCode || "US", newUser.zip)) {
      setShow("Zip Code is Invalid");
      return;
    }
    // if (!newUser.country) {
    //   setShow("Country is required");
    //   return;
    // }

    if (!newUser.isSignUp && !importMember && newUser.isMemberLogin === "Y") {
      if (!newUser.password) {
        setShow("Password is Required");
        return;
      }
      const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(newUser.password);

      if (!isValidPassword) {
        setShow(
          "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
        );
        return;
      }
    }

    if (!newUser.gender) {
      setShow("Required gender");
      return;
    }

    if (!newUser.insuranceCompany) {
      setShow("Insurance Provider is required");
      return;
    }
    // if (
    //   newUser.insuranceCompany &&
    //   MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 &&
    //   !newUser.insuranceGroupId
    // ) {
    //   setShow("Group ID is Required");
    //   return;
    // }

    if (!newUser.medicalNo || !newUser.medicalNo.trim()) {
      setShow(`${newUser.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is required`);
      return;
    }

    if (!isValidIDNumber(newUser.medicalNo)) {
      setShow(`${newUser.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is invalid`);
      return;
    }

    if (newUser.isMedicare === "Y" && !newUser.medicareNo) {
      setShow("Medicare Number Not be Empty");
      return;
    }
    if (newUser.isMedicare === "Y" && !isValidIDNumber(newUser.medicareNo)) {
      setShow("Medicare Number is invalid");
      return;
    }

    if (newUser.isSecondary === "Y") {
      if (!newUser.insuranceDetails?.insuranceCompany) {
        setShow("Secondary Insurance Provider is required");
        return;
      }
      if (
        newUser.insuranceDetails?.insuranceCompany &&
        MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 &&
        !newUser.insuranceDetails?.insuranceGroupId.trim()
      ) {
        setShow("Secondary Group ID is Required");
        return;
      }
      if (!newUser.insuranceDetails?.medicalNo || !newUser.insuranceDetails?.medicalNo.trim()) {
        setShow(
          `${
            newUser.insuranceDetails?.insuranceGroupId ? "Secondary Insurance Number" : "Secondary Medicare Number "
          } is Required`
        );
        return;
      }
      if (!isValidIDNumber(newUser.insuranceDetails?.medicalNo)) {
        setShow(`${newUser.insuranceDetails?.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is invalid`);
        return;
      }
      if (
        newUser.insuranceDetails?.isMedicare === "Y" &&
        !newUser.insuranceDetails?.medicareNo &&
        !newUser.medicareNo
      ) {
        setShow("Secondary Medicare Number Not be Empty");
        return;
      }

      if (newUser.insuranceDetails?.isMedicare === "Y" && !isValidIDNumber(newUser.insuranceDetails?.medicareNo)) {
        setShow("Secondary Medicare Number is invalid");
        return;
      }
    }
    if (CONFIG.isLabType) {
      // if (!newUser.primaryCarePhysician) {
      //   setShow("Primary CarePhysician is required");
      //   return;
      // }
      // if (!newUser.referringPyhsician) {
      //   setShow("Referring Pyhsician is required");
      //   return;
      // }
      if (!newUser.renderingProvider) {
        setShow("Rendering Provider is required");
        return;
      }
    } else {
      if (!newUser.subAgentID) {
        setShow("Sub Agent is required");
        return;
      }
    }
  };

  const onHandleSave = async (isConfirm, newUser) => {
    const validation = checkValidation(newUser);
    if (!validation) return;
    setLoading(true);
    const isRecordExists = await api.getUserValidate(newUser);

    if (isRecordExists.error) {
      setShow(isRecordExists.error);
      setLoading(false);
      return;
    }

    if (profileImage instanceof Object) {
      Object.assign(newUser, { ...newUser, profileImage: profileImage });
    }
    if (newUser.isMedicare === "N" && newUser.insuranceGroupId && !newUser.insuranceDetails?.medicareNo) {
      Object.assign(newUser, { ...newUser, medicareNo: "" });
    }
    // if (!newUser.isSecondary || newUser.isSecondary === "N") {
    //   Object.assign(newUser, { ...newUser, insuranceDetails: null });
    // }

    if (newUser.insuranceDetails?.isMedicare === "N" && newUser.insuranceDetails?.insuranceGroupId) {
      Object.assign(newUser, {
        ...newUser,
        insuranceDetails: { ...newUser.insuranceDetails, medicareNo: "" },
      });
    }

    let insuranceCardFrontFileName = "";
    let insuranceCardBackFileName = "";
    if (insuranceCardFront instanceof Object) {
      insuranceCardFrontFileName = await api.saveFileStorage(
        newUser.id,
        IMAGE_UPLOAD_TYPE.frontCard,
        insuranceCardFront
      );
    }

    if (insuranceCardBack instanceof Object) {
      insuranceCardBackFileName = await api.saveFileStorage(newUser.id, IMAGE_UPLOAD_TYPE.backCard, insuranceCardBack);
    }

    if (insuranceCardFrontFileName) {
      Object.assign(newUser, {
        insuranceCardFront: insuranceCardFrontFileName,
      });
    }
    if (insuranceCardBackFileName) {
      Object.assign(newUser, { insuranceCardBack: insuranceCardBackFileName });
    }

    if (newUser.subAgent) {
      let tests = null;
      if (newUser.isNew) {
        tests = newUser.subAgent?.testLimit;
      } else {
        tests = newUser.subAgent?.testLimit - newUser.testOrdered;
      }

      Object.assign(newUser, { testAvailable: tests < 0 ? 0 : tests });
    }

    handleSave(newUser);
    setLoading(false);
    handleClose();
  };
  const handleCropDone = (isConfirm, fileData) => {
    setShowImageCropper(false);
    if (isConfirm && fileData) {
      setProfileImage(new File([fileData], "profileImage.jpeg"));
    } else {
      setProfileImage(profileImage);
    }
  };

  const populate = (record) => {
    setShowPopOver(false);
    setSelectSearchData(null);
    const obj = {
      ...newUser,
      ...record,
      firstName: record.first,
      lastName: record.last,
      isNew: true,
      gender: record.sex,
      phoneNumber: searchPhone(record.phone_number),
      country: record.country ? record.country : "United States",
    };
    setNewUser(obj);
  };
  const searchPreReg = async (data, type) => {
    if (!data) return;
    setLoading(true);
    let phoneNo = data;
    if (phoneNo && type === "phone") {
      const phone = formatPhoneNumberIntl(data);

      const phoneArr = phone.split(" ");
      phoneArr.shift();
      phoneNo = phoneArr.join("");
    }
    let env = "";
    if (window.location.host.includes("localhost")) {
      env = "localhost";
    } else {
      env = "remote";
    }
    let record = [];
    try {
      record = await api.getPreRegisterData(phoneNo, env);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }

    if (record && record.length === 1) {
      populate(record[0]);
    }
    if (record && record.length > 1) {
      setRecordData(
        record.map((e) => {
          return {
            ...e,
            first: toTitleCase(e.first),
            last: toTitleCase(e.last),
          };
        })
      );
      setShowPopOver(!showPopOver);
    }
  };

  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      const obj = { ...newUser, callTime: user.callTime, onAlert: true };
      onHandleSave(false, obj);
    } else {
      onHandleSave(false, newUser);
    }
  };
  const handlePhoneNo = (e) => {
    setNewUser({
      ...newUser,
      phoneNumber: e,
    });
  };

  const handleAutoShipmentConfirmation = (isConfirm) => {
    setAutoShipmentConfirmation(false);
    if (!isConfirm) {
      setNewUser({ ...newUser, autoShipment: false });
    } else {
      setNewUser({ ...newUser, autoShipment: true });
    }
  };

  useEffect(() => {
    if (newUser.status === "active") {
      setEditButton(false);
    } else {
      setEditButton(true);
    }
  }, [newUser.status]);

  const insuranceObject = useMemo(() => {
    if (newUser.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  const secondaryInsuranceObject = useMemo(() => {
    if (newUser.insuranceDetails?.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceDetails.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"2xl"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {newUser.isNew ? `${t("createClient")}` : `${t("updateClient")}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <div className="d-md-flex  justify-content-between">
              <Col md="3" className="wrapper-profile">
                <div className="card-body px-3 pt-3">
                  <div className="profile-image mt-4 position-relative text-center">
                    <div className="crew-image-wrapper">
                      <figure className="profile-image-wrapper">
                        <div className="">
                          <img src={profileImage} alt="profile-image" />
                        </div>
                      </figure>
                    </div>
                    <p
                      className="text-center mx-auto mt-3 text-black mb-0 ellipsis"
                      style={{ maxWidth: "150px", width: "150px" }}
                    >{`${newUser.firstName} ${newUser.lastName}`}</p>
                    <small className="mb-0 text-center text-grey">{newUser.schrID}</small>
                    <br />
                    <small className="mb-0 text-center text-primary-color">{newUser.email}</small>
                  </div>
                  <hr className="horizontal-line" />
                  <div className="settings-profile">
                    <h4 className="profile-title">Settings</h4>
                    <div className="d-flex align-items-center justify-content-between profile-detail mb-3">
                      <span>Send SMS</span>
                      <SwitchToggle disabled checked />
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                    >
                      <span>Send Email</span>
                      <SwitchToggle disabled checked />
                    </div>

                    {!CONFIG.isLabType && (
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                      >
                        <span>Auto Shipment</span>
                        <SwitchToggle
                          checked={newUser.autoShipment}
                          disabled={editButton}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              autoShipment: e.target.checked ? true : false,
                            })
                          }
                        />
                      </div>
                    )}
                    {!newUser.isNew && (
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                      >
                        <span>Active</span>
                        <SwitchToggle
                          checked={newUser.status === "active"}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              status: e.target.checked ? "active" : "inactive",
                            })
                          }
                        />
                      </div>
                    )}

                    <h4 className="profile-title">Notes</h4>
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        value={newUser?.note}
                        readOnly={editButton}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            note: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="9" className="border-left-1">
                <div className="personal-info-wrapper pt-3 px-3">
                  <h4 className="text-decoration-underline text-black mt-0">Personal Info</h4>
                  <div className="testAdmin createClientsWrapper">
                    <div className="crew-profile-info">
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">First Name</label>
                        <Form.Control
                          readOnly={editButton}
                          maxLength={100}
                          className="modalInput"
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              firstName: getValidDep(e.target.value),
                            })
                          }
                          value={newUser.firstName}
                          placeholder="Enter First Name"
                          type="text"
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Middle Name</label>
                        <Form.Control
                          readOnly={editButton}
                          maxLength={100}
                          className="modalInput"
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              middleName: getValidDep(e.target.value),
                            })
                          }
                          value={newUser.middleName}
                          placeholder="Enter Middle Name"
                          type="text"
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Last Name</label>
                        <Form.Control
                          readOnly={editButton}
                          className="modalInput"
                          maxLength={100}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              lastName: getValidDep(e.target.value),
                            })
                          }
                          value={newUser.lastName}
                          placeholder="Enter Last Name"
                          type="text"
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Email Address</label>
                        <Form.Control
                          className="modalInput underlinedText"
                          maxLength={100}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              email: convertToLower(e.target.value),
                            })
                          }
                          value={newUser.email}
                          readOnly={editButton}
                          placeholder="Enter Email"
                          type="email"
                        />
                      </Form.Group>
                    </div>
                    <div className={"crew-profile-info"}>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Phone Number</label>
                        <PhoneNoInput
                          cssClass={newUser.isNew && "pd-right-30"}
                          handleChange={handlePhoneNo}
                          value={newUser.phoneNumber}
                          disabled={editButton}
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Date of Birth</label>
                        <DatePicker
                          className="w-100 orderTestDob"
                          disabled={editButton}
                          openCalendarOnFocus={false}
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          format="MM/dd/y"
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              dob:
                                moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                            })
                          }
                          value={
                            newUser.dob && new Date(newUser.dob) != "Invalid Date"
                              ? moment(newUser.dob, "YYYY-MM-DD").toDate()
                              : null
                          }
                          maxDate={new Date()}
                          minDate={new Date("1900-01-01")}
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper w-100">
                        <label className="mt-0 modalLineHeaders text-capitalize">Address</label>
                        <Form.Control
                          ref={ref}
                          className={`${!editButton ? "modalInput" : ""}`}
                          readOnly={editButton}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              street: getValidDep(e.target.value),
                            })
                          }
                          maxLength={255}
                          value={newUser.street}
                          placeholder="Enter Address"
                          type="text"
                        />
                        {/*<AutoCompleteAddress value={Employee?.street} handleChange={handleUpdateAddress} />*/}
                      </Form.Group>
                      <Form.Group className="form-group-wrapper w-100">
                        <label className="mt-0 modalLineHeaders text-capitalize">Apt/Unit#</label>
                        <Form.Control
                          className={`${!editButton ? "modalInput" : ""}`}
                          readOnly={editButton}
                          onChange={(e) => setNewUser({ ...newUser, street2: e.target.value })}
                          maxLength={255}
                          value={newUser.street2}
                          placeholder="Enter Apt/Unit#"
                          type="text"
                        />
                      </Form.Group>
                    </div>
                    <div className="crew-profile-info">
                      <Form.Group className="form-group-wrapper w-100">
                        <label className="mt-0 modalLineHeaders text-capitalize">City</label>
                        <Form.Control
                          readOnly={editButton}
                          className={`${!editButton ? "modalInput" : ""}`}
                          maxLength={65}
                          onChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                          value={newUser.city}
                          placeholder="Enter City"
                          type="text"
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">State</label>
                        <Form.Control
                          className={`${!editButton ? "modalInput" : ""}`}
                          readOnly={editButton}
                          onChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setNewUser({
                              ...newUser,
                              state: getValidSpaces(val),
                            });
                          }}
                          value={newUser.state}
                          maxLength={getStateLength(newUser)}
                          placeholder="Enter State Abbr: XX"
                          type="text"
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Zip Code</label>
                        <Form.Control
                          className={`${!editButton ? "modalInput" : ""}`}
                          onChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setNewUser({
                              ...newUser,
                              zip: newUser.isoCode === "US" ? formatZip(val) : getValidSpaces(val),
                            });
                          }}
                          readOnly={editButton}
                          value={newUser.isoCode === "US" ? formatZip(newUser.zip) : newUser.zip}
                          placeholder="Enter Zip Code"
                          maxLength={10}
                          type="text"
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Country</label>
                        <Select
                          options={countriesOption.filter((f) => f.value === "United States")}
                          blurInputOnSelect={true}
                          value={
                            newUser.country
                              ? {
                                  label: newUser.country,
                                  value: newUser.country,
                                }
                              : null
                          }
                          isDisabled={editButton}
                          menuPlacement="auto"
                          autoComplete="none"
                          autoFocus={false}
                          placeholder="Select Country"
                          onChange={(e) => {
                            const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                            setNewUser({
                              ...newUser,
                              country: e.value,
                              isoCode: country.code,
                            });
                          }}
                        />
                      </Form.Group>
                      <div>
                        <label className="my-0 modalLineHeaders">Gender</label>
                        <div className="d-flex justify-content-between w-100 flex-wrap">
                          <RadioButton
                            name="gender"
                            id="male"
                            label="Male"
                            checked={newUser?.gender === "M" ? true : false}
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                gender: e.target.checked ? "M" : "",
                              })
                            }
                            disabled={editButton}
                          />
                          <RadioButton
                            name="gender"
                            id="female"
                            checked={newUser?.gender === "F" ? true : false}
                            label="Female"
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                gender: e.target.checked ? "F" : "",
                              })
                            }
                            disabled={editButton}
                          />
                          <RadioButton
                            id="other"
                            checked={newUser?.gender === "X" ? true : false}
                            name="gender"
                            label="Not Specified"
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                gender: e.target.checked ? "X" : "",
                              })
                            }
                            disabled={editButton}
                          />
                        </div>
                      </div>
                      {/* </div>
                    <div className="crew-profile-info"> */}
                      {CONFIG.isLabType && (
                        <>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Primary Care Physician</label>
                            <Select
                              options={providers}
                              blurInputOnSelect={true}
                              className="w-100"
                              value={
                                newUser.primaryCarePhysician
                                  ? providers.find((item) => item.label === newUser.primaryCarePhysician)
                                  : null
                              }
                              isDisabled={editButton}
                              menuPlacement="auto"
                              autoComplete="none"
                              placeholder="Select Primary Care Physician"
                              onChange={(e) => {
                                setNewUser({
                                  ...newUser,
                                  primaryCarePhysician: e.label,
                                });
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Referring Pyhsician</label>
                            <Select
                              options={providers}
                              blurInputOnSelect={true}
                              className="w-100"
                              value={
                                newUser.referringPyhsician
                                  ? providers.find((item) => item.label === newUser.referringPyhsician)
                                  : null
                              }
                              isDisabled={editButton}
                              menuPlacement="auto"
                              autoComplete="none"
                              placeholder="Select Referring Physician"
                              onChange={(e) => {
                                setNewUser({
                                  ...newUser,
                                  referringPyhsician: e.label,
                                });
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Rendering Provider</label>
                            <Select
                              options={providers}
                              blurInputOnSelect={true}
                              className="w-100"
                              value={
                                newUser.renderingProvider
                                  ? providers.find((item) => item.value === newUser.renderingProvider)
                                  : null
                              }
                              isDisabled={editButton}
                              menuPlacement="auto"
                              autoComplete="none"
                              placeholder="Select Rendering Provider"
                              onChange={(e) => {
                                setNewUser({
                                  ...newUser,
                                  renderingProvider: e.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </>
                      )}
                    </div>
                    <div className="d-flex align-items-center column-gap-15 mb-3">
                      {(appContext.user?.isAdmin() || appContext.user?.isClient() || appContext.user?.isSite()) &&
                        !appContext?.userSubAgent &&
                        !CONFIG.isLabType && (
                          <>
                            <Form.Group className="w-50">
                              <label className="modalLineHeaders">Sub Agent:</label>
                              <Select
                                options={appContext.subAgents
                                  .filter((f) => f.id !== CONFIG.generalSubAgent)
                                  .map((s) => {
                                    return { ...s, label: s.name, value: s.id };
                                  })}
                                blurInputOnSelect={true}
                                menuPlacement="auto"
                                isDisabled={editButton}
                                placeholder="Select Sub Agent"
                                value={newUser.subAgent?.value ? newUser.subAgent : null}
                                onChange={(e) => {
                                  setNewUser({
                                    ...newUser,
                                    companyID: e.locationID,
                                    clientID: e.clientID,
                                    subAgentID: e.value,
                                    subAgent: e,
                                    ...(newUser.isChecked && {
                                      street: e.street,
                                      city: e.city,
                                      state: e.state,
                                      zip: e.zip,
                                      country: e.country,
                                      isoCode: countryListAllIsoData.find((f) => f.name === e.country)?.code || "US",
                                    }),
                                  });
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center column-gap-10">
                              <label className="modalLineHeaders mt-0 text-capitalize">use address:</label>
                              <Form.Check
                                className="use-address-field"
                                disabled={editButton}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    const findAgent = appContext.subAgents.find((f) => f.id === newUser.subAgentID);
                                    if (findAgent) {
                                      setNewUser({
                                        ...newUser,
                                        street: findAgent.street,
                                        city: findAgent.city,
                                        state: findAgent.state,
                                        zip: findAgent.zip,
                                        isChecked: true,
                                        country: findAgent.country,
                                        isoCode:
                                          countryListAllIsoData.find((f) => f.name === findAgent.country)?.code || "US",
                                      });
                                    } else {
                                      setNewUser({
                                        ...newUser,
                                        isChecked: true,
                                      });
                                    }
                                  }
                                }}
                              />
                            </Form.Group>
                          </>
                        )}
                    </div>
                  </div>
                  <div className="testAdmin createClientsWrapper">
                    <div className="crew-profile-column2 mb-3">
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Reffering Provider</label>
                        <Select
                          options={providers}
                          blurInputOnSelect={true}
                          className="w-75"
                          value={
                            newUser.referringProvider
                              ? providers.find((item) => item.value === newUser.referringProvider)
                              : null
                          }
                          isDisabled={editButton}
                          menuPlacement="auto"
                          autoComplete="none"
                          placeholder="Select Reffering Provider"
                          onChange={(e) => {
                            setNewUser({
                              ...newUser,
                              referringProvider: e.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Ordering Provider</label>
                        <Select
                          options={providers}
                          blurInputOnSelect={true}
                          className="w-100"
                          value={
                            newUser.orderingProvider
                              ? providers.find((item) => item.value === newUser.orderingProvider)
                              : null
                          }
                          isDisabled={editButton}
                          menuPlacement="auto"
                          autoComplete="none"
                          placeholder="Select Ordering Provider"
                          onChange={(e) => {
                            setNewUser({
                              ...newUser,
                              orderingProvider: e.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div></div>
                <div className="p-3">
                  <h4 className="text-decoration-underline text-black mt-0">Additional Info</h4>
                  <Row className="additional-info">
                    <Col md="4" className="testAdmin createClientsWrapper border-right-1">
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Primary Insurance</label>
                        <Select
                          options={INSURANCE_PROVIDER}
                          blurInputOnSelect={true}
                          menuPlacement="auto"
                          isDisabled={editButton}
                          placeholder="Select Primary Insurance"
                          value={insuranceObject}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              insuranceCompany: e.value,
                              insuranceCompanyCode: e.label,
                              insuranceGroupId: "",
                              medicalNo: "",
                              medicareNo: "",
                              isMedicare: "N",
                              isSecondary: "N",
                              insuranceDetails: {
                                ...newUser.insuranceDetails,
                                isMedicare: "N",
                                insuranceCompany:
                                  newUser.insuranceDetails?.insuranceCompany !== e.value
                                    ? newUser.insuranceDetails?.insuranceCompany
                                    : null,
                              },
                            })
                          }
                        />
                      </Form.Group>

                      {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                        <>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Group ID</label>
                            <Form.Control
                              className={`height-32 ${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              maxLength={16}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  insuranceGroupId: convertToUpper(e.target.value),
                                })
                              }
                              value={newUser.insuranceGroupId}
                              placeholder="Enter Group ID"
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Insurance Number</label>
                            <Form.Control
                              className={`height-32 ${!editButton ? "modalInput" : ""}`}
                              maxLength={16}
                              readOnly={editButton}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  medicalNo: convertToUpper(e.target.value),
                                })
                              }
                              value={newUser.medicalNo}
                              placeholder="Enter Insurance Number"
                              type="text"
                            />
                          </Form.Group>
                        </>
                      )}
                      {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                        <Form.Group className="form-group-wrapper order-select-area">
                          <label className="modalLineHeaders mt-0 text-capitalize">is this Medicare Advantage?</label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            <RadioButton
                              name="medicare"
                              id="yes"
                              label="Yes"
                              checked={newUser?.isMedicare === "Y" ? true : false}
                              disabled={editButton}
                              handleChange={(e) =>
                                e.target.checked &&
                                setNewUser({
                                  ...newUser,
                                  isMedicare: "Y",
                                  insuranceDetails: {
                                    ...newUser.insuranceDetails,
                                    isMedicare: "N",
                                    medicareNo: "",
                                  },
                                })
                              }
                            />
                            <RadioButton
                              name="medicare"
                              id="no"
                              checked={newUser?.isMedicare === "N" ? true : false}
                              disabled={editButton}
                              label="No"
                              handleChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  isMedicare: e.target.checked ? "N" : "",
                                  medicareNo: "",
                                })
                              }
                            />
                          </div>
                        </Form.Group>
                      )}
                      {newUser.insuranceCompany && newUser.isMedicare === "Y" && (
                        <Form.Group className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">
                            {MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"}
                          </label>
                          <Form.Control
                            className={`height-32 ${!editButton ? "modalInput" : ""}`}
                            readOnly={editButton}
                            maxLength={16}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                medicareNo: convertToUpper(e.target.value),
                              })
                            }
                            value={newUser.medicareNo}
                            placeholder={
                              MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"
                            }
                            type="text"
                          />
                        </Form.Group>
                      )}
                      {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1 && (
                        <Form.Group className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">
                            {MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"}
                          </label>
                          <Form.Control
                            className={`height-32 ${!editButton ? "modalInput" : ""}`}
                            readOnly={editButton}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                medicalNo: convertToUpper(e.target.value),
                                medicareNo: convertToUpper(e.target.value),
                              })
                            }
                            maxLength={16}
                            value={newUser.medicalNo}
                            placeholder={
                              MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"
                            }
                            type="text"
                          />
                        </Form.Group>
                      )}
                      <div className="form-group-wrapper text-end">
                        <span className="linkTag" onClick={() => setOpenInsuranceModal(INSURANCE_TYPE.primary)}>
                          Additional information
                        </span>
                      </div>

                      {openInsuranceModal === INSURANCE_TYPE.primary && (
                        <InsuranceDetailsModal
                          type={INSURANCE_TYPE.primary}
                          title="Primary Insurance Information"
                          insuranceDetails={{
                            insuranceCompany: newUser?.insuranceCompany,
                            insuranceCompanyCode: newUser?.insuranceCompanyCode,
                            insuranceGroupId: newUser?.insuranceGroupId,
                            insuranceType: newUser?.insuranceType,
                            isMedicare: newUser?.isMedicare,
                            medicalNo: newUser?.medicalNo,
                            medicareNo: newUser?.medicareNo,
                            copay: newUser?.primaryInsurance.copay,
                            deductible: newUser?.primaryInsurance.deductible,
                          }}
                          handleSaveChange={(obj) => {
                            let tempObj = { ...obj };
                            // if (obj.isMedicare === "Y") tempObj.insuranceDetails?.isMedicare = "N";
                            if (
                              obj.insuranceCompany &&
                              obj.insuranceCompany === newUser.insuranceDetails?.insuranceCompany
                            ) {
                              tempObj.isSecondary = "Y";
                              tempObj.insuranceDetails = {};

                              setOpenInsuranceModal(INSURANCE_TYPE.secondary);
                            } else {
                              setOpenInsuranceModal("");
                            }

                            tempObj.primaryInsurance = {
                              copay: tempObj.copay,
                              deductible: tempObj.deductible,
                            };
                            delete tempObj["copay"];
                            delete tempObj["deductible"];

                            setNewUser({ ...newUser, ...tempObj });
                          }}
                          handleClose={(key) => {
                            setOpenInsuranceModal("");
                            // if (!newUser.insuranceCompany) setNewUser({ ...newUser, isPrimary: "N" });
                          }}
                        />
                      )}

                      {newUser.insuranceCompany && (
                        <Form.Group className="form-group-wrapper order-select-area">
                          <label className="modalLineHeaders mt-0 text-capitalize">
                            Do you have Secondary Insurance?
                          </label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            <RadioButton
                              name="secondaryIns"
                              id="y"
                              label="Yes"
                              disabled={editButton}
                              checked={newUser?.isSecondary === "Y" ? true : false}
                              handleChange={(e) => {
                                setNewUser({
                                  ...newUser,
                                  isSecondary: e.target.checked ? "Y" : "",
                                });

                                if (e.target.checked) setOpenInsuranceModal(INSURANCE_TYPE.secondary);
                              }}
                            />
                            <RadioButton
                              name="secondaryIns"
                              id="n"
                              checked={newUser?.isSecondary === "N" ? true : false}
                              label="No"
                              disabled={editButton}
                              handleChange={(e) => {
                                setNewUser({
                                  ...newUser,
                                  isSecondary: e.target.checked ? "N" : "",
                                });
                              }}
                            />
                          </div>
                        </Form.Group>
                      )}

                      {newUser.isSecondary === "Y" && openInsuranceModal === INSURANCE_TYPE.secondary && (
                        <InsuranceDetailsModal
                          type={INSURANCE_TYPE.secondary}
                          title="Secondary Insurance Information"
                          insuranceDetails={{ ...(newUser.insuranceDetails || {}) }}
                          handleSaveChange={(obj) => {
                            setOpenInsuranceModal("");
                            setNewUser({ ...newUser, insuranceDetails: { ...newUser.insuranceDetails, ...obj } });
                          }}
                          handleClose={(key) => {
                            setOpenInsuranceModal("");
                            if (!newUser.insuranceDetails.insuranceCompany)
                              setNewUser({ ...newUser, isSecondary: "N" });
                          }}
                          primaryInsurance={newUser.insuranceCompany}
                          primaryMedicareAdvantage={newUser.isMedicare === "Y"}
                        />
                      )}

                      {!newUser.isSignUp && !importMember && !CONFIG.isLabType && (
                        <Form.Group className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">{t("createLoginForClient")}</label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            <RadioButton
                              name="login"
                              id="loginYes"
                              label="Yes"
                              disabled={editButton}
                              checked={newUser?.isMemberLogin === "Y" ? true : false}
                              handleChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  isMemberLogin: e.target.checked ? "Y" : "",
                                })
                              }
                            />
                            <RadioButton
                              name="login"
                              id="loginNo"
                              checked={newUser?.isMemberLogin === "N" ? true : false}
                              label="No"
                              disabled={editButton}
                              handleChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  isMemberLogin: e.target.checked ? "N" : "",
                                })
                              }
                            />
                          </div>
                        </Form.Group>
                      )}
                      {!newUser.isSignUp && !importMember && newUser.isMemberLogin === "Y" && (
                        <Form.Group className="w-100">
                          <label className="modalLineHeaders mt-0 text-capitalize">Password</label>
                          <Form.Control
                            className="modalInput underlinedText"
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                password: e.target.value,
                              })
                            }
                            maxLength={30}
                            value={newUser.password}
                            readOnly={editButton}
                            placeholder="Enter Password"
                            type="password"
                            autoComplete="none"
                          />
                        </Form.Group>
                      )}
                    </Col>
                    <Col md="4" className="testAdmin createClientsWrapper border-right-1">
                      {!newUser.insuranceCompany && (
                        <label className="modalLineHeaders mt-0 text-capitalize">Secondary Insurance Info</label>
                      )}

                      {newUser.insuranceDetails?.insuranceCompany && newUser.isSecondary === "Y" && (
                        <InputGrid
                          headerLabel={"Secondary Insurance Details# "}
                          list={[
                            { label: "Name: ", value: newUser.insuranceDetails.insuranceCompanyCode },
                            newUser.insuranceDetails.insuranceType && {
                              label: "Type: ",
                              value: newUser.insuranceDetails.insuranceType,
                            },
                            newUser.insuranceDetails.insuranceGroupId && {
                              label: "Group ID: ",
                              value: newUser.insuranceDetails.insuranceGroupId,
                            },
                            newUser.insuranceDetails.medicalNo && {
                              label: "Insurance No: ",
                              value: newUser.insuranceDetails.medicalNo,
                            },
                            newUser.insuranceDetails.medicareNo && {
                              label: "Medicare No: ",
                              value: newUser.insuranceDetails.medicareNo,
                            },
                            {
                              label: "Copay: ",
                              value: newUser.insuranceDetails.copay || "zero",
                            },
                            {
                              label: "Deductible: ",
                              value: newUser.insuranceDetails.deductible || "zero",
                            },
                          ]}
                          handleEdit={() => {
                            setNewUser({
                              ...newUser,
                              isSecondary: "Y",
                            });
                            setOpenInsuranceModal(INSURANCE_TYPE.secondary);
                          }}
                        />
                      )}

                      {!newUser.isNew && !CONFIG.isLabType && (
                        <>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Total Ordered Tests</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput height-32" : ""}`}
                              readOnly
                              value={newUser.testOrdered}
                              placeholder="Enter Total Ordered Tests"
                              maxLength={10}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Total Available Tests</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput height-32" : ""}`}
                              readOnly
                              value={newUser.testAvailable}
                              placeholder="Enter Total Available Tests"
                              maxLength={10}
                              type="text"
                            />
                          </Form.Group>
                        </>
                      )}
                    </Col>
                    <Col md="4" className="additional-info-radios border-right-1">
                      <div className="w-100">
                        <div className="d-block">
                          <div className="cards-heading">
                            <span>Insurance</span> Card Front
                          </div>
                          <div className="test-details-wrapper rounded-2">
                            <div className="imageWrapper">
                              <div className="d-flex align-items-center justify-content-center w-100">
                                <ImageInput
                                  type="Camera"
                                  setSelectedImage={setInsuranceCardFront}
                                  selectedImage={insuranceCardFront}
                                  imageCss="insuranceImageProfileSize"
                                  disabled={editButton}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="d-block">
                          <div className="cards-heading">
                            <span>Insurance</span> Card Back
                          </div>
                          <div className="test-details-wrapper rounded-2">
                            <div className="imageWrapper">
                              <div className="d-flex align-items-center justify-content-center w-100">
                                <ImageInput
                                  type="Camera"
                                  setSelectedImage={setInsuranceCardBack}
                                  selectedImage={insuranceCardBack}
                                  imageCss="insuranceImageProfileSize"
                                  disabled={editButton}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Modal.Body>
      {loading && <Loader />}
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {" "}
        {show.length > 0 && <strong style={{ color: "Red" }}>{show}</strong>}
      </div>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        {!newUser.isNew && newUser.isSignUp === 1 && (
          <Button
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            disabled={editButton}
            onClick={() => (handleClose(), resetCall(true))}
          >
            Reset Password
          </Button>
        )}
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          disabled={loading}
          className="modalButtons headerButton btn-fill"
          onClick={() => onHandleSave(true, { ...newUser })}
        >
          {newUser.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeModal;
