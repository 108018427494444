import React, { useState, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { INSURANCE_PROVIDER, INSURANCE_TYPE, MEDICATRE_IDS } from "constant";
import RadioButton from "components/RadioButton/RadioButton";
import { convertToUpper } from "utils";

const InsuranceDetailsModal = (props) => {
  const { handleClose, handleSaveChange, title, type, primaryInsurance, insuranceDetails, primaryMedicareAdvantage } =
    props;
  const [insuranceData, setInsuranceData] = useState(insuranceDetails);

  const InsuranceObject = useMemo(() => {
    if (insuranceData.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceData.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [insuranceData]);

  const handleSave = () => {
    if (!insuranceData.insuranceCompany) return;
    let insData = { ...insuranceData };
    if (insuranceData.isMedicare === "Y") {
      if (!insuranceData.medicareNo || !insuranceData.medicareNo.trim()) {
        return;
      }
    } else if (insuranceData.isMedicare === "N") {
      insData.medicareNo = "";
    }
    handleSaveChange(insData);
  };

  const showAdvantageRadio =
    type === INSURANCE_TYPE.secondary ? !primaryMedicareAdvantage && primaryInsurance !== "01192" : true;

  return (
    <>
      <Modal
        animation={true}
        onHide={() => {
          handleClose();
        }}
        className="seperateModal"
        show={true}
        style={{ paddingLeft: "0" }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper d-block">
            <div className="form-group-wrapper me-2 mt-4">
              <label className="mb-1">Insurance:</label>
              <Select
                options={INSURANCE_PROVIDER.filter((f) =>
                  primaryInsurance
                    ? f.value !== insuranceData.insuranceCompany && f.value !== primaryInsurance
                    : f.value !== insuranceData.insuranceCompany
                )}
                blurInputOnSelect={true}
                menuPlacement="auto"
                className="w-100"
                placeholder="Select Insurance"
                value={InsuranceObject}
                onChange={(e) => {
                  setInsuranceData({
                    ...insuranceData,
                    insuranceCompany: e.value,
                    insuranceCompanyCode: e.label,
                    insuranceType: e.payer_type,
                    insuranceGroupId: "",
                    isMedicare: "N",
                  });
                }}
              />
            </div>
            {insuranceData.insuranceCompany && MEDICATRE_IDS.indexOf(insuranceData.insuranceCompany) === -1 && (
              <>
                <div className="form-group-wrapper me-2">
                  <label className="mb-1">Group ID:</label>
                  <input
                    className={`height-32 modalInput`}
                    maxLength={16}
                    onChange={(e) =>
                      setInsuranceData({
                        ...insuranceData,
                        insuranceGroupId: convertToUpper(e.target.value),
                      })
                    }
                    value={insuranceData.insuranceGroupId}
                    placeholder="Enter Group ID"
                    type="text"
                  />
                </div>
                <div className="form-group-wrapper me-2 ">
                  <label className="mb-1">Insurance Number:</label>
                  <input
                    className={`height-32 modalInput`}
                    onChange={(e) =>
                      setInsuranceData({
                        ...insuranceData,
                        medicalNo: convertToUpper(e.target.value),
                      })
                    }
                    maxLength={16}
                    value={insuranceData.medicalNo}
                    placeholder="Enter Insurance Number"
                    type="text"
                  />
                </div>

                {showAdvantageRadio && (
                  <Form.Group className="form-group-wrapper">
                    <label className="modalLieHeaders emp-gender-mb text-capitalize">
                      Is the Insurance Information Entered an Advantage Plan?
                    </label>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <RadioButton
                        name="secondaryMedicare"
                        id="syes"
                        label="Yes"
                        checked={insuranceData.isMedicare === "Y" ? true : false}
                        handleChange={(e) =>
                          setInsuranceData({
                            ...insuranceData,
                            isMedicare: e.target.checked ? "Y" : "",
                          })
                        }
                      />
                      <RadioButton
                        name="secondaryMedicare"
                        id="sno"
                        checked={insuranceData?.isMedicare === "N" ? true : false}
                        label="No"
                        handleChange={(e) =>
                          setInsuranceData({
                            ...insuranceData,
                            isMedicare: e.target.checked ? "N" : "",
                            medicareNo: e.target.checked ? "" : insuranceData.medicareNo,
                          })
                        }
                      />
                    </div>
                  </Form.Group>
                )}
              </>
            )}

            {/* {insuranceData.insuranceCompany && insuranceData.isMedicare === "Y" && ( */}
            {showAdvantageRadio && insuranceData.isMedicare === "Y" && (
              <div className="form-group-wrapper me-2">
                <label className="mb-1">Medicare Number:</label>
                <input
                  className={`height-32 modalInput`}
                  maxLength={16}
                  onChange={(e) =>
                    setInsuranceData({
                      ...insuranceData,
                      medicareNo: convertToUpper(e.target.value),
                    })
                  }
                  value={insuranceData.medicareNo}
                  placeholder={"Medicare Number"}
                  type="text"
                />
              </div>
            )}

            {insuranceData.insuranceCompany && MEDICATRE_IDS.indexOf(insuranceData.insuranceCompany) !== -1 && (
              <div className="form-group-wrapper me-2 ">
                <label className="mb-1">Medicare Number:</label>
                <input
                  className={`height-32 modalInput`}
                  maxLength={16}
                  onChange={(e) =>
                    setInsuranceData({
                      ...insuranceData,
                      medicalNo: convertToUpper(e.target.value),
                      medicareNo: convertToUpper(e.target.value),
                    })
                  }
                  value={insuranceData.medicareNo}
                  placeholder={"Medicare Number"}
                  type="text"
                />
              </div>
            )}

            {insuranceData.insuranceCompany && (
              <>
                <div className="form-group-wrapper me-2">
                  <label className="mb-1">Copay:</label>
                  <input
                    className={`height-32 modalInput`}
                    maxLength={16}
                    step="any"
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      if (val >= 0) {
                        setInsuranceData({
                          ...insuranceData,
                          copay: val,
                        });
                      }
                    }}
                    value={insuranceData.copay}
                    placeholder={"Copay"}
                    type="number"
                  />
                </div>

                <div className="form-group-wrapper me-2">
                  <label className="mb-1">Deductible:</label>
                  <input
                    className={`height-32 modalInput`}
                    maxLength={16}
                    step="any"
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      if (val >= 0) {
                        setInsuranceData({
                          ...insuranceData,
                          deductible: val,
                        });
                      }
                    }}
                    value={insuranceData.deductible}
                    placeholder={"Deductible"}
                    type="number"
                  />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="saveButton">
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InsuranceDetailsModal;
