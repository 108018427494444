export const TESTING_SCHEDULE = "Testing Schedule";
export const QUESTION_SCHEDULE = "Question Schedule";
export const HEALTH_SAFETY_SCHEDULE = "Health & Safety Questions";
export const EMPLOYEE_DEPARTMENTS = "Employee Departments";
export const LOCATIONS = "Locations";
export const REGIONS = "Regions";
export const PROGRAMS = "Programs";
export const MESSAGE_SETTINGS = "Message Settings";
export const TIMER_TEST = "Timer Test";
export const DASHBOARD = "Dash Board";
export const REPORTS = "Reports";
export const CLAIMS = "Claims";
export const PROVIDER = "Provider";
export const GENERAL_SETTINGS = "General Settings";
