import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Form, Overlay, Popover } from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { usePlacesWidget } from "react-google-autocomplete";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import ImageCropModal from "components/Input/ImageCropModal";
import {
  TIMEZONE_OPT,
  PROFILE_IMAGE_PATH,
  EXTRERNAL_IMAGE_PATH,
  INSURANCE_PROVIDER,
  countryListAllIsoData,
  MEDICATRE_IDS,
} from "constant";
import {
  validatePhone,
  isValidEmail,
  convertToLower,
  formatZip,
  getValidDep,
  getValidReg,
  getValidSpaces,
  formatZipCode,
  toTitleCase,
  searchPhone,
  isValidName,
  isValidIDNumber,
  convertToUpper,
} from "../../../utils";
import { t } from "../../../stringConstants";
import moment from "moment";
import api from "api";
import { AppContext } from "context/app-context";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Default_Profile from "assets/img/default_profile.png";
import countriesOption from "countries";
import EditableDropDown from "components/Dropdown/EditableDropDown";
import CustomDropDown from "components/Dropdown/CustomDropDown";
import localnoOptions from "localno";
import QrCodeCard from "components/QrCard/QrCodeCard";
import ImageInput from "components/Input/ImageInput";
import EmployeeSearchRecord from "components/EmployeeSearchRecord";
import Loader from "components/Loader/Loader";
import RadioButton from "../../RadioButton/RadioButton";
import { IMAGE_UPLOAD_TYPE } from "constant";
import { Storage } from "aws-amplify";
import { CONFIG } from "constant";
const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const CheckEligibilityModal = (props) => {
  const { handleClose } = props;
  const appContext = useContext(AppContext);
  const [newUser, setNewUser] = useState({});
  const [address, setAddress] = useState(null);
  const [show, setShow] = useState("");
  const [loading, setLoading] = useState(false);
  const firstNameRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  //   useEffect(() => {
  //     if (address) {
  //       setNewUser({ ...newUser, ...address });
  //     }
  //   }, [address]);

  //   const handleAddressUpdate = (place) => {
  //     if (!place || Object.keys(place).length === 1) return;

  //     const obj = {};
  //     let address1 = "";
  //     let postcode = "";
  //     for (const curr of place.address_components) {
  //       const type = curr.types[0];

  //       switch (type) {
  //         case "street_number": {
  //           address1 = `${curr.long_name}`;
  //           break;
  //         }
  //         case "premise": {
  //           address1 = `${curr.long_name}`;
  //           break;
  //         }
  //         case "plus_code": {
  //           address1 = `${curr.long_name}`;
  //           break;
  //         }
  //         case "neighborhood": {
  //           address1 += ` ${curr.short_name}`;
  //           break;
  //         }
  //         case "sublocality_level_4": {
  //           address1 += ` ${curr.short_name}`;
  //           break;
  //         }
  //         case "sublocality_level_3": {
  //           address1 += ` ${curr.short_name}`;
  //           break;
  //         }
  //         case "sublocality_level_2": {
  //           address1 += ` ${curr.short_name}`;
  //           break;
  //         }
  //         case "route": {
  //           address1 += ` ${curr.short_name}`;
  //           break;
  //         }
  //         case "postal_code": {
  //           postcode = `${curr.long_name}`;
  //           break;
  //         }
  //         case "postal_code_suffix": {
  //           postcode = `${postcode}-${curr.long_name}`;
  //           break;
  //         }
  //         case "postal_town": {
  //           Object.assign(obj, { city: curr.long_name });
  //           break;
  //         }
  //         case "locality": {
  //           Object.assign(obj, { city: curr.long_name });
  //           break;
  //         }
  //         case "administrative_area_level_1": {
  //           Object.assign(obj, {
  //             city: obj.city || curr.long_name,
  //             state: curr.short_name,
  //           });
  //         }
  //         case "country": {
  //           Object.assign(obj, { country: curr.long_name });
  //           break;
  //         }
  //       }
  //     }
  //     Object.assign(obj, {
  //       street: address1.trim() || place.name,
  //       zip: postcode,
  //     });
  //     setAddress({ ...obj });
  //   };

  //   const { ref } = usePlacesWidget({
  //     apiKey: "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc",
  //     onPlaceSelected: (place) => {
  //       handleAddressUpdate(place);
  //     },
  //     options,
  //   });

  const onHandleSave = async (isConfirm, newUser) => {
    if (!newUser.firstName || newUser.firstName === " ") {
      setShow("First name is required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName || newUser.lastName === " ") {
      setShow("Last name is required");
      return;
    }

    if (!isValidName(newUser.lastName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.dob) {
      setShow("Date of Birth is required");
      return;
    }
    // if (!newUser.phoneNumber || newUser.phoneNumber === " ") {
    //   setShow("Phone number is required");
    //   return;
    // }
    // if (!validatePhone(newUser.phoneNumber)) {
    //   setShow("Invalid Phone Number");
    //   return;
    // }

    // if (!newUser.email || newUser.email === " ") {
    //   setShow("Email Address is required");
    //   return;
    // }

    // if (!isValidEmail(newUser.email)) {
    //   setShow("Invalid Email");
    //   return;
    // }

    // if (!newUser.gender) {
    //   setShow("Required gender");
    //   return;
    // }

    // if (!newUser.insuranceCompany) {
    //   setShow("Insurance Provider is required");
    //   return;
    // }
    // if (
    //   newUser.insuranceCompany &&
    //   MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 &&
    //   !newUser.insuranceGroupId.trim()
    // ) {
    //   setShow("Group ID is Required");
    //   return;
    // }

    if (!newUser.insuranceCompany) {
      setShow("Insurance Provider is required");
      return;
    }
    if (
      newUser.insuranceCompany &&
      MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 &&
      !newUser.insuranceGroupId.trim()
    ) {
      setShow("Group ID is Required");
      return;
    }

    if (!newUser.medicalNo.trim()) {
      setShow(`${newUser.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is required`);
      return;
    }

    if (!isValidIDNumber(newUser.medicalNo)) {
      setShow(`${newUser.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is invalid`);
      return;
    }

    if (newUser.isMedicare === "Y" && !newUser.medicareNo) {
      setShow("Medicare Number Not be Empty");
      return;
    }
    if (newUser.isMedicare === "Y" && !isValidIDNumber(newUser.medicareNo)) {
      setShow("Medicare Number is invalid");
      return;
    }
    const existingEmp = appContext.employees.find((item) => {
      if (item.firstName === newUser.firstName && item?.lastName === newUser.lastName && item.dob === newUser.dob) {
        return item;
      }
    });
    let nameData = {
      clientName: null,
      locationName: null,
      subAgentName: null,
    };
    const obj = newUser;
    if (existingEmp) {
      let clientName = appContext.companies.find((company) => company.id === existingEmp?.clientID)?.name;
      let locationName = appContext.locations.find((loc) => loc.id === existingEmp?.companyID)?.name;
      let subAgentName = appContext.subAgents.find((item) => item.id === existingEmp?.subAgent)?.name;
      nameData.clientName = clientName;
      nameData.locationName = locationName;
      nameData.subAgentName = subAgentName;
      Object.assign(obj, {
        clientID: existingEmp.clientID,
        locationID: existingEmp.companyID,
        subAgentID: existingEmp.subAgent,
      });
    } else {
      if (appContext.user?.isClient()) {
        Object.assign(obj, {
          clientID: appContext.userCompany.id,
        });
        nameData.clientName = appContext.userCompany.name;
      } else if (appContext.user?.isSite()) {
        Object.assign(obj, {
          clientID: appContext.userLocation.clientID,
          locationID: appContext.userLocation.id,
        });
        nameData.clientName = appContext.companies.find(
          (company) => company.id === appContext.userLocation.clientID
        ).name;
        nameData.locationName = appContext.userLocation.name;
      } else if (appContext.user?.isSubAgent()) {
        Object.assign(obj, {
          clientID: appContext.userSubAgent.clientID,
          locationID: appContext.userSubAgent.locationID,
          subAgentID: appContext.userSubAgent.id,
        });
        nameData.clientName = appContext.companies.find(
          (company) => company.id === appContext.userSubAgent.clientID
        ).name;
        nameData.locationName = appContext.locations.find((loc) => loc.id === appContext.userSubAgent.locationID).name;
        nameData.subAgentName = appContext.userSubAgent.name;
      }
    }

    try {
      setLoading(true);
      const res = await api.checkOrderEligibility(obj, nameData, appContext.user);

      if (!res.error) {
        setLoading(false);
        handleClose({
          ...res.data,
          ...nameData,
          ...newUser,
          payerId: newUser.insuranceCompany,
          groupId: newUser.insuranceGroupId,
          createdAt: moment().utc().toISOString(),
          message: "Success",
        });
      } else {
        setLoading(false);
        appContext.showErrorMessage(res.error.error_mesg);
        handleClose({
          ...nameData,
          ...newUser,
          payerId: newUser.insuranceCompany,
          groupId: newUser.insuranceGroupId,
          createdAt: moment().utc().toISOString(),
          message: "Unuccess",
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      appContext.showErrorMessage(err.message);
    }
  };

  const handlePhoneNo = (e) => {
    setNewUser({
      ...newUser,
      phoneNumber: e,
    });
  };

  const insuranceObject = useMemo(() => {
    if (newUser.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  return (
    <>
      <Modal
        show
        animation={true}
        size="md"
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        backdrop="static"
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {t("checkEligibility")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper">
            <div className="centerWrap">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">First Name: </label>
                <input
                  ref={firstNameRef}
                  value={newUser.firstName}
                  className="modalInput"
                  placeholder="First Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      firstName: getValidDep(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Last Name: </label>
                <input
                  value={newUser.lastName}
                  className="modalInput"
                  placeholder="Last Name"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      lastName: getValidDep(e.target.value),
                    })
                  }
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">DOB: </label>
                <DatePicker
                  className="w-100 checkEligibilityDOB"
                  openCalendarOnFocus={false}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  format="MM/dd/y"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                    })
                  }
                  value={
                    newUser.dob && new Date(newUser.dob) != "Invalid Date"
                      ? moment(newUser.dob, "YYYY-MM-DD").toDate()
                      : null
                  }
                  maxDate={new Date()}
                  minDate={new Date("1900-01-01")}
                />
              </div>
              {/* <div className="form-group-wrapper position-relative">
                <label className="modalLineHeaders">Email: </label>
                <input
                  value={newUser.email}
                  className={`modalInput ${newUser.isNew && "pd-right-30"}`}
                  placeholder="Email"
                  style={{
                    width: "100%",
                    flexShrink: "initial",
                  }}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      email: convertToLower(e.target.value),
                    })
                  }
                />
              </div> */}

              {/* <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone #: </label>
                <PhoneNoInput
                  cssClass={newUser.isNew && "pd-right-30"}
                  handleChange={handlePhoneNo}
                  value={newUser.phoneNumber}
                />
              </div> */}
              {/* <div className="form-group-wrapper emp-select-area">
                <label className="modalLineHeaders emp-gender-mb">
                  Gender:{" "}
                </label>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <RadioButton
                    name="gender"
                    id="male"
                    label="Male"
                    checked={newUser?.gender === "M" ? true : false}
                    handleChange={(e) =>
                      setNewUser({
                        ...newUser,
                        gender: e.target.checked ? "M" : "",
                      })
                    }
                  />
                  <RadioButton
                    name="gender"
                    id="female"
                    checked={newUser?.gender === "F" ? true : false}
                    label="Female"
                    handleChange={(e) =>
                      setNewUser({
                        ...newUser,
                        gender: e.target.checked ? "F" : "",
                      })
                    }
                  />
                  <RadioButton
                    id="other"
                    checked={newUser?.gender === "X" ? true : false}
                    name="gender"
                    label="Not Specified"
                    handleChange={(e) =>
                      setNewUser({
                        ...newUser,
                        gender: e.target.checked ? "X" : "",
                      })
                    }
                  />
                </div>
              </div> */}

              <div className="col-lg-12 col-12 mb-3">
                <label className="modalLineHeaders mt-0">Primary Insurance:</label>
                <Select
                  options={INSURANCE_PROVIDER}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select Primary Insurance"
                  className="w-100"
                  value={insuranceObject}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      insuranceCompany: e.value,
                      medicalNo: "",
                      insuranceGroupId: "",
                      insuranceProvider: e.payer_name,
                    })
                  }
                />
              </div>

              {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                <>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">Group ID:</label>
                    <input
                      value={newUser.insuranceGroupId}
                      className="modalInput"
                      maxLength={16}
                      placeholder="Enter Group ID"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          insuranceGroupId: convertToUpper(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">Insurance Number:</label>
                    <input
                      value={newUser.medicalNo}
                      className="modalInput"
                      maxLength={16}
                      placeholder="Enter Insurance Number"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          medicalNo: convertToUpper(e.target.value),
                        })
                      }
                    />
                  </div>
                </>
              )}

              {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1 && (
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Medicare#:</label>
                  <input
                    value={newUser.medicalNo}
                    className="modalInput"
                    maxLength={16}
                    placeholder={MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "ID Number"}
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        medicalNo: convertToUpper(e.target.value),
                        medicareNo: convertToUpper(e.target.value),
                      })
                    }
                  />
                </div>
              )}
              {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                <Form.Group className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0 text-capitalize">is this Medicare Advantage?</label>
                  <div className="d-flex justify-content-between w-100 flex-wrap">
                    <RadioButton
                      name="medicare"
                      id="yes"
                      label="Yes"
                      checked={newUser?.isMedicare === "Y" ? true : false}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          isMedicare: e.target.checked ? "Y" : "",
                        })
                      }
                    />
                    <RadioButton
                      name="medicare"
                      id="no"
                      checked={newUser?.isMedicare === "N" ? true : false}
                      label="No"
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          isMedicare: e.target.checked ? "N" : "",
                        })
                      }
                    />
                  </div>
                </Form.Group>
              )}
              {newUser.insuranceCompany && newUser.isMedicare === "Y" && (
                <div className={"mt-4"}>
                  <div className="form-group-wrapper me-2">
                    <label className="modalLineHeaders">Medicare#:</label>
                    <input
                      value={newUser.medicareNo}
                      className="modalInput"
                      maxLength={16}
                      placeholder={
                        MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"
                      }
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          medicareNo: convertToUpper(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {" "}
          {show.length > 0 && <strong style={{ color: "Red" }}>{show}</strong>}
        </div>
        {!loading ? (
          <Modal.Footer>
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>

            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={() => onHandleSave(true, { ...newUser })}
            >
              Check
            </Button>
          </Modal.Footer>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

export default CheckEligibilityModal;
