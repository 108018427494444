/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Orders from "views/AdminOrders";
import Profile from "views/Profile";
import garageIcon from "../assets/img/settings-garage.png";
import employeeIcon from "../assets/img/employee-icon.png";
import activeProgramIcon from "../assets/img/active-programs.png";
import copyIcon from "../assets/img/Copy-icon.svg";
import calendarIcon from "../assets/img/calendar-icon.svg";

const dashboardRoutes = [
  {
    path: "/orders",
    name: "Total Orders",
    icon: "nc-icon nc-badge",
    src: copyIcon,
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "nc-icon nc-badge",
    component: Profile,
    layout: "/admin",
  },
];

export default dashboardRoutes;
