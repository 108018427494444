import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  medFlowInLocalStorage,
  formatZip,
  checkValidity,
  getValidName,
  isValidName,
  isValidIdNum,
  isValidPhoneWithCode,
  getPhoneNo,
} from "utils";
import { useHistory, useLocation, Link } from "react-router-dom";
import logoImage from "assets/img/ltc_logo.png";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { QRCode } from "react-qrcode-logo";
import logo from "assets/img/logo.jpg";
import { Button } from "react-bootstrap";
import API from "api";
import html2canvas from "html2canvas";
import countriesOption from "countries";
import Select from "react-select";
import moment from "moment";
import Loader from "components/Loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  toTitleCase,
  convertToLower,
  isValidZipCode,
  getValidSpaces,
} from "utils";
import SweetAlert from "react-bootstrap-sweetalert";
import SingleDatePicker from "components/Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { isValidEmail } from "utils";
import {
  MIN_TEST_QUANTITY,
  newRegisterObj,
  countryListAllIsoData,
} from "constant";
import RegisterStepOne from "./RegisterStepOne";

let employeeId = "";

const PreRegister = (props) => {
  const [newUser, setNewUser] = useState(newRegisterObj);
  const [registrationDone, setRegistrationDone] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [locations, setLocations] = useState([]);
  const history = useHistory();
  const downloadRef = useRef();
  const location = useLocation();
  const [maxQty, setMaxQty] = useState(1);

  const [company, setCompnay] = useState({
    companyName: "Testing",
    companyWeb: "devtest",
    id: "10725574-1495-458d-a1c4-252becd947d0",
  });
  let url = window.location.href;

  useEffect(() => {
    if (location.state && location.state.data) {
      if (location.state.data.employee) {
        const emp = location.state.data.employee;
        console.log("employee", emp);
        if (emp.lastName) {
          setNewUser({
            ...emp,
            siteID: null,
            testSendQty: MIN_TEST_QUANTITY,
            phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
          });
          setMaxQty(emp.testAvailable);
        } else {
          setNewUser({
            ...newUser,
            siteID: null,
            testSendQty: MIN_TEST_QUANTITY,
            phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
          });
        }
      }
      if (location.state.data.locations) {
        setLocations(location.state.data.locations);
      }
    }
  }, [location.state]);

  console.log("location", newUser, locations);

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };
  console.log("newUSer", newUser);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      console.log("valval", val);
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const handleSubmit = async () => {
    if (!newUser.firstName) {
      showErrorMessage("First name is required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      showErrorMessage(
        "First name is invalid must contains at least 2 letters and Name should be realistic"
      );
      return;
    }

    if (!newUser.lastName) {
      showErrorMessage("Last name is required");
      return;
    }
    if (!isValidName(newUser.lastName)) {
      showErrorMessage(
        "Last name is invalid must contains at least 2 letters and Name should be realistic"
      );
      return;
    }

    if (!newUser.email) {
      showErrorMessage("Email is required");
      return;
    }
    if (!isValidEmail(newUser.email)) {
      showErrorMessage("Invalid Email");
      return;
    }

    if (!newUser.phoneNumber) {
      showErrorMessage("Phone Number is required");
      return;
    }

    if (!isValidPhoneWithCode(newUser.phoneNumber)) {
      showErrorMessage("Invalid Phone Number");
      return;
    }
    if (!newUser.dob) {
      showErrorMessage("Date of Birth is required");
      return;
    }

    if (!newUser.street.trim()) {
      showErrorMessage("Address is required");
      return;
    }
    if (!newUser.city.trim()) {
      showErrorMessage("City is required");
      return;
    }

    if (!newUser.state.trim()) {
      showErrorMessage("State is required");
      return;
    }
    if (!newUser.country) {
      showErrorMessage("Country is Required");
      return;
    }
    if (newUser.zip === null || !newUser.zip) {
      showErrorMessage("zip is required");
      return;
    }

    if (!isValidZipCode(newUser.isoCode || "US", newUser.zip)) {
      showErrorMessage("Invalid zipcode");
      return;
    }

    if (!newUser.medicalNo) {
      showErrorMessage("Medicare/Medicaid Number Number is required");
      return;
    }
    if (!newUser.sex) {
      showErrorMessage("Gender is required");
      return;
    }
    if (newUser.isVaccinated === null) {
      showErrorMessage("Vaccinated is required");
      return;
    }
    if (newUser.testSendQty === null) {
      showErrorMessage("Select tests quantity");
      return;
    }
    if (newUser.siteID === null) {
      showErrorMessage("Select location");
      return;
    }

    if (newUser.testAvailable <= 0) {
      setMessageError("Your monthly limit completed");
      return;
    }

    try {
      let [countryCode, phone] = getPhoneNo(newUser.phoneNumber);
      setLoading(true);
      let employeeObj = newUser;

      employeeObj = {
        ...employeeObj,
        phoneNumber: phone,
        countryCode: countryCode,
      };
      const response = await API.placeOrder(employeeObj);
      setLoading(false);
      setRegistrationDone(true);
    } catch (err) {
      employeeId = "";
      console.log("Errr", err);
      setLoading(false);
      setMessageError(err.message);
    }
  };

  console.log("newUSer", newUser);
  const downloadQRCode = async () => {
    const element = downloadRef.current;
    console.log("Eleme", element);
    const canvas = await html2canvas(element);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${newUser.phoneNumber.substring(2)}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getTestOrderQuantity = useMemo(() => {
    const options = [];
    for (let i = 1; i <= maxQty; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  }, [maxQty]);

  const getLocationOptions = useMemo(() => {
    return locations.map((l) => {
      return { value: l.id, label: l.name };
    });
  }, [locations]);

  if (step === 1) {
    return <RegisterStepOne />;
  }

  if (registrationDone) {
    return (
      <div className="registerFormWrapper">
        <div className="registerWrapper pre-register-comp">
          <div className="loginInner">
            <div className="text-center">
              {" "}
              <img src={logoImage} alt="logo" className="logo-width" />{" "}
            </div>
            <h4 className="w-100 text-center mt-4">
              <b>Test Order Successful</b>
            </h4>
            <p>
              Thank you for submitting your request for a LTC at home test. You
              should be receiving your test(s) within 3-5 business days.
            </p>

            <p>
              Please refer to your invitation for additional event details and
              FAQ's.
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="registerFormWrapper">
          <div className="registerWrapper pre-register-comp">
            <div className="loginInner">
              <div className="text-center">
                <img src={logoImage} alt="logo" className="logo-width" />
              </div>
              <h4 className="w-100 text-center mt-4 fs-4 fw-bold">
                Long Term Care Solution
              </h4>
              <h4 className="w-100 text-center mt-4 fs-4 fw-bold">
                Location Name
              </h4>
              <h4 className="w-100 text-center mt-4 fs-4 fw-bold">
                Order Tests
              </h4>
              <div className="form-wrapper">
                <div className="form-group-wrapper mt-2">
                  <label className="mb-1">First Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={newUser.firstName}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        firstName: getValidName(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Last Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={newUser.lastName}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        lastName: getValidName(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Email:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={newUser.email}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        email: convertToLower(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Phone Number:</label>
                  <PhoneNoInput
                    error={setPhoneError}
                    handleChange={(e) => {
                      setNewUser({
                        ...newUser,
                        phoneNumber: e,
                      });
                    }}
                    value={newUser.phoneNumber}
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="modalLineHeaders">DOB: </label>
                  <SingleDatePicker
                    onApply={(event, picker) => {
                      setNewUser({
                        ...newUser,
                        dob: moment(picker.startDate).format("YYYY-MM-DD"),
                      });
                    }}
                    value={newUser.dob}
                    currentDate={true}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">Address:</label>
                  <AutoCompleteAddress
                    value={newUser.street}
                    handleChange={handleUpdateAddress}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">City:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newUser.city}
                    placeholder="City"
                    onChange={(e) =>
                      setNewUser({ ...newUser, city: e.target.value })
                    }
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">State:</label>
                  <input
                    type="text"
                    className="form-control stateField"
                    placeholder="State Abbr: XX"
                    value={newUser.state}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) val = val.toUpperCase();
                      setNewUser({ ...newUser, state: getValidSpaces(val) });
                    }}
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Country:</label>
                  <Select
                    options={countriesOption}
                    blurInputOnSelect={true}
                    value={{ label: newUser.country, value: newUser.country }}
                    menuPlacement="auto"
                    placeholder="Select Country"
                    className="w-100"
                    onChange={(e) => {
                      const country = countryListAllIsoData.find(
                        (f) => f.name === e.value
                      ) || { code: "US" };
                      setNewUser({
                        ...newUser,
                        country: e.value,
                        isoCode: country.code,
                      });
                    }}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">Zip Code:</label>
                  <input
                    value={
                      newUser.isoCode === "US"
                        ? formatZip(newUser.zip)
                        : newUser.zip
                    }
                    type="text"
                    className="form-control"
                    placeholder="Enter Zip Code"
                    maxLength={10}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) val = val.toUpperCase();
                      setNewUser({
                        ...newUser,
                        zip:
                          newUser.isoCode === "US"
                            ? formatZip(val)
                            : getValidSpaces(val),
                      });
                    }}
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Medicare/Medicaid Number:</label>
                  <input
                    maxLength={16}
                    type="text"
                    className="form-control"
                    value={newUser.medicalNo}
                    placeholder="Medicare/Medicaid Number"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        medicalNo: checkValidity(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Gender: </label>
                  <div>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        checked={newUser.sex === "M"}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            sex: e.target.checked ? "M" : "",
                          })
                        }
                      />
                      <span className="radioButton">Male</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        checked={newUser.sex === "F"}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            sex: e.target.checked ? "F" : "",
                          })
                        }
                      />
                      <span className="radioButton">Female</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            sex: e.target.checked ? "X" : "",
                          })
                        }
                      />
                      <span className="radioButton">Not Specified</span>
                    </span>
                  </div>
                </div>

                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Vaccinated: </label>
                  <div>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="isVaccinated"
                        checked={newUser.isVaccinated === true}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isVaccinated: e.target.checked && true,
                          })
                        }
                      />
                      <span className="radioButton">Yes</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="isVaccinated"
                        checked={newUser.isVaccinated === false}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isVaccinated: e.target.checked && false,
                          })
                        }
                      />
                      <span className="radioButton">No</span>
                    </span>
                  </div>
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Select Location:</label>
                  <Select
                    options={getLocationOptions}
                    blurInputOnSelect={true}
                    menuPlacement="auto"
                    placeholder="Select Location"
                    className="w-100"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        siteID: e.value,
                      })
                    }
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Test Quantity:</label>
                  <Select
                    options={getTestOrderQuantity}
                    blurInputOnSelect={true}
                    defaultValue={{ value: 1, label: 1 }}
                    menuPlacement="auto"
                    placeholder="Select Quantity"
                    className="w-100"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        testSendQty: e.value,
                      })
                    }
                  />
                </div>
              </div>

              {error && (
                <div className="error-message">
                  {error.length > 0 && (
                    <strong style={{ color: "Red" }}>{error}</strong>
                  )}
                </div>
              )}
              {messageError && (
                <SweetAlert
                  show={messageError ? true : false}
                  error
                  title="Error"
                  onConfirm={() => setMessageError("")}
                >
                  {messageError}
                </SweetAlert>
              )}

              <div className="mt-2">
                <button
                  type="submit"
                  className="text-uppercase w-100 btn signup-btn"
                  onClick={handleSubmit}
                >
                  Order Test
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreRegister;
