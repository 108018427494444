import React, { useState, useRef } from "react";

const FileInput = (props) => {
  const { selectedFile, setSelectedFile } = props;

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (isValidFileType(file)) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
        alert("Invalid file type. Only images (JPEG, PNG, GIF) and PDFs are allowed.");
      }
    }
    event.target.value = null;
  };

  const isValidFileType = (file) => {
    const acceptedTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
    return acceptedTypes.includes(file.type);
  };

  const handleUploadButtonClick = () => {
    // Trigger the hidden file input click
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="form-group-wrapper">
        <label className="modalLineHeaders">Choose Image or Pdf: </label>
        <div className="upload-button" onClick={handleUploadButtonClick}>
          Upload File
        </div>

        <input
          type="file"
          ref={fileInputRef}
          accept="image/*,.pdf"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
      {selectedFile && (
        <div className="form-group-wrapper">
          <label className="modalLineHeaders"></label>
          <span className="selected-file">Selected File: {selectedFile.name}</span>
        </div>
      )}
    </>
  );
};

export default FileInput;
