import api from "api";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { claimActionOptions, claimActions } from "constant";
import moment from "moment";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { isValidAmount } from "utils";
import ImageInput from "components/Input/ImageInput";
import FileInput from "../Input/FileInput";

const ReturnAmountStatusModal = (props) => {
  const { handleClose, orderID, order, user } = props;
  const [detail, setDetail] = useState({});
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [retunStatusCard, setReturnStausCard] = useState("");
  const [error, setError] = useState("");
  const onHandleConfirm = async () => {
    if (!retunStatusCard) {
      setError("Please upload a file");
      return;
    }

    if (!detail.message || !detail.message.trim()) {
      setError("Please add message");
      return;
    }

    try {
      setLoading(true);
      let retunStatusCardFileName = "";
      const obj = { ...detail };
      if (retunStatusCard instanceof Object) {
        retunStatusCardFileName = await api.saveReturnedFileStorage(order.orderId, "returnedAmount", retunStatusCard);
      }

      if (retunStatusCardFileName) {
        Object.assign(obj, { retunStatusCardFileName });
      }

      const res = await api.returnedAmountStatus(obj, orderID, user);
      if (res?.error) {
        setError(res.error);
        setLoading(false);
        return;
      }
      setLoading(false);
      handleClose(true);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }

    // const data = employees.map((t) => {
    //   return {
    //     schrID: t.id,
    //     auditType: "ClearedForWork",
    //     action: "Done",
    //     reason,
    //     companyID: t.companyID,
    //     userName: appContext.user.name,
    //   };
    // });
    // await api.addlogs(data);
    // handleConfirm(true, reason);
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          Returned Amount
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* <Form.Group className="form-group-wrapper">
          <label className="modalLineHeaders mt-0 text-capitalize">Choose an action to perform:</label>
          <Select
            options={claimActionOptions}
            blurInputOnSelect={true}
            className="w-100"
            value={claimActionOptions.find((opt) => opt.value === detail.action) || ""}
            menuPlacement="auto"
            autoComplete="none"
            placeholder="Select Action"
            onChange={(e) => {
              setDetail({
                ...detail,
                action: e.value,
              });
            }}
          />
        </Form.Group> */}

        <FileInput setSelectedFile={setReturnStausCard} selectedFile={retunStatusCard} />

        {/* <div className="form-group-wrapper">
          <label className="modalLineHeaders">Return Status Card: </label>
          <div className="cardWrapper">
            <div className="d-flex align-items-center justify-content-center w-100">
              <ImageInput
                setSelectedImage={setReturnStausCard}
                selectedImage={retunStatusCard}
                imageCss="insuranceImageProfileSize my-1"
              />
            </div>
          </div>
        </div> */}

        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Message: </label>

          <textarea className="modalInput w-75" onChange={(e) => setDetail({ ...detail, message: e.target.value })}>
            {detail.message}
          </textarea>
        </div>
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => onHandleConfirm()}>
          Save
        </Button>
      </Modal.Footer>

      {loading && <Loader />}
    </Modal>
  );
};

export default ReturnAmountStatusModal;
