import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Card, Table } from "react-bootstrap";
import Select from "react-select";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { formatZip, getValidDep, getValidSpaces, searchPhone, getStateLength } from "utils";
import moment from "moment";
import { Storage } from "aws-amplify";
import api from "api";
import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";
import ProcedureModefierModal from "../ProcedureModefierModal";
import InputField from "components/InputField/InputField";
import RadioButton from "components/RadioButton/RadioButton";
import { PROCEDURE_CODE_HEADER } from "constant";
import { INSURANCE_PROVIDER } from "constant";
import ErrorMessage from "components/Message/ErrorMessage";
import InputSelectDropDown from "components/InputField/InputSelectDropDown";
import { DIAGNOSIS_OPTIONS } from "constant";
import { DIAGNOSIS_ITEMS } from "constant";
import { PARSE_ALPHA_INTO_NUM } from "constant";
import ConfirmationModal from "../ConfirmationModal";
import { formatEmployeeClaim } from "utils";
import { downloadDataAsCSV } from "utils";
import { formatEmployeesData } from "utils";
import EmployeePrevClaimsModal from "views/EmployeeClaims/EmployeePrevClaimsModal";
import { PREV_CLAIM_ACTIONS } from "constant";
import { isValidZipCode } from "utils";
import { CONFIRMATION_TYPE } from "constant";
import HospiceModifierModal from "./HospiceModifierModal";
import { HOSPICE_MODFIRE, POS_OPT, PROC_CODE_OPT, INSURANCE_TYPE, claimSubmitStatus } from "constant";
import { CONFIG } from "constant";
import ProcedureListView from "./components/ProcedureListView";
import { currencyformatter } from "constant";

const CreateClaimResubmissionModal = (props) => {
  const { user, handleClose, showPrevClaims } = props;
  const appContext = useContext(AppContext);
  const [procedure, setProcedure] = useState(false);
  const [newUser, setNewUser] = useState({});
  const [show, setShow] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [editObj, setEditObj] = useState({});
  const [providers, setProviders] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [setting, setSetting] = useState();
  const [prevClaims, setPrevClaims] = useState([]);
  const [cliList, setCliList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState(INSURANCE_TYPE.primary);
  const [employeeData, setEmployeeData] = useState(null);
  const [isHospice, setIsHospice] = useState(null);
  const [hospiceModifer, setHospiceModifier] = useState(false);
  const [openPrevClaimModal, setOpenPrevClaimModal] = useState(false);
  const [eraList, setEraList] = useState([]);

  const handleAddData = (newData) => {
    let newArray = [...dataList];
    newArray = newArray.map((obj) => {
      return { ...obj, from_date: newData.from_date };
    });
    newArray.push(newData);
    setDataList(newArray);
    setProcedure(false);
    setEditObj({});
  };

  useEffect(async () => {
    setIsDisabled(newUser.status === claimSubmitStatus.submitted);

    if (newUser.status && newUser.status === claimSubmitStatus.denied) {
      const era = await api.getClaimERA(newUser);
      if (era && era.length > 0) {
        setEraList(era);
      }
    }
  }, [newUser.status]);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    getCliData();
  }, []);

  useEffect(async () => {
    if (newUser.employeeID && !newUser.status) {
      await getEmployeeClaims(newUser.employeeID);
    }
  }, [newUser.employeeID]);

  const getEmployeeClaims = async (employeeID) => {
    const res = await api.findEmployeeClaims(employeeID);
    setPrevClaims(res);
    setOpenPrevClaimModal(true);
  };

  const getCliData = async () => {
    const res = await api.getCompanySetting();
    setSetting(res);
    setCliList(res.cliaNumber || []);
    if (res.cliaNumber && res.cliaNumber.length > 0) {
      const defaultCliaNumber = res.cliaNumber.find((s) => s.isDefault === "Yes");
      setNewUser((prev) => ({ ...prev, clia_number: defaultCliaNumber?.cliCode }));
    }
  };

  useEffect(() => {
    if (appContext?.providers.length > 0) {
      let arr = appContext.providers.map((m) => ({ ...m, value: m.id, label: m.name }));
      setProviders(arr);
    }
  }, [appContext.providers]);

  const handleConfirm = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) return;
    switch (type) {
      case CONFIRMATION_TYPE.SUBMIT_CLAIM:
        onHandleSave(false, "submitted", null, "claim");
        break;
      case CONFIRMATION_TYPE.RESUBMIT_CLAIM:
        onHandleSave(false, "submitted", null, "reclaim");
        break;
    }
  };

  const formatClaimData = (user) => {
    let modifiedObj = {};
    const newUser = user[0];

    if (newUser.employeeID) {
      const proc_array = newUser.proc_array.map((m) => ({
        ...m,
        proc_desc: PROC_CODE_OPT.find((f) => f.value === m.proc_code).label,
        posDesc: POS_OPT.find((f) => f.value === m.place_of_service).label,
        id: "prefix_" + Math.random().toString(36).substr(2, 9),
      }));
      setDataList(proc_array || []);
      setNewUser((prev) => ({
        ...prev,
        ...newUser,
        proc_array,
      }));
      return;
    }
    setEmployeeData(newUser);
    for (const key in newUser) {
      if (newUser.hasOwnProperty(key)) {
        if (key === "state") {
          modifiedObj["pat_state"] = newUser[key];
        }
        if (key === "id") {
          modifiedObj["employeeID"] = newUser[key];
        }
        if (key === "insuranceCompany") {
          modifiedObj["payerid"] = newUser[key];
          modifiedObj["payer_name"] = INSURANCE_PROVIDER.find((f) => f.value === newUser[key])?.label || "";
        }
        if (key === "medicalNo") {
          modifiedObj["ins_number"] = newUser[key];
        }
        if (key === "firstName") {
          modifiedObj["pat_name_f"] = newUser[key];
          modifiedObj["ins_name_f"] = newUser[key];
        }
        if (key === "lastName") {
          modifiedObj["pat_name_l"] = newUser[key];
          modifiedObj["ins_name_l"] = newUser[key];
        }
        if (key === "middleName") {
          modifiedObj["pat_name_m"] = newUser[key];
          modifiedObj["ins_name_m"] = newUser[key];
        }
        if (key === "dob") {
          modifiedObj["pat_dob"] = newUser[key];
          modifiedObj["ins_dob"] = newUser[key];
        }

        if (key === "city") {
          modifiedObj["pat_city"] = newUser[key];
          modifiedObj["ins_city"] = newUser[key];
        }
        if (key === "state") {
          modifiedObj["pat_state"] = newUser[key];
          modifiedObj["ins_state"] = newUser[key];
        }
        if (key === "zip") {
          modifiedObj["pat_zip"] = newUser[key];
          modifiedObj["ins_zip"] = newUser[key];
        }
        if (key === "street") {
          modifiedObj["pat_addr_1"] = newUser[key];
          modifiedObj["ins_addr_1"] = newUser[key];
        }
        if (key === "street2") {
          modifiedObj["pat_addr_2"] = newUser[key];
          modifiedObj["ins_addr_2"] = newUser[key];
        }
        if (key === "sex") {
          modifiedObj["pat_sex"] = newUser[key];
          modifiedObj["ins_sex"] = newUser[key];
        }
        if (key === "phoneNumber") {
          modifiedObj["pat_phone"] = newUser[key];
        }
        if (key === "providerID" || key === "refProviderID" || key === "ordProviderID") {
          modifiedObj["provider"] = {
            prov_id: newUser.providerID,
            prov_name: newUser.providerName,
            prov_name_f: newUser.providerFirstName,
            prov_name_l: newUser.providerLastName,
            prov_name_m: newUser.providerMidName,
            prov_npi: newUser.providerNpi,
            prov_taxid: newUser.providerTaxId,
            ref_id: newUser.refProviderID,
            ref_npi: newUser.refProviderNpi,
            ref_name: newUser.refProviderName,
            ref_name_l: newUser.refProviderFirstName,
            ref_name_f: newUser.refProviderLastName,
            ref_name_m: newUser.refProviderMiddleName,
            ord_prov_id: newUser.ordProviderID,
            ord_prov_npi: newUser.ordProviderNpi,
            ord_prov_name: newUser.ordProviderName,
            ord_prov_name_l: newUser.ordProviderFirstName,
            ord_prov_name_f: newUser.ordProviderLastName,
            ord_prov_name_m: newUser.ordProviderMiddleName,
          };
        }
        if (key === "schrID") {
          modifiedObj["pcn"] = newUser[key];
        }
      }
    }
    if (setting) {
      modifiedObj["bill_name"] = setting.name;
      modifiedObj["bill_npi"] = setting.npi;
      modifiedObj["bill_phone"] = setting.phoneNumber;
      modifiedObj["bill_state"] = setting.state;
      modifiedObj["bill_addr_1"] = setting.street;
      modifiedObj["bill_addr_2"] = setting.street2;
      modifiedObj["bill_city"] = setting.city;
      modifiedObj["bill_taxid"] = setting.taxid;
      modifiedObj["bill_taxid_type"] = setting.taxonomyType;
      modifiedObj["bill_zip"] = "910015432";
      modifiedObj["bill_id"] = "291U00000X";
    }
    if (newUser) {
      modifiedObj["insuranceDetails"] = newUser.insuranceDetails;
    }

    setNewUser((prev) => ({ ...prev, ...modifiedObj }));
  };

  useEffect(() => {
    if (user.length > 0) {
      formatClaimData(user);
    } else if (appContext.employees?.length > 0) {
      setEmployees(
        formatEmployeesData(
          appContext.employees,
          appContext.subAgents || [],
          appContext.locations || [],
          appContext.companies || [],
          appContext.providers || []
        )
      );
    }
  }, [user, appContext.employees, setting]);

  useEffect(() => {
    if (selectedEmployee) {
      formatClaimData([selectedEmployee]);
    }
  }, [selectedEmployee]);

  const handleAddHospiceModifire = (obj) => {
    setNewUser({ ...obj });
    setDataList(obj.proc_array);
  };

  useEffect(() => {
    if (insuranceInfo) {
      if (insuranceInfo === INSURANCE_TYPE.primary) {
        setNewUser({
          ...newUser,
          payerid: employeeData?.insuranceCompany || "",
          payer_name: employeeData?.insuranceCompanyCode || "",
          ins_number: employeeData?.medicalNo || "",
        });
      } else {
        setNewUser({
          ...newUser,
          payerid: employeeData.insuranceDetails?.insuranceCompany || "",
          payer_name: employeeData.insuranceDetails?.insuranceCompanyCode || "",
          ins_number: employeeData.insuranceDetails?.medicalNo || "",
        });
      }
    }
  }, [insuranceInfo, employeeData]);

  const onHandleSave = async (isNew, claimStatus, claimData, claimtype) => {
    const claim = claimData ? { ...claimData } : { ...newUser };
    const modifiedObj = claim;
    Object.assign(modifiedObj, {
      ins_name_f: claim.pat_name_f,
      ins_name_l: claim.pat_name_l,
      ins_name_m: claim.pat_name_m,
      ins_sex: claim.pat_sex,
      ins_addr_1: claim.pat_addr_1,
      ins_addr_2: claim.pat_addr_2,
      ins_city: claim.pat_city,
      ins_state: claim.pat_state,
      ins_dob: claim.pat_dob,
      ins_zip: claim.pat_zip,
    });

    if (employees.length > 0 && !selectedEmployee) {
      setError("Please Select an Employee");
      return;
    }

    if (!newUser.pat_name_f || !newUser.pat_name_f.trim()) {
      setError("First Name is required");
      return;
    }
    if (!newUser.pat_name_l || !newUser.pat_name_l.trim()) {
      setError("Last Name is required");
      return;
    }
    if (!newUser.pat_addr_1 || !newUser.pat_addr_1.trim()) {
      setError("Address is required");
      return;
    }

    if (!newUser.pat_city || !newUser.pat_city.trim()) {
      setError("city is required");
      return;
    }
    if (!newUser.pat_state || !newUser.pat_state.trim()) {
      setError("state is required");
      return;
    }

    if (!newUser.payer_name || !newUser.payer_name.trim()) {
      setError("insurance is required");
      return;
    }

    if (!newUser.ins_number || !newUser.ins_number.trim()) {
      setError("Insurance Number is required");
      return;
    }

    if (!newUser.pat_zip) {
      setError("Zip Code is required");
      return;
    }
    if (!isValidZipCode(newUser.isoCode || "US", newUser.pat_zip)) {
      setError("Zip Code is Invalid");
      return;
    }

    if ((claimData && claim?.proc_array?.length === 0) || (!claimData && (dataList == "" || dataList.length === 0))) {
      setError("Please add Procedure");
      return;
    }

    if (Object.keys(dataList).length > 0 || claim.proc_array.length > 0) {
      const mergedObj = Object.assign(modifiedObj, {
        proc_array: claimData ? claim.proc_array : dataList,
        status: claimStatus || "draft",
        updatedBy: appContext.user.sub,
        updatedByName: appContext.user.name,
      });

      if (prevClaims && prevClaims.length > 0) {
        if (prevClaims.some((f) => f.from_date_1 === claim?.proc_array[0].from_date)) {
          setError("Date of Service Not be Same!");
          return;
        }
      }
      setLoading(true);

      try {
        if (isNew) {
          const setting = await api.getCompanySetting();
          let seqNumber = setting.orderId;
          seqNumber++;

          Object.assign(mergedObj, { remote_claimid: `CL-${setting.code}-${seqNumber.toString().padStart(6, 0)}` });
          const claimSubmission = formatEmployeeClaim(mergedObj, setting);
          let res = await api.createClaim(claimSubmission, seqNumber);
          if (res.data) {
            if (claimStatus === "submitted") {
              downloadDataAsCSV([{ ...claimSubmission, id: res.data.id }], "employee_Claim");
              const fileName = `claim/claim_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
              await api.saveFileOnBucket(
                [{ ...claimSubmission, id: res.data.id, prov_id: "", ref_id: "", ord_prov_id: "" }],
                fileName
              );
              appContext.showSuccessMessage("Claim Submitted Successfully");
            } else {
              appContext.showSuccessMessage("Claim Created Successfully");
            }
          }
        } else {
          const emp = appContext.employees.find((f) => f.schrID === newUser.pcn);
          if (
            emp &&
            !hospiceModifer &&
            mergedObj.proc_array.every(
              (f) =>
                !HOSPICE_MODFIRE[f.mod1] &&
                !HOSPICE_MODFIRE[f.mod2] &&
                !HOSPICE_MODFIRE[f.mod3] &&
                !HOSPICE_MODFIRE[f.mod4]
            )
          ) {
            const res = await handleCheckEligibility(emp);

            if (res && res.benefit && res.benefit.length > 0) {
              const IsClaimHospice = res.benefit.find((f) => f.benefit_description.toLowerCase() === "hospice");
              if (IsClaimHospice) {
                setIsHospice({ ...newUser, proc_array: dataList });
                setLoading(false);
                return;
              }
            }
          }
          await handleUpdateClaim(mergedObj, claimStatus, claimtype);
        }
        setLoading(false);
        handleClose(true);
      } catch (err) {
        console.log("Error:-", err);
      }
    }
  };

  const handleUpdateClaim = async (mergedObj, claimStatus, claimtype) => {
    const claimSubmission = formatEmployeeClaim(mergedObj, setting);
    let res = await api.updateEmployeeClaim(claimSubmission);
    if (res && claimStatus === "submitted") {
      downloadDataAsCSV([claimSubmission], "employee_Claim");
      const fileName = `${claimtype}/${claimtype}_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      await api.saveFileOnBucket([{ ...claimSubmission, prov_id: "", ref_id: "", ord_prov_id: "" }], fileName);
      appContext.showSuccessMessage("Claim Submitted Successfully");
    } else {
      appContext.showSuccessMessage("Claim Updated Successfully");
    }
  };

  const handleCheckEligibility = async (emp) => {
    let response = "";
    if (emp.eligibilityStatus && emp.eligibilityStatus.createdAt.slice(0, 10) === moment().format("YYYY-MM-DD")) {
      const item = emp.eligibilityStatus;
      const data = JSON.parse(item.apiData);
      if (data.fileName) {
        try {
          let res = await Storage.get(`${data.fileName}`, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });

          const string = await res.Body.text();
          const apidata = JSON.parse(string);
          if (apidata?.elig) {
            console.log("apidata", apidata);
            response = apidata?.elig || "";
          } else {
            response = await api.checkManualEligibility(emp, appContext.user);
          }
        } catch (err) {
          console.log("Error:-", err.message);
          appContext.showErrorMessage(err.message);
        }
      }
    } else {
      response = await api.checkManualEligibility(emp, appContext.user);
    }

    return response;
  };

  const handleEdit = (data) => {
    setEditObj(data);
    setProcedure(true);
  };
  const handleUpdate = (item) => {
    let newArray = dataList?.map((obj) => {
      if (obj.id === item.id) {
        return item;
      } else {
        return obj;
      }
    });
    newArray = newArray.map((obj) => {
      return { ...obj, from_date: item.from_date };
    });
    setDataList(newArray);
    setEditObj({});
    setProcedure(false);
  };

  const handleDelete = (item) => {
    const newArray = dataList?.filter((obj) => {
      if (obj.id !== item.id) {
        return item;
      }
    });
    setDataList(newArray);
  };

  const handleUpdatePatientAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, pat_addr_1: val });
    } else {
      const addressObj = {
        pat_addr_1: val.street,
        pat_country: val.country,
        pat_city: val.city,
        pat_zip: val.zipcode,
        pat_state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };
  const handleUpdateInsAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, ins_addr_1: val });
    } else {
      const addressObj = {
        ins_addr_1: val.street,
        ins_country: val.country,
        ins_city: val.city,
        ins_zip: val.zipcode,
        ins_state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"2x2"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          {`Claim Info ${newUser.status ? `(${newUser.status})` : ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <div className="d-md-flex  justify-content-between">
              <Col md="12">
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <h4 className="text-decoration-underline text-black mt-0">Patient Info</h4>
                      <span className="text-secondary px-3">Patient Account #: {newUser.pcn}</span>
                      {employeeData?.insuranceDetails?.insuranceCompany && (
                        <div>
                          <Form.Group className="d-flex align-items-end">
                            <Form.Label className="my-0 modalLineHeaders w-100">Choose Insurance Info:</Form.Label>
                            <div className="d-flex justify-content-between">
                              <RadioButton
                                name="showInsurance"
                                id={INSURANCE_TYPE.primary}
                                checked={insuranceInfo === INSURANCE_TYPE.primary ? true : false}
                                label="Primary"
                                handleChange={(e) => setInsuranceInfo(INSURANCE_TYPE.primary)}
                                cssClass={"mx-1"}
                              />
                              <RadioButton
                                id={INSURANCE_TYPE.secondary}
                                checked={insuranceInfo === INSURANCE_TYPE.secondary ? true : false}
                                name="showInsurance"
                                label="Secondary"
                                handleChange={(e) => setInsuranceInfo(INSURANCE_TYPE.secondary)}
                                cssClass={"mx-1"}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      )}
                    </div>
                    <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                      <div className={"crew-profile-info crew-profile-again"}>
                        {employees.length > 0 && (
                          <Form.Group className="form-group-wrapper">
                            <Form.Label className="mt-0 text-capitalize">Select Employee</Form.Label>
                            <Select
                              options={employees}
                              blurInputOnSelect={true}
                              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                              getOptionValue={(option) => option.id}
                              menuPlacement="auto"
                              autoComplete="none"
                              placeholder="Select Employee"
                              onChange={(e) => setSelectedEmployee(e)}
                              isDisabled={isDisabled}
                            />
                          </Form.Group>
                        )}
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="First Name"
                          value={newUser.pat_name_f}
                          placeholder="First Name"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              pat_name_f: getValidDep(e.target.value),
                            })
                          }
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Middle Name"
                          value={newUser.pat_name_m}
                          placeholder="Middle Name"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              pat_name_m: getValidDep(e.target.value),
                            })
                          }
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Last Name"
                          value={newUser.pat_name_l}
                          placeholder="Last Name"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              pat_name_l: getValidDep(e.target.value),
                            })
                          }
                        />
                        <InputField
                          type="phone"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Phone"
                          value={newUser.pat_phone}
                          maxLength={65}
                          placeholder="Phone"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              pat_phone: e,
                            })
                          }
                        />
                        <div className="d-flex personal-info-cols-divide column-gap-10">
                          <div className="updated-middle-box w-100 first-col">
                            <InputField
                              type="datePicker"
                              inputStyle="w-100 orderTestDob"
                              labelStyle="text-capitalize"
                              label="DOB"
                              value={newUser.pat_dob}
                              placeholder="DOB"
                              disabled={isDisabled}
                              handleChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  pat_dob:
                                    moment(e).format("YYYY-MM-DD") != "Invalid date"
                                      ? moment(e).format("YYYY-MM-DD")
                                      : "",
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="crew-profile-info crew-profile-again">
                        <div>
                          <Form.Label className="mt-0 text-capitalize">Gender</Form.Label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            <RadioButton
                              name="gender"
                              id="male"
                              label="Male"
                              checked={newUser?.pat_sex === "M" ? true : false}
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewUser({
                                  ...newUser,
                                  pat_sex: e.target.checked ? "M" : "",
                                })
                              }
                            />
                            <RadioButton
                              name="gender"
                              id="female"
                              checked={newUser?.pat_sex === "F" ? true : false}
                              label="Female"
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewUser({
                                  ...newUser,
                                  pat_sex: e.target.checked ? "F" : "",
                                })
                              }
                            />
                            <RadioButton
                              id="other"
                              checked={newUser?.pat_sex === "X" ? true : false}
                              name="gender"
                              label="Not Specified"
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewUser({
                                  ...newUser,
                                  pat_sex: e.target.checked ? "X" : "",
                                })
                              }
                            />
                          </div>
                        </div>
                        <InputField
                          type="address"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Address"
                          value={newUser.pat_addr_1}
                          maxLength={65}
                          placeholder="Address"
                          disabled={isDisabled}
                          handleChange={(val, flag) => handleUpdatePatientAddress(val, flag)}
                        />

                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Apt/Unit#"
                          value={newUser.pat_addr_2}
                          maxLength={65}
                          placeholder="Apt/Unit#"
                          disabled={isDisabled}
                          handleChange={(e) => setNewUser({ ...newUser, pat_addr_2: e.target.value })}
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="City"
                          maxLength={65}
                          value={newUser.pat_city}
                          disabled={isDisabled}
                          placeholder="City"
                          handleChange={(e) => setNewUser({ ...newUser, pat_city: e.target.value })}
                        />

                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          value={newUser.pat_state}
                          placeholder="Enter State"
                          maxLength={10}
                          label="State"
                          disabled={isDisabled}
                          handleChange={(e) => {
                            setNewUser({
                              ...newUser,
                              pat_state: newUser.state,
                            });
                          }}
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          value={newUser.isoCode === "US" ? formatZip(newUser.pat_zip) : newUser.pat_zip}
                          placeholder="Enter Zip Code"
                          maxLength={10}
                          label="Zip"
                          disabled={isDisabled}
                          handleChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setNewUser({
                              ...newUser,
                              pat_zip: newUser.isoCode === "US" ? formatZip(val) : getValidSpaces(val),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3 crew-from-column">
                    <div className="w-30">
                      {/* <h4 className="text-decoration-underline text-black mt-0">Insurance Info</h4> */}
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex">
                          <Form.Group className="w-100">
                            <Form.Label className="text-capitalize">Insurance:</Form.Label>
                            <Select
                              options={INSURANCE_PROVIDER}
                              blurInputOnSelect={true}
                              isClearable={true}
                              menuPlacement="auto"
                              placeholder="Select Insurance"
                              isDisabled={isDisabled}
                              value={
                                newUser.payerid ? INSURANCE_PROVIDER.find((f) => f.value === newUser.payerid) : null
                              }
                              onChange={(e) => {
                                if (e) {
                                  setNewUser({ ...newUser, payer_name: e.label, payerid: e.value });
                                } else {
                                  setNewUser({ ...newUser, payer_name: "", payerid: "" });
                                }
                              }}
                            />
                          </Form.Group>
                          <InputField
                            type="text"
                            groupWrapper="insuredNumberField"
                            inputStyle="modalInput"
                            labelStyle="text-capitalize"
                            label="Insurance Number:"
                            value={newUser.ins_number}
                            placeholder="Insurance Number"
                            disabled={isDisabled}
                            handleChange={(e) => {
                              let val = e.target.value;
                              setNewUser({
                                ...newUser,
                                ins_number: val,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-50">
                      {/* <h4 className="text-decoration-underline text-black mt-0">Provider Info</h4> */}
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex">
                          <Form.Group className="w-100">
                            <Form.Label className="text-capitalize">Rendering Provider</Form.Label>
                            <Select
                              options={providers}
                              blurInputOnSelect={true}
                              isClearable={true}
                              menuPlacement="auto"
                              placeholder="Select Rendering Provider"
                              value={
                                newUser.provider?.prov_id
                                  ? providers.find((f) => f.value === newUser.provider?.prov_id)
                                  : null
                              }
                              isDisabled={isDisabled}
                              onChange={(e) => {
                                if (e) {
                                  setNewUser({
                                    ...newUser,
                                    provider: {
                                      ...newUser.provider,
                                      prov_id: e.id,
                                      prov_npi: e.npi,
                                      prov_name_l: e.firstName,
                                      prov_name_f: e.lastName,
                                      prov_name_m: e.middleName,
                                      prov_taxid: e.taxid,
                                    },
                                  });
                                } else {
                                  setNewUser({
                                    ...newUser,
                                    provider: {
                                      ...newUser.provider,
                                      prov_id: "",
                                      prov_npi: "",
                                      prov_name_l: "",
                                      prov_name_f: "",
                                      prov_name_m: "",
                                      prov_taxid: "",
                                    },
                                  });
                                }
                              }}
                            />
                          </Form.Group>

                          <Form.Group className="w-100">
                            <Form.Label className="text-capitalize">Referring Provider</Form.Label>
                            <Select
                              options={providers}
                              blurInputOnSelect={true}
                              isClearable={true}
                              menuPlacement="auto"
                              placeholder="Select Referring  Provider"
                              isDisabled={isDisabled}
                              value={
                                newUser.provider?.ref_id
                                  ? providers.find((f) => f.value === newUser.provider?.ref_id)
                                  : null
                              }
                              onChange={(e) => {
                                if (e) {
                                  setNewUser({
                                    ...newUser,
                                    provider: {
                                      ...newUser.provider,
                                      ref_id: e.id,
                                      ref_npi: e.npi,
                                      ref_name: e.name,
                                      ref_name_l: e.firstName,
                                      ref_name_f: e.lastName,
                                      ref_name_m: e.middleName,
                                    },
                                  });
                                } else {
                                  setNewUser({
                                    ...newUser,
                                    provider: {
                                      ...newUser.provider,
                                      ref_id: "",
                                      ref_npi: "",
                                      ref_name: "",
                                      ref_name_l: "",
                                      ref_name_f: "",
                                      ref_name_m: "",
                                    },
                                  });
                                }
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="w-100">
                            <Form.Label className="text-capitalize">Ordering Provider</Form.Label>
                            <Select
                              options={providers}
                              blurInputOnSelect={true}
                              isClearable={true}
                              menuPlacement="auto"
                              placeholder="Select Order Provider"
                              isDisabled={isDisabled}
                              value={
                                newUser.provider?.ord_prov_id
                                  ? providers.find((f) => f.value === newUser.provider?.ord_prov_id)
                                  : null
                              }
                              onChange={(e) => {
                                if (e) {
                                  setNewUser({
                                    ...newUser,
                                    provider: {
                                      ...newUser.provider,
                                      ord_prov_id: e.id,
                                      ord_prov_npi: e.npi,
                                      ord_prov_name: e.name,
                                      ord_prov_name_l: e.firstName,
                                      ord_prov_name_f: e.lastName,
                                      ord_prov_name_m: e.middleName,
                                    },
                                  });
                                } else {
                                  setNewUser({
                                    ...newUser,
                                    provider: {
                                      ...newUser.provider,
                                      ord_prov_id: "",
                                      ord_prov_npi: "",
                                      ord_prov_name: "",
                                      ord_prov_name_l: "",
                                      ord_prov_name_f: "",
                                      ord_prov_name_m: "",
                                    },
                                  });
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: "auto" }}>
                      {/* <h4 className="text-decoration-underline text-black mt-0">CLIA Info</h4> */}
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex">
                          <Form.Group className="w-100">
                            <Form.Label className="text-capitalize">CLIA Number:</Form.Label>
                            <Select
                              options={cliList}
                              blurInputOnSelect={true}
                              isClearable={true}
                              getOptionLabel={(option) => option.cliCode}
                              getOptionValue={(option) => option.cliCode}
                              isDisabled={isDisabled}
                              menuPlacement="auto"
                              placeholder="Select CLIA Number"
                              value={
                                newUser.clia_number
                                  ? { cliCode: newUser.clia_number, cliCode: newUser.clia_number }
                                  : null
                              }
                              onChange={(e) => {
                                setNewUser({ ...newUser, clia_number: e?.cliCode || "" });
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <h4 className="text-decoration-underline text-black mt-0">Diagnosis Code</h4>
                    <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                      <div className="crew-profile-complete crew-profile-again diagnosis-section">
                        {DIAGNOSIS_ITEMS.map((item) => (
                          <InputSelectDropDown
                            type="text"
                            inputStyle="modalInput"
                            labelStyle="text-capitalize"
                            label={item}
                            groupWrapper="section-item"
                            placeholder={"Search Code"}
                            readOnly={isDisabled}
                            optionList={DIAGNOSIS_OPTIONS}
                            value={newUser[`diag_${PARSE_ALPHA_INTO_NUM[item]}`]}
                            handleChange={(opt) =>
                              setNewUser({
                                ...newUser,
                                [`diag_${PARSE_ALPHA_INTO_NUM[item]}`]: opt.value,
                              })
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="text-decoration-underline text-black mt-0">Procedure Info</h4>
                      <div className="plus-icon" style={{ fontSize: "x-large" }}>
                        <i
                          className="fas fa-plus-circle fa-lg cursor-pointer w-auto"
                          onClick={() => {
                            if (!isDisabled) {
                              if (DIAGNOSIS_ITEMS.some((key) => newUser[`diag_${PARSE_ALPHA_INTO_NUM[key]}`])) {
                                setProcedure(true);
                              } else {
                                setError("Please Select At least one Diagnosis Code");
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                      <div className="data-listing-form">
                        <div>
                          <Table>
                            <thead>
                              <tr className="data-listing-row">
                                <th />
                                {PROCEDURE_CODE_HEADER.map((title) => (
                                  <th className="centered">{title}</th>
                                ))}
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {dataList?.map((data, index) => (
                                <ProcedureListView
                                  index={index}
                                  data={data}
                                  handleEdit={handleEdit}
                                  isDisabled={isDisabled}
                                  newUser={newUser}
                                  handleDelete={handleDelete}
                                  eraList={eraList}
                                />
                              ))}

                              {newUser.total_charge && (
                                <tr key={dataList.length}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-center">
                                    <span>{currencyformatter.format(newUser.total_charge || 0)}</span>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {procedure && (
                        <ProcedureModefierModal
                          data={editObj}
                          handleClose={() => {
                            setProcedure(false);
                            setEditObj({});
                          }}
                          handleAddData={handleAddData}
                          handleUpdate={handleUpdate}
                          claimData={newUser}
                          cssClass="seperateModal"
                        />
                      )}
                    </div>
                  </div>
                </Card>

                {(newUser.status === claimSubmitStatus.rejected || newUser.status === claimSubmitStatus.denied) && (
                  <>
                    <h4 className="profile-title">Note</h4>
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        value={newUser?.message}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            message: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </>
                )}
              </Col>
            </div>
          </div>
        </div>
      </Modal.Body>
      {loading && <Loader />}
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={handleConfirm}
          cssClass="seperateModal"
        />
      )}

      {prevClaims && prevClaims.length > 0 && openPrevClaimModal && (
        <EmployeePrevClaimsModal
          cssClass={"seperateModal"}
          user={newUser}
          handleClose={() => setOpenPrevClaimModal(false)}
          handleSaveClaim={(action, claim) => {
            if (action === PREV_CLAIM_ACTIONS.auto) {
              onHandleSave(true, "submitted", claim, "claim");
            } else if (action === PREV_CLAIM_ACTIONS.update) {
              setOpenPrevClaimModal(false);
              formatClaimData(claim);
            } else {
              setOpenPrevClaimModal(false);
            }
          }}
        />
      )}

      {isHospice && (
        <HospiceModifierModal
          newUser={{ ...isHospice }}
          handleClose={(val) => {
            if (val) setHospiceModifier(true);
            setIsHospice(null);
          }}
          handleSave={handleAddHospiceModifire}
        />
      )}

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>

        {newUser.status === claimSubmitStatus.draft && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill mx-3 ms-auto"
            onClick={() =>
              setOpenConfirmation({
                isShow: true,
                message: `Are you sure, you want to  Submit Employee Claim?`,
                actionType: CONFIRMATION_TYPE.SUBMIT_CLAIM,
                title: "Claim Submitted",
              })
            }
          >
            Submit Claim
          </Button>
        )}
        {(newUser.status === claimSubmitStatus.rejected || newUser.status === claimSubmitStatus.denied) && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill mx-3 ms-auto"
            onClick={() =>
              setOpenConfirmation({
                isShow: true,
                message: `Are you sure, you want to Re-Submit Employee Claim?`,
                actionType: CONFIRMATION_TYPE.RESUBMIT_CLAIM,
                title: "Claim Re-Submitted",
              })
            }
          >
            Re-Submit Claim
          </Button>
        )}

        {(!newUser.status || newUser.status === claimSubmitStatus.draft) && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(!newUser.status)}
          >
            {!newUser.status ? "Create" : "Update"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateClaimResubmissionModal;
