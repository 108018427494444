import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage";
import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";
import api from "api";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import { parseBooleanValue } from "utils";
import RadioButton from "components/RadioButton/RadioButton";
import { isValidAmount } from "utils";

const ClearClaimModal = (props) => {
  const { handleClose, orderID, title, claim, insurance, user } = props;
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const onHandleConfirm = async () => {
    if (insurance) {
      if (!status) {
        setError("Please select Insurance status");
        return;
      }

      if (status === "Approved" && !isValidAmount(amount)) {
        setError("Invalid Claim Amount");
        return;
      }
    }

    if (!reason) {
      setError("Please enter the reason");
      return;
    }

    try {
      setLoading(true);
      await api.deniedClaimStatus(
        orderID,
        reason,
        status,
        amount,
        insurance,
        claim,
        user
      );
      setLoading(false);
      handleClose(
        insurance
          ? `Insurance Status ${status} Successfully`
          : `Claim Status Rejected Successfully`
      );
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }

    // const data = employees.map((t) => {
    //   return {
    //     schrID: t.id,
    //     auditType: "ClearedForWork",
    //     action: "Done",
    //     reason,
    //     companyID: t.companyID,
    //     userName: appContext.user.name,
    //   };
    // });
    // await api.addlogs(data);
    // handleConfirm(true, reason);
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="my-0 modal-title h4"
          id="contained-modal-title-vcenter"
        >
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {insurance && (
          <>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">
                Insurance Status:
              </label>
              <div className="d-flex justify-content-between w-25 flex-wrap">
                <RadioButton
                  name="status"
                  id="approved"
                  label="Approved"
                  checked={status === "Approved" ? true : false}
                  handleChange={(e) =>
                    setStatus(e.target.checked ? "Approved" : "")
                  }
                />
                <RadioButton
                  name="status"
                  id="denied"
                  checked={status === "Denied" ? true : false}
                  label="Denied"
                  handleChange={(e) => {
                    setStatus(e.target.checked ? "Denied" : "");
                  }}
                />
              </div>
            </div>
            {status === "Approved" && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Claim Amount: </label>
                <input
                  className="modalInput w-25"
                  style={{ maxWidth: "10%" }}
                  type="number"
                  min="0"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            )}
          </>
        )}

        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Reason: </label>

          <textarea
            className="modalInput w-75"
            onChange={(e) => setReason(e.target.value)}
          >
            {reason}
          </textarea>
        </div>
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && (
          <ErrorMessage error={error} handleChange={() => setError("")} />
        )}
      </div>
      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => onHandleConfirm()}
        >
          Save
        </Button>
      </Modal.Footer>
      {loading && <Loader />}
    </Modal>
  );
};

export default ClearClaimModal;
