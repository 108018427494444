import api from "api";
import { PREV_CLAIM_TABLE } from "constant";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import EmpPrevClaimRow from "./EmpPrevClaimRow";
import { POS_OPT } from "constant";
import { PROC_CODE_OPT } from "constant";
import Loader from "components/Loader/Loader";
import { PREV_CLAIM_ACTIONS } from "constant";
import ErrorMessage from "components/Message/ErrorMessage";
import RadioButton from "components/RadioButton/RadioButton";
import { setProceduresFromDate } from "utils";
import moment from "moment";

const EmployeePrevClaimsModal = (props) => {
  const { user, handleClose, handleSaveClaim, cssClass } = props;
  const [empClaimsList, setEmpClaimsList] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [selectedDos, setSelectedDos] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [actionToPerform, setActionToPerform] = useState("");

  useEffect(() => {
    getEmployeeClaims();
  }, [user]);

  const getEmployeeClaims = async () => {
    setLoading(true);
    const res = await api.findEmployeeClaims(user.employeeID);
    if (res.length > 0) {
      const listToSave = res.map((claim) => {
        return {
          ...claim,
          proc_array: claim.proc_array.map((proc) => {
            return { ...proc, proc_name: PROC_CODE_OPT.find((obj) => obj.value === proc.proc_code)?.label || "" };
          }),
        };
      });
      setEmpClaimsList(listToSave);
    } else {
      handleClose(PREV_CLAIM_ACTIONS.new);
    }
    setLoading(false);
  };

  const handleCreate = (selectedClaim) => {
    if (!actionToPerform) return setError("Choose an action before proceeding!");
    const action = actionToPerform;

    const keysToDelete = ["createdAt", "updatedAt", "id", "status", "_deleted", "_version", "_lastChangedAt"];
    if (selectedClaim) {
      keysToDelete.forEach((key) => {
        if (selectedClaim.hasOwnProperty(key)) {
          delete selectedClaim[key];
        }
      });
    }

    switch (action) {
      case PREV_CLAIM_ACTIONS.update:
        if (selectedClaim) {
          handleSaveClaim(PREV_CLAIM_ACTIONS.update, [selectedClaim]);
        } else {
          setError("Please select any one claim!");
        }
        break;

      case PREV_CLAIM_ACTIONS.auto:
        if (selectedClaim && selectedDos) {
          if (empClaimsList.some((f) => f.from_date_1 === selectedDos)) {
            setError("Date of Service Not be Same!");
            return;
          }
          const claimToProceed = { ...selectedClaim };
          Object.assign(claimToProceed, {
            from_date: selectedDos,
          });

          const claim = setProceduresFromDate(claimToProceed, claimToProceed.from_date);
          console.log("auto: ", claim);
          handleSaveClaim(PREV_CLAIM_ACTIONS.auto, claim);
        } else {
          setError("Claim and Place of services should be filled before proceeding!");
        }
        break;

      case PREV_CLAIM_ACTIONS.new:
        handleSaveClaim(PREV_CLAIM_ACTIONS.new, user);
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      className={`${cssClass}`}
      centered
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Previous Claim List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <h4>Select a previous claim below to use as a template</h4>
          {selectedClaim && actionToPerform === PREV_CLAIM_ACTIONS.auto && (
            <>
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0  text-capitalize">DATE OF SERVICES</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  onChange={(e) => setSelectedDos(e.target.value)}
                  value={selectedDos}
                  placeholder="DATE OF SERVICES"
                  maxLength={10}
                  type="Date"
                />
              </Form.Group>
            </>
          )}
          <div>
            <Table>
              <thead>
                <tr className="data-listing-row">
                  <th />
                  {PREV_CLAIM_TABLE.map((title) => (
                    <th className="centered">{title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {empClaimsList.map((claim, index) => (
                  <EmpPrevClaimRow
                    claim={claim}
                    index={index}
                    selectedClaim={selectedClaim}
                    setSelectedClaim={setSelectedClaim}
                  />
                ))}
              </tbody>
            </Table>
          </div>
          <div>
            <Form.Label className="mt-0 text-capitalize">Choose an Action to Perform:</Form.Label>
            <div className="d-flex justify-content-between px-3 fw-bold">
              <RadioButton
                name="action"
                id={PREV_CLAIM_ACTIONS.update}
                checked={actionToPerform === PREV_CLAIM_ACTIONS.update}
                label="Open Template and Edit"
                handleChange={(e) => e.target.checked && setActionToPerform(PREV_CLAIM_ACTIONS.update)}
              />
              <RadioButton
                name="action"
                id={PREV_CLAIM_ACTIONS.auto}
                checked={actionToPerform === PREV_CLAIM_ACTIONS.auto}
                label="Create New From Existing"
                handleChange={(e) => e.target.checked && setActionToPerform(PREV_CLAIM_ACTIONS.auto)}
              />
              <RadioButton
                name="action"
                id={PREV_CLAIM_ACTIONS.new}
                checked={actionToPerform === PREV_CLAIM_ACTIONS.new}
                label="New Claim"
                handleChange={(e) => {
                  if (e.target.checked) setActionToPerform(PREV_CLAIM_ACTIONS.new);
                  setSelectedClaim(null);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      {loading && <Loader />}

      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() => handleCreate(selectedClaim)}
          disabled={!actionToPerform}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeePrevClaimsModal;
