import React from "react";
const Status = (props) => {
  const {
    type,
    size,
    crossIcon,
    tickIcon,
    color,
    title,
    cssClass,
    questionIcon,
  } = props;
  const getCrossType = () => {
    switch (type) {
      case "square":
        return "fa-times";
      case "circle":
        return "fa-times-circle";
      default:
        return "fa-times-circle";
    }
  };
  const getTickType = () => {
    switch (type) {
      case "square":
        return "fa-check";
      case "circle":
        return "fa-check-circle";
      default:
        return "fa-check-circle";
    }
  };
  const getQuestionType = () => {
    switch (type) {
      case "square":
        return "fa-question";
      case "circle":
        return "fa-question-circle";
      default:
        return "fa-question-circle";
    }
  };
  const getColor = () => {
    switch (color) {
      case "maroon":
        return "maroon";
      case "green":
        return "green";
      case "lightgreen":
        return "lightgreen";
      case "blue":
        return "blue";
      case "yellow":
        return "yellow";
      case "orange":
        return "orange";
      default:
        return "gray";
    }
  };
  const getSize = () => {
    switch (size) {
      case "sm":
        return "sm";
      case "md":
        return "md";
      case "lg":
        return "lg";
    }
  };
  return (
    <>
      {crossIcon && (
        <i
          className={`${cssClass} fas ${getCrossType()} ${getSize()} ${getColor()}`}
          title={title}
        />
      )}
      {tickIcon && (
        <i
          className={`${cssClass} fas ${getTickType()} ${getSize()} ${getColor()}`}
          title={title}
        />
      )}
      {questionIcon && (
        <i
          className={`${cssClass} fas ${getQuestionType()} ${getSize()} ${getColor()}`}
          title={title}
        />
      )}
    </>
  );
};
export default Status;
