import { TEST_QUANTITY } from "constant";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";

const TestQuantityModal = (props) => {
  const { handleConfirm, handleClose } = props;

  const [testQnty, setTestQnty] = useState(null);
  const [error, setError] = useState("");

  const handleSave = (testQnty) => {
    if (testQnty === null) {
      setError("Test Quantity not be empty");
      return;
    }
    handleConfirm(testQnty);
  };
  return (
    <Modal
      show
      backdrop="static"
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="my-0 modal-title h4"
          id="contained-modal-title-vcenter"
        >
          Test Quantity
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper">
          <label className="mb-1">Select Test Quantity:</label>
          <Select
            options={TEST_QUANTITY}
            blurInputOnSelect={true}
            menuPlacement="auto"
            placeholder="Select Test Quantity"
            className="w-100"
            onChange={(e) => setTestQnty(e.value)}
          />
        </div>
      </Modal.Body>
      {error && (
        <ErrorMessage handleChange={() => setError("")} error={error} />
      )}

      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => {
            handleSave(testQnty);
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestQuantityModal;
