import React, { useEffect, useState } from "react";
import deleteIcon from "assets/img/trash-icon.png";
import editIcon from "assets/img/edit-icon.png";
import { PARSE_ALPHA_INTO_NUM, currencyformatter, ERA_DENAIEL_REASONS } from "constant";
import { formatDateMDY } from "utils";
import Status from "components/Status";

const ProcedureListView = (props) => {
  const { data, isDisabled, newUser, handleDelete, handleEdit, index, eraList } = props;
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (eraList.length > 0) {
      let procData = null;
      eraList.forEach((f) => {
        const findProc = f.charge.find((s) => s.proc_code === data.proc_code);
        if (findProc) {
          procData = findProc;
        }
      });
      if (procData) {
        const reasons = procData.adjustment.map((m) => ERA_DENAIEL_REASONS.find((f) => f.Code === m.code).Reason);
        setReason([...new Set(reasons)]);
      }
    }
  }, [eraList]);

  return (
    <tr index={index}>
      <td className={`table-list-data text-center`}>
        <div className="d-flex align-items-center justify-content-around">
          <img src={editIcon} alt="edit" onClick={() => !isDisabled && handleEdit(data)} />
          {reason.length > 0 && (
            <div className="star-icon-wrapper" title={reason}>
              <Status type="circle" size="md" color="maroon" crossIcon />
            </div>
          )}
        </div>
      </td>
      <td className="table-list-data text-center">{formatDateMDY(data.from_date)}</td>
      <td className="table-list-data text-center" title={data?.posDesc}>
        {data.place_of_service}
      </td>
      <td className="table-list-data text-center" title={data?.proc_desc}>
        {data?.proc_code}
      </td>
      <td className="table-list-data text-center">{data?.mod1}</td>
      <td className="table-list-data text-center">{data?.mod2}</td>
      <td className="table-list-data text-center">{data?.mod3}</td>
      <td className="table-list-data text-center">{data?.mod4}</td>
      <td className="table-list-data text-center">{newUser[`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_1]}`]}</td>
      <td className="table-list-data text-center">{newUser[`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_2]}`]}</td>
      <td className="table-list-data text-center">{newUser[`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_3]}`]}</td>
      <td className="table-list-data text-center">{newUser[`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_4]}`]}</td>
      <td className="table-list-data text-center">{currencyformatter.format(data.charge || 0)}</td>
      <td className="table-list-data text-center">{data.units}</td>
      <td className="table-list-data text-center">
        <img
          src={deleteIcon}
          alt="delete"
          className="cursor-pointer"
          onClick={() => !isDisabled && handleDelete(data)}
        />
      </td>
    </tr>
  );
};

export default ProcedureListView;
