// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Employee, Site, Client, EmployeeOrder, AppSetting, InsuranceCompanies, Users, SubAgent, EligibiliyFiles, ClaimFiles, EmployeeClaims, ProviderInfo, CompanyStats } = initSchema(schema);

export {
  Employee,
  Site,
  Client,
  EmployeeOrder,
  AppSetting,
  InsuranceCompanies,
  Users,
  SubAgent,
  EligibiliyFiles,
  ClaimFiles,
  EmployeeClaims,
  ProviderInfo,
  CompanyStats
};