import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import Select from "react-select";
import { userCompanyID } from "utils";

const LocationImportModal = (props) => {
  const { handleClose, handleImport, appContext } = props;
  const getSelectedCompany = () => {
    if (appContext.user?.isClient()) {
      return userCompanyID.get();
    }
    return "";
  };
  const [selectedClient, setSelectedClient] = useState(getSelectedCompany());
  const handleOnDrop = (data) => {
    if (!selectedClient) {
      appContext.showErrorMessage("Please select the client first then import");
      return;
    }
    handleImport(data, selectedClient);
  };
  const handleOnError = (err) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return (
    <Modal
      show
      animation={true}
      backdrop="static"
      onHide={() => handleClose()}
      centered
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Import Facilities/Agents Data
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          {appContext.user?.isAdmin() && (
            // {(appContext.user?.isAdmin() || appContext.user?.isClient()) && (
            <div className="col-lg-12 col-12 mb-3">
              <label className="modalLineHeaders mt-0">Clients:</label>
              <Select
                options={appContext.companies.map((s) => {
                  return { ...s, label: s.name, value: s.id };
                })}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                blurInputOnSelect={true}
                menuPlacement="auto"
                placeholder="Select Client"
                className="w-100"
                onChange={(e) => setSelectedClient(e.value)}
              />
            </div>
          )}

          <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            config={{
              header: true,
              skipEmptyLines: true,
            }}
            onRemoveFile={handleOnRemoveFile}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => handleClose()}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationImportModal;
