import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  medFlowInLocalStorage,
  formatZip,
  checkValidity,
  getValidName,
  isValidName,
  isValidIdNum,
  isValidPhoneWithCode,
  getPhoneNo,
  isValidIDNumber,
} from "utils";
import { useHistory, useLocation, Link } from "react-router-dom";
import logoImage from "assets/img/ltc_logo.png";
import {
  newRegisterObj,
  MAX_TEST_QUANTITY,
  countryListAllIsoData,
  IMAGE_UPLOAD_TYPE,
  INSURANCE_PROVIDER,
} from "constant";
import DatePicker from "react-date-picker";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { QRCode } from "react-qrcode-logo";
import logo from "assets/img/logo.jpg";
import { Button, Form } from "react-bootstrap";
import API from "api";
import html2canvas from "html2canvas";
import countriesOption from "countries";
import Select from "react-select";
import moment from "moment";
import Loader from "components/Loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import EditIcon from "assets/img/edit-icon.png";
import { toTitleCase, convertToLower, isValidZipCode, getValidSpaces } from "utils";
import SweetAlert from "react-bootstrap-sweetalert";
import SingleDatePicker from "components/Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { isValidEmail } from "utils";
import OrderTestStepOne from "components/preRegister/OrderTestStepOne";
import { Auth } from "aws-amplify";
import ImageInput from "components/Input/ImageInput";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { v4 as uuidv4 } from "uuid";
import { convertToUpper } from "utils";
import { parseBooleanValue } from "utils";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import { getStateLength } from "utils";
import RadioButton from "components/RadioButton/RadioButton";
import { CONFIRMATION_TYPE } from "constant";
import { MEDICATRE_IDS } from "constant";
import { MEDICARE_ID } from "constant";
import MedicareModal from "components/Modal/OrderTestMedicareModal";
// import InsuranceModal from "components/Modal/InsuranceModal";
import ExpandSlectModal from "components/Modal/OrderTestMedicareModal";
import DropDown from "components/Modal/DropDown";

let employeeId = "";

const OrderTestSubAgent = (props) => {
  const [maxQty, setMaxQty] = useState(8);
  const [newUser, setNewUser] = useState({
    ...newRegisterObj,
    testSendQty: maxQty,
  });
  const [registrationDone, setRegistrationDone] = useState(false);
  const [modalAttribute, setmodalAttribute] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [locations, setLocations] = useState([]);
  const [step, setStep] = useState(1);
  const [pageExpire, setPageExpire] = useState(false);
  const history = useHistory();
  const downloadRef = useRef();
  const location = useLocation();
  const [insuranceCardFront, setInsuranceCardFront] = useState("");
  const [insuranceCardBack, setInsuranceCardBack] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState({
    isShow: false,
  });
  const [createNewAccount, setCreateNewAccount] = useState(false);
  const [showMedicareModal, setMedicareModal] = useState(false);
  const [showInsuranceModal, setInsuranceModal] = useState(false);
  const [showSelfModal, setSelfModal] = useState(false);

  let url = window.location.href;

  const {
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    if (id) {
      loadShowListing(id);
    }
  }, []);

  const loadShowListing = async (id) => {
    const locationData = await API.getLocationInformation(id, 1);
    setLoading(false);
    if (locationData && locationData.preRegistration) {
      setMaxQty(locationData.testLimit || 8);
      setNewUser({
        ...newUser,
        subAgents: locationData,
        location: locationData.location,
        subAgentID: locationData.id,
        testSendQty: locationData.testLimit,
      });
    } else {
      setPageExpire(true);
    }
  };

  useEffect(() => {
    if (location.state && location.state.data) {
      if (location.state.data.employee) {
        const emp = location.state.data.employee;
        if (emp.lastName) {
          setNewUser({
            ...emp,
            siteID: null,
            testSendQty: maxQty,
            phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
          });
          setMaxQty(emp.testAvailable);
        } else {
          setNewUser({
            ...newUser,
            siteID: null,
            testSendQty: maxQty,
            phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
          });
        }
      }
      if (location.state.data.locations) {
        setLocations(location.state.data.locations);
      }
    }
  }, [location.state]);

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      switch (type) {
        case CONFIRMATION_TYPE.AUTO_SHIPMENT:
          const obj = {
            ...newUser,
            autoShipment: false,
          };
          handleApiCall(obj);
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.AUTO_SHIPMENT:
        const obj = {
          ...newUser,
          autoShipment: true,
        };
        handleApiCall(obj);
        break;
    }
  };

  const handleSubmit = async () => {
    if (!newUser.firstName || !newUser.firstName.trim()) {
      showErrorMessage("First name is required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      showErrorMessage("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName || !newUser.lastName.trim()) {
      showErrorMessage("Last name is required");
      return;
    }
    if (!isValidName(newUser.lastName)) {
      showErrorMessage("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newUser.dob || !newUser.dob.trim()) {
      showErrorMessage("Date of Birth is required");
      return;
    }

    if (!newUser.gender) {
      showErrorMessage("Gender is required");
      return;
    }
    if (!newUser.email || !newUser.email.trim()) {
      showErrorMessage("Email is required");
      return;
    }
    if (!isValidEmail(newUser.email)) {
      showErrorMessage("Invalid Email");
      return;
    }
    if (newUser.isMedicare === "Y" && !newUser.medicareNo) {
      showErrorMessage("Medicare Number Not be Empty");
      return;
    }
    if (newUser.isMedicare === "Y" && !isValidIDNumber(newUser.medicareNo)) {
      showErrorMessage("Medicare Number is invalid");
      return;
    }
    if (newUser.isSecondary === "Y") {
      if (!newUser.insuranceDetails?.insuranceCompany) {
        showErrorMessage("Secondary Insurance Provider is required");
        return;
      }
      if (
        newUser.insuranceDetails?.insuranceCompany &&
        MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 &&
        !newUser.insuranceDetails?.insuranceGroupId.trim()
      ) {
        showErrorMessage("Secondary Group ID is Required");
        return;
      }
      if (!newUser.insuranceDetails?.medicalNo || !newUser.insuranceDetails?.medicalNo.trim()) {
        showErrorMessage(
          `${
            newUser.insuranceDetails?.insuranceGroupId ? "Secondary Insurance Number" : "Secondary Medicare Number "
          } is Required`
        );
        return;
      }
      if (!isValidIDNumber(newUser.insuranceDetails?.medicalNo)) {
        showErrorMessage(
          `${newUser.insuranceDetails?.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is invalid`
        );
        return;
      }
      if (newUser.insuranceDetails?.isMedicare === "Y" && !newUser.insuranceDetails?.medicareNo) {
        showErrorMessage("Secondary Medicare Number Not be Empty");
        return;
      }
      if (newUser.insuranceDetails?.isMedicare === "Y" && !isValidIDNumber(newUser.insuranceDetails?.medicareNo)) {
        showErrorMessage("Secondary Medicare Number is invalid");
        return;
      }
    }

    if (!newUser.street || !newUser.street.trim()) {
      showErrorMessage("Address is required");
      return;
    }

    if (!newUser.city || !newUser.city.trim()) {
      showErrorMessage("City is required");
      return;
    }

    if (!newUser.state || !newUser.state.trim()) {
      showErrorMessage("State is required");
      return;
    }
    if (newUser.state.length > getStateLength(newUser)) {
      showErrorMessage("State must be 2 characters");
      return;
    }
    if (!newUser.zip) {
      showErrorMessage("Zip Code is required");
      return;
    }
    if (!isValidZipCode(newUser.isoCode || "US", newUser.zip)) {
      showErrorMessage("Zip Code is Invalid");
      return;
    }

    if (!newUser.phoneNumber || !newUser.phoneNumber) {
      showErrorMessage("Phone Number is required");
      return;
    }

    if (!isValidPhoneWithCode(newUser.phoneNumber)) {
      showErrorMessage("Invalid Phone Number");
      return;
    }

    if (newUser.testSendQty === null) {
      showErrorMessage("Select tests quantity");
      return;
    }
    // if (!newUser?.companyID) {
    //   showErrorMessage("Select Location");
    //   return;
    // }

    if (newUser.testAvailable <= 0) {
      setMessageError({ message: "Your limit completed", type: "error" });
      return;
    }
    if (!newUser.isSelf) {
      showErrorMessage("Who are you ordering tests for?");
      return;
    }

    if (newUser.isSelf === "N" && (!newUser.requestedByFirstName || !newUser.requestedByFirstName.trim())) {
      showErrorMessage("Requested by first name is required");
      return;
    }

    if (newUser.isSelf === "N" && (!newUser.requestedByLastName || !newUser.requestedByLastName.trim())) {
      showErrorMessage("Requested by last name is required");
      return;
    }
    if (newUser.isSelf === "N" && !newUser.requestedByPhoneNumber) {
      showErrorMessage("Requested by Phone Number is required");
      return;
    }

    if (newUser.isSelf === "N" && !isValidPhoneWithCode(newUser.requestedByPhoneNumber)) {
      showErrorMessage("Requested by Phone Number is Invalid");
      return;
    }

    if (!newUser.isSignUp && createNewAccount) {
      if (!newUser.password) {
        showErrorMessage("Password is Required");
        return;
      }
      const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(newUser.password);

      if (!isValidPassword) {
        showErrorMessage(
          "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
        );
        return;
      }
    }
    const isRecordExists = await API.getUserValidate(newUser);
    if (isRecordExists.error) {
      showErrorMessage(isRecordExists.error);
      setLoading(false);
      return;
    }

    if (newUser.isMedicare === "N" && newUser.insuranceGroupId && !newUser.insuranceDetails?.medicareNo) {
      Object.assign(newUser, { ...newUser, medicareNo: "" });
    }

    if (newUser.isSelf === "Y") {
      Object.assign(newUser, {
        requestedByFirstName: "",
        requestedByLastName: "",
        requestedByPhoneNumber: "",
      });
    }
    if (newUser.isSelf === "N") {
      Object.assign(newUser, {
        userName: `${newUser.requestedByFirstName} ${newUser.requestedByLastName}`.trim(),
        requestedBySelf: 2,
      });
    }
    try {
      setLoading(true);
      if (!newUser.autoShipment) {
        setLoading(false);
        setOpenConfirmation({
          isShow: true,
          actionType: CONFIRMATION_TYPE.AUTO_SHIPMENT,
          title: "Enable Auto Shipment",
          message: `Would you like to enable auto shipments and receive ${newUser.testSendQty} COVID-19 tests every month automatically?`,
        });
      } else {
        handleApiCall(newUser);
      }
      // const res = await API.checkOrderEligibility(newUser);
      // if (!res.error) {

      // } else {
      //   setLoading(false);
      //   setMessageError({
      //     message: `${res.error?.error_mesg}`,
      //     type: "error",
      //   });
      // }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleApiCall = async (newUser) => {
    const memberID = uuidv4();
    try {
      let [countryCode, phone] = getPhoneNo(newUser.phoneNumber);
      setLoading(true);
      let employeeObj = newUser;

      let insuranceCardFrontFileName = "";
      let insuranceCardBackFileName = "";
      if (insuranceCardFront) {
        insuranceCardFrontFileName = await API.saveFileStorage(
          newUser.medicalNo,
          IMAGE_UPLOAD_TYPE.frontCard,
          insuranceCardFront
        );
      }
      if (insuranceCardBack) {
        insuranceCardBackFileName = await API.saveFileStorage(
          newUser.medicalNo,
          IMAGE_UPLOAD_TYPE.backCard,
          insuranceCardBack
        );
      }

      employeeObj = {
        ...employeeObj,
        sex: employeeObj.gender,
        phoneNumber: phone,
        countryCode: countryCode,
        orderDate: moment().format("YYYYMMDD"),
        insuranceCardFront: insuranceCardFrontFileName,
        insuranceCardBack: insuranceCardBackFileName,
        ...(!newUser.userName && {
          userName: `${newUser.firstName} ${newUser.lastName}`.trim(),
        }),
        userID: newUser.id || null,
      };

      if (employeeObj.isNew) {
        Object.assign(employeeObj, { id: memberID });
      }
      const response = await API.placeOrder(employeeObj);

      // setMessageError({
      //   message: `Thank you for ordering ${employeeObj.testSendQty} COVID-19 Antigen Tests. Please check your email for further information`,
      //   type: "success",
      // });
      if (response.error) {
        setMessageError({ message: response.error, type: "error" });
        setLoading(false);
        return;
      }
      if (createNewAccount) await API.createSystemUser(newUser, response);

      setLoading(false);
      setCreateNewAccount(false);
      setRegistrationDone(true);
    } catch (err) {
      employeeId = "";
      console.log("Errr", err);
      setLoading(false);
      setMessageError({ message: err.message, type: "error" });
    }
  };

  const onChangeFile = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      try {
        setLoading(true);
        setFiles(file);

        const fname = getFileName(file.name);
        setFileNames((prevName) => [...prevName, fname]);
        await Storage.put(fname, file, { bucket: "employee-external-tests" });
        event.target.value = null;
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const downloadQRCode = async () => {
    const element = downloadRef.current;

    const canvas = await html2canvas(element);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${newUser.phoneNumber.substring(2)}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getTestOrderQuantity = useMemo(() => {
    const options = [];
    for (let i = 2; i <= maxQty; i = i + 2) {
      options.push({ value: i, label: i });
    }
    return options;
  }, [maxQty]);

  const secondaryInsuranceObject = useMemo(() => {
    if (newUser.insuranceDetails?.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceDetails.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  const getLocationOptions = useMemo(() => {
    return locations.map((l) => {
      return { value: l.id, label: l.name };
    });
  }, [locations]);

  const handleProcced = async (obj) => {
    setLoading(true);
    try {
      const response = await API.getPatientData(obj.medicalNo, obj.insuranceGroupId, obj.insuranceCompany);
      const patientRecord = response.employee;
      const invalidGroupId = response.invalidGroupId || 0;

      setLoading(false);
      if (!patientRecord && invalidGroupId === 0) {
        setNewUser({
          ...newUser,
          ...obj,
          isoCode: "US",
        });
        setStep(2);
        return;
      }

      if (invalidGroupId === 1) {
        setMessageError({ message: "Invalid Group Id", type: "error" });
        return;
      }

      if (newUser.subAgentID !== patientRecord.subAgent) {
        setMessageError({
          message: "You are not in correct Sub Agent. Please contact admin",
          type: "error",
        });
        return;
      }

      if (patientRecord.testAvailable === 0) {
        setMessageError({
          message:
            "We could not process your order at this time. You have reached your limit for this program. Please try again next month and check your eligibility",
          type: "error",
        });
        return;
      }

      const country = patientRecord.country
        ? countryListAllIsoData.find((f) => f.name === patientRecord.country) || { code: "US" }
        : { code: "US" };

      setMaxQty(patientRecord.testAvailable);
      setNewUser({
        ...newUser,
        ...patientRecord,
        subAgentID: patientRecord.subAgent,
        testSendQty: patientRecord.testAvailable,
        gender: patientRecord.sex,
        ...(patientRecord.medicareNo &&
          patientRecord.insuranceCompany !== MEDICARE_ID && {
            isMedicare: "Y",
          }),
        ...(patientRecord.insuranceDetails && { isSecondary: "Y" }),
        insuranceDetails: {
          ...patientRecord.insuranceDetails,
          ...(patientRecord.insuranceDetails?.medicareNo &&
            patientRecord.insuranceDetails?.insuranceGroupId && {
              isMedicare: "Y",
            }),
        },
        isNew: false,
        isoCode: country.code,
        phoneNumber: `${patientRecord.countryCode}${patientRecord.phoneNumber}`,
      });
      setStep(2);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setStep(1);
    setRegistrationDone(false);
    setMaxQty(8);
    if (newUser.subAgents) {
      setNewUser({ ...newRegisterObj, subAgents: newUser.subAgents });
    } else {
      setNewUser({ ...newRegisterObj });
    }
  };

  if (loading) return <Loader />;

  if (pageExpire) return <p>Page Expired</p>;

  if (step === 1) {
    return (
      <OrderTestStepOne
        location={newUser.subAgents}
        handleProcced={handleProcced}
        errorMessageText={messageError}
        clearErrorMessage={setMessageError}
        subAgent={true}
      />
    );
  }

  if (newUser.status === "inactive") {
    return (
      <div className="registerFormWrapper">
        <div className="registerWrapper pre-register-comp">
          <div className="loginInner">
            <div className="text-center">
              {" "}
              <img src={logoImage} alt="logo" className="logo-width" />{" "}
            </div>
            <h4 className="w-100 text-center mt-4">
              <b>Account InActive, Please Contact Administrator</b>
            </h4>
          </div>
        </div>
      </div>
    );
  }

  if (registrationDone) {
    return (
      <div className="registerFormWrapper">
        <div className="registerWrapper pre-register-comp">
          <div className="loginInner">
            <div className="text-center">
              {" "}
              <img src={logoImage} alt="logo" className="logo-width" />{" "}
            </div>
            <h4 className="w-100 text-center mt-4">
              <b>Test Ordered Successful</b>
            </h4>
            <p>
              Thank you for ordering {newUser.testSendQty} COVID-19 Antigen Tests. Please check your email for further
              information
            </p>
          </div>
          <div className="mt-2 w-100 text-center">
            <Button variant="secondary" className="headerButton btn-fill" onClick={handleCancel}>
              Go Back
            </Button>
          </div>
        </div>
        {messageError && (
          <SweetAlert
            show={messageError ? true : false}
            type={messageError.type}
            title={convertToUpper(messageError.type)}
            onConfirm={() => setMessageError("")}
          >
            {messageError.message}
          </SweetAlert>
        )}
      </div>
    );
  }
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="registerFormWrapper">
          <div className="registerWrapper pre-register-comp" style={{ maxWidth: "700px", width: "700px" }}>
            <div className="loginInner">
              <div className="text-center">
                <img src={logoImage} alt="logo" className="logo-width" />
              </div>

              <h1 className="w-100 text-center mt-4 fs-4 fw-bold">{`Number of Tests Available ${maxQty}`}</h1>
              <div className="createClientsWrapper">
                <div className="leftSide">
                  <div className="form-group-wrapper">
                    <label className="mb-1">First Name:</label>
                    <input
                      type="text"
                      className="modalInput"
                      placeholder="First Name"
                      value={newUser.firstName}
                      maxLength={50}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          firstName: getValidName(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="mb-1">Address:</label>
                    <AutoCompleteAddress
                      value={newUser.street}
                      handleChange={handleUpdateAddress}
                      cssClass={"modalInput"}
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="mb-1">City:</label>
                    <input
                      type="text"
                      className="modalInput"
                      value={newUser.city}
                      placeholder="City"
                      maxLength={65}
                      onChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                    />
                  </div>
                  {/* <div className="form-group-wrapper ">
                  <label className="mb-1">Insurance Provider:</label>
                  <input
                    maxLength={16}
                    type="text"
                    className="modalInput"
                    value={
                      INSURANCE_PROVIDER.find(
                        (f) => f.value === newUser.insuranceCompany
                      )?.label
                    }
                    disabled={true}
                    placeholder="Insurance Provider"
                  />
                </div>
                {newUser.insuranceGroupId && (
                  <div className="form-group-wrapper ">
                    <label className="mb-1">Group ID:</label>
                    <input
                      maxLength={16}
                      type="text"
                      className="modalInput"
                      value={newUser.insuranceGroupId}
                >      disabled={true}
                      placeholder="Group ID"
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          insuranceGroupId: checkValidity(e.target.value),
                        })
                      }
                    />
                  </div>
                )}
                <div className="form-group-wrapper ">
                  <label className="mb-1">
                    {newUser.insuranceGroupId ? "Insurance ID#:" : "ID#:"}
                  </label>
                  <input
                    maxLength={16}
                    type="text"
                    className="modalInput"
                    value={newUser.medicalNo}
                    disabled={true}
                    placeholder="Medicare/Medicaid Number"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        medicalNo: checkValidity(e.target.value),
                      })
                    }
                  />
                </div> */}
                  {/* <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Gender: </label>
                  <div className="d-flex justify-content-between w-100 flex-wrap">
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        checked={newUser.sex === "M"}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            sex: e.target.checked ? "M" : "",
                          })
                        }
                      />
                      <span className="radioButton">Male</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        checked={newUser.sex === "F"}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            sex: e.target.checked ? "F" : "",
                          })
                        }
                      />
                      <span className="radioButton">Female</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            sex: e.target.checked ? "X" : "",
                          })
                        }
                      />
                      <span className="radioButton">Not Specified</span>
                    </span>
                  </div>
                </div> */}
                  <div className="form-group-wrapper">
                    <label className="mb-1">Zip Code:</label>
                    <input
                      value={newUser.isoCode === "US" ? formatZip(newUser.zip) : newUser.zip}
                      type="text"
                      className="modalInput"
                      placeholder="Enter Zip Code"
                      maxLength={10}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val) val = val.toUpperCase();
                        setNewUser({
                          ...newUser,
                          zip: newUser.isoCode === "US" ? formatZip(val) : getValidSpaces(val),
                        });
                      }}
                    />
                  </div>
                  <div className="form-group-wrapper ">
                    <label className="mb-1">Email:</label>
                    <input
                      type="text"
                      className="modalInput"
                      placeholder="Email"
                      value={newUser.email}
                      maxLength={75}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          email: convertToLower(e.target.value),
                        })
                      }
                    />
                  </div>
                  {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                    <Form.Group className="form-group-wrapper order-select-area">
                      <label className="modalLineHeaders emp-gender-mb text-capitalize">
                        is this Medicare Advantage?
                      </label>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <RadioButton
                          name="medicare"
                          id="yes"
                          label="Yes"
                          checked={newUser?.isMedicare === "Y" ? true : false}
                          onClick={() => {
                            setMedicareModal(true);
                          }}
                          handleChange={(e) => {
                            setNewUser({
                              ...newUser,
                              isMedicare: e.target.checked ? "Y" : "",
                              insuranceDetails: {
                                ...newUser.insuranceDetails,
                                isMedicare: "N",
                              },
                            });
                            setMedicareModal(true);
                          }}
                        />
                        <RadioButton
                          name="medicare"
                          id="no"
                          checked={newUser?.isMedicare === "N" ? true : false}
                          label="No"
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              isMedicare: e.target.checked ? "N" : "",
                            })
                          }
                        />
                      </div>
                    </Form.Group>
                  )}
                  {newUser.insuranceCompany && newUser.medicareNo && newUser.isMedicare === "Y" && (
                    <div className={!newUser.isNew ? "first-last-name-wrapper mt-4" : "mt-4"}>
                      <div className="form-group-wrapper me-2">
                        <label className="modalLineHeaders">Medicare#:</label>
                        <div className="editInput">
                          <input
                            value={newUser.medicareNo}
                            className="modalInput hidebordar"
                            maxLength={16}
                            placeholder={
                              MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"
                            }
                            style={{
                              width: "100%",
                            }}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                medicareNo: convertToUpper(e.target.value),
                              })
                            }
                            readOnly={newUser.medicareNo ? true : false}
                          />
                          <img
                            src={EditIcon}
                            width="18"
                            alt="edit icon"
                            onMouseLeave={(e) => (e.target.style.color = "black")}
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                            }}
                            aria-hidden="true"
                            onClick={(e) => {
                              setmodalAttribute("medicare");
                              setMedicareModal(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {newUser.insuranceCompany && (
                    <Form.Group className="form-group-wrapper order-select-area">
                      <label className="modalLineHeaders emp-gender-mb text-capitalize">
                        Do you have Secondary Insurance?
                      </label>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <RadioButton
                          name="secondary"
                          id="y"
                          label="Yes"
                          checked={newUser?.isSecondary === "Y" ? true : false}
                          handleChange={(e) => {
                            setNewUser({
                              ...newUser,
                              isSecondary: e.target.checked ? "Y" : "",
                            });
                            setInsuranceModal(true);
                          }}
                        />
                        <RadioButton
                          name="secondary"
                          id="n"
                          checked={newUser?.isSecondary === "N" ? true : false}
                          label="No"
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              isSecondary: e.target.checked ? "N" : "",
                            })
                          }
                        />
                      </div>
                    </Form.Group>
                  )}
                  <div className="order-select-edited">
                    {newUser.insuranceCompany &&
                      newUser.insuranceDetails?.insuranceCompany &&
                      newUser.isSecondary === "Y" && (
                        <>
                          <div className="column-gap-15 form-group-wrapper align-items-center mb-0 m-0 secondary-insurance-head">
                            <label className="w-94 heading-insurance">Secondary Insurance Info:</label>
                            <img
                              src={EditIcon}
                              width="18"
                              alt="edit icon"
                              onMouseLeave={(e) => (e.target.style.color = "black")}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              aria-hidden="true"
                              onClick={(e) => {
                                setmodalAttribute("insurance");
                                setInsuranceModal(true);
                              }}
                            />
                          </div>
                          <div className="form-group-wrapper d-flex m-0 mxh-10 secondary-insurance-change">
                            <label className="mxw-50">Insurance Name</label>
                            <input
                              className={`height-32 modalInput hidebordar ellipsis secondary-edit-input`}
                              maxLength={16}
                              value={secondaryInsuranceObject?.label || ""}
                              type="text"
                              readOnly={true}
                            />
                          </div>
                          {/* <Select
                          options={INSURANCE_PROVIDER}
                          blurInputOnSelect={true}
                          menuPlacement="auto"
                          className="w-100"
                          placeholder="Select Secondary Insurance"
                          value={secondaryInsuranceObject}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              insuranceDetails: {
                                ...newUser.insuranceDetails,
                                insuranceCompany: e.value,
                                insuranceCompanyCode: e.label,
                                insuranceGroupId: "",
                                isMedicare: "N",
                                isSecondary: "N",
                              },
                            })
                          }
                          isDisabled={true}
                        /> */}
                        </>
                      )}
                    {newUser.insuranceDetails?.insuranceCompany &&
                      newUser.isSecondary === "Y" &&
                      MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 && (
                        <>
                          {newUser.insuranceDetails.insuranceGroupId && (
                            <div className="form-group-wrapper me-2 d-flex m-0 mxh-10 secondary-insurance-change">
                              <label className="mb-1 mxw-50">Group ID:</label>
                              <div className="editInput">
                                <input
                                  className={`height-32 modalInput hidebordar  ellipsis secondary-edit-input`}
                                  maxLength={16}
                                  onChange={(e) =>
                                    setNewUser({
                                      ...newUser,
                                      insuranceDetails: {
                                        ...newUser.insuranceDetails,
                                        insuranceGroupId: convertToUpper(e.target.value),
                                      },
                                    })
                                  }
                                  value={newUser.insuranceDetails.insuranceGroupId}
                                  placeholder="Enter Secondary Group ID"
                                  type="text"
                                  readOnly={
                                    newUser.insuranceDetails.insuranceGroupId ||
                                    newUser.insuranceDetails.medicalNo ||
                                    newUser.insuranceDetails.medicareNo
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          )}

                          {newUser.insuranceDetails.medicalNo && (
                            <div className="form-group-wrapper me-2 d-flex m-0 mxh-10 secondary-insurance-change">
                              <label className="mb-1 mxw-50">Insurance Number:</label>
                              <div className="editInput">
                                <input
                                  className={`height-32 modalInput hidebordar ellipsis secondary-edit-input`}
                                  onChange={(e) =>
                                    setNewUser({
                                      ...newUser,
                                      insuranceDetails: {
                                        ...newUser.insuranceDetails,
                                        medicalNo: convertToUpper(e.target.value),
                                      },
                                    })
                                  }
                                  maxLength={16}
                                  value={newUser.insuranceDetails.medicalNo}
                                  placeholder="Enter Insurance Number"
                                  type="text"
                                  readOnly={
                                    newUser.insuranceDetails.insuranceGroupId ||
                                    newUser.insuranceDetails.medicalNo ||
                                    newUser.insuranceDetails.medicareNo
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                  </div>
                  {/* {newUser.insuranceDetails?.insuranceCompany &&
                    newUser.isSecondary === "Y" &&
                    newUser.isMedicare === "N" &&
                    MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 && (
                      <Form.Group className="form-group-wrapper">
                        <label className="modalLieHeaders emp-gender-mb text-capitalize">
                          is this Medicare Advantage?
                        </label>
                        <div className="d-flex justify-content-between w-100 flex-wrap">
                          <RadioButton
                            name="secondaryMedicare"
                            id="syes"
                            label="Yes"
                            checked={newUser.insuranceDetails.isMedicare === "Y" ? true : false}
                            onClick={() => {
                              setInsuranceModal(true);
                              setmodalAttribute("insurance");
                            }}
                            handleChange={(e) => {
                              setNewUser({
                                ...newUser,
                                insuranceDetails: {
                                  ...newUser.insuranceDetails,
                                  isMedicare: e.target.checked ? "Y" : "",
                                },
                              });
                              setInsuranceModal(true);
                              setmodalAttribute("insurance");
                            }}
                          />
                          <RadioButton
                            name="secondaryMedicare"
                            id="sno"
                            checked={newUser?.insuranceDetails.isMedicare === "N" ? true : false}
                            label="No"
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                insuranceDetails: {
                                  ...newUser.insuranceDetails,
                                  isMedicare: e.target.checked ? "N" : "",
                                },
                              })
                            }
                          />
                        </div>
                      </Form.Group>
                    )} */}
                  {newUser.insuranceDetails?.insuranceCompany &&
                    newUser.isSecondary === "Y" &&
                    newUser.insuranceDetails.medicareNo &&
                    MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) !== -1 && (
                      <div className="form-group-wrapper me-2 m-0 mxh-10 d-flex secondary-insurance-change ">
                        <label className="mb-1 mxw-50">Medicare Number:</label>
                        <div className="editInput">
                          <input
                            className={`height-32 modalInput hidebordar ellipsis secondary-edit-input`}
                            maxLength={16}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                insuranceDetails: {
                                  ...newUser.insuranceDetails,
                                  medicalNo: convertToUpper(e.target.value),
                                  medicareNo: convertToUpper(e.target.value),
                                },
                                ...(newUser.isMedicare === "N" &&
                                  !newUser.medicareNo && {
                                    medicareNo: convertToUpper(e.target.value),
                                  }),
                              })
                            }
                            value={newUser.insuranceDetails.medicareNo}
                            placeholder={"Medicare Number ins"}
                            type="text"
                            readOnly={
                              newUser.insuranceDetails.insuranceGroupId ||
                              newUser.insuranceDetails.medicalNo ||
                              newUser.insuranceDetails.medicareNo
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    )}
                  {newUser.insuranceDetails?.insuranceCompany &&
                    newUser.isSecondary === "Y" &&
                    newUser.insuranceDetails.medicareNo &&
                    newUser.insuranceDetails.isMedicare === "Y" && (
                      <div className="form-group-wrapper me-2 m-0 mxh-10 d-flex secondary-insurance-change">
                        <label className="mb-1 mxw-50">Medicare Number:</label>
                        <div className="editInput">
                          <input
                            className={`height-32 modalInput hidebordar ellipsis secondary-edit-input`}
                            maxLength={16}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                insuranceDetails: {
                                  ...newUser.insuranceDetails,
                                  medicareNo: convertToUpper(e.target.value),
                                },
                                medicareNo: convertToUpper(e.target.value),
                              })
                            }
                            value={newUser.insuranceDetails.medicareNo}
                            placeholder={"Medicare Number"}
                            type="text"
                            readOnly={
                              newUser.insuranceDetails.insuranceGroupId ||
                              newUser.insuranceDetails.medicalNo ||
                              newUser.insuranceDetails.medicareNo
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    )}
                </div>
                <div className="rightSide">
                  <div className="form-group-wrapper ">
                    <label className="mb-1">Last Name:</label>
                    <input
                      type="text"
                      className="modalInput"
                      placeholder="Last Name"
                      value={newUser.lastName}
                      maxLength={50}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          lastName: getValidName(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-12 col-12 mb-3">
                    <div className="form-group-wrapper">
                      <label className="mb-1">Apartment#:</label>
                      <input
                        value={newUser.street2}
                        className="modalInput"
                        maxLength={16}
                        placeholder="Apartment#"
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => setNewUser({ ...newUser, street2: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group-wrapper">
                    <label className="mb-1">State:</label>
                    <input
                      type="text"
                      className="modalInput stateField"
                      placeholder="State Abbr: XX"
                      value={newUser.state}
                      maxLength={getStateLength(newUser)}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val) val = val.toUpperCase();
                        setNewUser({ ...newUser, state: getValidSpaces(val) });
                      }}
                    />
                  </div>
                  <div className="form-group-wrapper ">
                    <label className="mb-1">Phone Number:</label>
                    <PhoneNoInput
                      error={setPhoneError}
                      handleChange={(e) => {
                        setNewUser({
                          ...newUser,
                          phoneNumber: e,
                        });
                      }}
                      value={newUser.phoneNumber}
                      cssClass="w-100"
                    />
                  </div>
                  {/* <div className="form-group-wrapper ">
                  <label className="mb-1">Country:</label>
                  <Select
                    options={countriesOption}
                    blurInputOnSelect={true}
                    value={{ label: newUser.country, value: newUser.country }}
                    menuPlacement="auto"
                    placeholder="Select Country"
                    className="w-100"
                    onChange={(e) => {
                      const country = countryListAllIsoData.find(
                        (f) => f.name === e.value
                      ) || { code: "US" };
                      setNewUser({
                        ...newUser,
                        country: e.value,
                        isoCode: country.code,
                      });
                    }}
                  />
                </div> */}
                  <div className="form-group-wrapper">
                    <label className="mb-1">DOB: </label>
                    <DatePicker
                      className="w-100 orderTestDob"
                      openCalendarOnFocus={false}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      format="MM/dd/y"
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                        })
                      }
                      value={
                        newUser.dob && new Date(newUser.dob) != "Invalid Date"
                          ? moment(newUser.dob, "YYYY-MM-DD").toDate()
                          : null
                      }
                      maxDate={new Date()}
                      minDate={new Date("1900-01-01")}
                    />
                  </div>
                  <div className="form-group-wrapper order-select-area">
                    <label className="modalLineHeaders emp-gender-mb">Gender:</label>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <RadioButton
                        name="gender"
                        id="male"
                        label="Male"
                        checked={newUser?.gender === "M" ? true : false}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            gender: e.target.checked ? "M" : "",
                          })
                        }
                      />
                      <RadioButton
                        name="gender"
                        id="female"
                        checked={newUser?.gender === "F" ? true : false}
                        label="Female"
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            gender: e.target.checked ? "F" : "",
                          })
                        }
                      />
                      <RadioButton
                        id="other"
                        checked={newUser?.gender === "X" ? true : false}
                        name="gender"
                        label="Not Specified"
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            gender: e.target.checked ? "X" : "",
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group-wrapper emp-select-area">
                    <label className="modalLineHeaders emp-gender-mb">Who are you ordering tests for?</label>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <DropDown
                        data={[
                          { label: "Myself", value: "Y" },
                          { label: "Someone Else", value: "N" },
                        ]}
                        handleChange={(val) => {
                          setNewUser({
                            ...newUser,
                            isSelf: val,
                          });
                          if (val === "N") {
                            setSelfModal(true);
                          }
                        }}
                      />
                      {/* <RadioButton
                        name="self"
                        id="orderYes"
                        label="Yes"
                        checked={newUser?.isSelf === "Y" ? true : false}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isSelf: e.target.checked ? "Y" : "",
                          })
                        }
                      />
                      <RadioButton
                        name="self"
                        id="orderNo"
                        checked={newUser?.isSelf === "N" ? true : false}
                        label="No"
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isSelf: e.target.checked ? "N" : "",
                          })
                        }
                      /> */}
                    </div>
                  </div>
                  <div className="order-select-edited">
                    {newUser.isSelf === "N" && newUser.requestedByFirstName && (
                      <>
                        <div className="column-gap-15 form-group-wrapper align-items-center mb-0 m-0 secondary-insurance-head">
                          <label className="w-94 heading-insurance">Secondary Personal Information:</label>
                          <img
                            src={EditIcon}
                            width="18"
                            alt="edit icon"
                            onMouseLeave={(e) => (e.target.style.color = "black")}
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                            }}
                            aria-hidden="true"
                            onClick={(e) => {
                              setmodalAttribute("self");
                              setSelfModal(true);
                            }}
                          />
                        </div>

                        <div className="form-group-wrapper m-0 mxh-10 secondary-insurance-change d-flex align-items-center">
                          <label className="mb-1">First Name:</label>
                          <input
                            type="text"
                            className="modalInput hidebordar ellipsis secondary-edit-input"
                            value={newUser.requestedByFirstName}
                            placeholder="First Name"
                            maxLength={65}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                requestedByFirstName: e.target.value,
                              })
                            }
                            readOnly
                          />
                        </div>
                      </>
                    )}
                    {newUser.isSelf === "N" && newUser.requestedByLastName && (
                      <div className="form-group-wrapper m-0 mxh-10 secondary-insurance-change d-flex">
                        <label className="mb-1">Last Name:</label>
                        <div className="editInput">
                          <input
                            type="text"
                            className="modalInput hidebordar ellipsis secondary-edit-input"
                            value={newUser.requestedByLastName}
                            placeholder="Last Name"
                            maxLength={65}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                requestedByLastName: e.target.value,
                              })
                            }
                            readOnly
                          />
                          {/* <img
                          src={EditIcon}
                          width="18"
                          alt="edit icon"
                          onMouseLeave={(e) => (e.target.style.color = "black")}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          aria-hidden="true"
                          onClick={(e) => {
                            setmodalAttribute("self");
                            setSelfModal(true);
                          }}
                        /> */}
                        </div>
                      </div>
                    )}
                    {newUser.isSelf === "N" && newUser.requestedByPhoneNumber && (
                      <div className="form-group-wrapper m-0 mxh-10 secondary-insurance-change d-flex">
                        <label className="mb-2">Phone:</label>
                        <div className="editInput">
                          <input
                            type="text"
                            className="modalInput hidebordar ellipsis secondary-edit-input"
                            value={newUser.requestedByPhoneNumber}
                            placeholder="Phone"
                            maxLength={65}
                            readOnly
                          />

                          {/* <img
                          src={EditIcon}
                          width="18"
                          alt="edit icon"
                          onMouseLeave={(e) => (e.target.style.color = "black")}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          aria-hidden="true"
                          onClick={(e) => {
                            setmodalAttribute("self");
                            setSelfModal(true);
                          }}
                        /> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="form-group-wrapper">
                  <label className="mb-1">Client Name:</label>
                  <input
                    value={newUser.clientName}
                    className="modalInput"
                    maxLength={80}
                    placeholder="Client Name"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setNewUser({ ...newUser, clientName: e.target.value })
                    }
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">Facilities Name:</label>
                  <input
                    value={newUser.locationName}
                    className="modalInput"
                    maxLength={80}
                    placeholder="Location Name"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setNewUser({ ...newUser, locationName: e.target.value })
                    }
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">Show Name:</label>
                  <input
                    value={newUser.siteName}
                    className="modalInput"
                    maxLength={80}
                    placeholder="Site Name"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setNewUser({ ...newUser, siteName: e.target.value })
                    }
                  />
                </div> */}
                {/* <div className="form-group-wrapper">
                  <label className="modalLineHeaders">
                    Insurance Card Front:
                  </label>
                  <ImageInput
                    type="Camera"
                    setSelectedImage={setInsuranceCardFront}
                    selectedImage={insuranceCardFront}
                    imageCss="insuranceImageSize"
                  />
                </div> */}

                {/* <div className="form-group-wrapper">
                  <label className="modalLineHeaders">
                    Insurance Card Back:
                  </label>
                  <ImageInput
                    type="Camera"
                    setSelectedImage={setInsuranceCardBack}
                    selectedImage={insuranceCardBack}
                    imageCss="insuranceImageSize"
                  />
                </div> */}

                {/* <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Test Quantity:</label>
                  <Select
                    options={getTestOrderQuantity}
                    blurInputOnSelect={true}
                    defaultValue={{
                      value: maxQty,
                      label: maxQty,
                    }}
                    menuPlacement="auto"
                    placeholder="Select Quantity"
                    className="w-100"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        testSendQty: e.value,
                      })
                    }
                  />
                </div> */}
              </div>
              {!newUser.isSignUp && (
                <div className="checkbox checkBoxWrapper justify-content-evenly mb-3">
                  <label className="modalLineHeaders">Please check this box to create an account</label>
                  <div className="d-flex justify-content-start align-items-center">
                    <ViewOnlyCheckBox
                      id="11"
                      checked={createNewAccount}
                      bgColor="blue"
                      handleChange={(e) => setCreateNewAccount(e.target.checked)}
                    />
                  </div>
                </div>
              )}
              {!newUser.isSignUp && createNewAccount && (
                <div className="form-group-wrapper ">
                  <label className="mb-1">Password:</label>
                  <input
                    type="password"
                    className="modalInput"
                    value={newUser.password}
                    placeholder="Password"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                  />
                </div>
              )}

              {error && (
                <div className="error-message">
                  {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
                </div>
              )}
              {messageError && (
                <SweetAlert
                  show={messageError ? true : false}
                  type={messageError.type}
                  title={convertToUpper(messageError.type)}
                  onConfirm={() => setMessageError("")}
                >
                  {messageError.message}
                </SweetAlert>
              )}

              {openConfirmation.isShow && (
                <ConfirmationModal
                  show={openConfirmation.isShow}
                  actionType={openConfirmation.actionType}
                  title={openConfirmation.title}
                  message={openConfirmation.message}
                  handleConfirm={onHandleConfirmation}
                  handleClose={() => setOpenConfirmation({ isShow: false })}
                  closeBtn
                />
              )}
              {showMedicareModal && (modalAttribute === "medicare" || modalAttribute == null) && (
                <ExpandSlectModal
                  user={{ ...newUser }}
                  handleSaveChange={(obj) => {
                    setMedicareModal(false);
                    setmodalAttribute(null);
                    setNewUser({ ...obj });
                  }}
                  handleClose={() => {
                    setMedicareModal(false);
                    setmodalAttribute(null);
                    setNewUser({ ...newUser, isMedicare: newUser.medicareNo ? "Y" : "N" });
                  }}
                  setNewUser={setNewUser}
                  modalType="medicare"
                  title="Please Enter Your Medicare Number"
                />
              )}
              {showSelfModal && (modalAttribute === "self" || modalAttribute == null) && (
                <ExpandSlectModal
                  user={{ ...newUser }}
                  handleSaveChange={(obj) => {
                    setSelfModal(false);
                    setmodalAttribute(null);
                    setNewUser({ ...obj });
                  }}
                  handleClose={() => {
                    setSelfModal(false);
                    setmodalAttribute(null);
                  }}
                  setNewUser={setNewUser}
                  modalType="self"
                  title="Personal Information"
                />
              )}
              {showInsuranceModal && (modalAttribute == "insurance" || modalAttribute == null) && (
                <ExpandSlectModal
                  user={{ ...newUser }}
                  handleSaveChange={(obj) => {
                    setInsuranceModal(false);
                    setmodalAttribute(null);
                    setNewUser({ ...obj });
                  }}
                  handleClose={(key) => {
                    setInsuranceModal(false);
                    setmodalAttribute(null);
                    setNewUser({
                      ...newUser,
                      isSecondary:
                        newUser.insuranceDetails?.insuranceCompany ||
                        newUser.insuranceDetails?.insuranceGroupId ||
                        newUser.insuranceDetails?.medicalNo ||
                        newUser.insuranceDetails?.medicareNo
                          ? "Y"
                          : "N",
                      insuranceDetails: {
                        ...newUser.insuranceDetails,
                        isMedicare:
                          newUser.insuranceDetails?.medicareNo && newUser.insuranceDetails?.isMedicare === "Y"
                            ? "Y"
                            : "N",
                      },
                    });
                  }}
                  setNewUser={setNewUser}
                  modalType="insurance"
                  title="Secondary Insurance Information"
                />
              )}

              <div className="mt-2">
                <button type="submit" className="text-uppercase w-100 btn signup-btn" onClick={handleSubmit}>
                  Submit Order
                </button>
              </div>
              <div className="mt-2">
                <Button
                  variant="secondary"
                  className="text-uppercase headerButton w-100 btn-fill font-changed"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderTestSubAgent;
