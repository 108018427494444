/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Users from "views/Users";
import Employees from "views/Employees";
import Locations from "views/Location";
import Orders from "views/AdminOrders";
import Companies from "views/Companies";
import checkEligibility from "views/CheckEligibility";
import SubAgents from "views/SubAgents";
import garageIcon from "../assets/img/settings-garage.png";
import employeeIcon from "../assets/img/employee-icon.png";
import activeProgramIcon from "../assets/img/active-programs.png";
import copyIcon from "../assets/img/Copy-icon.svg";
import calendarIcon from "../assets/img/calendar-icon.svg";
import Billing from "views/Billing";
import { t } from "stringConstants";

const dashboardRoutes = [
  {
    path: "/members",
    name: t("clients"),
    icon: "nc-icon nc-badge",
    src: employeeIcon,
    component: Employees,
    layout: "/admin",
  },

  {
    path: "/orders",
    name: t("totalOrders"),
    icon: "nc-icon nc-badge",
    src: copyIcon,
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: t("billing"),
    icon: "nc-icon nc-badge",
    component: Billing,
    layout: "/admin",
  },
  {
    path: "/checkeligibility",
    name: t("checkEligibility"),
    icon: "nc-icon nc-badge",
    src: calendarIcon,
    component: checkEligibility,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: t("companies"),
    icon: "nc-icon nc-badge",
    src: activeProgramIcon,
    component: Companies,
    layout: "/admin",
  },
  {
    path: "/facilities",
    name: t("facilities"),
    icon: "nc-icon nc-badge",
    src: activeProgramIcon,
    component: Locations,
    layout: "/admin",
  },
  {
    path: "/subagents",
    name: t("subagents"),
    icon: "nc-icon nc-badge",
    src: activeProgramIcon,
    component: SubAgents,
    layout: "/admin",
  },

  {
    path: "/users",
    name: t("users"),
    icon: "nc-icon nc-badge",
    src: garageIcon,
    component: Users,
    layout: "/admin",
  },
];

export default dashboardRoutes;
