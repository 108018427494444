import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";

const NameInfoModal = (props) => {
  const { handleSave, client, sendType, setSendType } = props;
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const onhandleSave = () => {
    if (!user.firstName || !user.firstName?.trim()) {
      setError("First Name not be empty");
      return;
    }
    if (!user.lastName || !user.lastName?.trim()) {
      setError("Last Name not be empty");
      return;
    }
    handleSave(user);
  };

  return (
    <>
      <Modal show animation={true} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              <div className="createClientsWrapper flex-wrap">
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">First Name:</label>
                    <input
                      type="text"
                      style={{ lineHeight: "normal" }}
                      className="modalInput"
                      value={user.firstName || ""}
                      onChange={(e) =>
                        setUser({ ...user, firstName: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Last Name</label>
                    <input
                      type="text"
                      style={{ lineHeight: "normal" }}
                      className="modalInput"
                      value={user.lastName || ""}
                      onChange={(e) =>
                        setUser({ ...user, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ minHeight: "3vh" }}>
              {error && (
                <ErrorMessage error={error} handleChange={() => setError("")} />
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="headerButton btn-fill"
            onClick={onhandleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NameInfoModal;
