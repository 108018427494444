import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";
import api from "api";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import { parseBooleanValue } from "utils";
import RadioButton from "components/RadioButton/RadioButton";
import { isValidAmount } from "utils";
import DatePicker from "react-date-picker";
import { isValidChequeNumber } from "utils";
import moment from "moment";
import { claimActionOptions, claimActions } from "constant";

const ReceivedAmountDetailsModal = (props) => {
  const { handleClose, orderID, user } = props;
  const [reason, setReason] = useState("");
  const [detail, setDetail] = useState({ action: claimActions.receive });
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const onHandleConfirm = async () => {
    if (!detail.amount || detail.amount <= 0) {
      setError(`Add ${detail.action === claimActions.receive ? "Received" : "Refund"} Amount`);
      return;
    }
    if (!isValidAmount(detail.amount)) {
      setError(`Invalid ${detail.action === claimActions.receive ? "Received" : "Refund"} Amount`);
      return;
    }

    if (!detail.chequeNo || !detail.chequeNo.trim()) {
      setError("Please add Check No");
      return;
    }
    // if (!isValidChequeNumber(detail.chequeNo)) {
    //   setError("Please enter valid cheque number");
    //   return;
    // }
    if (!detail.chequeDate) {
      setError("Please add check date");
      return;
    }

    if (!detail.message || !detail.message.trim()) {
      setError("Please add Message");
      return;
    }

    if (!detail.employeeClaimID || !detail.employeeClaimID.trim()) {
      setError("Please add Claim Number");
      return;
    }

    try {
      setLoading(true);
      const res = await api.addReceivedAmountDetails(detail, orderID, user, detail.action);

      if (res?.error) {
        setError(res.error);
        setLoading(false);
        return;
      }
      setLoading(false);
      handleClose(detail.action);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }

    // const data = employees.map((t) => {
    //   return {
    //     schrID: t.id,
    //     auditType: "ClearedForWork",
    //     action: "Done",
    //     reason,
    //     companyID: t.companyID,
    //     userName: appContext.user.name,
    //   };
    // });
    // await api.addlogs(data);
    // handleConfirm(true, reason);
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          Received Amount Details
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="form-group-wrapper">
          <label className="modalLineHeaders mt-0 text-capitalize">Choose an action to perform:</label>
          <Select
            options={claimActionOptions}
            blurInputOnSelect={true}
            className="w-100"
            value={claimActionOptions.find((opt) => opt.value === detail.action) || ""}
            menuPlacement="auto"
            autoComplete="none"
            placeholder="Select Action"
            onChange={(e) => {
              setDetail({
                ...detail,
                action: e.value,
              });
            }}
          />
        </Form.Group>
        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Received Amount: </label>
          <input
            className="modalInput w-25"
            style={{ maxWidth: "10%" }}
            type="number"
            min="0"
            value={detail.amount}
            onChange={(e) => setDetail({ ...detail, amount: e.target.value })}
          />
        </div>

        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Cheque Number: </label>
          <input
            className="modalInput w-75"
            placeholder="Enter Cheque Number"
            type="text"
            style={{ maxWidth: "75%" }}
            value={detail.chequeNo}
            onChange={(e) => setDetail({ ...detail, chequeNo: e.target.value })}
          />
        </div>
        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Claim Number: </label>
          <input
            className="modalInput w-75"
            placeholder="Enter Claim Number"
            type="text"
            style={{ maxWidth: "75%" }}
            value={detail.employeeClaimID}
            onChange={(e) => setDetail({ ...detail, employeeClaimID: e.target.value })}
          />
        </div>
        <div className="form-group-wrapper">
          <label className="modalLineHeaders mt-0 text-capitalize">Cheque Date</label>
          <DatePicker
            className="w-75 orderTestDob"
            openCalendarOnFocus={false}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            format="MM/dd/y"
            onChange={(e) =>
              setDetail({
                ...detail,
                chequeDate: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
              })
            }
            value={
              detail.chequeDate && new Date(detail.chequeDate) != "Invalid Date"
                ? moment(detail.chequeDate, "YYYY-MM-DD").toDate()
                : null
            }
          />
        </div>

        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Message: </label>

          <textarea className="modalInput w-75" onChange={(e) => setDetail({ ...detail, message: e.target.value })}>
            {detail.message}
          </textarea>
        </div>
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => onHandleConfirm()}>
          Save
        </Button>
      </Modal.Footer>

      {loading && <Loader />}
    </Modal>
  );
};

export default ReceivedAmountDetailsModal;
