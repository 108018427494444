import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Modal, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { HEALTH_AND_SAFTEY, newRoleOptions } from "constant";
import API from "api";
import { AppContext } from "context/app-context";
import {
  phoneFormatter,
  validatePhone,
  toTitleCase,
  parseBooleanValue,
} from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { FILTER_USER_ROLE } from "constant";

const UserModal = (props) => {
  const { show, user, handleSave, handleClose, resetCall, clients } = props;

  const appContext = useContext(AppContext);

  const getUserRole = () =>
    newRoleOptions.find((role) => role.value === user.role);
  const getLocation = (locationID) =>
    appContext.locations.find((c) => locationID === c.id);
  const [newUser, setNewUser] = useState(user);
  const [error, setError] = useState("");
  const [selectedRole, setSelectedRole] = useState(getUserRole());
  const [client, setClient] = useState([]);

  const [location, setLocation] = useState(getLocation(user.locationID));

  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    setNewUser(user);
    setSelectedRole(getUserRole());
  }, [user]);

  console.log("newUser", newUser);

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
    console.log(`Option selected:`, selectedOption, newUser);
    let obj = {
      ...newUser,
      role: selectedOption.value,
    };
    if (appContext.user.isAdmin()) {
      Object.assign(obj, {
        client: null,
        clientID: null,
        locationID: null,
        location: null,
        subAgent: null,
        subAgentID: null,
      });
    }
    if (appContext.user.isClient()) {
      Object.assign(obj, {
        locationID: null,
        location: null,
        subAgent: null,
        subAgentID: null,
      });
    }
    if (appContext.user.isSite()) {
      Object.assign(obj, {
        subAgent: null,
        subAgentID: null,
        client: null,
        clientID: null,
      });
    }
    if (appContext.user.isSubAgent()) {
      Object.assign(obj, {
        locationID: null,
        location: null,
        client: null,
        clientID: null,
      });
    }
    setNewUser(obj);
  };

  const onHandleSave = () => {
    let roles = [];
    if (!newUser.firstName) {
      setError("First name is required");
      return;
    }

    if (!newUser.lastName) {
      setError("Last name is required");
      return;
    }

    if (!newUser.email) {
      setError("Email name is required");
      return;
    }

    if (!newUser.phone) {
      setError("Phone name is required");
      return;
    }
    if (phoneError) {
      showErrorMessage("Invalid Phone Number");
      return;
    }
    if (newUser.isNew && !newUser.password) {
      setError("Password is requried");
      return;
    }

    if (!validatePhone(newUser.phone)) {
      setError("Invalid Phone Number");
      return;
    }

    if (!newUser.role) {
      setError("Please Select Role ");
      return;
    }

    if (
      newUser.role !== "Admins" &&
      newUser.role === "Clients" &&
      !newUser.clientID
    ) {
      setError("Client is required");
      return;
    }

    if (
      newUser.role !== "Admins" &&
      newUser.role === "Employers" &&
      !newUser.locationID
    ) {
      setError("Facility/Agent is required");
      return;
    }

    if (
      newUser.role !== "Admins" &&
      newUser.role === "SubAgents" &&
      !newUser.subAgentID
    ) {
      setError("Sub Agent is required");
      return;
    }

    if (newUser.role === "Admins") {
      roles = [{ role: "Admin" }];
    } else if (newUser.role === "SubAgents") {
      roles = [
        {
          role: "SubAgents",
          relatedId: newUser.subAgentID,
          title: newUser.subAgent.name,
        },
      ];
    } else if (newUser.role === "Clients") {
      roles = [
        {
          role: "Clients",
          relatedId: newUser.clientID,
          title: newUser.client.name,
        },
      ];
    } else if (newUser.role === "Employers") {
      roles = [
        {
          role: "Employers",
          relatedId: newUser.locationID,
          title: newUser.location.name,
        },
      ];
    }

    if (newUser.isNew) {
      console.log(newUser.password);
      const isValidPassword =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
          newUser.password
        );

      if (!isValidPassword) {
        setError(
          "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
        );
        return;
      }
    }
    console.log("newUSer", newUser, roles);
    handleSave({ ...newUser, role: selectedRole.value, roles: roles });
    handleClose();
  };
  console.log(newUser);

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create User" : "Update User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="row">
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">First Name: </label>
                <input
                  value={newUser.firstName}
                  className="modalInput w-100"
                  placeholder="First Name"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      firstName: toTitleCase(e.target.value),
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Last Name: </label>
                <input
                  value={newUser.lastName}
                  className="modalInput w-100"
                  placeholder="Last Name"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      lastName: toTitleCase(e.target.value),
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Email: </label>
                <input
                  value={newUser.email}
                  className="modalInput w-100"
                  placeholder="Email"
                  onChange={(e) =>
                    setNewUser({ ...newUser, email: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone Number:</label>
                <PhoneNoInput
                  error={setPhoneError}
                  handleChange={(e) =>
                    setNewUser({
                      ...newUser,
                      phone: e,
                    })
                  }
                  value={newUser.phone}
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                {newUser.isNew && (
                  <>
                    <label className="modalLineHeaders">Password: </label>

                    <input
                      type="password"
                      className="modalInput"
                      value={newUser.password}
                      placeholder="Password"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        setNewUser({ ...newUser, password: e.target.value })
                      }
                    />
                  </>
                )}
              </div>
            </div>

            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper activeEmploye">
                <label className="modalLineHeaders">Role:</label>

                <Select
                  options={newRoleOptions.filter((f) =>
                    FILTER_USER_ROLE[appContext?.user.baseRole].includes(
                      f.value
                    )
                  )}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  placeholder="Select a Role"
                  value={selectedRole}
                  onChange={handleRoleChange}
                  className="w-100 roleSelector"
                />
              </div>
            </div>
            {newUser.role === "Employers" && !appContext.user?.isSite() && (
              <div className="col-sm-12 createClientsWrapper">
                <div className="form-group-wrapper activeEmploye">
                  <label className="modalLineHeaders">
                    Select Facility/Agent:
                  </label>

                  <Select
                    className="w-100 "
                    options={appContext.locations.map((e) => {
                      return { ...e, label: e.name, value: e.id };
                    })}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    value={newUser.location}
                    menuPlacement="auto"
                    placeholder="Select Facility/Agent"
                    onChange={(e) => {
                      const client = appContext.companies.find(
                        (c) => c.id === e.clientID
                      );
                      setLocation(e);
                      setNewUser({
                        ...newUser,
                        locationID: e.value,
                        location: e,
                        clientID: e.clientID,
                        client: client,
                        subAgent: null,
                        subAgentID: null,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {newUser.role === "SubAgents" && !appContext.user?.isSubAgent() && (
              <div className="col-sm-12 createClientsWrapper">
                <div className="form-group-wrapper activeEmploye">
                  <label className="modalLineHeaders">Select subAgent:</label>

                  <Select
                    className="w-100 "
                    options={appContext.subAgents.map((e) => {
                      return { ...e, label: e.name, value: e.id };
                    })}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    value={newUser.subAgent}
                    menuPlacement="auto"
                    placeholder="Select Sub Agent"
                    onChange={(e) => {
                      setLocation(e);
                      console.log("Sub Agent", e);
                      const loc = appContext.locations.find(
                        (l) => l.id === e.locationID
                      );
                      const client = appContext.companies.find(
                        (c) => c.id === e.clientID
                      );
                      setNewUser({
                        ...newUser,
                        subAgentID: e.value,
                        subAgent: e,
                        locationID: e.locationID,
                        location: loc,
                        clientID: e.clientID,
                        client: client,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {newUser.role === "Clients" && !appContext.user?.isClient() && (
              <div className="col-sm-12 createClientsWrapper">
                <div className="form-group-wrapper activeEmploye">
                  <label className="modalLineHeaders">Select Client:</label>

                  <Select
                    className="w-100 "
                    options={appContext.companies.map((e) => {
                      return { ...e, label: e.name, value: e.id };
                    })}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    placeholder="Select Client"
                    value={newUser.client}
                    onChange={(e) => {
                      setLocation(e);
                      setNewUser({
                        ...newUser,
                        clientID: e.value,
                        client: e,
                        locationID: null,
                        location: null,
                        subAgent: null,
                        subAgentID: null,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {!newUser.isNew && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders"> </label>
                <label
                  className="modalLineHeaders linkedText"
                  style={{ minWidth: "50%" }}
                  onClick={() => (handleClose(), resetCall(true))}
                >
                  Reset Password
                </label>
              </div>
            )}
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && (
            <ErrorMessage error={error} handleChange={() => setError("")} />
          )}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            {newUser.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserModal;
