import React, { useState, useContext, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { AppContext } from "context/app-context";
import { PROVIDER, GENERAL_SETTINGS } from "constants/tabPillsContants";
import TabPills from "components/Tabs/TabPills";
import Loader from "components/Loader/Loader";
import Provider from "views/Provider";
import GeneralSettings from "views/GeneralSettings/GeneralSettings";

const LabTestTypesSetting = () => {
  const [tabsOptions, setTabOptions] = useState([]);
  const [loading, setLoading] = useState();
  const appContext = useContext(AppContext);

  useEffect(() => {
    setTabOptions(tabPillsConstants);
  }, [appContext.providers]);

  const tabPillsConstants = [
    {
      title: PROVIDER,
      length: appContext.providers.length,
      component: <Provider />,
    },
    {
      title: GENERAL_SETTINGS,
      component: <GeneralSettings />,
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <div className="system-settings-wrapper">
              <TabPills tabs={tabsOptions} />
            </div>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default LabTestTypesSetting;
