import React, { useEffect, useState, useMemo } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse, Form } from "react-bootstrap";
import { getValidReg } from "utils";
import { t } from "../../../stringConstants";
import ErrorMessage from "components/Message/ErrorMessage";
import Select from "react-select";
import api from "api";
import Loader from "components/Loader/Loader";
import Status from "components/Status";
import RadioButton from "components/RadioButton/RadioButton";
import CheckBoxButton from "components/CheckBoxButton/CheckBoxButton";
import ConfirmationModal from "../ConfirmationModal";

const OrderDetailItem = (props) => {
  const { item, index, handleChangeQuantity, eligibilityStatus, loading } = props;
  const [openAutoShipmentConfirmation, setOpenAutoShipmentConfirmation] = useState(false);
  const obj = eligibilityStatus.find((f) => f.id === item.id);
  const getTestOrderQuantity = useMemo(() => {
    let options = [];
    for (let i = item.minTestToOrder; i <= item.testAvailable; i = i + 2) {
      options.push({ value: i, label: i });
    }
    return options;
  }, [item]);

  return (
    <>
      <tr>
        {/*<td></td>*/}
        <td>{index + 1}</td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.firstName}>
          {item.firstName}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.lastName}>
          {item.lastName}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.street}>
          {item.street}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.city}>
          {item.city}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.state}>
          {item.state}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.zip}>
          {item.zip}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.testAvailable}>
          {item.testAvailable}
        </td>
        <td style={{ textAlign: "center" }}>
          <Select
            options={getTestOrderQuantity}
            blurInputOnSelect={true}
            isDisabled={loading}
            menuPlacement="auto"
            placeholder="Quantity"
            className="w-100"
            value={{ label: item.testSendQty, value: item.testSendQty }}
            onChange={(e) => handleChangeQuantity(e.value, index, "testSendQty")}
          />
        </td>
        <td style={{ textAlign: "center" }}>
          {obj && obj.errorStatus === "1" ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color="maroon" crossIcon />
            </div>
          ) : obj && obj.errorStatus === "0" ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color="green" crossIcon />
            </div>
          ) : (
            "-"
          )}
        </td>
        <td style={{ textAlign: "center" }}>
          <Form.Check>
            <Form.Check.Input
              style={{ cursor: "pointer" }}
              type="checkbox"
              disabled={loading}
              checked={item?.autoShipment === true ? true : false}
              onChange={(e) =>
                e.target.checked === true
                  ? setOpenAutoShipmentConfirmation(true)
                  : handleChangeQuantity(false, index, "autoShipment")
              }
            />
          </Form.Check>
        </td>
      </tr>
      {openAutoShipmentConfirmation && (
        <ConfirmationModal
          show={openAutoShipmentConfirmation}
          title="Confirm Auto Shipment"
          message={`${t("confirmAutoShipment", {
            total: item.location?.testLimit || "8",
          })}?`}
          handleConfirm={(isConfirm) => {
            handleChangeQuantity(isConfirm, index, "autoShipment");
            setOpenAutoShipmentConfirmation(false);
          }}
          handleClose={() => {
            setOpenAutoShipmentConfirmation(false);
          }}
          closeBtn={true}
        />
      )}
    </>
  );
};

export default OrderDetailItem;
