import React, { useState, useEffect } from "react";
import Login from "components/Login/Login";
import API from "api";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import RegisterStepOne from "components/preRegister/RegisterStepOne";
import OrderTest from "components/preRegister/OrderTest";
import UserLogin from "components/Login/UserLogin";
import OrderTestGeneric from "components/preRegister/OrderTestGeneric";
import OrderTestSubAgent from "components/preRegister/OrderTestSubAgent";
import Loader from "components/Loader/Loader";
import PreRegister from "components/preRegister/PreRegister";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getUSer();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/order-test/:id"
          render={(props) => <OrderTest {...props} />}
        />
        <Route
          path="/order-testkit/:id"
          render={(props) => <OrderTestSubAgent {...props} />}
        />
        <Route
          path="/order-test"
          render={(props) => <OrderTestGeneric {...props} />}
        />
        <Route
          path="/pre-register"
          render={(props) => <PreRegister {...props} />}
        />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/login" render={(props) => <Login />} />
        <Route path="/member-login" render={(props) => <UserLogin />} />
        <Redirect from="/" to="/login" />
      </Switch>
    </BrowserRouter>
  );
}
