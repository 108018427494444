/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      firstName
      middleName
      lastName
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $id: ID
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        middleName
        lastName
        jobTitle
        picture
        dob
        schrID
        pcn
        street
        street2
        city
        state
        countryCode
        country
        region
        location
        zip
        phoneNumber
        sex
        email
        isVaccinated
        subID
        idNumber
        department
        isNew
        employeeType
        vaccinationCardUpload
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        vaccinationFile
        isBooster
        boosterType
        boosterDate
        boosterLocation
        isExternalTest
        externalTestDate
        externalTestType
        externalTestResult
        externalTestFile
        companyID
        clientID
        subAgent
        unionNo
        localNo
        empTZ
        insuranceCompany
        insuranceCompanyCode
        medicalNo
        insuranceDetails
        medicareNo
        insuranceGroupId
        insuranceCardFront
        insuranceCardBack
        primaryInsurance
        testOrdered
        totalOrderd
        testAvailable
        updatedBy
        updatedByName
        isSignUp
        autoShipment
        loginID
        createdBy
        createdAt
        note
        noteAddedBy
        status
        isHospice
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmployees = /* GraphQL */ `
  query SyncEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        middleName
        lastName
        jobTitle
        picture
        dob
        schrID
        pcn
        street
        street2
        city
        state
        countryCode
        country
        region
        location
        zip
        phoneNumber
        sex
        email
        isVaccinated
        subID
        idNumber
        department
        isNew
        employeeType
        vaccinationCardUpload
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        vaccinationFile
        isBooster
        boosterType
        boosterDate
        boosterLocation
        isExternalTest
        externalTestDate
        externalTestType
        externalTestResult
        externalTestFile
        companyID
        clientID
        subAgent
        unionNo
        localNo
        empTZ
        insuranceCompany
        insuranceCompanyCode
        medicalNo
        insuranceDetails
        medicareNo
        insuranceGroupId
        insuranceCardFront
        insuranceCardBack
        primaryInsurance
        testOrdered
        totalOrderd
        testAvailable
        updatedBy
        updatedByName
        isSignUp
        autoShipment
        loginID
        createdBy
        createdAt
        note
        noteAddedBy
        status
        isHospice
        eligibilityStatus
        requestedDetails
        primaryCarePhysician
        referringPyhsician
        renderingProvider
        referringProvider
        orderingProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      clientID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $id: ID
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSites(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientID
        name
        contact_name
        phone_number
        contact_email
        street
        street2
        city
        state
        countryCode
        country
        zip
        testOrderCategory
        testLimit
        minTestOrderQty
        preRegistration
        totalOrders
        monthToDate
        yearToDate
        inceptionDate
        createdBy
        createdByName
        contactName
        webSite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSites = /* GraphQL */ `
  query SyncSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientID
        name
        contact_name
        phone_number
        contact_email
        street
        street2
        city
        state
        countryCode
        country
        zip
        testOrderCategory
        testLimit
        minTestOrderQty
        preRegistration
        totalOrders
        monthToDate
        yearToDate
        inceptionDate
        createdBy
        createdByName
        contactName
        webSite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      createdByName
      contactName
      webSite
      logo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $id: ID
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClients(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        contact_name
        phone_number
        contact_email
        street
        street2
        city
        state
        countryCode
        country
        zip
        testOrderCategory
        testLimit
        totalOrders
        monthToDate
        yearToDate
        inceptionDate
        minTestOrderQty
        preRegistration
        createdBy
        createdByName
        contactName
        webSite
        logo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        contact_name
        phone_number
        contact_email
        street
        street2
        city
        state
        countryCode
        country
        zip
        testOrderCategory
        testLimit
        totalOrders
        monthToDate
        yearToDate
        inceptionDate
        minTestOrderQty
        preRegistration
        createdBy
        createdByName
        contactName
        webSite
        logo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmployeeOrder = /* GraphQL */ `
  query GetEmployeeOrder($id: ID!) {
    getEmployeeOrder(id: $id) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmployeeOrders = /* GraphQL */ `
  query ListEmployeeOrders(
    $id: ID
    $filter: ModelEmployeeOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeOrders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        empID
        employeeID
        firstName
        lastName
        dob
        gender
        medicalNo
        phoneNumber
        email
        street
        street2
        city
        state
        zip
        countryCode
        country
        siteID
        locationID
        clientID
        subAgentID
        clientName
        locationName
        subAgentName
        siteName
        details
        test_type
        orderDate
        orderId
        eligibilityApiResponse
        orderStatus
        trackingURL
        trackingNumber
        testQty
        isActive
        payerId
        payerName
        pcn
        employeeClaimID
        claimFileID
        claimStatus
        claimSubmitDate
        claimAmount
        claimMessage
        amountRecieved
        amountRecievedDate
        expectedAmount
        chequeNo
        approvedRemarks
        InsuranceStatus
        InsuranceMessage
        requestedBySelf
        requestedBy
        requestedByName
        createdAt
        note
        dos
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmployeeOrders = /* GraphQL */ `
  query SyncEmployeeOrders(
    $filter: ModelEmployeeOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployeeOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        empID
        employeeID
        firstName
        lastName
        dob
        gender
        medicalNo
        phoneNumber
        email
        street
        street2
        city
        state
        zip
        countryCode
        country
        siteID
        locationID
        clientID
        subAgentID
        clientName
        locationName
        subAgentName
        siteName
        details
        test_type
        orderDate
        orderId
        eligibilityApiResponse
        orderStatus
        trackingURL
        trackingNumber
        testQty
        isActive
        payerId
        payerName
        pcn
        employeeClaimID
        claimFileID
        claimStatus
        claimSubmitDate
        claimAmount
        claimMessage
        amountRecieved
        amountRecievedDate
        expectedAmount
        chequeNo
        approvedRemarks
        InsuranceStatus
        InsuranceMessage
        requestedBySelf
        requestedBy
        requestedByName
        createdAt
        note
        dos
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAppSetting = /* GraphQL */ `
  query GetAppSetting($id: ID!) {
    getAppSetting(id: $id) {
      id
      code
      employeeCounter
      orderId
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      cliaNumber
      defaultSetting
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAppSettings = /* GraphQL */ `
  query ListAppSettings(
    $id: ID
    $filter: ModelAppSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppSettings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        employeeCounter
        orderId
        testLimit
        totalOrders
        minTestOrderQty
        bill_id
        name
        npi
        taxid
        taxonomyType
        email
        phoneNumber
        street
        street2
        city
        state
        zip
        cliaNumber
        defaultSetting
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppSettings = /* GraphQL */ `
  query SyncAppSettings(
    $filter: ModelAppSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        code
        employeeCounter
        orderId
        testLimit
        totalOrders
        minTestOrderQty
        bill_id
        name
        npi
        taxid
        taxonomyType
        email
        phoneNumber
        street
        street2
        city
        state
        zip
        cliaNumber
        defaultSetting
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInsuranceCompanies = /* GraphQL */ `
  query GetInsuranceCompanies($id: ID!) {
    getInsuranceCompanies(id: $id) {
      id
      code
      payerType
      name
      idFormat
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInsuranceCompanies = /* GraphQL */ `
  query ListInsuranceCompanies(
    $id: ID
    $filter: ModelInsuranceCompaniesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInsuranceCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        payerType
        name
        idFormat
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInsuranceCompanies = /* GraphQL */ `
  query SyncInsuranceCompanies(
    $filter: ModelInsuranceCompaniesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInsuranceCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        code
        payerType
        name
        idFormat
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roles
        firstName
        lastName
        note
        email
        personalization
        phone_number
        userRole
        clientID
        connectedID
        locationID
        subAgentID
        status
        systemUser
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        roles
        firstName
        lastName
        note
        email
        personalization
        phone_number
        userRole
        clientID
        connectedID
        locationID
        subAgentID
        status
        systemUser
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubAgent = /* GraphQL */ `
  query GetSubAgent($id: ID!) {
    getSubAgent(id: $id) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubAgents = /* GraphQL */ `
  query ListSubAgents(
    $id: ID
    $filter: ModelSubAgentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubAgents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientID
        locationID
        name
        contact_name
        phone_number
        contact_email
        street
        street2
        city
        state
        countryCode
        country
        zip
        testOrderCategory
        testLimit
        minTestOrderQty
        preRegistration
        totalOrders
        monthToDate
        yearToDate
        inceptionDate
        createdBy
        isDefault
        createdByName
        contactName
        webSite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubAgents = /* GraphQL */ `
  query SyncSubAgents(
    $filter: ModelSubAgentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubAgents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientID
        locationID
        name
        contact_name
        phone_number
        contact_email
        street
        street2
        city
        state
        countryCode
        country
        zip
        testOrderCategory
        testLimit
        minTestOrderQty
        preRegistration
        totalOrders
        monthToDate
        yearToDate
        inceptionDate
        createdBy
        isDefault
        createdByName
        contactName
        webSite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEligibiliyFiles = /* GraphQL */ `
  query GetEligibiliyFiles($id: ID!) {
    getEligibiliyFiles(id: $id) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEligibiliyFiles = /* GraphQL */ `
  query ListEligibiliyFiles(
    $id: ID
    $filter: ModelEligibiliyFilesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEligibiliyFiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientID
        locationID
        subAgentID
        clientName
        locationName
        subAgentName
        fileName
        finalFile
        filesName
        status
        message
        totalRecord
        totalSuccess
        totalFail
        fileDownloaded
        fileType
        requestedBy
        requestedByName
        updatedBy
        updatedByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEligibiliyFiles = /* GraphQL */ `
  query SyncEligibiliyFiles(
    $filter: ModelEligibiliyFilesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEligibiliyFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientID
        locationID
        subAgentID
        clientName
        locationName
        subAgentName
        fileName
        finalFile
        filesName
        status
        message
        totalRecord
        totalSuccess
        totalFail
        fileDownloaded
        fileType
        requestedBy
        requestedByName
        updatedBy
        updatedByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClaimFiles = /* GraphQL */ `
  query GetClaimFiles($id: ID!) {
    getClaimFiles(id: $id) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClaimFiles = /* GraphQL */ `
  query ListClaimFiles(
    $id: ID
    $filter: ModelClaimFilesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClaimFiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientID
        locationID
        subAgentID
        clientName
        locationName
        subAgentName
        fileName
        finalFile
        filesName
        status
        message
        totalRecord
        totalSuccess
        totalFail
        fileDownloaded
        fileType
        requestedBy
        requestedByName
        updatedBy
        updatedByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClaimFiles = /* GraphQL */ `
  query SyncClaimFiles(
    $filter: ModelClaimFilesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClaimFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientID
        locationID
        subAgentID
        clientName
        locationName
        subAgentName
        fileName
        finalFile
        filesName
        status
        message
        totalRecord
        totalSuccess
        totalFail
        fileDownloaded
        fileType
        requestedBy
        requestedByName
        updatedBy
        updatedByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmployeeClaims = /* GraphQL */ `
  query GetEmployeeClaims($id: ID!) {
    getEmployeeClaims(id: $id) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      submissionDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmployeeClaims = /* GraphQL */ `
  query ListEmployeeClaims(
    $id: ID
    $filter: ModelEmployeeClaimsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeClaims(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientID
        locationID
        subAgentID
        employeeID
        clientName
        locationName
        subAgentName
        status
        message
        orderId
        orderDate
        localOrderId
        fileid
        claimmd_id
        accept_assign
        bill_addr_1
        bill_addr_2
        bill_city
        bill_id
        bill_name
        bill_npi
        bill_phone
        bill_state
        bill_taxid
        bill_taxid_type
        bill_zip
        diag_1
        diag_2
        diag_3
        diag_4
        diag_5
        diag_6
        diag_7
        diag_8
        mod1_1
        mod2_1
        mod3_1
        mod4_1
        narrative_1
        mod1_2
        mod2_2
        mod3_2
        mod4_2
        narrative_2
        employment_related
        ins_addr_1
        ins_addr_2
        ins_city
        ins_dob
        ins_name_f
        ins_name_l
        ins_name_m
        ins_number
        ins_sex
        ins_state
        ins_zip
        pat_addr_1
        pat_addr_2
        pat_city
        pat_country
        pat_dob
        pat_name_f
        pat_name_l
        pat_name_m
        pat_phone
        pat_rel
        pat_sex
        pat_ssn
        pat_state
        pat_zip
        payer_name
        payer_order
        payerid
        pcn
        remote_claimid
        remote_fileid
        remote_batchid
        total_charge
        charge_1
        diag_ref_1
        from_date_1
        place_of_service_1
        place_of_service_2
        proc_code_1
        proc_code_2
        thru_date_1
        units_1
        remote_chgid_1
        remote_chgid_2
        thru_date_2
        units_2
        paymentMethod
        paymentCheckNo
        checkDate
        claimNo
        allowedPayment
        paidAmount
        adjustedAmount
        submissionDate
        isPost
        plan_type
        patient_responsibility
        total_paid
        crossover_carrier
        crossover_id
        prov_npi
        prov_id
        claim_received_date
        status_code
        payer_icn
        filing_code
        requestedBy
        requestedByName
        updatedBy
        updatedByName
        createdAt
        proc_array
        provider
        era
        clia_number
        note
        eraIds
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmployeeClaims = /* GraphQL */ `
  query SyncEmployeeClaims(
    $filter: ModelEmployeeClaimsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployeeClaims(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientID
        locationID
        subAgentID
        employeeID
        clientName
        locationName
        subAgentName
        status
        message
        orderId
        orderDate
        localOrderId
        fileid
        claimmd_id
        accept_assign
        bill_addr_1
        bill_addr_2
        bill_city
        bill_id
        bill_name
        bill_npi
        bill_phone
        bill_state
        bill_taxid
        bill_taxid_type
        bill_zip
        diag_1
        diag_2
        diag_3
        diag_4
        diag_5
        diag_6
        diag_7
        diag_8
        mod1_1
        mod2_1
        mod3_1
        mod4_1
        narrative_1
        mod1_2
        mod2_2
        mod3_2
        mod4_2
        narrative_2
        employment_related
        ins_addr_1
        ins_addr_2
        ins_city
        ins_dob
        ins_name_f
        ins_name_l
        ins_name_m
        ins_number
        ins_sex
        ins_state
        ins_zip
        pat_addr_1
        pat_addr_2
        pat_city
        pat_country
        pat_dob
        pat_name_f
        pat_name_l
        pat_name_m
        pat_phone
        pat_rel
        pat_sex
        pat_ssn
        pat_state
        pat_zip
        payer_name
        payer_order
        payerid
        pcn
        remote_claimid
        remote_fileid
        remote_batchid
        total_charge
        charge_1
        diag_ref_1
        from_date_1
        place_of_service_1
        place_of_service_2
        proc_code_1
        proc_code_2
        thru_date_1
        units_1
        remote_chgid_1
        remote_chgid_2
        thru_date_2
        units_2
        paymentMethod
        paymentCheckNo
        checkDate
        claimNo
        allowedPayment
        paidAmount
        adjustedAmount
        submissionDate
        isPost
        plan_type
        patient_responsibility
        total_paid
        crossover_carrier
        crossover_id
        prov_npi
        prov_id
        claim_received_date
        status_code
        payer_icn
        filing_code
        requestedBy
        requestedByName
        updatedBy
        updatedByName
        createdAt
        proc_array
        provider
        era
        clia_number
        note
        eraIds
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProviderInfo = /* GraphQL */ `
  query GetProviderInfo($id: ID!) {
    getProviderInfo(id: $id) {
      id
      name
      firstName
      lastName
      middleName
      code
      npi
      taxid
      speciality
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      isDefault
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProviderInfos = /* GraphQL */ `
  query ListProviderInfos(
    $id: ID
    $filter: ModelProviderInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProviderInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        firstName
        lastName
        middleName
        code
        npi
        taxid
        speciality
        email
        phoneNumber
        street
        street2
        city
        state
        zip
        isDefault
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProviderInfos = /* GraphQL */ `
  query SyncProviderInfos(
    $filter: ModelProviderInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProviderInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        firstName
        lastName
        middleName
        code
        npi
        taxid
        speciality
        email
        phoneNumber
        street
        street2
        city
        state
        zip
        isDefault
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
