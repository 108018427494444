import React, { useMemo, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  getValidReg,
  parseBooleanValue,
  convertToLower,
  isValidEmail,
  isValidPhoneWithCode,
  isValidZipCode,
  getValidSpaces,
  formatZip,
  getPreRegistrationLink,
} from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import countriesOption from "countries";
import Select from "react-select";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import {
  TEST_ORDER_LIMIT_CATEGORY,
  TEST_ORDER_QUANTITY,
  TEST_ORDER_LIMIT_CATEGORY_VALUE,
  MIN_TEST_LIMIT,
} from "constant";
import { countryListAllIsoData } from "constant";
import logo from "assets/img/logo.jpg";
import { QRCode } from "react-qrcode-logo";
import { CONFIG } from "constant";
import { getStateLength } from "utils";

const SubAgentsModal = (props) => {
  const { handleClose, selectItem, handleSave, appContext } = props;
  const getObject = () => {
    const country = countryListAllIsoData.find(
      (f) => f.name === selectItem.country
    ) || { code: "US" };

    return { ...selectItem, isoCode: country.code };
  };
  const [error, setError] = useState("");
  const [newLocation, setNewLocation] = useState(getObject());
  const [showCopyText, setShowCopyText] = useState(false);

  const handleSaveChange = () => {
    if (!newLocation.name || !newLocation.name.trim()) {
      setError("Sub Agent name required");
      return;
    }

    if (!newLocation.contact_name) {
      setError("Main Contact Name is required");
      return;
    }

    if (!newLocation.contact_email) {
      setError("Email is required");
      return;
    }
    if (!isValidEmail(newLocation.contact_email)) {
      setError("Invalid Email");
      return;
    }

    if (!newLocation.contactName) {
      setError("Phone Number is required");
      return;
    }
    if (!isValidPhoneWithCode(newLocation.contactName)) {
      setError("Invalid Phone Number");
      return;
    }

    if (!newLocation.phone_number) {
      setError("Main Contact Number is required");
      return;
    }

    if (!isValidPhoneWithCode(newLocation.phone_number)) {
      setError("Invalid Main Contact Number");
      return;
    }

    if (!newLocation.street.trim()) {
      setError("Address is required");
      return;
    }
    if (!newLocation.city.trim()) {
      setError("City is required");
      return;
    }

    if (!newLocation.state.trim()) {
      setError("State is required");
      return;
    }
    // if (!newLocation.country) {
    //   setError("Country is Required");
    //   return;
    // }
    if (newLocation.zip === null || !newLocation.zip) {
      setError("zip is required");
      return;
    }
    if (newLocation.isNew) {
      if (!newLocation.minTestOrderQty) {
        setError("Minimum Test Order Quantity is Required");
        return;
      }
      // if (!newLocation.password) {
      //   setError("Password is Required");
      //   return;
      // }
      // const isValidPassword =
      //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
      //     newLocation.password
      //   );

      // if (!isValidPassword) {
      //   setError(
      //     "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
      //   );
      //   return;
      // }
    }

    if (!isValidZipCode(newLocation.isoCode || "US", newLocation.zip)) {
      setError("Invalid zipcode");
      return;
    }

    // if (newLocation.clientID === null || !newLocation.clientID) {
    //   setError("Company is required");
    //   return;
    // }

    handleSave(newLocation);
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewLocation({ ...newLocation, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewLocation({ ...newLocation, ...addressObj });
    }
  };

  const handlePhoneNo = (e) => {
    setNewLocation({
      ...newLocation,
      phone_number: e,
    });
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${newLocation.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleCopyPRLink = (id) => {
    navigator.clipboard.writeText(getPreRegistrationLink(id, "kit"));
    setShowCopyText(true);
    setTimeout(() => setShowCopyText(false), 2000);
  };

  const testQuantityOption = useMemo(() => {
    const maxLimit = newLocation.client?.testLimit || MIN_TEST_LIMIT;
    if (maxLimit > 0) {
      let items = [];
      let ttl = maxLimit;
      for (let i = 2; i <= ttl; i = i + 2) {
        items.push({ value: i, label: i });
      }
      return items;
    }
    return TEST_ORDER_QUANTITY;
  }, [newLocation]);

  const minTestQuantityOption = useMemo(() => {
    let items = [];
    const value =
      newLocation.client?.minTestOrderQty || parseInt(newLocation.testLimit);
    if (value > 0) {
      for (let i = 2; i <= value; i = i + 2) {
        items.push({ value: i, label: i });
      }
    }
    return items;
  }, [newLocation]);

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {newLocation.isNew ? " Create New Sub Agent" : "Edit Sub Agent Data"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="createClientsWrapper">
          <div className="leftSide">
            <strong>Sub Agent Contact information:</strong>
            <div className="row mb-3">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Sub Agent Name:</label>
                <input
                  value={newLocation?.name}
                  className="modalInput"
                  placeholder="Sub Agent Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      name: getValidReg(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">
                  Sub Agent Address:
                </label>
                <AutoCompleteAddress
                  cssClass="modalInput"
                  value={newLocation.street}
                  handleChange={handleUpdateAddress}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">City:</label>
                <input
                  type="text"
                  className="modalInput"
                  value={newLocation.city}
                  placeholder="City"
                  onChange={(e) =>
                    setNewLocation({ ...newLocation, city: e.target.value })
                  }
                />
              </div>
              {/* <div className="form-group-wrapper ">
                <label className="modalLineHeaders">Country:</label>
                <Select
                  options={countriesOption}
                  blurInputOnSelect={true}
                  value={{
                    label: newLocation.country,
                    value: newLocation.country,
                  }}
                  menuPlacement="auto"
                  placeholder="Select Country"
                  className="w-100"
                  onChange={(e) => {
                    const country = countryListAllIsoData.find(
                      (f) => f.name === e.value
                    ) || { code: "US" };
                    setNewLocation({
                      ...newLocation,
                      country: e.value,
                      isoCode: country.code,
                    });
                  }}
                />
              </div> */}
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">State:</label>
                <input
                  type="text"
                  className="modalInput"
                  placeholder="State Abbr: XX"
                  value={newLocation.state}
                  maxLength={getStateLength(newLocation)}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val) val = val.toUpperCase();
                    setNewLocation({
                      ...newLocation,
                      state: getValidSpaces(val),
                    });
                  }}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Zip Code:</label>
                <input
                  value={
                    newLocation.isoCode === "US"
                      ? formatZip(newLocation.zip)
                      : newLocation.zip
                  }
                  type="text"
                  className="modalInput"
                  placeholder="Enter Zip Code"
                  maxLength={10}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val) val = val.toUpperCase();
                    setNewLocation({
                      ...newLocation,
                      zip:
                        newLocation.isoCode === "US"
                          ? formatZip(val)
                          : getValidSpaces(val),
                    });
                  }}
                />
              </div>
              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone Number:</label>
                <PhoneNoInput
                  handleChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contactName: e,
                    })
                  }
                  value={newLocation?.contactName}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Website:</label>
                <input
                  type="url"
                  className="modalInput"
                  placeholder="Url"
                  value={newLocation?.webSite}
                  onChange={(e) => {
                    setNewLocation({
                      ...newLocation,
                      webSite: e.target.value,
                    });
                  }}
                />
              </div>
              {/* {newLocation.isNew && (
                <div className="form-group-wrapper ">
                  <label className="mmodalLineHeaders">Password:</label>
                  <input
                    type="password"
                    className="modalInput"
                    value={newLocation.password}
                    placeholder="Password"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setNewLocation({
                        ...newLocation,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
              )} */}
            </div>
            {error && (
              <ErrorMessage error={error} handleChange={() => setError("")} />
            )}
          </div>
          <div className="leftSide">
            <div className="row mb-3">
              <strong>Main Contact information:</strong>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Main Contact Name:</label>
                <input
                  value={newLocation?.contact_name}
                  className="modalInput"
                  placeholder="Main Contact Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_name: getValidReg(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">
                  Main Contact Phone Number:
                </label>
                <PhoneNoInput
                  handleChange={handlePhoneNo}
                  value={newLocation.phone_number}
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Main Contact Email: </label>
                <input
                  value={newLocation.contact_email}
                  className={`modalInput ${newLocation.isNew && "pd-right-30"}`}
                  placeholder="Email"
                  style={{
                    width: "100%",
                    flexShrink: "initial",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_email: convertToLower(e.target.value),
                    })
                  }
                />
              </div>
              <div
                className="form-group-wrapper my-4"
                style={{ height: "32px" }}
              >
                <strong>Additional Details:</strong>
              </div>
              {!appContext.user?.isSite() && (
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">
                    Associated Facility/Agent:
                  </label>
                  <Select
                    options={appContext.locations
                      ?.filter((f) => f.id !== CONFIG.generalFacility)
                      .map((s) => {
                        return { ...s, label: s.name, value: s.id };
                      })}
                    blurInputOnSelect={true}
                    menuPlacement="auto"
                    placeholder="Select Facility/Agent"
                    className="w-100"
                    value={
                      newLocation.location?.value ? newLocation.location : null
                    }
                    onChange={(e) =>
                      setNewLocation({
                        ...newLocation,
                        locationID: e.value,
                        clientID: e.clientID,
                        location: e,
                        testLimit: e.testLimit,
                        minTestOrderQty: e.minTestOrderQty,
                      })
                    }
                  />
                </div>
              )}
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">
                  Order Limit Category :
                </label>
                <Select
                  options={TEST_ORDER_LIMIT_CATEGORY}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select limit category"
                  className="w-100"
                  value={{
                    label:
                      TEST_ORDER_LIMIT_CATEGORY_VALUE[
                        newLocation.testOrderCategory
                      ],
                    value: newLocation.testOrderCategory,
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      testOrderCategory: e.value,
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">
                  Max Test Quantity :
                </label>
                <Select
                  options={testQuantityOption}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select"
                  className="w-100"
                  value={
                    newLocation.testLimit
                      ? {
                          label: newLocation.testLimit,
                          value: newLocation.testLimit,
                        }
                      : null
                  }
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      testLimit: e.value,
                      minTestOrderQty: 2,
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">
                  Minimum Test Order Quantity :
                </label>
                <Select
                  options={minTestQuantityOption}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select Min Order Quantirty"
                  className="w-100"
                  value={
                    newLocation.minTestOrderQty
                      ? {
                          label: newLocation.minTestOrderQty,
                          value: newLocation.minTestOrderQty,
                        }
                      : null
                  }
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      minTestOrderQty: e.value,
                    })
                  }
                />
              </div>

              <div className="checkbox checkBoxWrapper justify-content-between">
                <span>Pre-Registration </span>
                <div className="d-flex justify-content-start align-items-center">
                  <span className="labelYesNo">
                    {parseBooleanValue(newLocation.preRegistration)}
                  </span>
                  <ViewOnlyCheckBox
                    id="11"
                    checked={newLocation.preRegistration || false}
                    bgColor="blue"
                    handleChange={(e) =>
                      setNewLocation({
                        ...newLocation,
                        preRegistration: e.target.checked,
                      })
                    }
                  />
                </div>
              </div>

              {newLocation.isNew === false && newLocation.preRegistration && (
                <div className="d-flex justify-content-between align-items-center position-relative">
                  {showCopyText && (
                    <div className="copy-link-text">Copied to clipboard!</div>
                  )}
                  <div className="fontFourteen">QR Code</div>
                  <div className="d-flex column-gap-10 align-items-center justify-content-between qr-code-download">
                    <div onClick={() => handleCopyPRLink(newLocation.id)}>
                      <i className="fas fa-copy" />
                    </div>
                    <div onClick={() => downloadQRCode()}>
                      <i className="fas fa-download"></i>
                    </div>
                    <span style={{ display: "none" }}>
                      <QRCode
                        id="qr-gen"
                        ecLevel={"H"}
                        value={getPreRegistrationLink(newLocation.id, "kit")}
                        enableCORS={true}
                        logoImage={logo}
                        size="250"
                        logoHeight={"70"}
                        logoWidth={"70"}
                        fgColor={"#000"}
                        bgColor={"#fff"}
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="headerButton btn-fill"
          onClick={() => handleClose()}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={handleSaveChange}
        >
          {newLocation.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubAgentsModal;
