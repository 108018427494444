import React from 'react';
const SwitchToggle  = (props) => {
    const {handleChange, checked, disabled} = props;
    return(
        <label className="switch">
            <input type="checkbox" onChange={handleChange} checked={checked} disabled={disabled} />
            <span className={`slider round ${disabled ? 'disabled' : ''}`} />
        </label>
    )
}
export default SwitchToggle;