import React from "react";

const Badge = (props) => {
  const { label, handleClose, location } = props;
  return (
    <div className="badge bg-secondary d-flex align-items-center justify-content-center gap-2 ">
      {location && <i className={location === "At Home" ? "fa fa-home" : "fa fa-building"} />}
      <span className="fs-6 fw-light">{label}</span>
      <i className="fas fa-times" onClick={() => handleClose(label)} />
    </div>
  );
};

export default Badge;
