import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import Select from "react-select";
import { Button, Modal, Table } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { INSURANCE_PROVIDER, MEDICATRE_IDS } from "constant";
import { getValidDep, convertToUpper } from "../../../utils";
import { t } from "../../../stringConstants";
import moment from "moment";

import Loader from "components/Loader/Loader";
import { currencyformatter } from "constant";

const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const EligibilityDetailsModal = (props) => {
  const { handleClose, data } = props;
  const [newUser, setNewUser] = useState(data);
  console.log("data", data);
  useEffect(() => {
    if (data) {
      const dateRange = data.plan_begin_date?.split("-");

      if (dateRange && dateRange.length > 0) {
        setNewUser({
          ...data,
          planStartDate: dateRange[0],
          planEndDate: dateRange[1],
        });
      } else {
        setNewUser({
          ...data,
        });
      }
    }
  }, [data]);

  const TableRow = ({ user, index }) => {
    return (
      <tr key={index}>
        <td style={{ width: "10%" }}>{index + 1}</td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.benefit_description}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.benefit_coverage_description}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {`${currencyformatter.format(user.benefit_amount || 0)}`}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Modal
        show
        animation={true}
        size="xl"
        onHide={() => {
          handleClose();
        }}
        backdrop="static"
        style={{ paddingLeft: "0" }}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {t("eligibilityDetails")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">First Name: </label>
                <span>{newUser.ins_name_f}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Last Name: </label>
                <span>{newUser.ins_name_l}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Status:</label>
                <span className="fw-bold">{newUser.benefit?.length > 1 ? "Active" : "Non-Active"}</span>
              </div>
            </div>
            {/* <div className="form-group-wrapper">
              <label className="modalLineHeaders">DOB: </label>
              <input
                value={moment(newUser.ins_dob).format("YYYY-MM-DD")}
                type="date"
                className="modalInput"
                placeholder="Dob"
                readOnly
              />
            </div> */}

            {/* <div className="col-lg-12 col-12 mb-3">
                <label className="modalLineHeaders mt-0">
                  Insurance Provider:
                </label>
                <Select
                  options={INSURANCE_PROVIDER}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select Insuraance Provider"
                  className="w-100"
                  value={insuranceObject}
                  readOnly
                />
              </div>

              {newUser.insuranceCompany &&
                MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                  <>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Group ID:</label>
                      <input
                        value={newUser.insuranceGroupId}
                        className="modalInput"
                        maxLength={16}
                        placeholder="Enter Group ID"
                        style={{
                          width: "100%",
                        }}
                        readOnly
                      />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Insurance ID#:</label>
                      <input
                        value={newUser.medicalNo}
                        className="modalInput"
                        maxLength={16}
                        placeholder="Enter Insurance ID"
                        style={{
                          width: "100%",
                        }}
                        readOnly
                      />
                    </div>
                  </>
                )}

              {newUser.insuranceCompany &&
                MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1 && (
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">ID#:</label>
                    <input
                      value={newUser.medicalNo}
                      className="modalInput"
                      maxLength={16}
                      placeholder={
                        MEDICATRE_IDS.includes(newUser.insuranceCompany)
                          ? "Medicare Number"
                          : "ID Number"
                      }
                      style={{
                        width: "100%",
                      }}
                      readOnly
                    />
                  </div>
                )} */}

            <div className="form-group-eligibity-wrapper">
              <label className="modalLineHeaders fw-bold">Plan Begin Date:</label>
              <span>
                {`${newUser.planStartDate ? moment(newUser.planStartDate).format("MMM DD, YYYY") : ""} -
                  ${newUser.planEndDate ? moment(newUser.planEndDate).format("MMM DD, YYYY") : ""}`}
              </span>
            </div>
          </div>
          <div className="form-group-wrapper d-block">
            <div className="table-responsive" style={{ overflow: "hidden" }}>
              <Table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>#</th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      benefit description
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      benefit coverage description
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      benefit amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newUser.benefit.length > 0 &&
                    newUser.benefit.map((user, index) => <TableRow user={user} index={index} key={index} />)}
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EligibilityDetailsModal;
