import { CONFIG } from "constant";
import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import Select from "react-select";
import { userCompanyID } from "utils";

const EmployeeImportModal = (props) => {
  const { handleClose, handleImport, appContext, title, subAgents } = props;
  const getSelectedCompany = () => {
    if (appContext.user?.isSubAgent() && !subAgents) {
      return userCompanyID.get();
    } else if (appContext.user?.isSite() && subAgents) {
      return userCompanyID.get();
    }
    if (appContext[subAgents ? "locations" : "subAgents"].length === 1) {
      return appContext[subAgents ? "locations" : "subAgents"][0].id;
    }
    return "";
  };
  const [selectedLocation, setSelectedLocation] = useState(getSelectedCompany());
  const handleOnDrop = (data) => {
    if (!selectedLocation) {
      appContext.showErrorMessage("Please select the facility/agent first then import");
      // return;
    } else {
      handleImport(data, selectedLocation);
    }
  };
  const handleOnError = (err) => {
    console.log({ err });
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return (
    <Modal show animation={true} backdrop="static" onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          {(appContext.user?.isAdmin() || appContext.user?.isClient() || appContext.user?.isSite()) && !CONFIG.isLabType && (
            <div className="col-lg-12 col-12 mb-3">
              <label className="modalLineHeaders mt-0">{subAgents ? "Facility/Agent" : "Sub Agents"}</label>
              <Select
                options={appContext[subAgents ? "locations" : "subAgents"]}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                value={
                  selectedLocation
                    ? appContext[subAgents ? "locations" : "subAgents"].find((f) => f.id === selectedLocation)
                    : null
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                blurInputOnSelect={true}
                menuPlacement="auto"
                placeholder={subAgents ? "Facility/Agent" : "Select Sub Agent"}
                className="w-100"
                onChange={(e) => setSelectedLocation(e.id)}
              />
            </div>
          )}

          {selectedLocation && (
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              config={{
                header: true,
                skipEmptyLines: true,
              }}
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeImportModal;
