import PopoverStick from "components/PopOverStick";
import { ProcTooltipHeader } from "constant";
import { currencyformatter } from "constant";
import React from "react";
import { Form } from "react-bootstrap";
import { CapsFirstLetter } from "utils";
import { formatDateMDY } from "utils";

const EmpPrevClaimRow = ({ index, claim, selectedClaim, setSelectedClaim }) => {
  const prov_name =
    claim.provider?.prov_name ||
    `${claim.provider?.prov_name_f} ${claim.provider?.prov_name_m} ${claim.provider?.prov_name_l}`;
  const ProcCode = claim.proc_array.length === 1 ? claim.proc_array[0].proc_name : "Multi";
  return (
    <>
      <tr index={index}>
        <td className="table-list-data text-center">
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              checked={selectedClaim?.id === claim.id || false}
              value={claim.id}
              onChange={(e) => (e.target.checked ? setSelectedClaim(claim) : setSelectedClaim(null))}
            />
          </Form.Check>
        </td>
        <td className="table-list-data text-center ellipsis" title={formatDateMDY(claim.from_date_1)}>
          {formatDateMDY(claim.proc_array[0].from_date)}
        </td>
        <PopoverStick
          cssClass="table-list-data text-center ellipsis"
          item={`${ProcCode || ""}`}
          columns={ProcTooltipHeader}
          values={claim.proc_array.map((proc) => ({ Code: proc.proc_code, Description: proc.proc_name })) || []}
        />

        <td className="table-list-data text-center ellipsis" title={claim.payer_name || ""}>
          {claim.payer_name || ""}
        </td>

        <td className="table-list-data text-center ellipsis" title={currencyformatter.format(claim.total_charge || 0)}>
          {currencyformatter.format(claim.total_charge || 0)}
        </td>

        <td className="table-list-data text-center ellipsis" title={CapsFirstLetter(prov_name)}>
          {CapsFirstLetter(prov_name)}
        </td>
      </tr>
    </>
  );
};

export default EmpPrevClaimRow;
