import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { Modal, Button } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";

const ImageCropModal = (props) => {
  const { selectedImage, handleConfirm } = props;
  const [blobFile, setBlobFile] = useState();
  const [crop, setCrop] = useState();

  useEffect(() => {
    setTimeout(() => {
      setCrop({
        unit: "%", // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 50,
        height: 50,
        aspect: 20 / 16,
      });
    }, 300);
  }, []);

  const imageRef = useRef();

  const closeModal = async (val) => {
    if (val) {
      const data = await getCroppedImg(imageRef.current, "profileImage.jpeg");
      handleConfirm(val, data);
    } else {
      handleConfirm(val, null);
    }
  };

  const getCroppedImg = (image, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        setBlobFile(blob);
        resolve(blob);
      }, "image/jpeg");
    });
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      backdrop="static"
      onHide={() => {
        closeModal(false);
      }}
      centered
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="my-0 modal-title h4"
          id="contained-modal-title-vcenter"
        >
          Crop Image
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}
      >
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => {
            console.log("Crop ", c);
            setCrop(c);
          }}
        >
          <img ref={imageRef} src={URL.createObjectURL(selectedImage)} />
        </ReactCrop>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="modalButtons"
          variant="primary"
          onClick={() => {
            closeModal(false);
          }}
        >
          Go back
        </Button>
        <Button
          className="modalButtons"
          variant="danger"
          onClick={() => {
            closeModal(true);
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageCropModal;
