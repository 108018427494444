import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import {
  calculateTdWidth,
  checkValidity,
  parseBooleanValue,
  tdPhone,
  tdEmail,
  sortList,
  sortingFilterInLC,
} from "utils";
import { t } from "../stringConstants";
import CompanyModal from "components/Modal/CompanyModal";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import HeaderItem from "components/Table/HeaderItem";
import Filter from "../components/Filter";
import MFPagination from "components/Pagination/MFPagination";
import {
  newLocationObject,
  TEST_ORDER_LIMIT_CATEGORY_VALUE,
  PAGE_LIMIT,
  CONFIG,
  USER_TYPE_USER,
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
} from "constant";
import Loader from "components/Loader/Loader";
import { Auth } from "aws-amplify";
import { emailMsgToCreatedClient } from "utils";
import { INSURANCE_PROVIDER } from "constant";
import { Link } from "react-router-dom";
import { formatOrder } from "utils";
import moment from "moment";
import { USER_TYPE_SUBAGENT } from "constant";
import { Remove_PayerID } from "constant";
import { CLIENT_PERSONALIZE } from "constants/personalization";
import { personalizationLocalStorage } from "utils";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import { tdCheckBox } from "utils";
import filterIcon from "../assets/img/filter.svg";
import addUserIcon from "../assets/img/addUser.svg";
import viewUserIcon from "../assets/img/viewUser.svg";
import Icon from "components/Icon";
import { formatNumber } from "utils";

const Companies = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(newLocationObject);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] =
    useState(false);

  const searchTerms = [
    "Name",
    "Contact Name",
    "Contact Email",
    "Contact Phone",
  ];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const sortData = (schs, sortParam) => {
    if (!sortDescending) {
      return [...schs].sort((a, b) =>
        a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0
      );
    } else {
      return [...schs].sort((a, b) =>
        b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0
      );
    }
  };

  // useEffect(async () => {
  //   if (INSURANCE_PROVIDER.length > 0) {
  //     const filterData = INSURANCE_PROVIDER.filter(
  //       (f) => !Remove_PayerID.includes(f.payerid)
  //     );
  //     console.log("filterData", filterData, INSURANCE_PROVIDER);
  //     // await API.addJSON(filterData);
  //   }
  // }, [INSURANCE_PROVIDER]);

  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(
    width - 160,
    personalize.length > 0 ? personalize.filter((p) => p.isCheck).length : 14
  );

  const currentItems = (locs) => {
    if (!locs || locs.length === 0) return [];
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return locs.slice(indexOfFirstUser, indexOfLastUser);
  };

  let locationsToMap = currentItems(filteredLocations);

  const getPageNumbers = (users) => {
    const pageNumbers =
      users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(locations);

  if (filteredLocations && filteredLocations.length > 0) {
    pageNumbers = getPageNumbers(filteredLocations);
  }

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.clients && sortLS.clients.sortBy) {
      setSortBy(sortLS.clients.sortBy);
      setSortDescending(sortLS.clients.sortDescending);
    }
    // if (sortLS.clients && sortLS.clients.filter) {
    //   setFilter(sortLS.clients.filter);
    // }
    setPersonalize(
      personalizationLocalStorage.get(
        appContext.user,
        "client",
        CLIENT_PERSONALIZE
      )
    );
  }, [appContext?.company]);

  const headerAlign = (item) => (item === "center" ? "centered" : undefined);

  const getOrders = async () => {
    let userType = appContext.user.isUser() ? USER_TYPE_USER : USER_TYPE_ADMIN;
    let id = null;
    if (appContext.user.isUser()) {
      id = appContext.employeeRecord?.id;
      if (!id) {
        const empRecord = await API.getLoggedInUserEmployeeID(
          null,
          appContext.user.preferred_username
        );
        if (empRecord) {
          id = empRecord.id;
        }
      }
    } else if (appContext.user.isClient()) {
      userType = USER_TYPE_CLIENT;
    } else if (appContext.user.isSubAgent()) {
      userType = USER_TYPE_SUBAGENT;
    }

    const data = await API.getOrders(userType, id);
    const formatttedOrders = data.map((o) => formatOrder(o));
    setOrders(formatttedOrders);
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (appContext.companies && appContext.companies.length > 0) {
      formatClient();
    }
  }, [appContext.companies, orders]);

  const formatClient = () => {
    const clients = appContext.companies?.filter(
      (f) => f.id !== CONFIG.generalClient
    );
    // const data = orders
    //   .filter((f) => f.clientID)
    //   .reduce(
    //     (obj, order) => {
    //       obj["generic"][order.clientID] =
    //         (obj["generic"][order.clientID] || 0) + order.testQty;
    //       if (moment(order.createdAt).isSame(new Date(), "month")) {
    //         obj["monthToDate"][order.clientID] =
    //           (obj["monthToDate"][order.clientID] || 0) + order.testQty;
    //       }
    //       return obj;
    //     },
    //     { generic: {}, monthToDate: {} }
    //   );

    // const locData = clients.map((t) => {
    //   return {
    //     ...t,
    //     totalOrders: data["generic"][t.id] || 0,
    //     yearToDate: data["generic"][t.id] || 0,
    //     monthToDate: data["monthToDate"][t.id] || 0,
    //   };
    // });

    setLocations(clients);
    setFilteredLocations(sortData(nestedFilter(clients, filter), sortBy));
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.clients = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (locations.length > 0) {
      setFilteredLocations(sortData(nestedFilter(locations, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    if (filteredLocations.length > 0) {
      handleSaveFilter();
      setFilteredLocations(sortData(filteredLocations, sortBy));
    }
  }, [sortBy, sortDescending]);

  const handleConfirmDelete = async (isConfirm) => {
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await API.deleteClient(itemToDelete.id);
      if (isDeleted) {
        //await API.deleteUser(itemToDelete.phone_number);
        appContext.resetCompanies();
        appContext.showSuccessMessage("Client deleted successfully");
      } else {
        appContext.showErrorMessage(
          "Client can't be deleted because few Members are linked with it"
        );
      }
      setItemToDelete(null);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const createUser = async (newUser) => {
    const firstName = newUser.contact_name.split(" ")[0];
    const lastName = newUser.contact_name.split(" ")[1];
    const attributePayload = {
      preferred_username: newUser.phone_number,
      email: newUser.contact_email,
      phone_number: newUser.phone_number,
      "custom:role": "Clients",
      "custom:autoConfirm": "true",
      "custom:firstName": `${firstName}`,
      "custom:lastName": `${lastName}`,
      "custom:note": `${newUser.contact_name}`,
      "custom:labID": newUser.id,
      "custom:personalisation": JSON.stringify({
        personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
      }),
    };

    try {
      const signUp = await Auth.signUp({
        username: newUser.phone_number,
        password: newUser.password,
        attributes: attributePayload,
      });

      const message = emailMsgToCreatedClient(
        newUser.contact_name,
        newUser.phone_number.replaceAll(/[^0-9]/gi, ""),
        newUser.password
      );

      await API.sendEmail([
        {
          email: newUser.contact_email,
          subject: "SafeCamp LTC Team",
          msg: message,
        },
      ]);
      return true;
    } catch (error) {
      appContext.showErrorMessage(error.message);
      return false;
    }
  };

  const tdLink = (item, dates, itemID, filterKey) => (
    <td
      className="ellipsis"
      style={{
        textAlign: "center",
        textOverflow: "none",
      }}
    >
      {item > 0 ? (
        <Link
          style={{ color: "#42cef5" }}
          to={{
            pathname: "/admin/orders",
            state: {
              name: itemID,
              term: filterKey,
              filterName: dates,
              filterTerm: dates,
            },
          }}
        >
          {formatNumber(item)}
        </Link>
      ) : (
        item
      )}
    </td>
  );

  const tdFormat = (item, user) => {
    if (item === "updatedAt") return formatDate(user.updatedAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "isVaccinated") return parseBooleanValue(user.isVaccinated);
    if (item === "insuranceCompany")
      return insuranceProviderFormatter(user.insuranceCompany);
    if (item === "testOrderCategory")
      return TEST_ORDER_LIMIT_CATEGORY_VALUE[user.testOrderCategory];
    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "contact_email") return tdEmail(user.contact_email);
    if (item.itemKey === "phone_number") return tdPhone(user.phone_number);
    if (item.itemKey === "qaDone") return tdCheckBox(item, user);
    if (item.itemKey === "monthToDate")
      return tdLink(user.monthToDate, "month", user.id, "clientID");
    if (item.itemKey === "yearToDate")
      return tdLink(user.yearToDate, "year", user.id, "clientID");
    if (item.itemKey === "totalOrders")
      return tdLink(user.totalOrders, "", user.id, "clientID");
    if (item.itemKey === "testDone") {
      return tdCheckBox(item, user);
    }
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const OnHandleLocation = async (loc) => {
    const findVal = locations.find(
      (f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name)
    );
    if (findVal) {
      appContext.showErrorMessage("Client already exist");
      return;
    }
    setOpenCreateModal(false);

    try {
      setLoading(true);

      if (loc.isNew) {
        const res = await API.newClient(loc, appContext.user);
        if (res) {
          // const created = await createUser({ ...res, ...loc });
          // if (created) {
          // } else {
          //   await API.deleteClient(res.id);
          // }
          appContext.showSuccessMessage("Client Created Successfully");
        }
      } else {
        await API.updateClient(loc);
        appContext.showSuccessMessage("Client Updated Successfully");
      }
      appContext.resetCompanies();
      setSelectItem(newLocationObject);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(err.message);
    }
  };

  const handleEdit = (item) => {
    setSelectItem({ ...item, isNew: false });
    setOpenCreateModal(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);

    const models = targetArray.filter((obj) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = personalizationLocalStorage.saveAs(
      data,
      "client"
    );
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await API.udpatePersonalization(
      appContext.user.phone_number,
      personalisationData
    );
  };

  const TableRow = ({ item }) => {
    return (
      <tr>
        <td className="icon">
          {item.id !== "5f696a38-55e0-43d0-8bbb-671884a09ae6" &&
            item.id !== "010e1818-b30b-49a5-a04c-dd7b9597e9b2" && (
              <img
                src={editIcon}
                alt="edit icon"
                width="18"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                onClick={() => handleEdit(item)}
              />
            )}
        </td>
        {personalize.map((data) => data.isCheck && renderTd(data, item))}

        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          {item.id !== "5f696a38-55e0-43d0-8bbb-671884a09ae6" &&
            item.id !== "010e1818-b30b-49a5-a04c-dd7b9597e9b2" && (
              <img
                src={trashIcon}
                alt="trash icon"
                width="15"
                style={{ marginLeft: "10px" }}
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                onClick={(e) => setItemToDelete(item)}
              />
            )}
        </td>
      </tr>
    );
  };
  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("companies")} (
                  {filteredLocations
                    ? formatNumber(filteredLocations.length)
                    : 0}
                  )
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => setOpenCreateModal(true)}
                    title={t("createCompany")}
                    label={"Create"}
                    iconType={"addUserIcon"}
                  />

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    locations.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        {personalize.map(
                          (item, i) =>
                            item.isCheck && (
                              <HeaderItem
                                key={i}
                                width={tdWidth}
                                ItemKey={item.itemKey}
                                title={item.title}
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                aligned={headerAlign(item.textAlign)}
                              />
                            )
                        )}
                        <th
                          className={`border-0`}
                          style={{
                            color: "#231f20",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {locationsToMap &&
                        locationsToMap.map((item, i) => {
                          return <TableRow key={i} item={item} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete Client"
              message="Are you sure, you want to remove Client?"
              handleConfirm={handleConfirmDelete}
            />
          )}

          {openPersonalizationModal && (
            <PersonalizationModal
              data={JSON.stringify(personalize)}
              handleChange={handlePersonalization}
              show={openPersonalizationModal}
              handleClose={() => setOpenPersonalizationModal(false)}
            />
          )}

          {openCreateModal && (
            <CompanyModal
              handleClose={() => {
                setSelectItem(newLocationObject), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleLocation}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Companies;
