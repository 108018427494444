import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import {
  Employee,
  Site,
  Client,
  EmployeeOrder,
  SubAgent,
  EligibiliyFiles,
  ProviderInfo,
  EmployeeClaims,
  Users,
} from "./models";
import { Auth, API, Storage, graphqlOperation } from "aws-amplify";
import * as queries from "./graphql/queries";
import { toTitleCase, capitalizeLetter } from "utils";
import {
  TEST_STATUS,
  SCHEDULE_CATEGORY,
  DAILY_QA_STATUS,
  TEST_TYPE_VALUE,
  USER_TYPE_USER,
  USER_TYPE_CLIENT,
  CONFIG,
  MEDICATRE_IDS,
  INSURANCE_PROVIDER,
} from "constant";
import axios from "axios";
import AWS from "aws-sdk";
import awsconfig from "./aws-exports";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import { userCompanyID, emailMsgToCreatedClient, emailMsgToCreatedFacility, emailMsgToSubAgent } from "utils";
import FileSaver from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { AWS_ENDPOINT } from "constants/endpoints";
import { STATUS } from "constant";
import { AppSetting } from "models";
import { getPhoneNo } from "utils";
import { MAX_TEST_QUANTITY } from "constant";
import { PERSONALIZATION } from "constant";
import { USER_TYPE_SUBAGENT } from "constant";
import { insuranceProviderFormatter } from "utils";
import { OrderStatusEvents } from "constant";
import { claimActions } from "constant";
import { RemoveSpaceAndChar } from "utils";
import { CLAIM_STATUS_LOG_OBJ } from "constant";
import { claimSubmitStatus } from "constant";

const PUBLIC_API_URL = "https://kzyxbb35nl.execute-api.eu-west-1.amazonaws.com/staging";

const BASE_URL_API = "https://jc24amz3s1.execute-api.eu-west-1.amazonaws.com/staging/";

const PATIENT_DATA_URL = `${BASE_URL_API}preregistration`;
const PLACE_ORDER = `${BASE_URL_API}ordertest`;
const GET_LOCATION_URL = `${BASE_URL_API}location`;

const MAX_PAGE_LIMIT = 1000;
const timezones = {
  "Eastern Standard Time": -240,
  "Eastern Daylight Time": -300,
  "Central Standard Time": -300,
  "Central Daylight Time": -360,
  "Mountain Standard Time": -360,
  "Mountain Daylight Time": -420,
  "Pacific Standard Time": -420,
  "Pacific Daylight Time": -480,
  "Pakistan Standard Time": +300,
};
const getTestGraphQL = async () => {
  console.log("getTestGraphQL ");
  try {
    const graphqlData = await API.graphql(
      graphqlOperation(queries.getEmployee, {
        id: "57b4f60a-acec-4150-8224-49eb3d2e5c8e",
      })
    );
    console.log("getTestGraphQL ", graphqlData);
  } catch (err) {
    console.log("Error ", err);
  }
};

const getCurrentUser = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser({ bypassCache: true });

    const compnayID = response.attributes["custom:labID"];
    const roles = response.signInUserSession.accessToken.payload["cognito:groups"] || [];
    let baseRole = "";
    if (roles.some((role) => role === "Admins")) {
      baseRole = "Admins";
    } else if (roles.some((role) => role === "Employers")) {
      baseRole = "Employers";
    } else if (roles.some((role) => role === "Clients")) {
      baseRole = "Clients";
    } else if (roles.some((role) => role === "SubAgents")) {
      baseRole = "SubAgents";
    } else if (roles.some((role) => role === "Employees")) {
      baseRole = "Employees";
    }

    return {
      ...response.attributes,
      baseRole,
      name: `${response.attributes["custom:firstName"]} ${response.attributes["custom:lastName"] || ""}`,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isUser: function () {
        return this.roles.some((role) => role === "Employees");
      },
      isSite: function () {
        return this.roles.some((role) => role === "Employers");
      },
      isClient: function () {
        return this.roles.some((role) => role === "Clients");
      },
      isSubAgent: function () {
        return this.roles.some((role) => role === "SubAgents");
      },
      companyID: compnayID,
    };
  } catch (err) {
    console.log(err);
    localStorage.clear();
    return null;
  }
};

const getAllUsers = async () => {
  const models = await DataStore.query(Employee);
  return models;
};

const signIn = async (username, password) => {
  try {
    const response = await Auth.signIn(username, password);
    // console.log(response);
    return {
      ...response.attributes,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isUser: function () {
        return this.roles.length === 0;
      },
    };
  } catch (err) {
    return null;
  }
};

const apiName = "apif79b844d";
const publicApiName = "api4af2df69";

const fetchAllClients = async () => {
  let path = "/clients";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const models = await API.get(publicApiName, path, myInit);

    return models;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};

const fetchUsersInGroup = async (groupName) => {
  let path = "/listUsersInGroup";
  let myInit = {
    queryStringParameters: {
      groupname: groupName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const models = await API.get(apiName, path, myInit);

    return models.Users;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};
const fetchAllUsers = async (filter, NextToken) => {
  let path = "/listUsers";
  let params = {
    queryStringParameters: {
      limit: 60,
      filter: filter,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, params);

  return models;
};

const changeUserRole = async (prevRole, newRole, username) => {
  let path = "/removeUserFromGroup";
  let prevRoleAlias;
  if (prevRole === "Crew") {
    prevRoleAlias = "Employee";
  } else if (prevRole === "Health & Safety") {
    prevRoleAlias = "Employer";
  } else {
    prevRoleAlias = prevRole;
  }
  let params = {
    body: {
      username: username,
      groupname: prevRoleAlias + "s",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const roleRemoval = await API.post(apiName, path, params);
    path = "/addUserToGroup";
    params.body["groupname"] = newRole + "s";
    const roleChange = await API.post(apiName, path, params);
    // console.log("Changing role", roleRemoval, roleChange);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to change user role");
  }
};

const confirmUserSignUp = async (username) => {
  console.log(username);
  const path = "/confirmUserSignUp";
  try {
    const params = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const done = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Fail to confirm user");
  }
};
const updateUserInfo = async (user, clientName, connectedID) => {
  console.log("[update_info]", user);
  const path = "/updateUserInfo";

  const params = {
    body: {
      username: user.username,
      roles: JSON.stringify(user.roles),
      firstName: user.firstName,
      lastName: user.lastName,
      phone_number: user.phone,
      email: user.email,
      clientID: user.clientID,
      locationID: user.locationID,
      subAgentID: user.subAgentID,
      userRole: user.role,
      prevRole: user.prevRole,
      note: clientName,
      connectedID,
      status: "Confirm",
      systemUser: 1,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[update_info]", infoUpdated);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user info");
  }
};

const sendEmailMessage = async (user) => {
  let fnc = null;
  if (user.role === "Clients") {
    fnc = emailMsgToCreatedClient;
  } else if (user.role === "Employers") {
    fnc = emailMsgToCreatedFacility;
  } else if (user.role === "SubAgents") {
    fnc = emailMsgToSubAgent;
  } else {
    return "";
  }
  const message = fnc(`${user.firstName} ${user.lastName}`, user.phone, user.password);

  await sendEmail([
    {
      email: user.email,
      subject: "SafeCamp LTC Team",
      msg: message,
    },
  ]);
};

const addUserClients = async (user, clientName, connectedID) => {
  console.log("[addUserClients]", user);
  const path = "/addUserClients";

  const params = {
    body: {
      username: user.phone,
      roles: JSON.stringify(user.roles),
      firstName: user.firstName,
      lastName: user.lastName,
      phone_number: user.phone,
      email: user.email,
      clientID: user.clientID,
      locationID: user.locationID,
      subAgentID: user.subAgentID,
      userRole: user.role,
      note: clientName,
      connectedID,
      status: "Confirm",
      systemUser: 1,
      personalization: JSON.stringify(PERSONALIZATION),
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);

    console.log("[addUserClients]", infoUpdated);

    await sendEmailMessage(user);

    //  await api.sendSMSNotification(user.phone, msg);
  } catch (e) {
    console.log("Error changing addUserClients:", e);
  }
};

const udpatePersonalization = async (user, data) => {
  const path = "/updatePersonalisationInfo";
  let note = "";

  const params = {
    body: {
      data: JSON.stringify(data),
      username: user, // user.phone_number,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const updateAssociatedInfo = async (username, note) => {
  console.log("[update_info]", username, note);
  const path = "/updateAssociatedInfo";

  const params = {
    body: {
      username: username,
      note: note,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const updateUserPassword = async (username, password) => {
  console.log("[update_user_password_info]", username, password);
  const path = "/updateUserPassword";
  const params = {
    body: {
      username: username,
      password: password,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[update_user_password_info]", infoUpdated);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user password");
  }
};

const deleteUserLinkFromEmployee = async (val) => {
  const users = await DataStore.query(Employee, (e) => e.subID("eq", val));
  users.forEach(async (user) => {
    await DataStore.save(
      Employee.copyOf(user, (updated) => {
        updated.subID = null;
      })
    );
  });
};

const deleteUser = async (user) => {
  console.log("[delete_user_password_info]", user);
  const path = "/deleteUser";
  const params = {
    body: {
      id: user.id,
      username: user.userID,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    //alert("Unable to update user password");
  }
};

const sendSMSNotification = async (phoneNumber, message) => {
  try {
    const notification = await axios.post(AWS_ENDPOINT + "/notification", {
      phone_number: phoneNumber,
      message: message,
    });
    return notification;
  } catch (error) {
    console.log(error);
  }
};

const parseMessage = (msg, emp, companyData, testType) => {
  let message = msg;
  message = message.replace(/{firstName}/g, toTitleCase(emp.firstName));
  message = message.replace(/{lastName}/g, toTitleCase(emp.lastName));
  message = message.replace(/{testType}/g, testType);
  return message;
};

const parseMessageCallTime = (msg, firstName, lastName, testType, callTime) => {
  let message = msg;
  message = message.replace(/{firstName}/g, toTitleCase(firstName));
  message = message.replace(/{lastName}/g, toTitleCase(lastName));
  message = message.replace(/{testType}/g, testType);
  message = message.replace(/{callTime}/g, callTime);
  return message;
};

export const formatDateMDY = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");
const buildAWSDate = (dobFromID) => {
  const awsdob = [];
  awsdob.push(dobFromID.substring(4));
  awsdob.push(dobFromID.substring(0, 2));
  awsdob.push(dobFromID.substring(2, 4));
  return awsdob.join("-");
};

const sendEmail = async (data) => {
  console.log("[sendEmail]", data);
  const path = "/sendEmail";
  // const path = "/sendInvitationEmail";
  // const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[SEND_EMAIL]", infoUpdated);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};

const sendEmailToCrew = async (data) => {
  console.log("[sendEmailToCrew]", data);
  // const path = "/sendEmailToCrew";
  const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[SEND_EMAIL]", infoUpdated);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};

const fetchAllEmployees = async () => {
  const models = await DataStore.query(Employee);
  return models.length;
};

const getEmployees = async () => {
  let models = [];
  console.log("User Company ", userCompanyID.get());
  if (userCompanyID.get()) {
    console.log("User Company ", userCompanyID.get());
    models = await DataStore.query(Employee, (e) => e.companyID("eq", userCompanyID.get()), {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });

    const clientRecords = await DataStore.query(Employee, (e) => e.clientID("eq", userCompanyID.get()), {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });
    const subAgentRecords = await DataStore.query(Employee, (e) => e.subAgent("eq", userCompanyID.get()), {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });
    console.log("subAgentRecords", subAgentRecords);
    models = models.concat(clientRecords);
    models = models.concat(subAgentRecords);
  } else {
    models = await DataStore.query(Employee, Predicates.ALL, {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });
  }
  return models;
};

const getEmployeeClients = async (username) => {
  console.log("[getEmployeeClients]");
  const path = "/getEmployeeClients";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      username: username,
    },
  };

  const counterData = await API.post(apiName, path, params);
  return counterData.Items.length > 0 ? counterData.Items[0] : null;
};

const getEmployeeByID = async (emp) => {
  let models = [];
  if (emp.id) {
    models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  } else {
    models = await DataStore.query(Employee, (e) =>
      e.firstName("eq", emp.firstName).lastName("eq", emp.lastName).dob("eq", emp.dob)
    );
  }
  return models.length > 0 ? models[0] : null;
};

const getLoggedInUserEmployeeID = async (id, medicalno) => {
  let models = null;
  if (id) {
    models = await DataStore.query(Employee, (e) => e.loginID("eq", medicalno).id("ne", id));
  } else {
    models = await DataStore.query(Employee, (e) => e.loginID("eq", medicalno));
  }
  return models.length > 0 ? models[0] : null;
};

const getUserEmployeeID = async (newUser) => {
  let models = [];
  if (MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1) {
    models = await DataStore.query(Employee, (e) =>
      e.medicalNo("eq", newUser.medicalNo).insuranceCompany("eq", newUser.insuranceCompany).id("ne", newUser.id)
    );
  } else {
    models = await DataStore.query(Employee, (e) =>
      e
        .medicalNo("eq", newUser.medicalNo)
        .insuranceCompany("eq", newUser.insuranceCompany)
        .insuranceGroupId("eq", newUser.insuranceGroupId)
        .id("ne", newUser.id)
    );
  }
  return models.length > 0 ? models[0] : null;
};

const getDuplicateData = async () => {
  const models = await DataStore.query(Employee, Predicates.ALL);
  const ttl = models.length;

  const emps = models.filter(
    (f, i, arr) =>
      arr.findIndex(
        (m) =>
          m.firstName === f.firstName && f.lastName === m.lastName && f.medicalNo === m.medicalNo && f.dob === m.dob
      ) !== i
  );
  const duplicateData = models.filter(
    (f, i) =>
      emps.findIndex(
        (m) =>
          m.firstName === f.firstName && f.lastName === m.lastName && f.medicalNo === m.medicalNo && f.dob === m.dob
      ) !== -1
  );
  return duplicateData;
};

const isEmployeeExists = async (newUser) => {
  let models = [];
  if (MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1) {
    models = await DataStore.query(Employee, (e) =>
      e.medicalNo("eq", newUser.medicareNo).insuranceCompany("eq", newUser.insuranceCompany).id("ne", newUser.id)
    );
  } else {
    models = await DataStore.query(Employee, (e) =>
      e
        .medicalNo("eq", newUser.medicareNo)
        .insuranceCompany("eq", newUser.insuranceCompany)
        .insuranceGroupId("eq", newUser.groupId)
        .id("ne", newUser.id)
    );
  }
  return models.length > 0 ? models[0] : null;
};

const getUserFromLocalDB = async (username, user) => {
  const path = "/getUserFromLocalDB";
  let type = user.baseRole;
  // if (user?.baseRole !== "Admins") {
  //   type = user.baseRole;
  // }
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: userCompanyID.get(),
      type: type,
    },
  };
  const apiRes = await API.post(apiName, path, params);
  return apiRes;
};

const getEligibilityList = async (param) => {
  const path = "/getEligibilityList";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: param,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes;
};

const newEmployee = async (emp, num, user) => {
  let seqNumber = num;
  let isUpdateCounter = false;
  const setting = await getCompanySetting();
  console.log("newEmp", emp);
  if (!seqNumber) {
    seqNumber = setting.employeeCounter;
    isUpdateCounter = true;
    seqNumber++;
  }
  const employeeID = CONFIG.isLabType
    ? `LTCS-${setting.code}-${seqNumber.toString().padStart(6, 0)}`
    : `LTCS-${seqNumber.toString().padStart(6, 0)}`;
  const loginID = `LTCS${seqNumber.toString().padStart(6, 0)}${moment().format("HHmmss")}`;

  const employeeObject = {
    firstName: emp.firstName,
    lastName: emp.lastName,
    schrID: employeeID,
    email: emp.email,
    street: toTitleCase(emp.street),
    street2: emp.street2,
    sex: emp.gender || emp.sex,
    isSchedule: 0,
    isVaccinated: emp.isVaccinated || false,
    country: toTitleCase(emp.country),
    state: capitalizeLetter(emp.state),
    city: toTitleCase(emp.city),
    insuranceCompany: emp.insuranceCompany,
    insuranceDetails: emp.insuranceDetails,
    insuranceGroupId: emp.insuranceGroupId,
    insuranceCardFront: emp.insuranceCardFront,
    insuranceCardBack: emp.insuranceCardBack,
    insuranceCompanyCode: emp.insuranceCompanyCode,
    primaryInsurance: emp.primaryInsurance,
    ...(emp.medicareNo && {
      medicareNo: emp.medicareNo?.trim().toUpperCase() || null,
    }),
    medicalNo: emp.medicalNo?.trim().toUpperCase() || null,
    zip: emp.zip,
    isNew: false,
    isSignUp: emp.password ? 1 : 0,
    testAvailable: emp.testAvailable || 8,
    testOrdered: 0,
    clientID: emp.clientID || CONFIG.generalClient,
    companyID: emp.companyID || CONFIG.generalFacility,
    subAgent: emp.subAgentID,
    autoShipment: emp.autoShipment,
    loginID: loginID,
    updatedBy: user?.sub,
    updatedByName: user?.name,
    note: emp.note,
    status: "active",
    localNo: emp.localNo,
    referringPyhsician: emp.referringPyhsician,
    renderingProvider: emp.renderingProvider,
    referringProvider: emp.referringProvider,
    orderingProvider: emp.orderingProvider,
    primaryCarePhysician: emp.primaryCarePhysician,
  };

  if (emp.phoneNumber) {
    const [countryCode, phoneNo] = getPhoneNo(emp.phoneNumber);
    Object.assign(employeeObject, {
      phoneNumber: phoneNo,
      countryCode: countryCode,
    });
  }

  if (emp.dob) {
    Object.assign(employeeObject, { dob: emp.dob });
  }

  const res = await DataStore.save(new Employee(employeeObject));
  if (isUpdateCounter) {
    console.log("seqNumber", seqNumber);
    await updateCompnayEmployeeNo(seqNumber);
  }
  return { data: res.loginID, id: res.id };
};

const newProviderInfo = async (user) => {
  const providerObject = {
    name: user.name,
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    code: user.code,
    npi: user.npi,
    taxid: user.taxid,
    speciality: user.speciality,
  };

  const res = await DataStore.save(new ProviderInfo(providerObject));
  return res;
};

const createClaim = async (data, number) => {
  const empClaim = {
    ...data,
    charge_1: data.charge_1?.toString(),
    total_charge: data.total_charge?.toString(),
    units_2: data.units_2?.toString(),
  };
  const res = await DataStore.save(new EmployeeClaims(empClaim));
  if (number) await updateCompnayClaimNo(number);
  return { data: res };
};

const updateEmployee = async (emp, user) => {
  console.log("apiemp", emp);
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  const dbEmp = models[0];
  const [countryCode, phoneNo] = getPhoneNo(emp.phoneNumber);
  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.firstName = emp.firstName;
      updated.lastName = emp.lastName;
      updated.middleName = emp.middleName;
      updated.dob = emp.dob;
      updated.schrID = emp.schrID;
      updated.email = emp.email;
      updated.phoneNumber = phoneNo;
      updated.countryCode = countryCode;
      updated.street = toTitleCase(emp.street);
      updated.street2 = emp.street2;
      updated.sex = emp.gender || emp.sex;
      updated.isVaccinated = emp.isVaccinated;
      updated.country = toTitleCase(emp.country);
      updated.city = toTitleCase(emp.city);
      if (emp.status === "inactive") {
        updated.testAvailable = 0;
      }
      if (emp.password) {
        updated.isSignUp = 1;
      }
      updated.zip = emp.zip;
      updated.medicalNo = emp.medicalNo.trim().toUpperCase() || null;
      updated.state = capitalizeLetter(emp.state);
      updated.companyID = emp.companyID;
      updated.clientID = emp.clientID;
      updated.subAgent = emp.subAgentID;
      updated.insuranceCompany = emp.insuranceCompany;
      updated.insuranceGroupId = emp.insuranceGroupId;
      updated.insuranceCardFront = emp.insuranceCardFront;
      updated.insuranceCardBack = emp.insuranceCardBack;
      updated.insuranceDetails = emp.insuranceDetails;
      updated.insuranceCompanyCode = emp.insuranceCompanyCode;
      updated.primaryInsurance = emp.primaryInsurance;
      updated.medicareNo = emp.medicareNo?.trim().toUpperCase() || null;
      updated.autoShipment = emp.autoShipment;
      updated.picture = emp.profileImage instanceof Object ? `${emp.id}.jpeg` : emp.picture;
      updated.updatedBy = user?.sub;
      updated.updatedByName = user?.name;
      updated.note = emp.note;
      updated.status = emp.status;
      updated.referringPyhsician = emp.referringPyhsician;
      updated.primaryCarePhysician = emp.primaryCarePhysician;
      updated.renderingProvider = emp.renderingProvider;
      updated.referringProvider = emp.referringProvider;
      updated.orderingProvider = emp.orderingProvider;
    })
  );
  try {
    if (emp.profileImage instanceof Object) {
      console.log("Before", emp.profileImage);
      await Storage.put(`profile/${emp.id}.jpeg`, emp.profileImage, {
        bucket: "medflow-images",
      });
      console.log("aFter", emp.profileImage);
    }
  } catch (err) {
    console.log("Error", err.message);
  }
  return { data: emp.loginID, id: emp.id };
};
const fetchAllCrewMembers = async (groupName, NextToken) => {
  const path = "/listUsersInGroup";
  let users = [];
  const myInit = {
    queryStringParameters: {
      groupname: groupName,
      limit: 60,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, myInit);
  return models;
};

const deleteEmployee = async (id, user) => {
  let claims = [];
  if (CONFIG.isLabType) {
    claims = await findEmployeeClaims(id);
  }
  if (claims.length > 0 && claims.some((f) => f.status !== claimSubmitStatus.draft)) return false;
  const models = await DataStore.query(Employee, (e) => e.id("eq", id));

  if (models) await DataStore.delete(Employee, (obj) => obj.id("eq", id));
  const empObj = models[0];
  const logObj = {
    firstName: empObj.firstName,
    lastName: empObj.lastName,
    dob: empObj.dob,
    empID: empObj.id,
    medicalNo: empObj.medicalNo,
    clientName: empObj.clientName,
    locationName: empObj.locationName,
    subAgentName: empObj.subAgentName,
    locationID: empObj.locationID,
    clientID: empObj.clientID,
    subAgentID: empObj.subAgentID,
    userID: user.sub,
    userName: user.name,
    action: null,
    eventType: "EmployeeDeleted",
    eventData: "EmployeeDeleted",
  };

  await saveLogs(logObj);
  return true;
};

const addAuditLogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_program.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};

const addEligibilityCsv = async (data, fileName, bucketName) => {
  console.log({ data });
  await Storage.put(`${fileName}`, jsonToCSV(data), {
    bucket: bucketName,
  });
};

const saveFileOnBucket = async (data, fileName) => {
  await Storage.put(`${fileName}`, jsonToCSV(data), {
    bucket: CONFIG.updateBucket,
  });
};

const addlogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_audit_logs.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};

const addJSON = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_payer_list.json`, data, {
    bucket: "medflow-images",
  });
};

const getCompanySetting = async () => {
  let models = await DataStore.query(AppSetting, Predicates.ALL);
  if (models.length === 0) {
    await DataStore.save(
      new AppSetting({
        employeeCounter: 1,
      })
    );
    models = await DataStore.query(AppSetting, Predicates.ALL);
  }
  return models[0];
};

const updateSignUp = async (emp) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  const dbEmp = models[0];
  if (dbEmp) {
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.isSignUp = 1;
      })
    );
  }
};

const updateCompnayEmployeeNo = async (number) => {
  const setting = await getCompanySetting();
  console.log("setting", setting);
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.employeeCounter = number;
    })
  );
};

const updateCompnayDefaultSetting = async (defaultSetting) => {
  const setting = await getCompanySetting();
  console.log("setting", setting);
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.defaultSetting = { ...(setting.defaultSetting || {}), ...(defaultSetting || {}) };
    })
  );
};
const updateLabData = async () => {
  const setting = await getCompanySetting();
  console.log("setting", setting);
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.name = "ALTA DIAGNOSTIC LABORATORIES, INC";
      updated.npi = "1063180354";
      updated.taxid = "871701212";
      updated.taxonomyType = "E";
      updated.phoneNumber = "8185730434";
      updated.street = "2268 LINCOLN AVE";
      updated.street2 = "";
      updated.city = "ALTADENA";
      updated.state = "CA";
    })
  );
};
const updateCompnayClaimNo = async (number) => {
  const setting = await getCompanySetting();
  console.log("setting", setting);
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.orderId = number;
    })
  );
};

const updateCliItem = async (arr) => {
  const setting = await getCompanySetting();
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.cliaNumber = arr;
    })
  );
};

const updateMember = async (members) => {
  const ttl = members.length;
  for (let i = 0; i < ttl; i++) {
    const emp = members[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.insuranceCompany = emp.insuranceCompany;
        updated.insuranceCompanyCode = emp.insuranceCompanyCode;
        updated.medicalNo = emp.medicalNo;
        updated.insuranceDetails = emp.insuranceDetails;
      })
    );
  }
};

const updateMemberTestKit = async (members, facility) => {
  const ttl = members.length;
  for (let i = 0; i < ttl; i++) {
    const member = members[i];
    if (!member) continue;

    const models = await DataStore.query(Employee, (e) => e.id("eq", member.id));
    const dbEmp = models[0];

    let tests = null;
    tests = facility.testLimit - dbEmp.testOrdered;

    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.testAvailable = tests < 0 ? 0 : tests;
        updated.clientID = facility.clientID;
      })
    );
  }
};

const getLocations = async (value) => {
  if (userCompanyID.get() && value) {
    const models = await DataStore.query(Site, (e) => e.clientID("eq", userCompanyID.get()), {
      sort: (s) => s.name(SortDirection.ASCENDING),
    });
    return models;
  }
  const models = await DataStore.query(Site, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  return models;
};

const getLocationById = async (id) => {
  const models = await DataStore.query(Site, (e) => e.id("eq", id));
  return models[0];
};
const getSubAgentById = async (id) => {
  const models = await DataStore.query(SubAgent, (e) => e.id("eq", id));
  return models[0];
};
const getClientById = async (id) => {
  const models = await DataStore.query(Client, (e) => e.id("eq", id));
  return models[0];
};

const getCompanies = async () => {
  const models = await DataStore.query(Client, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  });
  return models;
};

const getEligibilityFiles = async () => {
  const user = await getCurrentUser();
  const models = await DataStore.query(EligibiliyFiles, (e) => e.requestedBy("eq", user.sub), {
    sort: (s) => s.createdAt(SortDirection.DESCENDING),
  });
  return models.length > 0 ? [models[0]] : [];
};

const getSubAgents = async () => {
  let models = [];
  console.log("User Company ", userCompanyID.get());
  if (userCompanyID.get()) {
    console.log("User Company ", userCompanyID.get());
    models = await DataStore.query(SubAgent, (e) => e.clientID("eq", userCompanyID.get()));

    const facilityRecords = await DataStore.query(SubAgent, (e) => e.locationID("eq", userCompanyID.get()));
    models = models.concat(facilityRecords);
  } else {
    models = await DataStore.query(SubAgent, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    });
  }

  return models;
};

const getPreRegisterData = async (data, env) => {
  console.log("[getPreRegisterRecordNew]");
  const path = "/getPreRegisterRecordNew";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      // phone_number: "26951b33-c456-42de-9afb-33431695d56e",
      env: env,
      phone_number: data,
    },
  };

  const counterData = await API.post(apiName, path, params);

  return counterData.Items;
};

const getClaimERA = async (claim) => {
  console.log("[getClaimERA]");
  const path = "/getClaimERA";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: claim.id,
    },
  };

  const res = await API.post(apiName, path, params);

  return res.data;
};
const getPatientERA = async (era) => {
  console.log("[getPatientERA]");
  const path = "/getPatientERA";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      eraid: era.eraid,
      ltcid: era.pcn,
    },
  };

  const res = await API.post(apiName, path, params);

  return res;
};

const newLocations = async (loc, user) => {
  const res = await DataStore.save(
    new Site({
      name: loc.name,
      clientID: loc.clientID,
      contact_name: loc.contact_name,
      phone_number: loc.phone_number,
      contact_email: loc.contact_email,
      street: loc.street,
      street2: loc.street2,
      city: loc.city,
      state: loc.state,
      webSite: loc.webSite,
      contactName: loc.contactName,
      countryCode: loc.countryCode,
      country: loc.country,
      zip: loc.zip,
      testOrderCategory: loc.testOrderCategory?.toString(),
      testLimit: loc.testLimit || 8,
      minTestOrderQty: loc.minTestOrderQty,
      preRegistration: loc.preRegistration,
      createdBy: user.sub,
      createdByName: user.name,
    })
  );
  return res;
};

const updateLocation = async (loc) => {
  const models = await DataStore.query(Site, (s) => s.id("eq", loc.id));
  await DataStore.save(
    Site.copyOf(models[0], (updated) => {
      updated.name = loc.name;
      updated.clientID = loc.clientID;
      updated.contact_name = loc.contact_name;
      updated.phone_number = loc.phone_number;
      updated.contact_email = loc.contact_email;
      updated.street = loc.street;
      updated.street2 = loc.street2;
      updated.city = loc.city;
      updated.state = loc.state;
      updated.webSite = loc.webSite;
      updated.contactName = loc.contactName;
      updated.countryCode = loc.countryCode;
      updated.country = loc.country;
      updated.zip = loc.zip;
      updated.testOrderCategory = loc.testOrderCategory?.toString();
      updated.testLimit = loc.testLimit;
      updated.minTestOrderQty = loc.minTestOrderQty;
      updated.preRegistration = loc.preRegistration;
    })
  );
};

const newEligibiliyFiles = async (obj, user) => {
  const res = await DataStore.save(
    new EligibiliyFiles({
      clientID: obj.clientID,
      locationID: obj.locationID,
      subAgentID: obj.subAgentID,
      clientName: obj.clientName,
      locationName: obj.locationName,
      subAgentName: obj.subAgentName,
      fileName: obj.fileName,
      fileType: obj.fileType,
      status: "Pending",
      totalRecord: obj.totalRecord,
      totalSuccess: obj.totalSuccess,
      totalFail: obj.totalFail,
      fileDownloaded: 0,
      requestedBy: user.sub,
      requestedByName: user.name,
    })
  );
  return res;
};

const updateEligibilityFile = async (obj) => {
  const model = await DataStore.query(EligibiliyFiles, (s) => s.id("eq", obj.id));
  const dbEligFile = model[0];
  const res = await DataStore.save(
    EligibiliyFiles.copyOf(dbEligFile, (updated) => {
      updated.fileDownloaded = 1;
    })
  );
  return res;
};
const newSubAgent = async (loc, user) => {
  const res = await DataStore.save(
    new SubAgent({
      name: loc.name,
      clientID: loc.clientID,
      locationID: loc.locationID,
      contact_name: loc.contact_name,
      phone_number: loc.phone_number,
      contact_email: loc.contact_email,
      street: loc.street,
      street2: loc.street2,
      city: loc.city,
      state: loc.state,
      webSite: loc.webSite,
      contactName: loc.contactName,
      countryCode: loc.countryCode,
      country: loc.country,
      zip: loc.zip,
      testOrderCategory: loc.testOrderCategory?.toString(),
      testLimit: loc.testLimit || 8,
      minTestOrderQty: loc.minTestOrderQty,
      preRegistration: loc.preRegistration,
      createdBy: user.sub,
      createdByName: user.name,
    })
  );
  return res;
};

const updateSubAgent = async (loc) => {
  const models = await DataStore.query(SubAgent, (s) => s.id("eq", loc.id));
  await DataStore.save(
    SubAgent.copyOf(models[0], (updated) => {
      updated.name = loc.name;
      updated.clientID = loc.clientID;
      updated.locationID = loc.locationID;
      updated.contact_name = loc.contact_name;
      updated.phone_number = loc.phone_number;
      updated.contact_email = loc.contact_email;
      updated.street = loc.street;
      updated.street2 = loc.street2;
      updated.city = loc.city;
      updated.state = loc.state;
      updated.webSite = loc.webSite;
      updated.contactName = loc.contactName;
      updated.countryCode = loc.countryCode;
      updated.country = loc.country;
      updated.zip = loc.zip;
      updated.testOrderCategory = loc.testOrderCategory?.toString();
      updated.testLimit = loc.testLimit;
      updated.minTestOrderQty = loc.minTestOrderQty;
      updated.preRegistration = loc.preRegistration;
    })
  );
};

const deleteLocation = async (id) => {
  const employees = await DataStore.query(SubAgent, (e) => e.locationID("eq", id));

  if (employees.length > 0) {
    return false;
  }

  await DataStore.delete(Site, (obj) => obj.id("eq", id));
  return true;
};

const deleteSubAgent = async (id) => {
  const employees = await DataStore.query(Employee, (e) => e.subAgent("eq", id));

  if (employees.length > 0) {
    return false;
  }

  await DataStore.delete(SubAgent, (obj) => obj.id("eq", id));
  return true;
};

const newClient = async (loc, user) => {
  const res = await DataStore.save(
    new Client({
      name: loc.name,
      contact_name: loc.contact_name,
      phone_number: loc.phone_number,
      contact_email: loc.contact_email,
      street: loc.street,
      street2: loc.street2,
      city: loc.city,
      state: loc.state,
      webSite: loc.webSite,
      contactName: loc.contactName,
      countryCode: loc.countryCode,
      testOrderCategory: loc.testOrderCategory,
      country: loc.country,
      zip: loc.zip,
      createdBy: user.sub,
      createdByName: user.name,
      testLimit: loc.testLimit || 8,
      minTestOrderQty: loc.minTestOrderQty,
      logo: loc.logo,
    })
  );
  return res;
};

const newCreateUser = async (user) => {
  const res = await DataStore.save(
    new Users({
      clientID: "",
      connectedID: "",
      email: "pclarke@othermind.com",
      firstName: "Super",
      lastName: "Admin",
      locationID: "",
      note: "System",
      personalization: JSON.stringify(PERSONALIZATION),
      phone_number: "+18888888888",
      roles: '[{"role":"Admin"}]',
      status: "Confirm",
      subAgentID: "",
      systemUser: 1,
      userID: "+18888888888",
      userRole: "Admins",
    })
  );
  return res;
};

const updateClient = async (loc) => {
  const models = await DataStore.query(Client, (s) => s.id("eq", loc.id));
  await DataStore.save(
    Client.copyOf(models[0], (updated) => {
      updated.name = loc.name;
      updated.contact_name = loc.contact_name;
      updated.phone_number = loc.phone_number;
      updated.contact_email = loc.contact_email;
      updated.street = loc.street;
      updated.street2 = loc.street2;
      updated.city = loc.city;
      updated.webSite = loc.webSite;
      updated.contactName = loc.contactName;
      updated.state = loc.state;
      updated.countryCode = loc.countryCode;
      updated.country = loc.country;
      updated.testOrderCategory = loc.testOrderCategory;
      updated.zip = loc.zip;
      updated.testLimit = loc.testLimit;
      updated.minTestOrderQty = loc.minTestOrderQty;
      updated.logo = loc.logo;
    })
  );
};

const deleteClient = async (id) => {
  const employees = await DataStore.query(Site, (e) => e.clientID("eq", id));

  if (employees.length > 0) {
    return false;
  }

  await DataStore.delete(Client, (obj) => obj.id("eq", id));
  return true;
};

const createOrder = async (ids, orderObj, user) => {
  const ttl = ids.length;
  const limitExcced = [];
  let apiRes = {};
  const path = "/checkEligibility";
  for (let i = 0; i < ttl; i++) {
    const empObj = ids[i];
    if (!empObj) continue;
    const empRecord = await DataStore.query(Employee, (e) => e.id("eq", ids[i]));
    const emp = empRecord[0];
    if (emp.testAvailable < orderObj.quantity) {
      limitExcced.push(emp);
      continue;
    }
    const locationItem = await DataStore.query(Site, (s) => s.id("eq", emp.companyID || CONFIG.generalFacility));
    const model = await DataStore.query(Client, (e) => e.id("eq", emp.clientID || CONFIG.generalClient));
    const client = model[0];
    const locationName = locationItem[0]?.name;
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        empID: ids[i],
        firstName: emp.firstName,
        lastName: emp.lastName,
        companyID: emp.companyID,
        clientID: emp.clientID,
        subAgentID: emp.subAgent,
        clientName: client?.name,
        locationName: locationName,
        testSendQty: orderObj.quantity,
        userName: user.name,
        userID: user.sub,
        details: {
          firstName: emp.firstName,
          lastName: emp.lastName,
          phoneNumber: emp.phoneNumber,
          countryCode: emp.countryCode,
          dob: emp.dob,
          email: emp.email,
          street: emp.street,
          street2: emp.street2,
          state: emp.state,
          zip: emp.zip,
          city: emp.city,
          medicalNo: emp.medicalNo,
          locationName: locationName,
          insuranceCompany: emp.insuranceCompany,
          insuranceGroupId: emp.insuranceGroupId,
        },
        fdos: "20231010", // moment().format("YYYYMMDD"),
      },
    };
    apiRes = await API.post(apiName, path, params);

    if (!apiRes.error) {
      await DataStore.save(
        Employee.copyOf(empRecord[0], (updated) => {
          updated.testOrdered = 0; //emp.testOrdered + orderObj.quantity;
          updated.testAvailable = 0; // emp.testAvailable - orderObj.quantity;
        })
      );
    }
  }
  return [limitExcced, apiRes];
};

// const createBulkOrder = async (employees, user) => {
//   const ttl = employees.length;
//   const limitExcced = [];
//   for (let i = 0; i < ttl; i++) {
//     const empObj = employees[i];
//     const empRecord = await DataStore.query(Employee, (e) =>
//       e.id("eq", empObj.id)
//     );
//     const emp = empRecord[0];
//     if (emp.testAvailable < empObj.quantity) {
//       limitExcced.push(emp);
//       continue;
//     }

//     const locationItem = await DataStore.query(Site, (s) =>
//       s.id("eq", empObj.companyID)
//     );
//     const locationName = locationItem[0].name;

//     await DataStore.save(
//       new EmployeeOrder({
//         empID: empObj.id,
//         employeeID: empObj.id,
//         siteID: emp.companyID,
//         locationID: emp.companyID,
//         clientID: emp.clientID,
//         testQty: empObj.testSendQty,
//         requestedBy: user.sub,
//         requestedByName: user.name,
//         test_type: "Antigen",
//         details: {
//           firstName: emp.firstName,
//           lastName: emp.lastName,
//           phoneNumber: emp.phoneNumber,
//           countryCode: emp.countryCode,
//           street: emp.street,
//           street2: emp.street2,
//           email: emp.email,
//           medicalNo: emp.medicalNo,
//           locationName: locationName,
//         },
//         orderId: "-1",
//         orderStatus: "Pending",
//         orderDate: moment().format("YYYYMMDD"),
//         isActive: 1,
//       })
//     );

//     await DataStore.save(
//       Employee.copyOf(empRecord[0], (updated) => {
//         updated.testOrdered = emp.testOrdered + empObj.testSendQty;
//         updated.testAvailable = emp.testAvailable - empObj.testSendQty;
//       })
//     );
//   }
//   return limitExcced;
// };

const deniedClaimStatus = async (orderID, msg, status, amount, insurance, claim, user) => {
  const orders = await DataStore.query(EmployeeOrder, (e) => e.id("eq", orderID));
  const empObj = orders[0];
  await DataStore.save(
    EmployeeOrder.copyOf(empObj, (updated) => {
      if (insurance) {
        updated.InsuranceStatus = status;
        updated.InsuranceMessage = msg;
        updated.expectedAmount = parseFloat(amount);
      }
      if (claim) {
        updated.claimStatus = "Rejected";
        updated.claimMessage = msg;
      }
    })
  );

  const logObj = {
    firstName: empObj.firstName,
    lastName: empObj.lastName,
    dob: empObj.dob,
    message: msg,
    empID: empObj.employeeID,
    localOrderId: empObj.id,
    medicalNo: empObj.medicalNo,
    groupId: "",
    payerid: empObj.payerId,
    clientName: empObj.clientName,
    locationName: empObj.locationName,
    subAgentName: empObj.subAgentName,
    locationID: empObj.locationID,
    clientID: empObj.clientID,
    subAgentID: empObj.subAgentID,
    userID: user.sub,
    userName: user.name,
    action: null,
    eventType: insurance ? `claim${status}` : "claimRejected",
    eventData: insurance ? status : "Rejected",
    orderId: empObj.orderId,
  };

  await saveLogs(logObj);
};

const returnedAmountStatus = async (obj, orderID, user) => {
  const orders = await DataStore.query(EmployeeOrder, (e) => e.id("eq", orderID));
  const empObj = orders[0];
  console.log("dbOrd", empObj);

  await DataStore.save(
    EmployeeOrder.copyOf(empObj, (updated) => {
      updated.InsuranceStatus = "Returned";
      updated.InsuranceMessage = obj.message;
      updated.amountRecieved = 0;
      updated.approvedRemarks = obj.message;
      updated.files = empObj.files ? `${empObj.files},${obj.retunStatusCardFileName}` : obj.retunStatusCardFileName;
    })
  );

  const logObj = {
    firstName: empObj.firstName,
    lastName: empObj.lastName,
    dob: empObj.dob,
    message: obj.message,
    empID: empObj.employeeID,
    localOrderId: empObj.id,
    medicalNo: empObj.medicalNo,
    file: obj.retunStatusCardFileName,
    groupId: "",
    payerid: empObj.payerId,
    clientName: empObj.clientName,
    locationName: empObj.locationName,
    subAgentName: empObj.subAgentName,
    locationID: empObj.locationID,
    clientID: empObj.clientID,
    subAgentID: empObj.subAgentID,
    userID: user.sub,
    userName: user.name,
    action: null,
    eventType: "claimAmountReturned",
    eventData: "Claim Amount Returned",
    details: JSON.stringify({
      amount: empObj.amount,
    }),
    orderId: empObj.orderId,
  };

  await saveLogs(logObj);
};
const addReceivedAmountDetails = async (obj, orderID, user, action) => {
  const orders = await DataStore.query(EmployeeOrder, (e) => e.id("eq", orderID));
  const empObj = orders[0];
  const res = {};
  let amountToSave = parseFloat(obj.amount) || 0;
  if (action === claimActions.return) {
    if (parseFloat(empObj.claimAmount) < parseFloat(obj.amount)) {
      Object.assign(res, {
        error: "Cannot refund amount greater than the Claimed amount.",
      });
      return res;
    }

    amountToSave = parseFloat(empObj.claimAmount) - parseFloat(obj.amount);
  }
  await DataStore.save(
    EmployeeOrder.copyOf(empObj, (updated) => {
      updated.InsuranceStatus = action === claimActions.receive ? "Paid" : "Refund";
      updated.InsuranceMessage = obj.message;
      updated.amountRecieved = amountToSave;
      updated.chequeNo = obj.chequeNo;
      updated.employeeClaimID = obj.employeeClaimID;
      updated.amountRecievedDate = moment(obj.chequeDate).toISOString();
      updated.approvedRemarks = obj.message;
    })
  );
  const logObj = {
    firstName: empObj.firstName,
    lastName: empObj.lastName,
    dob: empObj.dob,
    message: obj.message,
    empID: empObj.employeeID,
    localOrderId: empObj.id,
    medicalNo: empObj.medicalNo,
    groupId: "",
    payerid: empObj.payerId,
    clientName: empObj.clientName,
    locationName: empObj.locationName,
    subAgentName: empObj.subAgentName,
    locationID: empObj.locationID,
    clientID: empObj.clientID,
    subAgentID: empObj.subAgentID,
    userID: user.sub,
    userName: user.name,
    action: null,
    eventType: action === claimActions.receive ? "claimAmountReceived" : "claimAmountReturned",
    eventData: action === claimActions.receive ? "Claim Amount Received" : "Claim Amount Returned",
    details: JSON.stringify({
      amount: obj.amount,
      chequeDate: obj.chequeDate,
      chequeNo: obj.chequeNo,
    }),
    orderId: empObj.orderId,
  };

  await saveLogs(logObj);
};

const UpdateOrderData = async (id) => {
  // let employees = await DataStore.query(EmployeeOrder, (e) => e.id("eq", id));
  let allOrders = await DataStore.query(EmployeeOrder, Predicates.ALL);
  let employees = await DataStore.query(Employee, Predicates.ALL);

  allOrders = allOrders.filter((f) => f.amountRecieved === 82.32);
  console.log("Total", allOrders);
  const ttl = allOrders.length;
  const newList = [];
  for (let i = 0; i < ttl; i++) {
    // const totalOrders = empOrders.reduce((result, value) => {
    //   result = result + value.testQty;
    //   return result;
    // }, 0);
    //console.log("totalOrders", totalOrders, emp.testOrdered);
    // await DataStore.save(
    //   EmployeeOrder.copyOf(allOrders[i], (updated) => {
    //     updated.amountRecieved = 94.08;
    //     // updated.testQty = 0;
    //     // updated.subAgentID = "f8fe97c1-e689-4fd3-8924-55fdd6a5fb9c";
    //     // updated.subAgentName = "Billing Requests";
    //   })
    // );
  }

  console.log("Employee", newList);
  return newList;
  console.log("Employees", employees);
  // await DataStore.save(
  //   EmployeeOrder.copyOf(employees[0], (updated) => {
  //     updated.orderDate = "20221001";
  //     // updated.testQty = 0;
  //     // updated.subAgentID = "f8fe97c1-e689-4fd3-8924-55fdd6a5fb9c";
  //     // updated.subAgentName = "Billing Requests";
  //   })
  // );
};

const updateOrderStatus = async (Ids, status, trackingNumber, orderDateTime, msg, user) => {
  for (let i = 0; i < Ids.length; i++) {
    const id = Ids[i];
    const employees = await DataStore.query(EmployeeOrder, (e) => e.id("eq", id));
    console.log("Employees", employees);
    const empObj = employees[0];
    await DataStore.save(
      EmployeeOrder.copyOf(empObj, (updated) => {
        updated.orderStatus = status;
        if (trackingNumber) {
          updated.trackingNumber = trackingNumber;
        }
      })
    );

    const logObj = {
      firstName: empObj.firstName,
      lastName: empObj.lastName,
      dob: empObj.dob,
      logDate: orderDateTime,
      ...(trackingNumber && { trackingNo: trackingNumber }),
      message: msg,
      empID: empObj.employeeID,
      localOrderId: empObj.id,
      medicalNo: empObj.medicalNo,
      groupId: "",
      payerid: empObj.payerId,
      clientName: empObj.clientName,
      locationName: empObj.locationName,
      subAgentName: empObj.subAgentName,
      locationID: empObj.locationID,
      clientID: empObj.clientID,
      subAgentID: empObj.subAgentID,
      userID: user.sub,
      userName: user.name,
      action: null,
      eventType: `Order${OrderStatusEvents[status]}`,
      eventData: `Order ${OrderStatusEvents[status]}`,
      orderId: empObj.orderId,
    };
    await saveLogs(logObj);
  }
};

const updateOrdQtyManual = async (orders, ordQty, subagent, user) => {
  const ttl = orders.length;
  console.log({ orders, ordQty, subagent });
  for (let i = 0; i < ttl; i++) {
    const id = orders[i];
    const employees = await DataStore.query(EmployeeOrder, (e) => e.id("eq", id));
    console.log("Employees", employees);
    const empObj = employees[0];
    await DataStore.save(
      EmployeeOrder.copyOf(empObj, (updated) => {
        updated.testQty = ordQty;
        if (subagent) {
          updated.subAgentName = subagent.name;
          updated.subAgentID = subagent.id;
          updated.orderStatus = "Pending";
        }
      })
    );
  }
};

const updateTestAvailable = async (Ids, testQty) => {
  const ttl = Ids.length;
  for (let i = 0; i < ttl; i++) {
    const Id = Ids[i];
    const model = await DataStore.query(Employee, (e) => e.id("eq", Id));
    const dbEmp = model[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.testAvailable = testQty;
        //  updated.testOrdered = 16;
      })
    );
  }
};

const UpdateOrderForTest = async (Ids) => {
  // let employees = await DataStore.query(Employee, Predicates.ALL);
  const allOrders = await DataStore.query(EmployeeOrder, Predicates.ALL);
  // employees = employees.filter((f) => f.status === "active");
  // console.log("Status", employees);
  // let arr = [];
  // for (let i = 0; i < employees.length; i++) {
  //   const m = employees[i];
  //   // await DataStore.save(
  //   //   Employee.copyOf(m, (updated) => {
  //   //     updated.testAvailable = 8;
  //   //   })
  //   // );
  // }
  // console.log("Employee", arr);

  // return arr;

  // // const orderList = allOrders.filter(
  // //   (f) => f.claimStatus === "Rejected" && f.InsuranceStatus === "Paid"
  // // );
  // // const employeesList = employees.filter(
  // //   (e) => e.testAvailable > 0 && e.eligibilityStatus?.message === "Eligibile"
  // // );

  // console.log("IDS", Ids);

  // for (let i = 0; i < Ids.length; i++) {
  //   let ord = Ids[i];
  //   const modal = await DataStore.query(EmployeeOrder, (e) =>
  //     e.id("eq", ord.id)
  //   );
  //   const order = modal[0];
  //   await DataStore.save(
  //     EmployeeOrder.copyOf(order, (updated) => {
  //       updated.orderStatus = "Delivered";
  //     })
  //   );
  // }

  // // for (let i = 0; i < billOnly.length; i++) {
  // //   let order = billOnly[i];
  // //   await DataStore.save(
  // //     EmployeeOrder.copyOf(order, (updated) => {
  // //       updated.orderStatus = "Sent";
  // //     })
  // //   );
  // // }

  // return;
  let employees = await DataStore.query(Employee, (e) => e.status("eq", "active"));
  const list = [
    {
      ltcID: "dummy",
      firstName: "dummy",
      lastName: "dummy",
      email: "dummya",
      phoneNumber: "dummy",
      dob: "dummy",
      testOrdered: "testorder",
      street: "dummy",
      street2: "dummy",
      ciyt: "dummy",
      state: "dummy",
      zip: "dummy",
      sept: 9,
      oct: 0,
      nov: 0,
      dec: 0,
      jan: 0,
      feb: 0,
      status: "dummy",
      septClaim: "duummy",
      octClaim: "duummy",
      novClaim: "duummy",
      decClaim: "duummy",
      janClaim: "duummy",
      febClaim: "duummy",
      note: "dummay",
    },
  ];
  for (let i = 0; i < employees.length; i++) {
    const e = employees[i];

    const employee = {
      ltcID: e.schrID,
      firstName: e.firstName,
      lastName: e.lastName,
      email: e.email,
      phoneNumber: e.phoneNumber,
      dob: e.dob,
      testOrdered: e.testOrdered,
      street: e.street,
      street2: e.street2,
      ciyt: e.city,
      state: e.state,
      zip: e.zip,
      status: e.status,
      note: e.note,
    };
    const orders = allOrders.filter((f) => f.employeeID === e.id);

    if (orders.length > 0) {
      const sept = orders.filter((f) => f.orderDate === "20220901");

      if (sept.length > 0) {
        employee.sept = 0;
        employee.septClaim = sept[0].InsuranceStatus;
        console.log("sept", employee);
      }
      const oct = orders.filter((f) => f.orderDate === "20221001");
      if (oct.length > 0) {
        employee.oct = oct[0]?.testQty;
        employee.octClaim = oct[0].InsuranceStatus;
      }

      const nov = orders.filter((f) => f.orderDate === "20221101");
      if (nov.length > 0) {
        employee.nov = nov[0].testQty;
        employee.novClaim = nov[0].InsuranceStatus;
      }

      const dec = orders.filter((f) => f.orderDate === "20221201");
      if (dec.length > 0) {
        employee.dec = dec[0].testQty;
        employee.decClaim = dec[0].InsuranceStatus;
      }
      const jan = orders.filter((f) => f.orderDate === "20230101");
      if (jan.length > 0) {
        employee.jan = jan[0].testQty;
        employee.janClaim = jan[0].InsuranceStatus;
      }

      const feb = orders.filter((f) => f.orderDate === "20230201");
      if (feb.length > 0) {
        employee.feb = feb[0].testQty;
        employee.febClaim = feb[0].InsuranceStatus;
      }

      list.push({ ...employee });
    } else {
      list.push({ ...employee });
    }
  }
  return list;
  // await DataStore.save(
  //   Employee.copyOf(employees[0], (updated) => {
  //     updated.testAvailable = 8;
  //   })
  // );

  // const emps = await DataStore.query(Employee, Predicates.ALL);

  // const members = emps.filter(
  //   (f) => f.insuranceCompany !== "01192" && f.medicareNo
  // );

  // console.log('Employee', members);

  // const updateMember = members.map((m) => {
  //   return {
  //     ...m,
  //     insuranceCompany: "01192",
  //     insuranceCompanyCode: "Medicare",
  //     medicalNo: m.medicareNo,
  //     insuranceDetails: {
  //       insuranceCompany: m.insuranceCompany,
  //       insuranceCompanyCode: m.insuranceCompanyCode,
  //       insuranceGroupId: m.insuranceGroupId,
  //       medicalNo: m.medicalNo,

  //     },
  //   };
  // });

  // for (let i = 0; i < employees.length; i++) {
  //   await DataStore.save(
  //     Employee.copyOf(employees[i], (updated) => {
  //       updated.testAvailable = 0;
  //       updated.testOrdered = 0;
  //     })
  //   );
  // }

  return;
  const ttl = employees.length;
  for (let i = 0; i < ttl; i++) {
    const emp = employees[i];

    // if (empOrders.length > 0) {
    //   await DataStore.save(
    //     Employee.copyOf(emp, (updated) => {
    //       updated.testAvailable = 0;
    //     })
    //   );
    // }
    //  console.log('empOrders',empOrders)
  }

  // console.log(
  //   "orders",
  //   orders.filter((f) => f.InsuranceMessage && f.InsuranceStatus === "Denied")
  // );
  // const orderToUpdate = orders.filter(
  //   (f) => f.InsuranceMessage && f.InsuranceStatus === "Denied"
  // );
  // const ttl = orderToUpdate.length;
  // for (let i = 0; i < ttl; i++) {
  //   const order = orderToUpdate[i];
  //   await DataStore.save(
  //     EmployeeOrder.copyOf(order, (updated) => {
  //       updated.InsuranceStatus = 'Paid';
  //     })
  //   );
  // }

  // const ttl = orderToUpdate.length;
  // for (let i = 0; i < ttl; i++) {
  //   const order = orderToUpdate[i];
  //   await DataStore.save(
  //     EmployeeOrder.copyOf(order, (updated) => {
  //       updated.subAgentName = "Website Requests";
  //     })
  //   );
  // }
  // if (emp) {
  //   console.log("PCN", empOrder.pcn, emp.id, emp.schrID);
  //   await DataStore.save(
  //     EmployeeOrder.copyOf(empOrder, (updated) => {
  //       updated.payerName = insuranceProviderFormatter(emp.insuranceCompany);
  //       updated.locationID = "ee258792-d7d6-4edb-ac23-1acfd903e750";
  //       updated.clientID = "c092f107-d3a3-4942-b9fb-83a537e32249";
  //       updated.subAgentID = "75b8b762-d5df-47a9-ba27-760e40797917";
  //       updated.clientName = "Tackl Health";
  //       updated.locationName = "Internal Tackl Health";
  //       updated.subAgentName = "Website Requests";
  //       updated.orderStatus = "Delivered";
  //       updated.claimAmount = 96;
  //       updated.testQty = 0;
  //       updated.pcn = emp.schrID;
  //       updated.claimSubmitDate = moment().toISOString();
  //     })
  //   );
  // }
};

const createPreviousOrder = async (empObj, user) => {
  const obj = {
    empID: empObj.id,
    employeeID: empObj.id,
    siteID: empObj.companyID,
    locationID: empObj.companyID,
    clientID: empObj.clientID,
    testQty: empObj.qty,
    requestedBy: user.sub,
    requestedByName: user.name,
    test_type: "Antigen",
    details: {
      firstName: empObj.firstName,
      lastName: empObj.lastName,
      phoneNumber: empObj.phoneNumber || "",
      countryCode: empObj.countryCode,
      street: empObj.street,
      street2: empObj.street2,
      city: empObj.city,
      state: empObj.state,
      country: empObj.country,
      email: empObj.email || "",
      medicalNo: empObj.medicalNo,
      clientName: "Tackl Health",
      locationName: "Internal Tackl Health",
    },
    clientName: "Tackl Health",
    locationName: "Internal Tackl Health",
    orderId: "-1",
    orderStatus: "Processed",
    orderDate: "20230101",
    isActive: 1,
  };

  // await DataStore.save(new EmployeeOrder(obj));

  return obj;
};

const getOrdersBilling = async (type, id) => {
  if (type && type === USER_TYPE_USER) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.employeeID("eq", id).claimSubmitDate("ne", null));
    return models;
  }

  if (type && type === USER_TYPE_CLIENT) {
    const models = await DataStore.query(EmployeeOrder, (e) =>
      e.clientID("eq", userCompanyID.get()).claimSubmitDate("ne", null)
    );
    return models;
  }

  if (type && type === USER_TYPE_SUBAGENT) {
    const models = await DataStore.query(EmployeeOrder, (e) =>
      e.subAgentID("eq", userCompanyID.get()).claimSubmitDate("ne", null)
    );
    return models;
  }

  if (userCompanyID.get()) {
    const models = await DataStore.query(EmployeeOrder, (e) =>
      e.siteID("eq", userCompanyID.get()).claimSubmitDate("ne", null)
    );
    return models;
  }
  const models = await DataStore.query(EmployeeOrder, (e) => e.claimSubmitDate("ne", null));

  return models;
};

const getAllPendingOrders = async (type, id) => {
  const models = await DataStore.query(EmployeeOrder, (e) => e.orderStatus("ne", "Delivered"));

  return models;
};

const getOrders = async (type, id) => {
  if (type && type === USER_TYPE_USER) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.employeeID("eq", id));
    return models;
  }

  if (type && type === USER_TYPE_CLIENT) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.clientID("eq", userCompanyID.get()));
    return models;
  }

  if (type && type === USER_TYPE_SUBAGENT) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.subAgentID("eq", userCompanyID.get()));
    return models;
  }

  if (userCompanyID.get()) {
    const models = await DataStore.query(EmployeeOrder, (e) => e.siteID("eq", userCompanyID.get()));
    return models;
  }
  const models = await DataStore.query(EmployeeOrder, Predicates.ALL);

  return models;
};

const getOrderGQL = async (type, id, nextToken, param) => {
  let model = [];
  let pageNextToken = null;
  const params = {
    filter: param,
    limit: 60,
  };
  if (type && type === USER_TYPE_USER) {
    Object.assign(params.filter, { employeeID: { eq: id } });
  } else if (type && type === USER_TYPE_CLIENT) {
    Object.assign(params.filter, { clientID: { eq: userCompanyID.get() } });
  } else if (type && type === USER_TYPE_SUBAGENT) {
    Object.assign(params.filter, { subAgentID: { eq: userCompanyID.get() } });
  } else if (userCompanyID.get()) {
    Object.assign(params.filter, { siteID: { eq: userCompanyID.get() } });
  }
  if (nextToken) {
    Object.assign(params, { nextToken: nextToken });
  }

  try {
    do {
      if (pageNextToken) {
        Object.assign(params, { nextToken: pageNextToken });
      }
      const graphqlData = await API.graphql(graphqlOperation(queries.listEmployeeOrders, params));
      model = model.concat(graphqlData.data.listEmployeeOrders.items);
      pageNextToken = graphqlData.data.listEmployeeOrders.nextToken;
    } while (pageNextToken && model.length < 60);
    return { items: model, nextToken: pageNextToken };
  } catch (err) {
    console.log("Error:- EmployeeGraphQl", err.message);
  }
};

const deleteOrder = async (items) => {
  const ttl = items.length;
  for (let i = 0; i < ttl; i++) {
    const obj = items[i];
    if (obj) {
      const employeesOrders = await DataStore.query(EmployeeOrder, (e) => e.employeeID("eq", obj.employeeID));

      const totalOrders = employeesOrders.reduce((val, obj) => {
        return obj.testQty + val;
      }, 0);

      const thisMonthOrder = employeesOrders.filter((f) =>
        moment(f.orderDate).isBetween(moment().startOf("month"), moment().endOf("month"))
      );

      if (
        thisMonthOrder.length === 1 &&
        moment(obj.orderDate).isBetween(moment().startOf("month"), moment().endOf("month"))
      ) {
        if (obj.testQty > 0) {
          const models = await DataStore.query(Employee, (e) => e.id("eq", obj.employeeID));
          if (models.length > 0) {
            const dbEmp = models[0];
            const testOrdered = dbEmp.testOrdered - obj.testQty < 0 ? 0 : dbEmp.testOrdered - obj.testQty;
            const testAvailable = dbEmp.testAvailable + obj.testQty;

            await DataStore.save(
              Employee.copyOf(dbEmp, (updated) => {
                updated.testOrdered = testOrdered;
                updated.testAvailable = testAvailable;
              })
            );
          }
        }
      } else {
        if (obj.testQty > 0) {
          const models = await DataStore.query(Employee, (e) => e.id("eq", obj.employeeID));
          if (models.length > 0) {
            const testOrdered = totalOrders - obj.testQty;
            const dbEmp = models[0];
            await DataStore.save(
              Employee.copyOf(dbEmp, (updated) => {
                updated.testOrdered = testOrdered;
              })
            );
          }
        }
      }
      await DataStore.delete(EmployeeOrder, (o) => o.id("eq", obj.id));
    }
  }
  return true;
};

const getOrderByEmployee = async (employeeID) => {
  let model = [];
  let pageNextToken = null;
  const params = {
    filter: { employeeID: { eq: employeeID } },
    limit: 3000,
  };

  try {
    do {
      if (pageNextToken) {
        Object.assign(params, { nextToken: pageNextToken });
      }
      const graphqlData = await API.graphql(graphqlOperation(queries.listEmployeeOrders, params));
      model = model.concat(graphqlData.data.listEmployeeOrders.items);
      pageNextToken = graphqlData.data.listEmployeeOrders.nextToken;
    } while (pageNextToken);
    return model;
  } catch (err) {
    console.log("Error:- EmployeeGraphQl", err.message);
  }
};

const getLocationInformation = async (id, type) => {
  const counterData = await axios.post(GET_LOCATION_URL, { id, type });
  return counterData.data.data;
};

const checkOrderEligibility = async (obj, nameData, user) => {
  const path = "https://jc24amz3s1.execute-api.eu-west-1.amazonaws.com/staging/eligibility";
  const data = {
    firstName: obj.firstName,
    lastName: obj.lastName,
    dob: obj.dob,
    userName: user?.name,
    userID: user?.sub,
    groupId: obj.insuranceGroupId,
    medicalNo: obj.medicalNo,
    insuranceCompany: obj.insuranceCompany,
    insuranceProvider: obj.insuranceProvider,
    clientID: obj.clientID,
    locationID: obj.locationID,
    subAgentID: obj.subAgentID,
    ...nameData,
  };
  const res = await axios.post(path, { data });
  return res.data;
};

const getPatientData = async (medicalNo, groupId, insuranceId) => {
  const counterData = await axios.post(PATIENT_DATA_URL, {
    medicalNo,
    groupId,
    insuranceId,
    location: 1,
  });

  return counterData.data.data;
};

const getUserValidate = async (obj) => {
  const counterData = await axios.post(PATIENT_DATA_URL, {
    medicalNo: obj.medicalNo,
    medicareNo: obj.medicareNo,
    groupId: obj.insuranceGroupId,
    insuranceId: obj.insuranceCompany,
    firstName: obj.firstName,
    lastName: obj.lastName,
    dob: obj.dob,
    id: obj.id,
    isValidate: true,
  });
  console.log("counterData", counterData);
  return counterData.data.data;
};

const placeOrder = async (data) => {
  const counterData = await axios.post(PLACE_ORDER, { data });

  return counterData.data;
};

const getUserSite = async () => {
  const models = await DataStore.query(Site, (e) => e.id("eq", userCompanyID.get()));
  return models[0];
};

const getUserSubAgent = async () => {
  const models = await DataStore.query(SubAgent, (e) => e.id("eq", userCompanyID.get()));
  return models[0];
};

const getUserDefaultSubAgent = async () => {
  const models = await DataStore.query(SubAgent, (e) => e.clientID("eq", userCompanyID.get()).isDefault("eq", true));
  return models[0];
};

const getUserCompany = async () => {
  const models = await DataStore.query(Client, (e) => e.id("eq", userCompanyID.get()));
  return models[0];
};

const getOrderLogs = async (id, type) => {
  const path = "/getOrderLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
      type,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const saveLogs = async (logObj) => {
  const path = "/saveLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: logObj,
  };
  await API.post(apiName, path, params);
};

const getEmployeeLogs = async (id) => {
  const path = "/getEmployeeLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes;
};

const deleteLogRecord = async (id) => {
  const path = "/deleteLogs";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };
  const apiRes = await API.post(apiName, path, params);

  return apiRes;
};

const createBulkOrder = async (employees, user, responseEligibility, orderMonth, billingOnly) => {
  const path = "/checkEligibility";
  const limitExcced = [];

  employees.forEach(async (empObj) => {
    if (empObj.testAvailable < empObj.quantity) {
      limitExcced.push(empObj);
      return;
    }
    const model = await DataStore.query(Client, (e) => e.id("eq", empObj.clientID || CONFIG.generalClient));
    const client = model[0];
    const bodyObj = {
      empID: empObj.id,
      firstName: empObj.firstName,
      lastName: empObj.lastName,
      clientID: empObj.clientID,
      companyID: empObj.companyID,
      subAgentID: empObj.subAgentID,
      clientName: client?.name || "Tackl Health",
      locationName: empObj.location?.name || "Internal Tackl Health",
      subAgentName: empObj.subAgentName || "Website Requests",
      testSendQty: empObj.testSendQty,
      schrID: empObj.schrID,
      userName: user.name,
      userID: user.sub,
      details: {
        firstName: empObj.firstName,
        lastName: empObj.lastName,
        phoneNumber: empObj.phoneNumber,
        countryCode: empObj.countryCode,
        dob: empObj.dob,
        street: empObj.street,
        street2: empObj.street2,
        zip: empObj.zip,
        city: empObj.city,
        state: empObj.state,
        email: empObj.email,
        medicalNo: empObj.medicareNo || empObj.medicalNo,
        locationName: empObj.location?.name || "Internal Tackl Health",
        insuranceCompany: "01192",
        insuranceGroupId: empObj.insuranceGroupId,
        insuranceProvider: "Medicare",
      },
      orderDate: moment(orderMonth).format("YYYYMMDD"),
      fdos: moment(orderMonth).format("YYYYMMDD"),
      billOnly: billingOnly,
      empOp: "e",
    };
    try {
      const params = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
        body: bodyObj,
      };
      const apiRes = await API.post(apiName, path, params);
      if (apiRes.error) {
        responseEligibility({ empId: apiRes.id, msg: apiRes.error.error_mesg, errorStatus: "1" });
      } else {
        responseEligibility({ empId: apiRes.id, msg: "suceess", errorStatus: "0" });
      }
    } catch (error) {
      if (error.request) {
        responseEligibility({ empId: bodyObj.empID, msg: "Network Error", errorStatus: "1" });
      } else {
        // There was an error setting up the request
        responseEligibility({ empId: error.id, msg: error.message, errorStatus: "1" });
      }
    }
  });
};

const checkManualBulkEligibility = async (employees, user) => {
  const path = "/checkEligibility";

  const ttl = employees.length;
  const resStatus = [];
  for (let index = 0; index < ttl; index++) {
    try {
      const empObj = employees[index];
      const model = await DataStore.query(Client, (e) => e.id("eq", empObj.clientID || CONFIG.generalClient));
      const client = model[0];

      const params = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
        body: {
          empID: empObj.id,
          firstName: empObj.firstName,
          lastName: empObj.lastName,
          middleName: empObj.middleName,
          clientID: empObj.clientID,
          companyID: empObj.companyID,
          subAgentID: empObj.subAgentID,
          clientName: client?.name || "Tackl Health",
          locationName: empObj.location?.name || "Internal Tackl Health",
          subAgentName: "Website Requests",
          testSendQty: 8,
          userName: user.name,
          userID: user.sub,
          details: {
            firstName: empObj.firstName,
            lastName: empObj.lastName,
            phoneNumber: empObj.phoneNumber,
            countryCode: empObj.countryCode,
            dob: empObj.dob,
            street: empObj.street,
            street2: empObj.street2,
            zip: empObj.zip,
            city: empObj.city,
            state: empObj.state,
            email: empObj.email,
            medicalNo: empObj.medicalNo,
            locationName: empObj.location?.name || "Internal Tackl Health",
            insuranceCompany: empObj.insuranceCompany,
            insuranceGroupId: empObj.insuranceGroupId,
            insuranceProvider: insuranceProviderFormatter(empObj.insuranceCompany),
          },
          fdos: moment().format("YYYYMMDD"),
        },
      };
      const apiRes = await API.post(apiName, path, params);
      console.log("apiRes", apiRes);
      const res = apiRes.data;
      if (res && res.benefit && res.benefit.length > 0) {
        const isEligibleClaim = res.benefit.find(
          (f) =>
            f.benefit_code == "30" &&
            f.benefit_coverage_code == "1" &&
            f.insurance_type_description.toLowerCase() === "medicare part b"
        );
        if (isEligibleClaim) {
          resStatus.push("Passed");
        } else {
          resStatus.push("Failed");
        }
      } else {
        resStatus.push("Failed");
      }
    } catch (err) {
      console.log("Err", err);
    }
  }
  return resStatus;
};

const checkManualEligibility = async (empObj, user) => {
  const path = "/checkEligibility";

  try {
    const model = await DataStore.query(Client, (e) => e.id("eq", empObj.clientID || CONFIG.generalClient));
    const subAgentModel = await DataStore.query(SubAgent, (e) =>
      e.id("eq", empObj.subAgentID || CONFIG.generalSubAgent)
    );
    const locationModel = await DataStore.query(SubAgent, (e) =>
      e.id("eq", empObj.companyID || CONFIG.generalFacility)
    );
    const subAgent = subAgentModel[0];
    const location = locationModel[0];
    const client = model[0];

    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        empID: empObj.id,
        firstName: empObj.firstName,
        lastName: empObj.lastName,
        middleName: empObj.middleName,
        clientID: empObj.clientID,
        companyID: empObj.companyID,
        subAgentID: empObj.subAgentID,
        clientName: client?.name || "Tackl Health",
        locationName: location?.name || "Internal Tackl Health",
        subAgentName: subAgent?.name || "Website Requests",
        testSendQty: 8,
        userName: user.name,
        userID: user.sub,
        details: {
          firstName: empObj.firstName,
          lastName: empObj.lastName,
          phoneNumber: empObj.phoneNumber,
          countryCode: empObj.countryCode,
          dob: empObj.dob,
          street: empObj.street,
          street2: empObj.street2,
          zip: empObj.zip,
          city: empObj.city,
          state: empObj.state,
          email: empObj.email,
          medicalNo: empObj.medicalNo,
          locationName: empObj.location?.name || "Internal Tackl Health",
          insuranceCompany: empObj.insuranceCompany,
          insuranceGroupId: empObj.insuranceGroupId,
          insuranceProvider: insuranceProviderFormatter(empObj.insuranceCompany),
        },
        fdos: moment().format("YYYYMMDD"),
      },
    };
    const apiRes = await API.post(apiName, path, params);
    console.log("apiRes", apiRes);
    const res = apiRes.data;
    return res;
  } catch (err) {
    console.log("Err", err);
  }
};

const saveFileStorage = async (id, type, fileContent) => {
  try {
    let fileName = `${id || "w"}`;
    if (type) fileName = `${fileName}_${type}_${moment().format("YYYY_MM_DD_HH_mm_ss")}.png`;
    await Storage.put(`VaccinationImage/${fileName}`, fileContent);
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const saveReturnedFileStorage = async (id, type, fileContent) => {
  try {
    let fileName = `${id || "w"}`;
    if (type) fileName = `${fileName}_${type}_${moment().format("YYYY_MM_DD_HH_mm_ss")}.png`;
    await Storage.put(`ReturnedStatus/${fileName}`, fileContent);
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const saveImageFile = async (id, fileContent) => {
  try {
    let fileName = `${id || "ltc"}`;
    fileName = `${fileName}_${moment().format("YYYY_MM_DD_HH_mm_ss")}.png`;
    await Storage.put(`logoImages/${fileName}`, fileContent, {
      bucket: "medflow-images",
    });
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const updateOrderInsurance = async (orders, payer, user) => {
  const ttl = orders.length;
  for (let i = 0; i < ttl; i++) {
    const orderObj = orders[i];
    const orderRecord = await DataStore.query(EmployeeOrder, (e) => e.id("eq", orderObj.id));

    await DataStore.save(
      EmployeeOrder.copyOf(orderRecord[0], (updated) => {
        updated.payerId = payer.payerid;
        updated.payerName = payer.label;
      })
    );

    const logObj = {
      firstName: orderObj.firstName,
      lastName: orderObj.lastName,
      dob: orderObj.dob,
      localOrderId: orderObj.id,
      empID: orderObj.employeeID,
      medicalNo: orderObj.medicalNo,
      clientName: orderObj.clientName,
      locationName: orderObj.locationName,
      subAgentName: orderObj.subAgentName,
      locationID: orderObj.locationID,
      clientID: orderObj.clientID,
      subAgentID: orderObj.subAgentID,
      userID: user.sub,
      userName: user.name,
      message: `Change insurance provider ${orderRecord[0].payerName} to ${payer.label}`,
      action: null,
      eventType: "OrderUpdate",
      eventData: "OrderUpdate",
      orderId: orderObj.orderId,
    };
    await saveLogs(logObj);
  }
  return true;
};

const updateOrderQuantity = async (employees) => {
  const ttl = employees.length;
  const limitExcced = [];
  for (let i = 0; i < ttl; i++) {
    const empObj = employees[i];
    const empRecord = await DataStore.query(Employee, (e) => e.id("eq", empObj.id));
    const emp = empRecord[0];
    if (emp.testAvailable < emp.quantity) {
      limitExcced.push(emp);
      continue;
    }
    // await DataStore.save(
    //   Employee.copyOf(empRecord[0], (updated) => {
    //     if (!empObj.error) {
    //       updated.testOrdered = emp.testOrdered + empObj.testSendQty;
    //       updated.testAvailable = emp.testAvailable - empObj.testSendQty;
    //     }
    //     updated.autoShipment = empObj.autoShipment;
    //   })
    // );
  }
  return limitExcced;
};

const createSystemUser = async (newUser, obj) => {
  if (!newUser.isSignUp) {
    const attributePayload = {
      preferred_username: obj.data,
      email: newUser.email,
      phone_number: newUser.phoneNumber,
      "custom:role": "Employees",
      "custom:autoConfirm": "true",
      "custom:firstName": `${newUser.firstName}`,
      "custom:lastName": `${newUser.lastName}`,
      "custom:note": `${newUser.firstName} ${newUser.lastName}`,
      "custom:labID": obj.id,
      "custom:personalisation": JSON.stringify({
        personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
      }),
    };
    console.log("attributePayload", attributePayload);
    const signUp = await Auth.signUp({
      username: obj.data,
      password: newUser.password,
      attributes: attributePayload,
    });
  }
};

const addOrderNote = async (obj) => {
  const model = await DataStore.query(EmployeeOrder, (e) => e.id("eq", obj.id));
  const dbOrder = model[0];
  if (dbOrder) {
    await DataStore.save(
      EmployeeOrder.copyOf(dbOrder, (updated) => {
        updated.note = obj.note;
      })
    );
  }
  if (dbOrder) {
    const modal = await DataStore.query(Employee, (e) => e.id("eq", dbOrder.employeeID));
    const emp = modal[0];
    if (emp) {
      await DataStore.save(
        Employee.copyOf(emp, (updated) => {
          updated.note = obj.note.message;
          updated.noteAddedBy = obj.userName;
          updated.updatedBy = obj.userID;
          updated.updatedByName = obj.userName;
        })
      );
    }
  }
  const path = "/saveNotesInLogs";
  const params = {
    body: obj,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const addClaimNote = async (obj) => {
  const model = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbOrder = model[0];
  if (dbOrder) {
    await DataStore.save(
      EmployeeClaims.copyOf(dbOrder, (updated) => {
        updated.note = obj.note;
      })
    );
  }
  if (dbOrder) {
    const modal = await DataStore.query(Employee, (e) => e.id("eq", dbOrder.employeeID));
    const emp = modal[0];
    if (emp) {
      await DataStore.save(
        Employee.copyOf(emp, (updated) => {
          updated.note = obj.note;
          updated.noteAddedBy = obj.userName;
          updated.updatedBy = obj.userID;
          updated.updatedByName = obj.userName;
        })
      );
    }
  }
  const path = "/saveNotesInLogs";
  const params = {
    body: obj,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const addMemberNote = async (obj) => {
  const model = await DataStore.query(Employee, (e) => e.id("eq", obj.id));
  const dbEmp = model[0];
  if (dbEmp) {
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.note = obj.note;
        updated.status = obj.status;
        if (obj.status === "inactive") {
          updated.testAvailable = 0;
        }
        updated.noteAddedBy = obj.userName;
        updated.updatedBy = obj.userID;
        updated.updatedByName = obj.userName;
      })
    );
  }
};

const getProviderInfo = async () => {
  const models = await DataStore.query(ProviderInfo, Predicates.ALL);
  return models;
};

const getEmployeeClaims = async () => {
  const models = await DataStore.query(EmployeeClaims, Predicates.ALL);
  return models;
};
const findEmployeeClaims = async (id) => {
  const models = await DataStore.query(EmployeeClaims, (obj) => obj.employeeID("eq", id));
  return models;
};
const updateEmployeeClaim = async (obj) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbClaim = models[0];
  await DataStore.save(
    EmployeeClaims.copyOf(dbClaim, (updated) => {
      updated.ins_addr_1 = obj.ins_addr_1;
      updated.ins_addr_2 = obj.ins_addr_2;
      updated.ins_city = obj.ins_city;
      updated.ins_dob = obj.ins_dob;
      updated.ins_name_f = obj.ins_name_f;
      updated.ins_name_l = obj.ins_name_l;
      updated.ins_number = obj.ins_number;
      updated.ins_sex = obj.ins_sex;
      updated.ins_state = obj.ins_state;
      updated.ins_zip = obj.ins_zip;
      updated.pat_addr_1 = obj.pat_addr_1;
      updated.pat_addr_2 = obj.pat_addr_2;
      updated.pat_city = obj.pat_city;
      updated.pat_country = obj.pat_country;
      updated.pat_dob = obj.pat_dob;
      updated.pat_name_f = obj.pat_name_f;
      updated.pat_name_l = obj.pat_name_l;
      updated.pat_name_m = obj.pat_name_m;
      updated.pat_phone = obj.pat_phone;
      updated.pat_rel = obj.pat_rel;
      updated.pat_sex = obj.pat_sex;
      updated.pat_state = obj.pat_state;
      updated.pat_zip = obj.pat_zip;
      updated.payer_name = obj.payer_name;
      if (obj.status === claimSubmitStatus.submitted) {
        updated.submissionDate = moment().toISOString();
        updated.orderDate = moment().format("YYYYMMDD");
      }
      updated.payerid = obj.payerid;
      updated.payer_icn = obj.payer_icn;
      updated.claimNo = obj.claimNo;
      updated.from_date_1 = obj.from_date_1;
      updated.provider = obj.provider;
      updated.proc_array = obj.proc_array;
      updated.status = obj.status;
      updated.updatedBy = obj.updatedBy;
      updated.total_charge = obj.total_charge?.toString();
      updated.updatedByName = obj.updatedByName;
    })
  );
  return true;
};

const mergeEmployeeLogs = async (primaryId, deletedIds) => {
  const path = "/mergeEmployeeLogs";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      primaryId,
      deletedIds,
      companyID: userCompanyID.get(),
    },
  };
};

const updateClaims = async (obj, user, provider, refProvider, ordProvider) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.employeeID("eq", obj.id));
  const ttl = models.length;
  for (let i = 0; i < ttl; i++) {
    const dbClaim = models[i];
    await DataStore.save(
      EmployeeClaims.copyOf(dbClaim, (updated) => {
        updated.ins_addr_1 = obj.street;
        updated.ins_addr_2 = obj.street2;
        updated.ins_city = obj.ins_city;
        updated.ins_dob = obj.dob;
        updated.ins_name_f = obj.firstName;
        updated.ins_name_l = obj.lastName;
        updated.ins_name_m = obj.middleName || "";
        updated.ins_number = obj.medicalNo;
        updated.ins_sex = obj.gender || obj.sex;
        updated.ins_state = obj.state;
        updated.ins_zip = obj.zip;
        updated.pat_addr_1 = obj.street;
        updated.pat_addr_2 = obj.street2;
        updated.pat_city = obj.city;
        updated.pat_country = obj.country;
        updated.pat_dob = obj.dob;
        updated.pat_name_f = obj.firstName;
        updated.pat_name_l = obj.lastName;
        updated.pat_name_m = obj.middleName || "";
        updated.pat_phone = obj.phoneNumber;
        updated.pat_sex = obj.sex || obj.gender;
        updated.pat_state = obj.state;
        updated.pat_zip = obj.zip;
        updated.payer_name = obj.insuranceCompanyCode;
        updated.payerid = obj.insuranceCompany;
        updated.provider = {
          ...(dbClaim.provider || {}),
          ...(provider && {
            prov_id: provider.id,
            prov_name_f: provider.firstName,
            prov_name_l: provider.lastName,
            prov_name_m: provider.middleName,
            prov_npi: provider.npi,
            prov_taxid: provider.taxid,
          }),
          ...(refProvider && {
            ref_id: refProvider.id,
            ref_name_f: refProvider.firstName,
            ref_name_l: refProvider.lastName,
            ref_name_m: refProvider.middleName,
            ref_npi: refProvider.npi,
          }),
          ...(ordProvider && {
            ord_prov_id: ordProvider.id,
            ord_prov_name_f: ordProvider.firstName,
            ord_prov_name_l: ordProvider.lastName,
            ord_prov_name_m: ordProvider.middleName,
            ord_prov_npi: ordProvider.npi,
          }),
        };
        updated.updatedBy = user?.sub;
        updated.updatedByName = user?.name;
      })
    );
  }
};
const deleteEmployeeClaim = async (id) => {
  await DataStore.delete(EmployeeClaims, (obj) => obj.id("eq", id));
};

const updateEmployeeClaimStatus = async (obj, claimStatus, message) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbClaim = models[0];
  await DataStore.save(
    EmployeeClaims.copyOf(dbClaim, (updated) => {
      updated.status = claimStatus;
    })
  );
};

const updateEmployeeClaimStatusWithLogs = async (obj, date, user) => {
  const models = await DataStore.query(EmployeeClaims, (e) => e.id("eq", obj.id));
  const dbClaim = models[0];
  await DataStore.save(
    EmployeeClaims.copyOf(dbClaim, (updated) => {
      updated.status = obj.status;
      updated.message = obj.message;
    })
  );

  const logDate = moment(date, "YYYY-MM-DDTHH:mm").toISOString();

  const logObj = {
    employeeID: obj.employeeID,
    empID: obj.employeeID,
    orderId: obj.remote_claimid.slice(7),
    medicalNo: obj.ins_number.toUpperCase(),
    payerid: obj.payerid,
    payerName: obj.payer_name || "",
    locationID: obj.locationID || "",
    clientID: obj.clientID || "",
    subAgentID: obj.subAgentID || "",
    firstName: obj.pat_name_f,
    lastName: obj.pat_name_l,
    dob: obj.pat_dob,
    message: obj.message || "",
    clientName: obj.clientName || "",
    locationName: obj.locationName || "",
    subAgentName: obj.subAgentName || "",
    claimID: obj.id,
    eventType: CLAIM_STATUS_LOG_OBJ[obj.status],
    eventData: CLAIM_STATUS_LOG_OBJ[obj.status],
    userName: user.name || "System",
    userID: user.sub || null,
    amount: obj.total_charge,
    logDate,
  };

  console.log({ logObj });
  await saveLogs(logObj);
};

const updateEmployeeInsNo = async (emps) => {
  const ttl = emps.length;
  for (let i = 0; i < ttl; i++) {
    const obj = emps[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", obj.id));
    const dbClaim = models[0];
    await DataStore.save(
      Employee.copyOf(dbClaim, (updated) => {
        updated.medicalNo = RemoveSpaceAndChar(obj.medicalNo);
        updated.medicareNo = RemoveSpaceAndChar(obj.medicareNo);
      })
    );
  }
};

const deleteProviderInfo = async (id) => {
  await DataStore.delete(ProviderInfo, (obj) => obj.id("eq", id));
};

const updateProviderInfo = async (user) => {
  const models = await DataStore.query(ProviderInfo, (e) => e.id("eq", user.id));
  const dbEmp = models[0];
  await DataStore.save(
    ProviderInfo.copyOf(dbEmp, (updated) => {
      updated.name = user.name;
      updated.firstName = user.firstName;
      updated.middleName = user.middleName;
      updated.lastName = user.lastName;
      updated.code = user.code;
      updated.speciality = user.speciality;
      updated.npi = user.npi;
      updated.taxid = user.taxid;
    })
  );
};

const createNewLab = async () => {
  const obj = {
    name: "ALTA Default Sub Agent",
    contact_name: "Maya Chakhalyan",
    phone_number: "+18185730434",
    contact_email: "maya@altalab.com",
    street: "2268 LINCOLN AVE",
    city: "ALTADENA",
    state: "CA",
    contactName: "Maya Chakhalyan",
    countryCode: "+1",
    country: "US",
    zip: "910015432",
  };
  // await DataStore.save(
  //   new AppSetting({
  //     code: '001',
  //     employeeCounter: 0,
  //     orderId: 0,
  //     testLimit: 8,
  //     totalOrders: 0,
  //     minTestOrderQty:8
  //   })
  // );
  const client = await DataStore.save(
    new Client({
      name: obj.name,
      contact_name: obj.contact_name,
      phone_number: obj.phone_number,
      contact_email: obj.email,
      street: obj.street,
      city: obj.city,
      state: obj.state,
      contactName: obj.contact_name,
      countryCode: obj.countryCode,
      country: obj.country,
      zip: obj.zip,
    })
  );

  const facility = await DataStore.save(
    new Site({
      name: `${obj.name} Default Facility`,
      clientID: client.id,
      contact_name: obj.contact_name,
      phone_number: obj.phone_number,
      contact_email: obj.email,
      street: obj.street,
      city: obj.city,
      state: obj.state,
      contactName: obj.contact_name,
      countryCode: obj.countryCode,
      country: obj.country,
      zip: obj.zip,
      createdByName: "Admin",
    })
  );
  const subAgent = await DataStore.save(
    new SubAgent({
      name: `${obj.name} Default SubAgent`,
      clientID: client.id,
      locationID: facility.id,
      contact_name: obj.contact_name,
      phone_number: obj.phone_number,
      contact_email: obj.email,
      street: obj.street,
      city: obj.city,
      state: obj.state,
      contactName: obj.contact_name,
      countryCode: obj.countryCode,
      country: obj.country,
      zip: obj.zip,
      createdByName: "Admin",
    })
  );
};

export default {
  addOrderNote,
  createPreviousOrder,
  isEmployeeExists,
  fetchAllClients,
  getUserEmployeeID,
  createSystemUser,
  getPreRegisterData,
  getCompanySetting,
  addAuditLogs,
  deleteEmployee,
  getCurrentUser,
  getAllUsers,
  getUserSite,
  getUserSubAgent,
  getUserDefaultSubAgent,
  getUserCompany,
  updateOrderQuantity,
  saveFileStorage,
  signIn,
  fetchUsersInGroup,
  fetchAllUsers,
  changeUserRole,
  updateUserInfo,
  addUserClients,
  deleteUser,
  updateUserPassword,
  sendSMSNotification,
  sendEmail,
  sendEmailToCrew,
  deleteUserLinkFromEmployee,
  getEmployees,
  getEmployeeByID,
  getLoggedInUserEmployeeID,
  newEligibiliyFiles,
  getEligibilityFiles,
  newEmployee,
  updateEmployee,
  fetchAllEmployees,
  createClaim,
  getLocations,
  getLocationById,
  getSubAgentById,
  getSubAgents,
  checkOrderEligibility,
  addJSON,
  getCompanies,
  confirmUserSignUp,
  udpatePersonalization,
  fetchAllCrewMembers,
  addlogs,
  updateCompnayEmployeeNo,
  newLocations,
  updateLocation,
  newSubAgent,
  updateSubAgent,
  deleteLocation,
  deleteSubAgent,
  newClient,
  updateClient,
  updateMemberTestKit,
  deleteClient,
  createOrder,
  createBulkOrder,
  getEmployeeClients,
  getOrders,
  deleteOrder,
  getOrderByEmployee,
  getPatientData,
  updateEligibilityFile,
  placeOrder,
  deniedClaimStatus,
  getClientById,
  getLocationInformation,
  getOrderLogs,
  newProviderInfo,
  getUserFromLocalDB,
  getEligibilityList,
  addEligibilityCsv,
  saveFileOnBucket,
  getEmployeeLogs,
  deleteLogRecord,
  getOrderGQL,
  updateSignUp,
  saveImageFile,
  getUserValidate,
  addReceivedAmountDetails,
  saveLogs,
  UpdateOrderForTest,
  getDuplicateData,
  getOrdersBilling,
  getAllPendingOrders,
  addMemberNote,
  checkManualBulkEligibility,
  UpdateOrderData,
  updateMember,
  updateTestAvailable,
  updateOrderStatus,
  updateOrdQtyManual,
  getProviderInfo,
  deleteProviderInfo,
  updateProviderInfo,
  updateCliItem,
  getEmployeeClaims,
  deleteEmployeeClaim,
  findEmployeeClaims,
  updateEmployeeClaim,
  updateEmployeeClaimStatus,
  updateEmployeeClaimStatusWithLogs,
  updateLabData,
  updateEmployeeInsNo,
  updateClaims,
  updateOrderInsurance,
  getClaimERA,
  getPatientERA,
  checkManualEligibility,
  addClaimNote,
  newCreateUser,
  updateCompnayDefaultSetting,
  returnedAmountStatus,
  saveReturnedFileStorage,
};
