import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MEDICATRE_IDS } from "constant";
import Select from "react-select";
import { INSURANCE_PROVIDER } from "constant";
import RadioButton from "components/RadioButton/RadioButton";
import { convertToUpper } from "utils";
import PhoneNoInput from "components/Phone/PhoneNoInput";
const ExpandSlectModal = (props) => {
  const { handleClose, user, modalType, handleSaveChange, title } = props;
  const [newUser, setNewUser] = useState(user);
  const secondaryInsuranceObject = useMemo(() => {
    if (newUser.insuranceDetails?.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceDetails.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  const handleSave = () => {
    if (modalType === "medicare") {
      if (!newUser.medicareNo || !newUser.medicareNo.trim()) {
        return;
      }
    }
    handleSaveChange(newUser);
  };

  return (
    <>
      <Modal
        animation={true}
        onHide={() => {
          handleClose();
        }}
        show={true}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper d-block">
            {newUser.insuranceCompany && modalType === "medicare" && newUser.isMedicare === "Y" && (
              <div className="w-100">
                <div className="form-group-wrapper me-2">
                  <label className="modalLineHeaders">Medicare#:</label>
                  <input
                    value={newUser.medicareNo}
                    className="modalInput"
                    maxLength={16}
                    placeholder={
                      MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"
                    }
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        medicareNo: convertToUpper(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            )}

            {newUser.insuranceCompany && modalType === "insurance" && newUser.isSecondary === "Y" && (
              <div className="form-group-wrapper me-2 mt-4">
                <label className="mb-1">Secondary Insurance:</label>
                <Select
                  options={INSURANCE_PROVIDER.filter((f) => f.value !== newUser.insuranceCompany)}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  className="w-100"
                  placeholder="Select Secondary Insurance"
                  value={secondaryInsuranceObject}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      insuranceDetails: {
                        ...newUser.insuranceDetails,
                        insuranceCompany: e.value,
                        insuranceCompanyCode: e.label,
                        insuranceGroupId: "",
                        isMedicare: "N",
                        isSecondary: "N",
                      },
                    })
                  }
                />
              </div>
            )}
            {newUser.insuranceDetails?.insuranceCompany &&
              modalType === "insurance" &&
              newUser.isSecondary === "Y" &&
              MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 && (
                <>
                  <div className="form-group-wrapper me-2">
                    <label className="mb-1">Secondary Group ID:</label>
                    <input
                      className={`height-32 modalInput`}
                      maxLength={16}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          insuranceDetails: {
                            ...newUser.insuranceDetails,
                            insuranceGroupId: convertToUpper(e.target.value),
                          },
                        })
                      }
                      value={newUser.insuranceDetails.insuranceGroupId}
                      placeholder="Enter Secondary Group ID"
                      type="text"
                    />
                  </div>
                  <div className="form-group-wrapper me-2 ">
                    <label className="mb-1">Secondary Insurance Number:</label>
                    <input
                      className={`height-32 modalInput`}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          insuranceDetails: {
                            ...newUser.insuranceDetails,
                            medicalNo: convertToUpper(e.target.value),
                          },
                        })
                      }
                      maxLength={16}
                      value={newUser.insuranceDetails.medicalNo}
                      placeholder="Enter Insurance Number"
                      type="text"
                    />
                  </div>
                </>
              )}
            {newUser.insuranceDetails?.insuranceCompany &&
              modalType === "insurance" &&
              newUser.isSecondary === "Y" &&
              newUser.isMedicare === "N" &&
              MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 &&
              MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                <Form.Group className="form-group-wrapper">
                  <label className="modalLieHeaders emp-gender-mb text-capitalize">
                    Is the Insurance Information Entered an Advantage Plan?
                  </label>
                  <div className="d-flex justify-content-between w-100 flex-wrap">
                    <RadioButton
                      name="secondaryMedicare"
                      id="syes"
                      label="Yes"
                      checked={newUser.insuranceDetails.isMedicare === "Y" ? true : false}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          insuranceDetails: {
                            ...newUser.insuranceDetails,
                            isMedicare: e.target.checked ? "Y" : "",
                          },
                        })
                      }
                    />
                    <RadioButton
                      name="secondaryMedicare"
                      id="sno"
                      checked={newUser?.insuranceDetails.isMedicare === "N" ? true : false}
                      label="No"
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          insuranceDetails: {
                            ...newUser.insuranceDetails,
                            isMedicare: e.target.checked ? "N" : "",
                          },
                        })
                      }
                    />
                  </div>
                </Form.Group>
              )}
            {newUser.insuranceDetails?.insuranceCompany &&
              modalType === "insurance" &&
              newUser.isSecondary === "Y" &&
              MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) !== -1 && (
                <div className="form-group-wrapper me-2 ">
                  <label className="mb-1">Secondary Medicare Number:</label>
                  <input
                    className={`height-32 modalInput`}
                    maxLength={16}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        insuranceDetails: {
                          ...newUser.insuranceDetails,
                          medicalNo: convertToUpper(e.target.value),
                          medicareNo: convertToUpper(e.target.value),
                        },
                        ...(newUser.isMedicare === "N" &&
                          !newUser.medicareNo && {
                            medicareNo: convertToUpper(e.target.value),
                          }),
                      })
                    }
                    value={newUser.insuranceDetails.medicareNo}
                    placeholder={"Medicare Number"}
                    type="text"
                  />
                </div>
              )}
            {newUser.insuranceDetails?.insuranceCompany &&
              modalType === "insurance" &&
              newUser.isSecondary === "Y" &&
              newUser.insuranceDetails.isMedicare === "Y" && (
                <div className="form-group-wrapper me-2">
                  <label className="mb-1">Secondary Medicare Number:</label>
                  <input
                    className={`height-32 modalInput`}
                    maxLength={16}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        insuranceDetails: {
                          ...newUser.insuranceDetails,
                          medicareNo: convertToUpper(e.target.value),
                        },
                        medicareNo: convertToUpper(e.target.value),
                      })
                    }
                    value={newUser.insuranceDetails.medicareNo}
                    placeholder={"Medicare Number"}
                    type="text"
                  />
                </div>
              )}

            {newUser.isSelf === "N" && modalType === "self" && (
              <>
                <div className="form-group-wrapper">
                  <label className="mb-1">First Name:</label>
                  <input
                    type="text"
                    className="modalInput"
                    value={newUser.requestedByFirstName}
                    placeholder="First Name"
                    maxLength={65}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        requestedByFirstName: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group-wrapper">
                  <label className="mb-1">Last Name:</label>
                  <input
                    type="text"
                    className="modalInput"
                    value={newUser.requestedByLastName}
                    placeholder="Last Name"
                    maxLength={65}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        requestedByLastName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Phone:</label>
                  <PhoneNoInput
                    handleChange={(e) => {
                      setNewUser({
                        ...newUser,
                        requestedByPhoneNumber: e,
                      });
                    }}
                    value={newUser.requestedByPhoneNumber}
                    cssClass="w-100"
                  />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="saveButton">
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExpandSlectModal;
