import React, { useEffect, useState, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { getValidReg } from "utils";
import { MIN_TEST_QUANTITY } from "constant";
import ErrorMessage from "components/Message/ErrorMessage";
import Select from "react-select";
import api from "api";
import Loader from "components/Loader/Loader";
import { t } from "stringConstants";

const OrderModal = (props) => {
  const { handleClose, employeeIds, appContext, testAvailable } = props;
  const [error, setError] = useState("");
  const [newOrder, setNewOrder] = useState({ quantity: MIN_TEST_QUANTITY });
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState(6);

  useEffect(() => {
    loadSetting();
  }, []);

  const loadSetting = async () => {
    const obj = await api.getCompanySetting();
    setSetting(obj);
  };

  const handleSaveChange = async () => {
    setLoading(true);
    try {
      const [limitExccedEmployees, res] = await api.createOrder(
        employeeIds,
        newOrder,
        appContext.user
      );
      setLoading(false);
      if (res.error) {
        appContext.showErrorMessage(t("orderFailedMsg"));
        return;
      }
      await appContext.resetEmployeeRecord();
      handleClose(true, limitExccedEmployees, newOrder);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
      appContext.showErrorMessage("Fail to add the order");
    }
  };

  const getTestOrderQuantity = useMemo(() => {
    const options = [];
    let ttlLoop = 6;
    if (setting) {
      ttlLoop = setting?.testLimit;
    }
    if (appContext.employeeRecord) {
      ttlLoop = appContext.employeeRecord.testAvailable;
    }

    for (let i = 2; i <= ttlLoop; i = i + 2) {
      options.push({ value: i, label: i });
    }
    return options;
  }, [setting]);

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      onHide={() => handleClose(false)}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Order Test
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="row mb-3">
          {testAvailable && (
            <h1 className="w-100 text-center mt-4 fs-4 fw-bold">
              {`Number of Tests Available ${testAvailable}`}
            </h1>
          )}

          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">Quantity:</label>
            <Select
              options={getTestOrderQuantity}
              blurInputOnSelect={true}
              menuPlacement="auto"
              placeholder="Quantity"
              className="w-100"
              value={
                newOrder.quantity
                  ? { label: newOrder.quantity, value: newOrder.quantity }
                  : null
              }
              onChange={(e) =>
                setNewOrder({
                  ...newOrder,
                  quantity: e.value,
                })
              }
            />
          </div>
        </div>
        {error && (
          <ErrorMessage error={error} handleChange={() => setError("")} />
        )}
      </Modal.Body>
      <Modal.Footer>
        {loading && <Loader />}
        <Button
          variant="primary"
          className="headerButton btn-fill"
          onClick={() => handleClose(false)}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={handleSaveChange}
        >
          Submit Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderModal;
