import React from "react";
import Form from "react-bootstrap/Form";
import styles from "components/InputField/InputStyle.module.css";
import DatePicker from "react-date-picker";
import moment from "moment";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import PhoneNoInput from "components/Phone/PhoneNoInput";

const InputField = (props) => {
  const { label, type, placeholder, handleChange, labelStyle, inputStyle, value, maxlength, groupWrapper, disabled } =
    props;

  const getInPutFields = (inputType) => {
    switch (inputType) {
      case "phone":
        return <PhoneNoInput handleChange={handleChange} value={value} disabled={disabled} />;
      case "address":
        return (
          <AutoCompleteAddress
            cssClass={inputStyle}
            value={value}
            disabled={disabled}
            handleChange={(e) => handleChange(e)}
          />
        );
      case "datePicker":
        return (
          <DatePicker
            className={`${inputStyle}`}
            openCalendarOnFocus={false}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            format="MM/dd/y"
            onChange={(e) => handleChange(e)}
            disabled={disabled}
            value={value && new Date(value) != "Invalid Date" ? moment(value, "YYYY-MM-DD").toDate() : null}
            maxDate={new Date()}
            minDate={new Date("1900-01-01")}
          />
        );
      default:
        return (
          <Form.Control
            type={inputType}
            maxLength={maxlength}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => handleChange(e)}
            className={`${styles.inputStyling} ${inputStyle}`}
          />
        );
    }
  };
  return (
    <Form.Group className={`form-group-wrapper ${groupWrapper}`}>
      {label ? <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>{label}</Form.Label> : ""}
      {getInPutFields(type)}
    </Form.Group>
  );
};

export default InputField;
