import React, { useState, useRef, useEffect } from "react";
import { Card, Table, Row, Col } from "react-bootstrap";
import HeaderItem from "components/Table/HeaderItem";
import { CLI_ACTIONS, CLI_DEFAULT_OPTIONS } from "../../constant";
import { t } from "../../stringConstants";
import { calculateTdWidth, formatNumber } from "../../utils";
import EditIcon from "../../assets/img/edit-icon.png";
import TrashIcon from "../../assets/img/trash-icon.png";
import addUserIcon from "../../assets/img/Create-Employee-icon.svg";
import "../../../src/tableStyling.css";
import Loader from "components/Loader/Loader";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import AddGeneralSettingsModal from "components/GeneralSettings/AddGeneralSettingsModal";
import api from "api";

const GeneralSettings = (props) => {
  const [cliList, setCliList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(0);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const mainWidth = useRef();
  const componentRef = useRef();

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);

  useEffect(() => {
    getCliData();
  }, []);

  const getCliData = async () => {
    const res = await api.getCompanySetting();
    setCliList(res.cliaNumber || []);
  };

  const tdWidth = calculateTdWidth(width - 160, 2);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    if (cliList.length > 0) setCliList(sortUsers(cliList, sortBy));
  }, [sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const updateCliData = async (data, indexToDelete) => {
    setOpenCreateModal(false);
    setLoading(true);
    try {
      const prevList = [...cliList];
      let newList = [...cliList];
      const objToSave = {
        cliCode: data.cliCode,
        isDefault: data.isDefault,
      };

      if (data.isDefault === CLI_DEFAULT_OPTIONS.yes) {
        newList = prevList.map((item) => {
          return {
            ...item,
            isDefault: CLI_DEFAULT_OPTIONS.no,
          };
        });
      }
      if (data.action === CLI_ACTIONS.add) {
        newList.push(objToSave);
      } else if (data.action === CLI_ACTIONS.update) {
        newList.splice(selectedItem.index, 1, objToSave);
      } else if (data.action === CLI_ACTIONS.delete) {
        newList.splice(indexToDelete, 1);
      }

      await api.updateCliItem(newList);
      setSelectedItem(null);
      setCliList(newList);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const TableRow = ({ item, index }) => {
    return (
      <tr>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
          <img
            src={EditIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => {
              setSelectedItem({ item, index });
              setOpenCreateModal(true);
            }}
          />
        </td>
        <td className="ellipsis" title={item.cliCode}>
          {item.cliCode}
        </td>
        <td className="ellipsis" title={item.isDefault}>
          {item.isDefault === CLI_DEFAULT_OPTIONS.yes ? <ViewCheckBox id="filter" bgColor="green" /> : "-"}
        </td>
        <td
          className="icon"
          style={{
            textOverflow: "visible",
            width: "45px",
            minWidth: "45px",
            maxWidth: "45px",
          }}
        >
          <img
            src={TrashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => {
              updateCliData({ ...item, action: CLI_ACTIONS.delete }, index);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Body className="table-full-width desktop-noScroll">
                <Card className="strpied-tabled-with-hover w-50">
                  <Card.Body className="table-full-width desktop-noScroll">
                    <div className="d-flex justify-content-between align-items-center px-2 pe-4">
                      <span className="fw-bold">
                        <strong>
                          {t("cliList")} ({formatNumber(cliList.length)})
                        </strong>
                      </span>
                      <i
                        className="fas fa-plus-circle fa-lg cursor-pointer"
                        alt={t("addCli")}
                        title={t("addCli")}
                        onClick={() => setOpenCreateModal(true)}
                      />
                    </div>
                    <div className="table-responsive pendingReleaseTable">
                      <Table>
                        <thead ref={componentRef}>
                          <tr>
                            <th />
                            <HeaderItem
                              width={tdWidth}
                              ItemKey={"cliCode"}
                              title={"CLIA Code"}
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                            />
                            <HeaderItem
                              width={tdWidth}
                              ItemKey={"isDefault"}
                              title={"Default"}
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                            />
                          </tr>
                        </thead>
                        <tbody>
                          {cliList &&
                            cliList.map((item, i) => {
                              return <TableRow key={i} item={item} index={i} />;
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>

          {openCreateModal && (
            <AddGeneralSettingsModal
              handleClose={() => {
                setOpenCreateModal(false);
              }}
              handleSave={updateCliData}
              selectedItem={selectedItem?.item || null}
            />
          )}
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default GeneralSettings;
