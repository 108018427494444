import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Card, Table, Row, Col } from "react-bootstrap";
import EmployeeModal from "components/Employee/EmployeeModal";
import api from "api";
import { AppContext } from "context/app-context";
import { formatDOB, getVaccinated, getValidDep } from "utils";
import { useAsync } from "react-use";
import { set } from "date-fns";
import { checkValidity } from "utils";
import LocationModal from "./LocationModal";
import SubAgentsModal from "./SubAgentsModal";

const ImportErrorModal = ({
  errData,
  successData,
  handleClose,
  title,
  count,
  member,
  location,
  subAgents,
}) => {
  const appContext = useContext(AppContext);
  const [newUser, setNewUser] = useState(null);
  const [counter, setCounter] = useState(count);
  const [empData, setEmpData] = useState(errData);
  const [newIds, setNewIds] = useState([]);

  const handleEditUser = (index, user) => {
    const obj = { ...user, isNew: true, index: index };
    setNewUser(obj);
  };
  const dataUpdated = (user) => {
    const errDatas = [...empData];
    const findVal = errDatas[user.index];
    const { data } = findVal;
    Object.assign(data, { status: true });
    errDatas.splice(user.index, 1, findVal);
    setEmpData(errDatas);
  };

  const createUser = async (user) => {
    setCounter((counter) => counter++);
    try {
      const resultID = await api.newEmployee(user, null, appContext.user);
      // get the new employee id and push into array to set as selected
      if (resultID) {
        setNewIds((prevIds) => [...prevIds, resultID.id]);
      }

      dataUpdated(user);
      setNewUser(null);
    } catch (error) {
      console.log("ERROR:User-", error);
      alert(error.message);
    }
  };

  const OnHandleLocation = async (loc) => {
    const findVal = appContext.locations.find(
      (f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name)
    );
    if (findVal) {
      appContext.showErrorMessage("Facility/Agent already exists");
      return;
    }

    try {
      await api.newLocations(loc, appContext.user);
      dataUpdated(loc);
      setNewUser(null);
      appContext.resetLocations();
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(err.message);
    }
  };

  const OnHandleLocationSA = async (loc) => {
    const findVal = appContext.subAgents.find(
      (f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name)
    );
    if (findVal) {
      appContext.showErrorMessage("Sub Agent already exists");
      return;
    }

    try {
      await api.newSubAgent(loc, appContext.user);
      dataUpdated(loc);
      setNewUser(null);
      appContext.resetSubAgents();
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(err.message);
    }
  };

  const TableRow = ({ err, index }) => {
    const { data } = err;

    return (
      <tr>
        <td>{index + 1}</td>
        <td>{err.message}</td>
        {data && (
          <td>
            {!data.status ? (
              <i
                className="fas fa-edit"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.color = "blue";
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                id={data.id}
                onClick={(e) => handleEditUser(index, err.data)}
              />
            ) : (
              <>
                <div>
                  <input
                    id={"id"}
                    className="checkbox-custom"
                    name="checkbox-1"
                    type="checkbox"
                    checked
                  />
                  <label
                    htmlFor={"id"}
                    className="checkbox-custom-label mb-0"
                  />
                </div>
              </>
            )}
          </td>
        )}
      </tr>
    );
  };
  const successMessage = () => {
    if (successData.length === 0) return "No Data Imported";
    return `Successfully Imported ${successData.length} ${title}${
      successData.length === 1 ? "" : "s"
    }`;
  };

  return (
    <>
      <Modal
        backdrop="static"
        show
        animation={true}
        onHide={() => handleClose(newIds)}
        size={"lg"}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Import {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="marginTop0">
          <>
            <h4 style={{ margin: 0, marginBottom: "10px" }}>
              {successMessage()}
            </h4>
            {empData.length > 0 && (
              <div style={{ flex: 1 }}>
                <Row>
                  <Col md="12">
                    <Card className="strpied-tabled-with-hover">
                      <Card.Body>
                        <div className="form-group-wrapper d-block">
                          <div
                            className="table-responsive"
                            style={{ overflow: "hidden" }}
                          >
                            <span>Errors Details</span>
                            <Table className="table-hover table-striped">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}>Sr#</th>
                                  <th>Error</th>
                                </tr>
                              </thead>
                              <tbody>
                                {empData.length > 0 &&
                                  empData.map((err, index) => (
                                    <TableRow
                                      err={err}
                                      key={index}
                                      index={index}
                                    />
                                  ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </>
        </Modal.Body>

        <Modal.Footer className="flex-row-reverse">
          <Button
            className="headerButton btn-fill"
            variant="secondary"
            onClick={() => handleClose(newIds)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {newUser && member && (
        <EmployeeModal
          user={newUser}
          handleClose={() => {
            setNewUser(null);
          }}
          handleSave={createUser}
          importMember={true}
          users={appContext.employees}
        />
      )}
      {newUser && location && (
        <LocationModal
          handleClose={() => {
            setNewUser(null);
          }}
          selectItem={newUser}
          handleSave={OnHandleLocation}
          appContext={appContext}
        />
      )}
      {newUser && subAgents && (
        <SubAgentsModal
          handleClose={() => {
            setNewUser(null);
          }}
          selectItem={newUser}
          handleSave={OnHandleLocationSA}
          appContext={appContext}
        />
      )}
    </>
  );
};

export default ImportErrorModal;
