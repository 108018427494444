/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:eae9c7ca-9bbe-49a1-bb26-a82581b5f2b1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_M4b9pstUs",
    "aws_user_pools_web_client_id": "6mkgf0lanqqqtphb1ps1pgba4i",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://pnn7sl4eezb77h6qrub6fx3uii.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ztdqomvf2zhs7kvabbnco3shry",
    "aws_cloud_logic_custom": [
        {
            "name": "apif79b844d",
            "endpoint": "https://7ni3xmd405.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "api4af2df69",
            "endpoint": "https://uhmu1gatv7.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "ltcs957227e4e170496f986198549508735a110457-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
