import React, { useState, useEffect, useRef, useMemo } from "react";
import logoImage from "../../assets/img/ltc_logo.png";
import companyLogo from "../../assets/img/tackl_logo.png";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { formateDateLL, formateDatellll } from "utils";
import { INSURANCE_PROVIDER, MEDICATRE_IDS, CONFIG } from "constant";
import { convertToLower, isValidEmail } from "utils";
import Select, { createFilter } from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import { isValidIDNumber } from "utils";
import { convertToUpper } from "utils";
import RadioButton from "components/RadioButton/RadioButton";
import { Form } from "react-bootstrap";

const OrderTestStepOne = (props) => {
  const { location, handleProcced, errorMessageText, clearErrorMessage, subAgent } = props;
  const [newUser, setNewUser] = useState({});
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showCompanyLogo, setCompanyLogo] = useState(false);

  useEffect(() => {
    if (location && location.company) {
      const patternString = "tackl";
      const pattern = new RegExp(patternString, "i");
      const match = location?.company?.name?.match(pattern);
      if (match) {
        setCompanyLogo(true);
      }
    }
  });
  useEffect(() => {
    showErrorMessage(errorMessageText?.message);
  }, [errorMessageText]);

  const insuranceObject = useMemo(() => {
    if (newUser.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  const showErrorMessage = (message) => {
    setError(message);

    const handleClick = () => {
      setError("");
      clearErrorMessage("");
      document.removeEventListener("click", handleClick);
    };

    setTimeout(() => {
      if (message || error) {
        document.addEventListener("click", handleClick);
      }
    }, 1000);
  };

  const handleSubmit = (newUser) => {
    if (!newUser.insuranceCompany) {
      showErrorMessage("please Select Insurance provider");
      return;
    }
    if (
      newUser.insuranceCompany &&
      MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 &&
      !newUser.insuranceGroupId.trim()
    ) {
      showErrorMessage("Group ID is Required");
      return;
    }

    if (!newUser.medicalNo || !newUser.medicalNo.trim()) {
      showErrorMessage(`${newUser.insuranceGroupId ? "ID Number" : "Medicare Number"}  is required`);
      return;
    }

    if (!isValidIDNumber(newUser.medicalNo)) {
      showErrorMessage(`${newUser.insuranceGroupId ? "ID Number" : "Medicare Number"}  is invalid`);
      return;
    }

    if (newUser.isMedicare === "N" && newUser.insuranceGroupId) {
      Object.assign(newUser, { ...newUser, medicareNo: "" });
    }

    handleProcced(newUser);
  };

  const showCompanyTitle = () => {
    if (location && location.company) {
      return (
        <>
          <h4 className="w-100 text-center mt-4 fs-4 fw-bold">{location.company.name}</h4>
        </>
      );
    }
    return "";
  };

  const showLocationTitle = () => {
    if (
      (location && CONFIG.generalFacility !== location.id) ||
      (subAgent && location && CONFIG.generalSubAgent !== location.id)
    ) {
      return <h4 className="w-100 text-center mt-4 fs-4 fw-bold">{location.name}</h4>;
    }
    return "";
  };

  return (
    <div className="registerFormWrapper">
      <div className="registerWrapper pre-register-comp">
        <div className="text-center">
          <img src={showCompanyLogo ? companyLogo : logoImage} alt="logo" className="logo-width" />
        </div>
        <div className="codeScannerWrapper codeScannerPhoneInput">
          <div className="registered-successful text-start">
            <h4 className="w-100 text-center mt-4 fs-4 fw-bold">Order Free COVID-19 Tests</h4>
            {showCompanyTitle()}
            {showLocationTitle()}
            <div className="form-group-wrapper me-2 mt-4">
              <label className="mb-1">Primary Insurance:</label>
              <Select
                options={INSURANCE_PROVIDER}
                blurInputOnSelect={true}
                menuPlacement="auto"
                placeholder="Select Primary Insurance"
                className="w-100"
                value={insuranceObject}
                onChange={(e) =>
                  setNewUser({
                    ...newUser,
                    insuranceCompany: e.value,
                    insuranceCompanyCode: e.label,
                    insuranceGroupId: "",
                    medicalNo: "",
                    medicareNo: "",
                    isMedicare: "N",
                  })
                }
              />
            </div>

            {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
              <>
                <div className={!newUser.isNew ? "first-last-name-wrapper mt-4" : " mt-4"}>
                  <div className="form-group-wrapper me-2">
                    <label className="mb-1">Group ID:</label>
                    <input
                      value={newUser.insuranceGroupId}
                      className="modalInput"
                      maxLength={16}
                      placeholder="Enter Group ID"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          insuranceGroupId: convertToUpper(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className={!newUser.isNew ? "first-last-name-wrapper" : ""}>
                  <div className="form-group-wrapper me-2 mt-4">
                    <label className="mb-1">Insurance Number#:</label>
                    <input
                      value={newUser.medicalNo}
                      className="modalInput"
                      maxLength={16}
                      placeholder="Enter Insurance Number"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          medicalNo: convertToUpper(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1 && (
              <div className={!newUser.isNew ? "first-last-name-wrapper mt-4" : "mt-4"}>
                <div className="form-group-wrapper me-2">
                  <label className="modalLineHeaders">Medicare#:</label>
                  <input
                    value={newUser.medicalNo}
                    className="modalInput"
                    maxLength={16}
                    placeholder={
                      MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"
                    }
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        medicalNo: convertToUpper(e.target.value),
                        medicareNo: convertToUpper(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            )}

            {/* <div className="form-group-wrapper mt-5 mb-5">
              <label className="mb-1">ID#:</label>
              <input
                value={newUser.medicalNo}
                className="modalInput"
                maxLength={16}
                placeholder="Medicare/Medicaid Number"
                style={{
                  width: "100%",
                }}
                onChange={(e) =>
                  setNewUser({ ...newUser, medicalNo: e.target.value })
                }
              />
            </div> */}

            {error && <ErrorMessage error={error} handleChange={() => setError("")} timeLess />}
            <div className="mt-4 w-50 mx-auto">
              <button
                type="submit"
                className="text-uppercase w-100 btn signup-btn"
                onClick={() => handleSubmit(newUser)}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTestStepOne;
