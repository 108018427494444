import { CONFIG } from "constant";
import { formatNumber } from "utils";

const stringData = {
  clients: CONFIG.isLabType ? "Patients" : "Members",
  client: CONFIG.isLabType ? "Patient" : "Member",
  importClientDataTitle: CONFIG.isLabType ? "Import Patient Data" : "Import Member Data",
  loadClient: CONFIG.isLabType ? "Upload Patients" : "Load Member",
  selectOneClientError: CONFIG.isLabType ? "Please select one patient" : "Please select one member",
  clientTestAvailableUpdate: CONFIG.isLabType
    ? "{total} Patient(s) Test Available Successfully updated"
    : "{total} Member(s) Test Available Successfully updated",
  createLoginForClient: CONFIG.isLabType ? "Create a Login for Patient?" : "Create a Login for Member?",
  deleteClient: "Confirm Deletion",
  updateClient: CONFIG.isLabType ? "Update Patient Data" : "Update Member Data",
  memberProfile: CONFIG.isLabType ? "Patient Profile" : "Member Profile",
  clientName: CONFIG.isLabType ? "Patient name" : "Member Name",
  requestClientRemoval: CONFIG.isLabType
    ? "Are you sure, you want to delete the selected {total} patient"
    : "Are you sure, you want to delete the selected {total} member",
  filter: "Filter",
  selectAll: "Select All",
  deselectAll: "Deselect All",
  denialClaim: "Claim Denied",
  submitClaim: "Submit Claim",
  createClaim: "Create Claim",
  claimCancel: "Claim Cancel",
  claimAmount: "Claim Amount",
  procedureCode: "Procedure Code",
  employeeClaim: "Claims",
  diagnosisCode: "Diagnosis Code",
  delete: "Delete",
  checkEligibility: "Check Eligibility",
  provider: "Provider",
  settings: "Settings",
  generalSettings: "General Settings",
  providerCreatedSuccessfully: "Provider Created Successfully",
  providerUpdatedSuccessfully: "Provider Updated Successfully",
  eligibilityDetails: "Eligibility Details",
  createClient: CONFIG.isLabType ? "New Patient" : "Create New Member",
  loadClientData: CONFIG.isLabType ? "Upload Patients" : "Load Member Data",
  createMember: CONFIG.isLabType ? "New Patient" : "Create",
  loadOrderData: "Load Order Data",
  importTracking: "Import Tracking ID",
  clientData: CONFIG.isLabType ? "Patient Data" : "Member Data",
  personalize: "Edit Columns",
  orderTest: "Order Test",
  orderConfirmationDescription: "Are you sure you want to place an order for {total} COVID-19 Antigen Tests",
  orderSuccessMsg: "You have successfully placed an order for {total} COVID-19 Antigen tests",
  orderFailedMsg:
    "You are no longer eligible for this program. Please verify your insurance information or contact your insurance carrier and try again",
  multipleOrderSuccessMsg: CONFIG.isLabType
    ? "Selected patients have successfully placed an order for {total} COVID-19 Antigen tests"
    : "Selected members have successfully placed an order for {total} COVID-19 Antigen tests",
  multipleOrderFailedMsg: CONFIG.isLabType
    ? "Selected patients are no longer eligible for this program. Please verify their insurance information or contact insurance carrier and try again"
    : "Selected members are no longer eligible for this program. Please verify their insurance information or contact insurance carrier and try again",
  confirmAutoShipment:
    "Are you sure you want to enable SafeCamp LTC to auto ship {total} tests to your address on the first of every month",
  action: "Action",
  scheduleNotAssigned: "Schedule Not Assigned",
  clientCreatedSuccessfully: CONFIG.isLabType ? "Patient created successfully" : "Member created successfully",
  clientUpdatedSuccessfully: CONFIG.isLabType ? "Patient updated successfully" : "Member updated successfully",
  clientDeletedSuccessfully: CONFIG.isLabType
    ? "You have successfully deleted the selected patient"
    : "You have successfully deleted the selected member",
  clientHaveClaims: "Patient Have Claims ",
  clientAlreadyExistOnRow: CONFIG.isLabType ? "Patient Already exist on row" : "Member Already exist on row",
  facilities: "Facilities/Agents",
  dashboard: "Dash Board",
  reports: "Reports",
  claims: "Claims",
  billing: "Billing",
  subagents: "Sub Agents",
  createSubAgents: "Create New Sub Agent",
  createFacility: "Create New Facility/Agent",
  loadLocationData: "Import Facility/Agent",
  locCsvSampleName: "Facilities_Agents CSV sample",
  SACsvSampleName: "Sub Agent CSV sample",
  eligibilityCsvSampleName: "eligibility_sample_csv",
  orderSampleCsv: "Order_Sample",
  eligibilityStatusFile: "Eligibility_Status_File",
  downloadCsvSample: "Download CSV Sample",
  loadSubAgentsData: "Import Sub Agents",
  eligibilityStatus: "Eligibility Status",
  companies: "Clients",
  createCompany: "Create New Client",
  bulkEligibility: "Bulk Eligibility",
  orders: "Orders",
  totalOrders: "Orders",
  submitOrder: "Submit Order",
  close: "Close",
  users: "System Users",
  createAUser: "Create a User",
  crews: "Crews",
  showUnconfirmedUsers: "Show Unconfirmed Users",
  liveSearch: "Live Search",
  exportToExcel: "Export To Excel",
  srNo: "Sr#",
  qtyOrdered: "Qty Ordered",
  orderStatus: "Order Status",
  personalizeColumns: "Edit Columns",
  emailMsgToCreatedClient: "",
  emptyFile: "Empty File",
  insuranceProvider: "Insurance Provider",
  selectinsuranceProvider: "Select Insurance Provider",
  insuranceProviderRequired: "Insurance Provider is required",
  procedureCodeRequired: "Procedure Code is required",
  diagnosisCode: "Diagnosis Code",
  modifireCode: "Modifier Code",
  typeConfirm: "Please type Confirm",
  quanity: "Quanity",
  create: "Create",
  cliList: "CLIA List",
  updateClaimStatus: "Update Status",
  addCli: "Add CLIA",
  update: "Update",
  Eligibility: "Eligibility",
  coverageDetails: "Coverage Details",
};

export const t = (key, obj) => {
  let val = stringData[key];
  if (obj) {
    Object.entries(obj).forEach(([key, value]) => {
      val = val.replace(`{${key}}`, formatNumber(value));
    });
  }
  return val;
};
