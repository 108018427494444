import React, { useState, useEffect, useRef, useContext } from "react";
import logoImage from "../../assets/img/ltc_logo.png";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { convertToLower, isValidEmail, getPhoneNo } from "utils";
import api from "api";
import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";
import { useHistory } from "react-router-dom";
import RadioButton from "components/RadioButton/RadioButton";

const RegisterStepOne = (props) => {
  const [newUser, setNewUser] = useState({});
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);

  const appContext = useContext(AppContext);
  let history = useHistory();

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleSubmit = async () => {
    if (!newUser.phone) {
      showErrorMessage("Phone is required");
      return;
    }
    if (newUser.phone && phoneError) {
      showErrorMessage("Invalid Phone Number");
      return;
    }
    const [countryCode, phoneNo] = getPhoneNo(newUser.phone);

    try {
      setLoading(true);

      const response = await api.getRegisterData(phoneNo);
      let obj = response;
      if (!response.employee) {
        obj = {
          employee: { countryCode: countryCode, phoneNumber: phoneNo },
          locations: response.locations || [],
        };
      }
      history.push("/order-test-detail", { data: obj });
    } catch (err) {
      console.log("Error ", err);
    }

    setLoading(false);
  };
  console.log("newUSerPrg", newUser);
  return (
    <div className="registerFormWrapper">
      <div className="registerWrapper pre-register-comp">
        <div className="text-center">
          <img src={logoImage} alt="logo" className="logo-width" />
        </div>
        <div className="codeScannerWrapper codeScannerPhoneInput">
          <div className="registered-successful">
            <h4 className="w-100 text-center mt-4">
              <b>Long Term Care Solution</b>
            </h4>
            <h4 className="w-100 text-center mt-4">
              <b>Register Your Account</b>
            </h4>

            <div className="w-50">
              <label className="my-0 modalLineHeaders">
                Are You Register for
              </label>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <RadioButton
                  name="register"
                  id="ind"
                  label="Individual"
                  checked={newUser?.register === "Individual" ? true : false}
                  handleChange={(e) =>
                    setNewUser({
                      ...newUser,
                      register: e.target.checked ? "Individual" : "",
                    })
                  }
                />
                <RadioButton
                  name="register"
                  id="cmop"
                  checked={newUser?.register === "Company" ? true : false}
                  label="Company"
                  handleChange={(e) =>
                    setNewUser({
                      ...newUser,
                      register: e.target.checked ? "Company" : "",
                    })
                  }
                />
              </div>
            </div>

            {error && (
              <div className="error-message">
                <h6>{error}</h6>
              </div>
            )}
            <div className="mt-2 w-50 mx-auto">
              <button
                type="submit"
                className="text-uppercase w-100 btn signup-btn"
                onClick={handleSubmit}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default RegisterStepOne;
