import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styles from "components/InputField/InputStyle.module.css";
import { debounce } from "debounce";
import { ViewportList } from "react-viewport-list";

const InputSelectDropDown = (props) => {
  const {
    label,
    type,
    placeholder,
    handleChange,
    labelStyle,
    inputStyle,
    value,
    maxlength,
    groupWrapper,
    optionList,
    readOnly,
  } = props;

  const [input, setInput] = useState(value || "");
  const [description, setDescription] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const handleTypeDelay = useCallback(
    debounce((data) => {
      handleChangeInputText(data);
    }, 100),
    []
  );

  useEffect(() => {
    if (value) {
      const option = optionList.find((opt) => opt.value.toLowerCase().includes(value.toLowerCase()));
      setInput(value);
      setDescription(option?.label || "");
    } else {
      setDescription("");
    }
  }, [value]);

  const handleChangeInputText = (data) => {
    if (!data || !data.trim()) {
      setFilteredList([]);
      return;
    }
    const options = optionList.filter(
      (opt) =>
        opt.label.toLowerCase().includes(data.toLowerCase()) || opt.value.toLowerCase().includes(data.toLowerCase())
    );
    setFilteredList(options);
  };

  const optionRow = (option, index) => {
    return (
      <li
        key={index}
        value={option.value}
        className="listOption"
        onClick={() => {
          setInput(option.value);
          handleChange(option);
          setFilteredList([]);
        }}
        title={option.label}
      >
        <span className="fw-bold optListContent">{option.value}</span>
        <span className="fw-light optListContent">{option.label}</span>
      </li>
    );
  };

  return (
    <Form.Group className={`form-group-wrapper ${groupWrapper}`}>
      {label ? <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>{label}</Form.Label> : ""}
      <div className="position-relative w-100">
        <Form.Control
          type={type}
          maxLength={maxlength}
          title={description}
          value={input}
          readOnly={readOnly}
          placeholder={placeholder}
          className={`${styles.inputStyling} ${inputStyle}`}
          onChange={(e) => {
            const val = e.target.value;
            setInput(val);
            setDescription("");
            handleTypeDelay(val);
          }}
        />
        {filteredList && !readOnly && filteredList.length > 0 && (
          <ul
            className="optionListWrapper"
            onBlur={() => {
              setInput("");
              setFilteredList([]);
            }}
          >
            <ViewportList items={filteredList} overflowAnchor="auto" withCache={true}>
              {(item, index) => optionRow(item, index)}
            </ViewportList>
          </ul>
        )}
      </div>
    </Form.Group>
  );
};

export default InputSelectDropDown;
