import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Card, Row, Col } from "react-bootstrap";
import { formatOrderDate } from "utils";
import API from "api";
import Loader from "components/Loader/Loader";
import { Audit_Parse } from "constant";
import { Storage } from "aws-amplify";
import moment from "moment";
import EligibilityDetailsModal from "../CheckEligibility/EligibilityDetailsModal";
import { ActionEventType } from "constant";
import { CONFIG } from "constant";
import { claimLogsTitle } from "utils";
import { usersNo } from "constant";
import { applyCustomOrder } from "constant";
import { fieldLabel } from "constant";
import { AdditionalKeys } from "constant";
import { insuranceProviderFormatter } from "utils";
import { objTypes } from "constant";
import { formatViewUpdateClaimList } from "utils";
import FullImageView from "components/Image/FullPageImageView";

const OrderLogsModal = (props) => {
  const { show, handleClose, order, appContext, claim } = props;
  const [tracking, setTracking] = useState("");
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eligibilityDetailsData, setEligibilityDetailsData] = useState(null);
  const [ImageView, setImageView] = useState("");

  useEffect(() => {
    fetchTrackingLink();
  }, []);

  const getFileFromStorage = async (file, setImage, dir) => {
    if (file) {
      try {
        const path = await Storage.get(`${dir}${file}`);
        setImage(path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  const handleOpenDetailModal = async (item) => {
    if (item.apiData) {
      const data = JSON.parse(item.apiData);
      if (data.fileName) {
        try {
          let res = await Storage.get(`${data.fileName}`, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });

          res.Body.text().then((string) => {
            // handle the String data return String
            const apidata = JSON.parse(string);
            setEligibilityDetailsData(apidata?.elig || null);
          });
        } catch (err) {
          console.log("Error:-", err.message);
          appContext.showErrorMessage(err.message);
        }
      } else {
        setEligibilityDetailsData(data?.elig || null);
      }
    } else {
      setEligibilityDetailsData(item);
    }
  };

  const fetchTrackingLink = async () => {
    if (order) {
      setLoading(true);
      try {
        const logData = await API.getOrderLogs(order.id, claim ? "c" : "o");
        const items = logData.Items.filter((l) => l.eventType === "Shipped");
        if (items.length > 0) {
          if (items[0].tracking_url) {
            setTracking(items[0].tracking_url);
          }
        }
        const logsFilter = logData.Items.filter((f) => f.eventType !== "Eligibility");
        const eligObj = logData.Items?.filter((f) => f.eventType === "Eligibility");
        if (eligObj) logsFilter.push(...eligObj);

        const viewList = logsFilter
          .map((m) => ({
            ...m,
            createdAt: m.createdAt?.replace("2022-02", "2023-02"),
          }))
          .sort((a, b) => {
            const testerB = b.createdAt;
            const testerA = a.createdAt;
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
        setLogs(formatViewUpdateClaimList(viewList));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("Error:-", err);
      }
    }
  };

  const parseEventData = (event, val) => {
    const EVENT_TYPES = "DeliveredShippedMovedDeclinedPickedPackedReturnReturned";
    if (EVENT_TYPES.includes(event)) {
      return formatOrderDate(val);
    }
    if (event.includes("Eligibility")) {
      return "Passed Eligibility Check";
    }
    return val;
  };

  const handleClickEvent = (item) => {
    if (item.eventType === "Eligibility") handleOpenDetailModal(item);
    if (item.eventType === "OrderShipped" && item.trackingNumber) {
      window.open(`https://www.stamps.com/tracking-details/?t=${item.trackingNumber.replace(/\D/g, "")}`, "_blank");
    }
    if (item.file) getFileFromStorage(item.file, setImageView, "ReturnedStatus/");
  };

  const TableCollapse = ({ objKeys, newRecord, oldRecord }) => {
    return (
      <>
        {fieldLabel[objKeys] &&
        newRecord[objKeys][objTypes[objKeys] || "S"] !== oldRecord[objKeys][objTypes[objKeys] || "S"] ? (
          <tr className="expended-row">
            <td style={{ textAlign: "center" }}>{fieldLabel[objKeys]}</td>
            <td style={{ textAlign: "center" }}>
              {objKeys === "insuranceCompany"
                ? insuranceProviderFormatter(oldRecord[objKeys][objTypes[objKeys] || "S"])
                : objKeys === "eligibilityStatus"
                ? oldRecord[objKeys] && oldRecord[objKeys]["M"]["message"][objTypes[objKeys] || "S"]
                : oldRecord[objKeys] && oldRecord[objKeys][objTypes[objKeys] || "S"]}
            </td>
            <td style={{ textAlign: "center" }}>
              {objKeys === "insuranceCompany"
                ? insuranceProviderFormatter(newRecord[objKeys][objTypes[objKeys] || "S"])
                : objKeys === "eligibilityStatus"
                ? newRecord[objKeys] && newRecord[objKeys]["M"]["message"][objTypes[objKeys] || "S"]
                : newRecord[objKeys] && newRecord[objKeys][objTypes[objKeys] || "S"]}
            </td>
          </tr>
        ) : (
          <></>
        )}
      </>
    );
  };

  const TableRow = ({ item, sr }) => {
    const [open, setOpen] = useState(null);
    return (
      <>
        <tr>
          <td className="tdWidth10">
            {item.changeObject && (
              <i
                onClick={() => setOpen(!open ? item.changeObject : null)}
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.color = "#8B0000";
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                className={open ? "fas fa-minus" : "fas fa-plus"}
              />
            )}
          </td>
          <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
            {sr}
          </td>
          <td className={`ellipsis `}>{formatOrderDate(item.createdAt)}</td>
          <td
            className={`ellipsis `}
            style={{
              cursor: ActionEventType.includes(item.eventType) ? "pointer" : "",
            }}
            onClick={() => handleClickEvent(item)}
          >
            {Audit_Parse[item.eventType]}
          </td>
          <td
            className={`ellipsis`}
            title={
              (item.eventType !== "ClaimCreated" && claimLogsTitle(item.message)) ||
              parseEventData(item.eventType, Audit_Parse[item.eventData] || item.eventData)
            }
          >
            {parseEventData(item.eventType, Audit_Parse[item.eventData] || item.eventData)}
          </td>
          <td className={`ellipsis`}>{item.userName || "Admin"}</td>

          {(appContext.user?.phone_number === "+17777777777" || appContext.user?.phone_number === usersNo.ibrahim) && (
            <td
              onClick={async () => {
                await API.deleteLogRecord(item.id);
                appContext.showSuccessMessage("delete Successfully");
                await fetchTrackingLink();
              }}
            >
              <i className="fas fa-trash cursor-pointer" />
            </td>
          )}
        </tr>
        {open && (
          <tr>
            <td colSpan={"12"} className="expended-table-child">
              <Card className="striped-table-card">
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive overFlow-y-hidden">
                      <Table className="table expandedTableChild">
                        <thead>
                          <tr>
                            <th className="fw-bold" style={{ textAlign: "center" }}>
                              Field
                            </th>
                            <th style={{ textAlign: "center" }}>Old Value</th>
                            <th style={{ textAlign: "center" }}>New Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {open.newRecord &&
                            open.oldRecord &&
                            applyCustomOrder(Object.keys(open.newRecord), Object.keys(fieldLabel))
                              .filter((f) => !AdditionalKeys.includes(f))
                              .map((m) => {
                                return (
                                  <TableCollapse objKeys={m} oldRecord={open.oldRecord} newRecord={open.newRecord} />
                                );
                              })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </td>
          </tr>
        )}
      </>
    );
  };

  return (
    <Modal backdrop="static" show={show} animation={true} onHide={() => handleClose()} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {claim ? "Claim Detail" : `Order Logs - ${order.orderId}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="table-responsive">
          {loading && <Loader />}
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="tdWidth10" />
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  Sr#
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Date</span>
                  </div>
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Action</span>
                  </div>
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Event</span>
                  </div>
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">User</span>
                  </div>
                </th>
                {(appContext.user?.phone_number === "+17777777777" ||
                  appContext.user?.phone_number === usersNo.ibrahim) && <th className="tdWidth10"></th>}
              </tr>
            </thead>
            <tbody>
              {logs &&
                logs.map((o, index) => {
                  return <TableRow key={index} item={o} sr={logs.length - index} />;
                })}
            </tbody>
          </Table>
        </div>
        <div>
          {tracking && (
            <a href={tracking} target="_blank">
              Live Tracking <i className="fa fa-external-link" />
            </a>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
      {eligibilityDetailsData && (
        <EligibilityDetailsModal handleClose={() => setEligibilityDetailsData(null)} data={eligibilityDetailsData} />
      )}
      {ImageView && <FullImageView image={ImageView} handleClose={() => setImageView("")} />}
    </Modal>
  );
};

export default OrderLogsModal;
