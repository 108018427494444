import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const CameraModal = (props) => {
  const { setImage, setCaptureImage, cssClass } = props;
  const videoRef = useRef();
  const canvasRef = useRef();
  const [messageError, setMessageError] = useState("");

  const constraints = { video: true, audio: false };

  const base64ImgToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  };

  const snapShot = async () => {
    try {
      const context = canvasRef.current.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

      const fileName = `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_capturedImage.png`;
      const base64Image = canvasRef.current.toDataURL("image/png");
      const file = await base64ImgToFile(base64Image, fileName);

      setImage(file);
      handleClose();
    } catch (error) {
      console.log(error);
      setCaptureImage(false);
    }
  };

  useEffect(() => {
    const connectCamera = async () => {
      try {
        await navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        });
      } catch (error) {
        console.log(error);
        setMessageError(`${error}`);
      }
    };

    connectCamera();
  }, []);

  const handleClose = async () => {
    await videoRef.current?.srcObject?.getVideoTracks().forEach((track) => track.stop());
    setCaptureImage(false);
  };

  return (
    <>
      {messageError ? (
        <SweetAlert
          show={messageError ? true : false}
          error
          title="Error"
          onConfirm={() => {
            setMessageError("");
            handleClose();
          }}
        >
          {messageError}
        </SweetAlert>
      ) : (
        <Modal
          show={true}
          animation={true}
          onHide={handleClose}
          style={{ paddingLeft: "0" }}
          centered
          className={`${cssClass}`}
        >
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Capture Image
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              paddingTop: 0,
            }}
          >
            <div className="camera-popover-container">
              <>
                <video ref={videoRef} autoPlay style={{ width: "100%" }} />
                <canvas ref={canvasRef} style={{ display: "none" }} />
              </>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button style={{ marginBottom: 10 }} variant="secondary" className="modalButtons" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" className="headerButton btn-fill" onClick={snapShot}>
              Capture
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CameraModal;
